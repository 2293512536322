import React from "react";
import { withTranslation } from "react-i18next";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HeaderNavbar from "components/Navbars/HeaderNavbar";
import moment from 'moment';
import { get, checkToken } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import Loading from "../../components/Loading/Loading"
import RPTTransaction from "./RPTTransaction";
import RPTCommission from "./RPTCommission";
import RPTMemberTree_NLevel from "./RPTMemberTree_NLevel";
import withRouter from "../../components/common/withRouter";
import Profile from "views/Home/Profile";

class RPTHistoryCommon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            currentTab: props.params.type === "2" ? "ComHistory" : props.params.type === "3" ? "NLevel" : props.params.type === "4" ? "Profile" : "TransHistory",
            info: {
                "twoFA": true,
                "refCode": "",
                "balance": 0,
                "comPending": 0,
                "currentPackageAmount": 0,
                "totalF1BuyPackageDue": 0
            },
            bankInfo: {
                "nameBank": "",
                "accNumberBank": "",
                "cardholderNameBank": ""
            },
            countdownInterval: null,
            totalSeconds: null,
            seconds: 0,
            minutes: 0,
            hours: 0,
            countdown: 0
        }

    }
    componentDidMount = () => {
        document.body.classList.add('bg-other');
        document.body.classList.remove('bg-index');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.hanldeLoadBasicInfo();
    }
    componentWillUnmount = () => {
        if (this.state.countdownInterval) {
            clearInterval(this.state.countdownInterval);
        }
    }
    hanldeLoadBasicInfo = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/UserInfoBasic');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                if (response.data.data != null) {
                    let _info = response.data.data.userInfo;
                    _info.inHistory = true;
                    // _info.remainWithdrawHours = "00:00:00";
                    this.setState({
                        info: _info,
                        bankInfo: response.data.data.bankInfo
                    })
                    //CountDown time                    
                    // if (!this.state.countdownInterval) {
                    //     let countdownInterval = setInterval(() => {
                    //         let { info } = this.state;
                    //         this.countDown(info.remainWithdrawHours);
                    //     }, 1000);
                    //     this.setState({ countdownInterval: countdownInterval });
                    // }
                    localStorage.setItem("refCode", response.data.data.userInfo.refCode);
                    localStorage.setItem("TwoFA", response.data.data.userInfo.twoFA);
                    localStorage.setItem("Balance", response.data.data.userInfo.balance);
                    localStorage.setItem("CurrentPackageAmount", response.data.data.userInfo.currentPackageAmount);
                    localStorage.setItem("ComPending", response.data.data.userInfo.comPending);
                    localStorage.setItem("TotalF1", response.data.data.userInfo.totalF1BuyPackageDue);
                } else {
                    clearInterval(this.state.countdownInterval);
                }

            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    countDown = (timeString) => {
        if (timeString) {
            var { totalSeconds, hours, minutes, seconds, info } = this.state;

            if (totalSeconds === null) {
                let [hours, minutes, seconds] = timeString.split(':').map(Number);
                let _totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
                this.setState({
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds,
                    totalSeconds: _totalSeconds
                })
            }
            if (totalSeconds <= 0) {
                this.setState((prevState) => ({
                    info: { ...prevState.info, remainTimeString: "00:00:00", seconds: 0, countdown: info.countdown === undefined ? 0 : info.countdown + 1 }
                }));
                // return;
            } else {
                seconds--;
                if (seconds < 0) {
                    minutes--;
                    seconds = 59;
                }
                if (minutes < 0) {
                    hours--;
                    minutes = 59;
                }
                totalSeconds--;
                const countdownString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                this.setState({
                    totalSeconds: totalSeconds,
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds,
                })

                this.setState((prevState) => ({
                    info: { ...prevState.info, remainTimeString: countdownString, seconds: seconds, countdown: info.countdown === undefined ? 0 : info.countdown + 1 }
                }));
            }
        }
    }
    handleChangeTab = (tab) => {
        this.setState({ currentTab: tab })
    }
    render() {
        const { params } = this.props;
        const { isLoading, currentTab, info, bankInfo } = this.state
        return (
            <>
                <HeaderNavbar info={info} fluid={true} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} />
                <main className="cover-member" ref="main">
                    <section className="section">
                        <Tabs
                            defaultActiveKey={currentTab}
                            className="mb-3 justify-content-center"
                            onSelect={(k) => this.handleChangeTab(k)}
                        >
                            <Tab eventKey="TransHistory" title={t("Wallet")} >
                                <RPTTransaction currentTab={currentTab} info={info} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} type={params.type} bankInfo={bankInfo} handleChangeTab={this.handleChangeTab} />
                            </Tab>
                            <Tab eventKey="ComHistory" title={t("Bonus")} >
                                <RPTCommission currentTab={currentTab} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} info={info} type={params.type} />
                            </Tab>
                            <Tab eventKey="NLevel" title={t("Member")} >
                                <RPTMemberTree_NLevel currentTab={currentTab} type={params.type} info={info} />
                            </Tab>
                            <Tab eventKey="Profile" title={t("Profile")} >
                                <Profile currentTab={currentTab} type={params.type} info={info} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} />
                            </Tab>
                        </Tabs>
                    </section>
                </main>
                {isLoading && <Loading />}
                <ToastContainer pauseOnFocusLoss={false} />
            </>
        );
    }
}
export default withTranslation()(withRouter(RPTHistoryCommon));
