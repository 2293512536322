import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/common/withRouter";
import { postNoAuth, redirectToURL } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import i18n from "i18next";
import { renderMultipleLang } from "services/common";
import configs from "configs/configs";
import { io } from "socket.io-client";
class Error extends React.Component {
  constructor(props) {
    super(props);
    this.socket = io(configs.SocketUM);
  }
  componentDidMount() {
    // renderMultipleLang();
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });
    this.socket.on("UM", (msg) => {
      // console.log("Received message:", msg);
      if (msg.isUM) {
        redirectToURL("/um")
      }
    });
  }
  componentWillUnmount() {
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <main ref="main" className="outside">
          <section className="section section-shaped section-lg">
            <Container >
              <Row className="justify-content-center">
                <Col className="flex-center-center" >
                  <Card className="bg-login text-center  shadow ">
                    <CardBody className="px-lg-5 py-lg-5">
                      <img
                        className="img-um"
                        alt={t("UM")}
                        title={t("UM")}
                        src={require("assets/img/BIT.png")}
                      />
                      <div className="text-muted">
                        <div className="txt-error-login text-primary-color"><span className="">{t("Your login session has expired, please")}</span> <b>{t("Launch App To learn English")}</b></div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(withRouter(Error));
