import axios from 'axios';
import configs from 'configs/configs';
const apiService = axios.create({
    baseURL: configs.ApiRoot,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    },
});
const apiServiceFormData = axios.create({
    baseURL: configs.ApiRoot,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*'
    },
});
const apiServiceNoAuthen = axios.create({
    baseURL: configs.ApiRoot,
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    },
});
export const redirectToURL = (url) => {
    let location = new URL(window.location.href);
    let domain = `${location.protocol}//${location.hostname}`;
    if(location.port){
        domain +=":"+location.port;
    }
    window.location.href = domain + url;
    // window.location.href = configs.WebRoot + url;
};

export const checkToken = (data) => {
    let location = new URL(window.location.href);
    let domain = `${location.protocol}//${location.hostname}`;
    if(location.port){
        domain +=":"+location.port;
    }
try {
    if (data && data.errorCode) {
        if (data.errorCode === '401' || data.errorCode === '403' || data.errorCode === 403 || data.errorCode === 401) {
            localStorage.clear();
            if(domain===configs.WebRootEmail){
                redirectToURL("/e-login")
            } else{
                redirectToURL("/login")
            }
        }
    }
    if (data && data.status) {
        if (data.status === 401 || data.status === 403 || data.status === '401' || data.status === '403') {
            localStorage.clear();
            if(domain===configs.WebRootEmail){
                redirectToURL("/e-login")
            } else{
                redirectToURL("/login")
            }
        }
    }
} catch (e) {
    console.log(e);
    localStorage.clear();
    if(domain===configs.WebRootEmail){
        redirectToURL("/e-login")
    } else{
        redirectToURL("/login")
    }
}
};

// Hàm gọi API GET
export const get = (endpoint, data) => {
    return apiService.get(endpoint + paramsSerializer(data));
};
export const getNoAuth = (endpoint, data) => {
    return apiServiceNoAuthen.get(endpoint + paramsSerializer(data));
};
// Hàm gọi API POST
export const post = (endpoint, data) => {
    return apiService.post(endpoint, data);
};
export const postNoAuth = (endpoint, data) => {
    return apiServiceNoAuthen.post(endpoint, data);
};
// Hàm gọi API POST với FormData
export const postFormData = (endpoint, data) => {
    return apiServiceFormData.post(endpoint, data);
};
// Hàm gọi API PUT
export const put = (endpoint, data) => {
    return apiService.put(endpoint, data);
};

// Hàm gọi API DELETE
export const remove = (endpoint) => {
    return apiService.delete(endpoint);
};
function paramsSerializer(params) {
    // "Hide" the `answer` param
    let _params = '';
    if (params) {
        _params = "?" + Object.entries(Object.assign({}, params)).
            map(([key, value]) => `${key}=${value}`).
            join('&')

    }
    return _params;
}
var widget;
export function YouglishWidget() {

    console.log("YouglishWidget is called");

    const tag = document.createElement('script');
    tag.src = "https://youglish.com/public/emb/widget.js";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    <div id="widget-1"></div>
}
var views = 0, curTrack = 0, totalTracks = 0;
function onFetchDone(event) {

    console.log("onFetchDone is called");

    if (event.totalResult == 0) {
        alert("No result found");
    }
    else totalTracks = event.totalResult;
}

function onVideoChange(event) {
    curTrack = event.trackNumber;
    views = 0;
}

function onCaptionConsumed(event) {
    if (++views < 3) {
        widget.replay();
    } else if (curTrack < totalTracks) {
        widget.next();
    }
}

export function onYouglishAPIReady() {

    console.log("onYouglishAPIReady is called");
    const widget = new window.YG.Widget("widget-1", {
        width: 640,
        components: 9,
        events: {
            onFetchDone: onFetchDone,
            onVideoChange: onVideoChange,
            onCaptionConsumed: onCaptionConsumed,
        },
    });

    widget.fetch("courage", "english");
}