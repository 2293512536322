import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const hash = useLocation();
    return (
        <WrappedComponent
            {...props}
            params={params}
            hash={hash}
        />
    );
};

export default withRouter;