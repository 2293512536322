import React from "react";
import ReactDOM from "react-dom/client";
// core components
import HeaderNavbar from "components/Navbars/HeaderNavbar.js";
import { withTranslation } from "react-i18next";
import { get, post, checkToken, redirectToURL } from "services/apiServices";
import { t } from "i18next";
import { toast, ToastContainer } from "react-toastify";
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/words.scss";
import Loading from "../../components/Loading/Loading";
import ReactHowler from "react-howler";
// import { Howl } from 'howler';
import configs from "configs/configs";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
// import AudioPlayer from "./AudioPlayer"
// import { renderString, calculateTimeRemaining } from "services/common";
import axios from "axios";
import PhraseItem from "./PhraseItem";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { validateImage } from "image-validator";
import { FormatNumberNTC, formatNumber } from "services/common";
import { io } from "socket.io-client";
import cheerio from 'cheerio'
import nextpharse from 'assets/img/nextpharse.gif';
import dollar20 from 'assets/img/20.gif';
import dollar50 from 'assets/img/50.gif';
import dollar100 from 'assets/img/100.gif';
// Gneral Focus Hook
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    ref.current && ref.current.focus();
  };
  return { setFocus, ref };
};
export function openIosKeyboard() {
  const input = document.createElement("input");
  input.setAttribute("type", "text");
  input.setAttribute("style", "position: fixed; top: -100px; left: -100px;");
  document.body.appendChild(input);
  input.focus();
  // it's safe to remove the fake input after a 30s timeout
  setTimeout(() => {
    document.body.removeChild(input);
  }, 30 * 1000);
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showTopic: false,
      showTopicUser: false,
      isActiveTopic: window.innerWidth < 992 ? false : true,
      showPlaylist: false,
      isMobile: window.innerWidth < 992,
      listTopic: [],
      listUserTopic: [],
      listPharse: [],
      selectedTopic: null,
      selectedTopicUser: null,
      currentTrackIndex: 0,
      isPlayingSingleWord: false,
      isPlayingPlaylist: false,
      isPlayingPlaylistLoop: false,
      isPlayingPlaylistLoopCount: 0,
      selectedPhrasePlaylist: [],
      userPlaylist: [],
      modalUserPlaylist: [],
      selectedPhraseToAddPlaylist: null,
      showModalPlaylist: false,
      createPLName: "",
      showCreatePlaylist: false,
      errcreatePLName: "",
      selectedPlaylist: null,
      selectedPlaylistToEdit: null,
      confirmText: "",
      showConfirm: false,
      modalType: 0,
      currentTabTopic: true,
      showScrollTop: document.documentElement.scrollTop > 0,
      // showVietnamese: false,
      showSearchInput: false,
      showSearchABC: false,
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "0-9",
      ],
      alphabetSelected: "",
      searchKey: "",
      phraseRefs: [],
      currentSearchType: 0, //0 by topic, 1: by playlist, 2: by first char, 3: by input search,
      PageIndex: 1,
      PageSize: 20,
      TotalRows: 0,
      topicName: "",
      replayCount: 0,
      info: {
        twoFA: true,
        refCode: "",
        balance: 0,
        comPending: 0,
        currentPackageAmount: 0,
        totalF1BuyPackageDue: 0,
      },
      arrFlag: [
        {
          id: 0,
          img: "pronunciation",
          name: "IPA",
          propertiesTopic: "pronounce",
          propertiesPhrase: "pronounce",
          propertiesGroup: "phraseGroupPronounce",
          code: "en",
        },
        {
          id: 2,
          img: "flag_china",
          name: "中国",
          propertiesTopic: "chinaName",
          propertiesPhrase: "chinaMeans",
          propertiesGroup: "phraseGroupChinaMeans",
          code: "zh",
        },
        {
          id: 3,
          img: "flag_india",
          name: "भारत",
          propertiesTopic: "hindiName",
          propertiesPhrase: "hindiMeans",
          propertiesGroup: "phraseGroupHindiMeans",
          code: "hi",
        },
        {
          id: 14,
          img: "flag_spanish",
          name: "España",
          propertiesTopic: "spainName",
          propertiesPhrase: "spainMeans",
          propertiesGroup: "phraseGroupSpainMeans",
          code: "es",
        },
        {
          id: 4,
          img: "flag_france",
          name: "France",
          propertiesTopic: "franceName",
          propertiesPhrase: "franceMeans",
          propertiesGroup: "phraseGroupFranceMeans",
          code: "fr",
        },
        {
          id: 16,
          img: "flag_arabic",
          name: "عربي",
          propertiesTopic: "arabName",
          propertiesPhrase: "arabMeans",
          propertiesGroup: "phraseGroupArabMeans",
          code: "ar",
        },
        {
          id: 8,
          img: "flag_russia",
          name: "Русский",
          propertiesTopic: "russiaName",
          propertiesPhrase: "russiaMeans",
          propertiesGroup: "phraseGroupRussiaMeans",
          code: "ru",
        },
        {
          id: 9,
          img: "flag_portugal",
          name: "Português",
          propertiesTopic: "portugalName",
          propertiesPhrase: "portugalMeans",
          propertiesGroup: "phraseGroupPortugalMeans",
          code: "pt",
        },
        {
          id: 15,
          img: "flag_Italian",
          name: "Italiano",
          propertiesTopic: "italyName",
          propertiesPhrase: "italyMeans",
          propertiesGroup: "phraseGroupItalyMeans",
          code: "it",
        },
        {
          id: 6,
          img: "flag_germany",
          name: "Deutsch",
          propertiesTopic: "germanyName",
          propertiesPhrase: "germanyMeans",
          propertiesGroup: "phraseGroupGermanyMeans",
          code: "de",
        },
        {
          id: 1,
          img: "flag_vietnam",
          name: "Tiếng Việt",
          propertiesTopic: "vietnameseName",
          propertiesPhrase: "phraseVietnameseMeans",
          propertiesGroup: "phraseGroupVietnameseMeans",
          code: "vi",
        },
        // {
        //   id: 5,
        //   img: "flag_turkey",
        //   name: "Türkiye",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // },
        // {
        //   id: 7,
        //   img: "flag_japan",
        //   name: "日本",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // },
        // {
        //   id: 10,
        //   img: "flag_greece",
        //   name: "Ελληνικά",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // },
        // {
        //   id: 11,
        //   img: "flag_poland",
        //   name: "Πολωνία",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // },
        // {
        //   id: 12,
        //   img: "flag_sweden",
        //   name: "Sverige",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // },
        // {
        //   id: 13,
        //   img: "flag_netherlands",
        //   name: "Nederland",
        //   propertiesTopic: "pronounce",
        //   propertiesPhrase: "pronounce",
        //   propertiesGroup: "phraseGroupPronounce"
        // }
      ],
      // topicLang: [
      //   {
      //     id: "en",
      //     value: "pronounce",
      //   },
      //   {
      //     id: "zh",
      //     value: "chinaName",
      //   },
      //   {
      //     id: "hi",
      //     value: "hindiName",
      //   },
      //   {
      //     id: "es",
      //     value: "spainName",
      //   },
      //   {
      //     id: "fr",
      //     value: "franceName",
      //   },
      //   {
      //     id: "ar",
      //     value: "arabName",
      //   },
      //   {
      //     id: "ru",
      //     value: "russiaName",
      //   },
      //   {
      //     id: "pt",
      //     value: "portugalName",
      //   },
      //   {
      //     id: "it",
      //     value: "italyName",
      //   },
      //   {
      //     id: "de",
      //     value: "germanyName",
      //   },
      //   {
      //     id: "vi",
      //     value: "vietnameseName",
      //   }
      // ],
      currentLanguage: {
        id: 1,
        img: "flag_vietnam",
        name: "Tiếng Việt",
        propertiesTopic: "vietnameseName",
        propertiesPhrase: "phraseVietnameseMeans",
        propertiesGroup: "phraseGroupVietnameseMeans",
        code: "vi",
      },
      currentFlags:
        localStorage.getItem("flag") && localStorage.getItem("flag") !== "null"
          ? JSON.parse(localStorage.getItem("flag"))
          : {
            id: 0,
            img: "pronunciation",
            name: "IPA",
            propertiesTopic: "pronounce",
            propertiesPhrase: "pronounce",
            propertiesGroup: "phraseGroupPronounce",
            code: "en",
          },
      isLoaded: false,
      isWaiting: false,
      selectedPhrase: null,
      showBackupPhrase: false,
      listBackup: [],
      groupPhrase: [],
      isRepeat: false,
      isHidePhraseText: false,
      isPlayAll: 0,
      currentPlay: 0, //0: 1 time, 1: NLP,
      showBuyPackage: false,
      audioElement: null,
      apiKey: "AIzaSyC13XnP9se0LQEMtPmRFWkW73J1KYHzUiA",
      videoId: "",
      showVideo: false,
      listStartTime: [],
      listVideoID: [],
      currentVideoIndex: 0,
      currentSeconds: 0,
      showImage: false,
      listImages: [],
      currentImageIndex: 0,
      currentImage: "",
      currentPhraseSpeech: null,
      countdownInterval: null,
      modalContribute: false,
      modalContributeDelete: false,
      modalContributeAddSub: false,
      deleteTopic: 0,
      addSubTopic: 0,
      modalContributeAdd: false,
      modalContributeShare: false,
      contributeType: 1, //1: phrase, 2: answer, 3: user topic answer
      contributeValue: "",
      addtopic: "",
      addPhraseTopic: "",
      idTopicShare: 0,
      idTopicAddPhrase: 0,
      emailShare: "",
      arrShareEmail: [],
      viewPhraeTopic: false,
      contributeValueVN: "",
      contributeByPhraseID: 0,
      errorContribute: "",
      errorContributeVN: "",
      contributeTopicID: 0,
      modalEditPhrase: false,
      editPhraseValue: "",
      editPhraseValueVN: "",
      editPhraseID: 0,
      errorEditPhrase: "",
      errorEditPhraseVN: "",
      accessToken: "",
      currentAPIKeyIndex: 0,
      showFindF1: false,
      errorAddTopic: "",
      shareEmail: "",
      phraseTopic: "",
      answerPhrase: "",
      showModalNotify: false,
      showModalreceiveBIT: false,
      showModalReceiveScholarShip: false,
      textCongratulate: "",
      playlistArr: [],
      amountScholarship: 20,
      showModalReceiveScholarShip_Car: false,
      showModalCar1: false,
      showModalCar2: false,
      showModalCar3: false,
      showModalLearnNextPhrase: false,
      showModaShare1Get300: false,
      showModaShare2Get500: false,
      showModaShare1Get500: false,
      showModalReceiveScholarShipFake: false,
      inviteFriend: 0,
      amountBonus_phrase: 0,
      inviteFriend_phrase: 0,
      recommendCar: 0,
      showPolicyModal: false,
      popupType: -1,//0: error, 1: congratulate, 2: fake, 3: car
      metaDataLinkCar: null,
      ListCoin: [],
      info: {},
    };
    window.addEventListener("resize", this.handleResize);
    this.ListPhraseRef = React.createRef();
    this.IndexPageRef = React.createRef();
    this.formRef = React.createRef();
    this.targetTopicDrop = React.createRef();
    // this.inputRefs = [];
    this.searchRef = utilizeFocus();
    this.audio = null;
    // this.audioWorker = new Worker('audioWorker.js');
    // this.audioWorker.onmessage = this.handleWorkerMessage;
    this.socket = io(configs.SocketKickToken, { auth: { userId: localStorage.getItem("userId") } });
    this.socketUM = io(configs.SocketUM);
    this.preloadImages = this.preloadImages.bind(this);
  }

  handleAuth = () => {
    // Điều hướng người dùng đến trang xác thực của Google
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=111267039706-489lpqeq2onbpnsrfirfanhhifeqchpn.apps.googleusercontent.com&redirect_uri=http://localhost:3000&response_type=token&scope=https://www.googleapis.com/auth/youtube.force-ssl`;
  };
  componentWillUnmount = () => {
    this.ListPhraseRef.current.removeEventListener("scroll", this.handleScroll);
    this.IndexPageRef.current.removeEventListener(
      "scroll",
      this.handleScrollMB
    );
    // this.audioWorker.terminate();
    if (this.state.countdownInterval) {
      clearInterval(this.state.countdownInterval);
    }
    this.socket.disconnect();
    this.socketUM.disconnect();
  };
  componentDidMount() {
    // this.onTooglePolicyModal();
    // this.showModalReceiveScholarShip("Chuc mung ban", 200);
    // this.showModalReceiveScholarShip_Car();    
    this.hanldeLoadBasicInfo();
    this.socketUM.on("connect", () => {
      console.log("UM Connected");
    });
    this.socketUM.on("UM", (msg) => {
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
    this.socket.on("connect", () => {
      console.log("connected");
    });
    this.socket.on("KickToken", (msg) => {
      if (msg && !msg.IsLearning) {
        redirectToURL("/")
      }
    });
    if (window.Telegram) {
      window.Telegram.WebApp.BackButton.isVisible = true;
      window.Telegram.WebApp.BackButton.onClick(() => {
        redirectToURL('/')
      });
    }

    this.ListPhraseRef.current.addEventListener("scroll", this.handleScroll);
    this.IndexPageRef.current.addEventListener("scroll", this.handleScrollMB);
    document.body.classList.remove("bg-other");
    document.body.classList.add("bg-index");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //setup event audio playlist
    const audioElement = document.getElementById("audioPlayist");
    if (audioElement) {
      audioElement.addEventListener("ended", this.handleNext);
      audioElement.addEventListener("canplaythrough", this.handleLoad);
      audioElement.addEventListener("error", this.handleNext);
    }
    this.setState({ audioElement });
    this.hanldeLoadBasicInfo();
    this.hanldeLoadTopic();
    this.hanldeLoadUserTopic();
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("click", this.handleClickOutside1);

    // Kiểm tra xem URL có chứa access token không
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const receivedAccessToken = urlParams.get("access_token");

    if (receivedAccessToken) {
      // Lưu access token vào state
      this.setState({ accessToken: receivedAccessToken });
    }
    this.preloadImages();
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutside1);
  }
  handleClickOutside = (event) => {
    // Kiểm tra xem click có xảy ra bên ngoài `div` target hay không
    if (
      this.targetTopicDrop.current &&
      !this.targetTopicDrop.current.contains(event.target) &&
      this.state.showTopic
    ) {
      // Nếu click bên ngoài, ẩn `div` và cập nhật state
      this.setState({
        showTopic: false,
      });
    }
  };
  handleClickOutside1 = (event) => {
    // Kiểm tra xem click có xảy ra bên ngoài `div` target hay không
    if (
      this.targetTopicDrop.current &&
      !this.targetTopicDrop.current.contains(event.target) && this.state.showTopicUser
    ) {
      // Nếu click bên ngoài, ẩn `div` và cập nhật state
      this.setState({
        showTopicUser: false,
      });
    }
  };
  handleLoad = () => {
    const { selectedPhrasePlaylist, currentTrackIndex } = this.state;
    if (selectedPhrasePlaylist && selectedPhrasePlaylist.length > 0) {
      // if (selectedPhrasePlaylist[currentTrackIndex].parentIndex) {
      //   let _parent = this.state.listPharse.find(
      //     (x) =>
      //       x.index === selectedPhrasePlaylist[currentTrackIndex].parentIndex
      //   );
      // if (_parent) {
      //   this.scrollToCenter(_parent);
      // }
      // } else {
      this.scrollToCenter(selectedPhrasePlaylist[currentTrackIndex]);
      // }
    }
  };
  // handleWorkerMessage = (e) => {
  //   const { type } = e.data;
  //   if (type === 'play') {
  //     console.log('Audio started playing');
  //   } else if (type === 'end') {
  //     console.log('Audio playback ended');
  //   }
  // };
  playAudio = () => {
    this.audio.load();
    this.audio.play();
    // console.log("this.audio playlist", this.audio);
  };

  pauseAudio = () => {
    this.audio.pause();
  };

  hanldeLoadBasicInfo = async () => {
    // this.setState({ isLoading: true });
    try {
      let response = await get("/Users/UserInfoBasic");
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          const { arrFlag } = this.state;
          let code = response.data.data.userInfo.language;
          let _info = response.data.data.userInfo;
          if (!code) {
            code = "vi";
          }
          let languageObject = arrFlag.find((lang) => lang.code === code);
          this.setState({
            RefCode: response.data.data.userInfo.refCode,
            TwoFA: response.data.data.userInfo.twoFA,
            Balance: response.data.data.userInfo.balance,
            CurrentPackageAmount: response.data.data.userInfo.currentPackageAmount,
            info: response.data.data.userInfo,
            Trial: response.data.data.userInfo.useTrial,
            UseTrialPlayPlaylist: response.data.data.userInfo.useTrialPlayPlaylist,
            currentLanguage: languageObject,
          });
          this.setState({ info: _info });
          localStorage.setItem("refCode", response.data.data.userInfo.refCode);
          localStorage.setItem("TwoFA", response.data.data.userInfo.twoFA);
          localStorage.setItem("Balance", response.data.data.userInfo.balance);
          localStorage.setItem(
            "CurrentPackageAmount",
            response.data.data.userInfo.currentPackageAmount
          );
          localStorage.setItem(
            "ComPending",
            response.data.data.userInfo.comPending
          );
          localStorage.setItem(
            "TotalF1",
            response.data.data.userInfo.totalF1BuyPackageDue
          );
          localStorage.setItem("Trial", response.data.data.userInfo.useTrial);
          localStorage.setItem("SC", response.data.data.userInfo.wallet_BIT > response.data.data.userInfo.settingMinWalletBITToAddPhraseContribute);
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      // this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  scrollTop = () => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  };
  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 992,
    });
  };
  handleScroll = () => {
    // debugger
    this.setState({
      showScrollTop: document.documentElement.scrollTop > 0,
    });
    const refContainer = this.ListPhraseRef.current;
    if (refContainer) {
      const { scrollTop, scrollHeight, clientHeight } = refContainer;
      if (scrollHeight - scrollTop === clientHeight) {
        this.loadMoreData();
      }
    }
  };
  handleScrollMB = () => {
    this.setState({
      showScrollTop: document.documentElement.scrollTop > 0,
    });
    const IndexPageRef = this.IndexPageRef.current;
    if (IndexPageRef) {
      const { scrollTop, scrollHeight, clientHeight } = IndexPageRef;
      if (scrollHeight - scrollTop === clientHeight) {
        this.loadMoreData();
      }
    }
  };
  toogleShowTopic = () => {
    this.setState((prevState) => ({ showTopic: !prevState.showTopic }));
    if (this.state.showTopicUser) {
      this.setState({ showTopicUser: !this.state.showTopicUser });
    }
  };
  toogleShowTopicUser = () => {
    this.setState((prevState) => ({ showTopicUser: !prevState.showTopicUser }));
    localStorage.setItem("search", null);
    this.setState((prevState) => ({ showTopic: false }));
  };
  handleHidePlaylist = () => {
    this.setState({ showPlaylist: false });
  };
  hanldeShowTopic = () => {
    if (this.state.showTopicUser) {
      this.setState({ showTopicUser: !this.state.showTopicUser });
    }
    this.setState({
      showTopic: true,
      isActiveTopic: true,
      showPlaylist: false,
      currentTabTopic: true,
      // showSearchABC: false,
      showSearchInput: false,
    });
    // if (this.state.selectedTopic) {
    //   this.hanldeLoadPharse(this.state.selectedTopic.topicID)
    // }
  };
  hanldeShowTopicUser = () => {
    this.setState((prevState) => ({ showTopic: false }));
    this.setState({
      showTopicUser: true,
      isActiveTopic: true,
      showPlaylist: false,
      currentTabTopic: true,
      // showSearchABC: false,
      showSearchInput: false,
    });
    // if (this.state.selectedTopic) {
    //   this.hanldeLoadPharse(this.state.selectedTopic.topicID)
    // }
  };
  hanldeShowPlaylist = () => {
    this.setState({
      // showTopic: false,
      isActiveTopic: false,
      showPlaylist: true,
      currentTabTopic: false,
      // showSearchABC: false,
      showSearchInput: false,
    });
    this.hanldeLoadPlaylist(0);
  };
  hanldeLoadTopic = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await get("/Users/GetTopic?PageSize=9999&PageIndex=1");
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          if (response.data.data.length > 0) {
            if (
              localStorage.getItem("search") &&
              localStorage.getItem("search") !== "null"
            ) {
              let search = JSON.parse(localStorage.getItem("search"));
              switch (search.type) {
                case 0: {
                  //topic
                  let _selectedTopic = response.data.data.find(
                    (el) => el.topicID === search.keyword
                  );
                  this.setState({ selectedTopic: _selectedTopic });
                  if (search.keyword === "-2") {
                    this.hanldeTopLoadPharse();
                  } else {
                    this.hanldeLoadPharse(search.keyword, 1);
                  }
                  break;
                }
                case 1: {
                  // by playlist
                  this.hanldeLoadPharseByPlaylist();
                  break;
                }
                case 2: {
                  // by first char
                  if (!this.state.isMobile) {
                    this.toogleSearchABC();
                  }
                  this.setState({ alphabetSelected: search.keyword });
                  this.hanldeLoadPharseByStartCharacter(search.keyword, 1);
                  break;
                }
                case 3: {
                  // by input search
                  this.setState({ searchKey: search.keyword });
                  this.hanldeLoadPharseBySearchKey(search.keyword, 1);
                  break;
                }
                case 4: {
                  // by user topic
                  this.setState({ searchKey: search.keyword });
                  this.hanldeLoadPharseUserTopic(search.keyword);
                  break;
                }
              }
              // console.log(search);
            } else {
              // if (localStorage.getItem("CurrentPackageAmount") !== '0') {
              //   let _selectedTopic = response.data.data[0];
              //   this.setState({ selectedTopic: _selectedTopic });
              //   this.hanldeLoadPharse(_selectedTopic.topicID, 1)
              // } else {

              // }
              // let _freeTopic = response.data.data.filter(x => x.isView);
              // if (_freeTopic && _freeTopic.length > 0) {
              //   let _selectedTopic = _freeTopic[0];
              //   this.setState({ selectedTopic: _selectedTopic });
              //   this.hanldeLoadPharse(_selectedTopic.topicID, 1)
              // }
              let _selectedTopic = response.data.data.find(
                (el) => el.topicID === 164 //default Topic 2 words
              );
              if (_selectedTopic) {
                this.setState({ selectedTopic: _selectedTopic });
                this.hanldeLoadPharse(_selectedTopic.topicID, 1);
                // this.hanldeTopLoadPharse();
              }
            }
            this.setState({ listTopic: response.data.data });
          }
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  hanldeLoadUserTopic = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await get("/Users/GetUserTopic?PageSize=9999&PageIndex=1");
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          this.setState({ listUserTopic: response.data.data });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  handleRefData = (data) => {
    let _phraseRefs = {};
    data.map((el, index) => {
      _phraseRefs[index] = React.createRef();
      el.index = index;
      if (el.listAnswer) {
        //map parent index to list answer
        el.listAnswer.map((x, vt) => {
          x.parentIndex = index;
          x.index = "sub" + index + vt;
          _phraseRefs[x.index] = React.createRef();
          return x;
        });
      }
      return el;
    });
    this.setState({ phraseRefs: _phraseRefs, listPharse: data });
  };
  hanldeLoadPharse = async (TopicID, pageIndex) => {
    //Save search
    let search = {
      type: 0,
      keyword: TopicID,
    };
    localStorage.setItem("search", JSON.stringify(search));
    //
    this.setState({ isLoading: true, currentSearchType: 0 });
    try {
      let _params = {
        TopicID: TopicID ? TopicID : 0,
        PageSize: this.state.PageSize,
        PageIndex: pageIndex,
      };
      let url = "/Users/GetPhraseOfTopic";
      if (TopicID === -1) {
        url = "/Users/GetPhraseOfMyPhrases";
      }
      let response = await get(url, _params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          // const _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          let phraseType = TopicID === -1 ? 2 : 1;
          let newDataPharse = response.data.data.map((el) => {
            el.phraseType = phraseType; //1: cụm từ hệ thống, 2: cụm đóng góp, 3: user Phrase,
            if (el.listAnswer && el.listAnswer.length > 0) {
              el.listAnswer = el.listAnswer.map(x => {
                x.phraseType = phraseType;
                return x;
              })
            }
            return el;
          });
          let _listPharse = [...this.state.listPharse, ...newDataPharse];
          this.handleRefData(_listPharse);
          this.setState({
            listPharse: newDataPharse,
            TotalRows: response.data.totalRows,
          });
          // console.log(newDataPharse.map(x => {
          //   return {
          //     phraseID: x.phraseID,
          //     phrase: x.phrase,
          //   }
          // }));
          this.setState({
            currentTrackIndex: 0,
            isPlayingSingleWord: false,
            isPlayingPlaylist: false,
            selectedPhrasePlaylist: [],
            alphabetSelected: "",
            searchKey: "",
            isActiveTopic: true,
            showPlaylist: false,
            showSearchInput: false,
          });
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
          if (response.data.totalRows > 20) {
            this.hanldeLoadPharseAll();
          }
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  hanldeLoadPharseUserTopic = async (TopicID) => {
    let search = {
      type: 4,
      keyword: TopicID,
    };
    localStorage.setItem("search", JSON.stringify(search));
    this.setState({ isLoading: true, currentSearchType: 4 });
    try {
      let response = await get(
        `/Users/GetPhraseByTopic?PageSize=9999&PageIndex=1&TopicID=${TopicID}`
      );
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          // const _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          const newDataPharse = response.data.data.map((el) => {
            el.phraseType = 3; //1: cụm từ hệ thống, 2: cụm đóng góp, 3: user Phrase,
            if (el.listAnswer && el.listAnswer.length > 0) {
              el.listAnswer = el.listAnswer.map(x => {
                x.phraseType = 3;
                return x;
              })
            }
            return el;
          });
          this.handleRefData(newDataPharse);
          this.setState({
            listPharse: newDataPharse,
            TotalRows: response.data.totalRows,
          });
          // console.log(newDataPharse.map(x => {
          //   return {
          //     phraseID: x.phraseID,
          //     phrase: x.phrase,
          //   }
          // }));
          this.setState({
            currentTrackIndex: 0,
            isPlayingSingleWord: false,
            isPlayingPlaylist: false,
            selectedPhrasePlaylist: [],
            alphabetSelected: "",
            searchKey: "",
            isActiveTopic: true,
            showPlaylist: false,
            showSearchInput: false,
          });
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  hanldeTopLoadPharse = async (TopicID, pageIndex) => {
    //Save search

    localStorage.setItem("search", null);
    //
    this.setState({ isLoading: true, currentSearchType: -1 });
    try {
      let _params = {
        TopicID: TopicID ? TopicID : 0,
        PageSize: this.state.PageSize,
        PageIndex: pageIndex,
      };
      let url = "/Users/GetTopPhrase";

      let response = await get(url);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          // const _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          const newDataPharse = response.data.data;
          this.handleRefData(newDataPharse);
          this.setState({
            listPharse: response.data.data,
            TotalRows: response.data.totalRows,
          });
          // console.log(newDataPharse.map(x => {
          //   return {
          //     phraseID: x.phrasDeID,
          //     phrase: x.phrase,
          //   }
          // }));
          this.setState({
            currentTrackIndex: 0,
            isPlayingSingleWord: false,
            isPlayingPlaylist: false,
            selectedPhrasePlaylist: [],
            alphabetSelected: "",
            searchKey: "",
            isActiveTopic: true,
            showPlaylist: false,
            showSearchInput: false,
          });
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  //when click button all load all phrase of topic
  hanldeLoadPharseAll = async () => {
    this.setState({ currentSearchType: 0 });
    const { selectedTopic, PageIndex, PageSize } = this.state;
    try {
      let _params = {
        TopicID: selectedTopic.topicID ? selectedTopic.topicID : 0,
        PageIndex: PageIndex,
        PageSize: PageSize,
      };
      let url = "/Users/GetPhraseOfTopicByIndex";
      if (selectedTopic.topicID === -1) {
        url = "/Users/GetPhraseOfMyPhrasesByIndex";
      }
      let response = await get(url, _params);
      if (response && response.data.errorCode === 0) {
        if (response.data.data != null) {
          let phraseType = selectedTopic.topicID === -1 ? 2 : 1;
          const newDataPharse = response.data.data.map((el) => {
            el.phraseType = phraseType; //1: cụm từ hệ thống, 2: cụm đóng góp, 3: user Phrase,
            if (el.listAnswer && el.listAnswer.length > 0) {
              el.listAnswer = el.listAnswer.map(x => {
                x.phraseType = phraseType;
                return x;
              })
            }
            return el;
          });
          let _listPharse = [...this.state.listPharse, ...newDataPharse];
          var sort_data = [..._listPharse];
          sort_data.map((el, index) => {
            if (el.phraseGroupID === 0) {
              el.phraseGroupNumberOrder = 999999;
            }
            return el;
          });
          sort_data.sort(
            (a, b) => a.phraseGroupNumberOrder - b.phraseGroupNumberOrder
          );
          let maxPage = Math.ceil(
            response.data.totalRows / this.state.PageSize
          );
          this.handleRefData(sort_data);
          this.setState({
            listPharse: sort_data,
            TotalRows: response.data.totalRows,
            PageIndex: maxPage,
          });
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeLoadPharseByPlaylist = async () => {
    //Save search
    let search = {
      type: 1,
      keyword: "",
    };
    localStorage.setItem("search", JSON.stringify(search));
    let showSC = localStorage.getItem("SC");
    const el_divPhraseLst = document.getElementById("div_ListPhrase"); // Thay 'your-element-id' bằng id của phần tử bạn muốn kiểm tra
    if (showSC === "true") {
      // console.log('Thanh cuộn đã xuất hiện');
      el_divPhraseLst.classList.add("favorite-list");
    } else {
      //console.log('Không có thanh cuộn');
      el_divPhraseLst.classList.remove("favorite-list");
    }
    //playlistID
    this.setState({ isLoading: true, currentSearchType: 1 });
    try {
      let response = await get("/Users/GetPhraseFromPlaylist");
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          this.handleRefData(response.data.data);
          this.setState({ listPharse: response.data.data });
          // const _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          this.setState({
            currentTrackIndex: 0,
            isPlayingSingleWord: false,
            isPlayingPlaylist: false,
            selectedPhrasePlaylist: [],
            // phraseRefs: _phraseRefs,
            selectedTopic: null,
            alphabetSelected: "",
            searchKey: "",
            // showTopic: false,
            isActiveTopic: false,
            showPlaylist: true,
            showSearchInput: false,
            PageIndex: 1,
            isPlayAll: 0,
          });
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeLoadPharseByStartCharacter = async (key, pageIndex) => {
    //Save search
    let search = {
      type: 2,
      keyword: key ? key : "",
    };
    localStorage.setItem("search", JSON.stringify(search));
    //
    this.setState({ isLoading: true, currentSearchType: 2 });
    let _params = {
      CharacterOrNumber: key ? key : "",
      PageSize: this.state.PageSize,
      PageIndex: pageIndex,
    };
    try {
      let response = await get(`/Users/GetPhraseByCharacterOrNumber`, _params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          // this.setState({ listPharse: response.data.data });
          const newDataPharse = [
            ...this.state.listPharse,
            ...response.data.data,
          ];
          // let _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          this.handleRefData(newDataPharse);
          this.setState({
            listPharse: newDataPharse,
            TotalRows: response.data.totalRows,
          });
          //hidden load all
          if (response.data.totalRows > 20) {
            this.hanldeLoadPharseByStartCharacterAll(key, pageIndex);
          }
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  //load all phrase hidden
  hanldeLoadPharseByStartCharacterAll = async (key, pageIndex) => {
    //Save search
    let search = {
      type: 2,
      keyword: key ? key : "",
    };
    localStorage.setItem("search", JSON.stringify(search));
    //
    this.setState({ currentSearchType: 2 });
    let _params = {
      CharacterOrNumber: key ? key : "",
      PageSize: 99999,
      PageIndex: 1,
    };
    try {
      let response = await get(`/Users/GetPhraseByCharacterOrNumber`, _params);
      if (response && response.data.errorCode === 0) {
        if (response.data.data != null) {
          let dataRest = response.data.data.slice(
            pageIndex * this.state.PageSize
          );
          // const _phraseRefs = {};
          // dataRest.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          let _listPharse = [...this.state.listPharse, ...dataRest];
          let maxPage = Math.ceil(
            response.data.totalRows / this.state.PageSize
          );
          this.handleRefData(_listPharse);
          this.setState({
            listPharse: _listPharse,
            TotalRows: response.data.totalRows,
            PageIndex: maxPage,
          });
          //Load Audio Cache
          const audioUrls = dataRest.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeLoadPharseBySearchKey = async (keyword, pageIndex) => {
    //Save search
    let search = {
      type: 3,
      keyword: keyword,
    };
    localStorage.setItem("search", JSON.stringify(search));
    //
    this.setState({ isLoading: true, currentSearchType: 3 });
    let _params = {
      Phrase: keyword,
      PageSize: this.state.PageSize,
      PageIndex: pageIndex,
    };
    try {
      let response = await get(`/Users/GetPhraseByCharacter`, _params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          // this.setState({ listPharse: response.data.data });
          const newDataPharse = [
            ...this.state.listPharse,
            ...response.data.data,
          ];
          // let _phraseRefs = {};
          // response.data.data.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          this.handleRefData(newDataPharse);
          this.setState({
            listPharse: newDataPharse,
            TotalRows: response.data.totalRows,
          });
          //hidden load all
          if (response.data.totalRows > 20) {
            this.hanldeLoadPharseBySearchKeyAll(keyword, pageIndex);
          }
          //Load Audio Cache
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  //load all phrase hidden
  hanldeLoadPharseBySearchKeyAll = async (keyword, pageIndex) => {
    let search = {
      type: 3,
      keyword: keyword,
    };
    localStorage.setItem("search", JSON.stringify(search));
    //
    this.setState({ currentSearchType: 3 });
    let _params = {
      Phrase: keyword,
      PageSize: 99999,
      PageIndex: 1,
    };
    try {
      let response = await get(`/Users/GetPhraseByCharacter`, _params);
      if (response && response.data.errorCode === 0) {
        if (response.data.data != null) {
          let dataRest = response.data.data.slice(
            pageIndex * this.state.PageSize
          );
          // const _phraseRefs = {};
          // dataRest.forEach(item => {
          //   _phraseRefs[item.phraseID] = React.createRef();
          // });
          // const newRefPhrase = { ...this.state.phraseRefs, ..._phraseRefs };
          let _listPharse = [...this.state.listPharse, ...dataRest];
          let maxPage = Math.ceil(
            response.data.totalRows / this.state.PageSize
          );
          this.handleRefData(_listPharse);
          this.setState({
            listPharse: _listPharse,
            TotalRows: response.data.totalRows,
            PageIndex: maxPage,
          });
          //Load Audio Cache
          const audioUrls = dataRest.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  handleSelectedTopic = (item) => {
    if (item.isView) {
      this.setState({
        selectedTopic: item,
        PageIndex: 1,
        listPharse: [],
        TotalRows: 0,
        isPlayAll: 0,
        currentTrackIndex: 0,
        selectedPlaylist: [],
        showTopic: false,
        showSearchABC: false,
      }); //
      if (item.topicID == -2) {
        this.hanldeTopLoadPharse();
      }
      else {
        this.hanldeLoadPharse(item.topicID, 1);
      }
    } else {
      // toast.error(t("Permission denied!"), {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 5000
      // });
      this.setState({ showFindF1: true });
    }
  };
  handleSelectedUserTopic = (item) => {
    if (item) {
      // this.setState({ showTopicUser: !this.state.showTopicUser ,selectedTopicUser: item});
      this.setState({
        PageIndex: 1,
        listPharse: [],
        TotalRows: 0,
        isPlayAll: 0,
        currentTrackIndex: 0,
        selectedPlaylist: [],
        showSearchABC: false,
        showTopicUser: !this.state.showTopicUser,
        selectedTopicUser: item
      }); //
      this.hanldeLoadPharseUserTopic(item.topicID);
    } else {
      // toast.error(t("Permission denied!"), {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 5000
      // });
      this.setState({ showFindF1: true });
    }
  };
  handleSelectedPlaylist = (item) => {
    this.setState({ selectedPlaylist: item });
    this.hanldeLoadPharseByPlaylist(item.playlistID);
  };
  splitStringIntoWords = (inputString) => {
    // const cleanedString = inputString.replace(/[^\w\s]/gi, '').toLowerCase(); // Loại bỏ các ký tự đặc biệt
    const wordsArray = inputString.split(/\s+/); // Tách chuỗi thành mảng các từ
    return wordsArray;
  };
  playSingleWord = (word) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    // this.playAudio();
    let file_name = word.replace(/[^\w\s]/gi, "").toLowerCase();
    this.setState({
      soundPathSingleWord: configs.RootSingleWord + file_name + ".mp3",
      isPlayingSingleWord: true,
    });
  };
  updateItemList = (list, item) => {
    list.map((el, index) => {
      if (el.isDelete) {
        if (el.id === item.id) {
          el = item;
        }
      } else {
        if (el.phraseID === item.phraseID) {
          el = item;
        }
      }
      return el;
    });
    return list;
  };
  playTrack = async (item, loop, isChildPhrase) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    item.loop = loop;
    item.isPlaying = true;
    item.completeListen = false;
    // debugger
    if (item) {
      // if (CurrentPackageAmount === 0 && Trial) {
      //   let type = 4;
      //   if (loop) {
      //     this.updateTrialTimes(5);
      //   }
      //   let response = await this.updateTrialTimes(type);
      //   if (response) {
      //     if (response.useTrial) {
      //       let _listPharse = [...this.state.listPharse];
      //       if (isChildPhrase) {
      //         //update backup item
      //         _listPharse.map((el, index) => {
      //           if (el.index === item.parentIndex) {
      //             this.scrollToCenter(el);
      //             // if (el.showBackupPhrase) {
      //             //   el.listBackup = this.updateItemList(el.listBackup, item);
      //             // }
      //             el.listAnswer = this.updateItemList(el.listAnswer, item);
      //           }
      //           return el;
      //         });
      //       } else {
      //         this.scrollToCenter(item);
      //         _listPharse = this.updateItemList(_listPharse, item);
      //       }
      //       this.setState({ listPharse: _listPharse });
      //     }
      //   }
      // } else {
      let _listPharse = [...this.state.listPharse];
      if (isChildPhrase) {
        //update backup item
        _listPharse.map((el, index) => {
          if (el.index === item.parentIndex) {
            this.scrollToCenter(el);
            // if (el.showBackupPhrase) {
            //   el.listBackup = this.updateItemList(el.listBackup, item);
            // }
            el.listAnswer = this.updateItemList(el.listAnswer, item);
          }
          return el;
        });
      } else {
        this.scrollToCenter(item);
        _listPharse = this.updateItemList(_listPharse, item);
      }
      this.setState({ listPharse: _listPharse });
      // }
    }
  };
  // checkLoop = (item)=>{
  //   if (item) {
  //     let _listPharse = [...this.state.listPharse];
  //     _listPharse.map((el, index) => {
  //       if (el.phraseID === item.phraseID) {
  //         item.countLoop = item.countLoop + 1;
  //         el = item;
  //         this.setState({ listPharse: _listPharse });
  //         return;
  //       }
  //     });
  //   }
  // }
  onLoadLoopItem = (item) => {
    // item.loop = false;
    if (item.loop) {
      if (item.countLoop === undefined) item.countLoop = 0;
      if (item.countLoop > 3) {
        item.countLoop = 0;
        item.loop = false;
        item.isPlaying = false;
        item.isWaiting = false;
      } else {
        item.isPlaying = true;
        item.countLoop = item.countLoop + 1;
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.listPharse !== prevState.listPharse) {
      // const { selectedPhrase } = this.state;
      // if (selectedPhrase && selectedPhrase.completeListen) {
      //   let inputElement = document.getElementById('input' + selectedPhrase.phraseID + selectedPhrase.index);
      //   inputElement.focus();
      // }
      const el_divPhraseLst = document.getElementById("div_ListPhrase"); // Thay 'your-element-id' bằng id của phần tử bạn muốn kiểm tra
      if (el_divPhraseLst.scrollHeight > el_divPhraseLst.clientHeight) {
        // console.log('Thanh cuộn đã xuất hiện');
        el_divPhraseLst.classList.remove("no-scroll");
      } else {
        //console.log('Không có thanh cuộn');
        el_divPhraseLst.classList.add("no-scroll");
      }
    }
    if (this.state.currentSearchType !== prevState.currentSearchType) {
      // const { selectedPhrase } = this.state;
      // if (selectedPhrase && selectedPhrase.completeListen) {
      //   let inputElement = document.getElementById('input' + selectedPhrase.phraseID + selectedPhrase.index);
      //   inputElement.focus();
      // }
      const el_divPhraseLst = document.getElementById("div_ListPhrase"); // Thay 'your-element-id' bằng id của phần tử bạn muốn kiểm tra
      if (this.state.currentSearchType === 1 && this.state.info.wallet_BIT > this.state.info.settingMinWalletBITToAddPhraseContribute) {
        // console.log('Thanh cuộn đã xuất hiện');
        el_divPhraseLst.classList.add("favorite-list");
      } else {
        //console.log('Không có thanh cuộn');
        el_divPhraseLst.classList.remove("favorite-list");
      }
    }
  }
  updatePhraseList = (item) => {
    this.setState({ selectedPhrase: item });
    let _listPharse = [...this.state.listPharse];
    _listPharse.map((el, index) => {
      if (el.phraseID === item.phraseID) {
        el = item;
        this.setState({ listPharse: _listPharse });
      } else {
        el.completeListen = false
      }
      return el;
    });
  };
  hanldeShowPractive = (item) => {
    if (item) {
      if (!item.loop || item.countLoop === 0) {
        item.countLoop = 0;
        item.loop = false;
        item.isPlaying = false;
        item.completeListen = true;
        item.rewriteText = "";
        this.handleSetListenLearnedPhrase(item);
      }
      item.isWaiting = true;
      setTimeout(() => {
        item.isWaiting = false;
        this.updatePhraseList(item);
      }, 1000);
      this.updatePhraseList(item);
      // if (item.loop) {
      //   setTimeout(() => {
      //     let _listPharse = [...this.state.listPharse];
      //     _listPharse.map((el, index) => {
      //       if (el.phraseID === item.phraseID) {
      //         // item.refInput.focus();
      //         el = item;
      //         this.setState({ listPharse: _listPharse });
      //       }
      //       return el;
      //     });
      //   }, 1000)
      // } else {

      // }
    }
  };
  handleSetCurrentPhraseSpeech = (item) => {
    this.setState({ currentPhraseSpeech: item });
  };
  handleInputPractice = (value, item) => {
    item.rewriteText = value;
    let _listPharse = [...this.state.listPharse];
    _listPharse.map((el, index) => {
      if (item.parentIndex >= 0) {
        if (el.index === item.parentIndex) {
          // if (el.showBackupPhrase) {
          //   el.listBackup = this.updateItemList(el.listBackup, item);
          // }
          el.listAnswer = this.updateItemList(el.listAnswer, item);
        }
        this.setState({ listPharse: _listPharse });
      } else {
        if (el.index === item.index) {
          el = item;
          this.setState({ listPharse: _listPharse });
        }
      }
      return el;
    });
  };

  handlePracticeWord = async (item, type, isChildPhrase) => {
    if (type === 0) {
      //submit
      //handle update status practive
      let formIsValid = true;
      let error = "";
      if (!item.rewriteText) {
        formIsValid = false;
        // error = t("Please enter the phrase.");
      }
      if (!formIsValid) {
        item.completeListen = false;
        let _listPharse = [...this.state.listPharse];
        if (isChildPhrase) {
          //update backup item
          _listPharse.map((el, index) => {
            if (el.index === item.parentIndex) {
              // if (el.showBackupPhrase) {
              //   el.listBackup = this.updateItemList(el.listBackup, item);
              // }
              el.listAnswer = this.updateItemList(el.listAnswer, item);
            }
            return el;
          });
        } else {
          _listPharse = this.updateItemList(_listPharse, item);
        }
        this.setState({ listPharse: _listPharse });
      } else {
        item.completeListen = false;
        item.errRewriteText = error;
        // Submit form
        this.setState({ isLoading: true });
        try {
          let params = {
            PhraseID: item.phraseID,
            TopicID: item.topicID,
            WordPhrase: item.rewriteText,
          };
          let url = "/Users/SetLearnedPhrase";

          if (item.isDelete) {
            url = "/Users/SetLearnedPhraseFromMyPhrases";
            params = {
              PhraseID: item.id,
              TopicID: -1,
              WordPhrase: item.rewriteText,
            };
          }
          if (item.phraseType === 3) {
            url = "/Users/SetLearnedPhraseOfUser";
            params = {
              PhraseID: item.phraseID,
              TopicID: item.topicID,
              WordPhrase: item.rewriteText,
            };
          }
          const response = await post(url, params);
          if (response.data.errorCode === 0) {
            this.setState({ isLoading: false });
            item.haveLearned = true;
            let _listPharse = [...this.state.listPharse];
            if (isChildPhrase) {
              //update backup item
              _listPharse.map((el, index) => {
                if (el.index === item.parentIndex) {
                  // if (el.showBackupPhrase) {
                  //   el.listBackup = this.updateItemList(el.listBackup, item);
                  // }
                  el.listAnswer = this.updateItemList(el.listAnswer, item);
                }
                return el;
              });
            } else {
              _listPharse = this.updateItemList(_listPharse, item);
            }
            this.setState({ listPharse: _listPharse });
            //notify sholarship
            let message = t("Excellent! The phrase you entered is absolutely accurate.");
            if (response.data.data && response.data.data.amountBonus > 0) {
              this.showModalReceiveScholarShip(message, response.data.data.amountBonus);
            }
            else {
              if(response.data.data.notifyType===2){//fake
                this.showModalReceiveScholarShipFake(response.data.data.recommendAmount, response.data.data.inviteFriend);
              }
              else{//learnContinute
                this.showModalLearnNextPhrase();
              }
              // if (response.data.data.recommendAmount > 0) {
              //   if (response.data.data.totalTrans === 2 && response.data.data.amountReceived === 20) {
              //     this.showModalLearnNextPhrase();
              //   } else if ((response.data.data.totalTrans === 3 || response.data.data.totalTrans === 4) && response.data.data.amountReceived === 50) {
              //     this.showModalLearnNextPhrase();
              //   } else {
              //     let inviteFriend = 0;
              //     if (response.data.data.recommendAmount === 20) {
              //       inviteFriend = 2 - response.data.data.totalTrans;
              //     } else if (response.data.data.recommendAmount === 50) {
              //       inviteFriend = 3 - response.data.data.totalTrans;
              //     } else if (response.data.data.recommendAmount === 100) {
              //       inviteFriend = 5 - response.data.data.totalTrans;
              //     }
              //     if (inviteFriend > 0) {
              //       this.showModalReceiveScholarShipFake(response.data.data.recommendAmount, inviteFriend);
              //     }
              //     else {
              //       this.showModalLearnNextPhrase();
              //     }
              //   }
              // }
              // else {
              //   // if (response.data.data.totalTrans >= 0) {
              //   // if (response.data.data.totalTrans < 2) {
              //   //   // sửa lại popup nhận 3 cái xe
              //   //   this.showModalReceiveScholarShip_Car();
              //   // }
              //   // else
              //   // if (response.data.data.totalTrans === 2 && response.data.data.amountReceived >= 20 && response.data.data.amountReceived < 50) {
              //   //   this.showModaShare1Get300();
              //   // }
              //   // else if (response.data.data.totalTrans < 5 && response.data.data.amountReceived >= 50 && response.data.data.amountReceived < 100) {
              //   //   if (response.data.data.totalTrans === 4) {
              //   //     this.showModaShare1Get500();
              //   //   }
              //   //   else {
              //   //     this.showModaShare2Get500();
              //   //   }
              //   // }
              //   // else {
              //   this.showModalLearnNextPhrase();
              //   //   }
              //   // }
              // }
              // toast.success(message, {
              //   position: toast.POSITION.TOP_CENTER,
              //   autoClose: 5000,
              // });
            }
            this.hanldeLoadBasicInfo();
          } else {
            this.setState({ isLoading: false });

            if (response.data.errorCode === 2) {
              this.setState({ showModalNotify: true })
            }
            // else if (response.data.data.errorCode === 3) {
            //   // sửa lại popup nhận 3 cái xe
            //   this.showModalReceiveScholarShip_Car();
            // } else if (response.data.data.errorCode === 4) {
            //   // sửa lại popup Học bổng có thể nằm trong câu tiếp theo mời học tiếp
            //   this.showModalLearnNextPhrase();
            // } 
            else {
              toast.error(t(response.data.errorMessage), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
                onOpen: () => checkToken(response.data),
              });
            }
          }
        } catch (error) {
          this.setState({ isLoading: false });
          let response = error.response;
          if (response.data) {
            if (response.data.errorCode === 2) {
              this.setState({ showModalNotify: true })
            }
            // else if (response.data.data.errorCode === 3) {
            //   // sửa lại popup nhận 3 cái xe
            //   this.showModalReceiveScholarShip_Car();
            // } else if (response.data.data.errorCode === 4) {
            //   // sửa lại popup Học bổng có thể nằm trong câu tiếp theo mời học tiếp
            //   this.showCongratulate();
            // } 
            else {
              toast.error(t(response.data.errorMessage), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
                onOpen: () => checkToken(response.data),
              });
            }
          } else {
            checkToken(response);
          }
        }
      }
    } else {
      //cancel
      item.completeListen = false;
      item.errRewriteText = "";
      let _listPharse = [...this.state.listPharse];
      if (isChildPhrase) {
        //update backup item
        _listPharse.map((el, index) => {
          if (el.index === item.parentIndex) {
            // if (el.showBackupPhrase) {
            //   el.listBackup = this.updateItemList(el.listBackup, item);
            // }
            el.listAnswer = this.updateItemList(el.listAnswer, item);
          }
          return el;
        });
      } else {
        _listPharse = this.updateItemList(_listPharse, item);
      }
      this.setState({ listPharse: _listPharse });
    }
  };

  playSelected = (type) => {
    if (type === 0) {
      //all
      this.setState({ currentTrackIndex: 0 });
      let _listPlaylist = [...this.state.listPharse];
      _listPlaylist.map((el, index) => {
        el.selectedPlaylist = true;
        if (el.listAnswer) {
          el.listAnswer.map((ans) => {
            ans.selectedPlaylist = true;
            return ans;
          });
        }
        return el;
      });
      let groupPhrase = this.handleGroupPhrase(_listPlaylist);
      let flattern_phrase = [].concat(...groupPhrase);
      //Add Answer phrase to playlist
      let add_answers = flattern_phrase.flatMap((item) => {
        if (item.listAnswer && item.listAnswer.length > 0) {
          return [item, ...item.listAnswer];
        } else {
          return [item];
        }
      });
      this.setState({ selectedPhrasePlaylist: add_answers });
    } else if (type === 2) {
      //clear all
      this.setState({ currentTrackIndex: 0, isPlayingPlaylist: false });
      let _listPlaylist = [...this.state.listPharse];
      _listPlaylist.map((el, index) => {
        el.selectedPlaylist = false;
        el.loop = false;
        // if (el.showBackupPhrase && el.listBackup) {
        //   el.showBackupPhrase = false;
        //   this.clearPhraseChildFromSelected(el.listBackup);
        // }
        if (el.listAnswer) {
          this.clearPhraseChildFromSelected(el.listAnswer);
        }
        return el;
      });
      this.setState({ listPharse: _listPlaylist });
      this.setState({ selectedPhrasePlaylist: [] });
    }
  };
  checkExistInPlaylist = (item) => {
    let _listPlaylist = [...this.state.selectedPhrasePlaylist];
    let isExist = _listPlaylist.some((el) => el.index === item.index);
    return isExist;
  };
  hanldeAddPhraseToPlaylist = (e, item, isChildPhrase) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    this.setState({ currentTrackIndex: 0 });
    let _listPlaylist = [...this.state.selectedPhrasePlaylist];
    let isExist = false;
    if (isChildPhrase) {
      isExist = _listPlaylist.some((el) => el.phraseID === item.phraseID);
    } else {
      isExist = _listPlaylist.some((el) => el.index === item.index);
    }
    // console.log(isExist, item);
    let new_listPlaylist = [];
    if (e.target.checked) {
      // if (!isExist) {
      item.selectedPlaylist = true;
      if (!isExist) {
        _listPlaylist.push(item);
      }
      if (!isChildPhrase) {
        // if (item.hasBackup && item.showBackupPhrase) {
        //   item.listBackup.map((el, idx) => {
        //     let exist = _listPlaylist.some(x => x.phraseID === el.phraseID && x.parentIndex === el.parentIndex);
        //     if (!el.selectedPlaylist && !exist) {
        //       el.selectedPlaylist = true;
        //       _listPlaylist.push(el);
        //     }
        //   })
        // }
        if (item.listAnswer) {
          item.listAnswer.map((el, idx) => {
            let exist = _listPlaylist.some(
              (x) =>
                ((x.phraseID > 0 && x.phraseID === el.phraseID) ||
                  (x.phraseID === 0 && x.id === el.id)) &&
                x.parentIndex === el.parentIndex
            );
            if (!el.selectedPlaylist && !exist) {
              el.selectedPlaylist = true;
              _listPlaylist.push(el);
            }
          });
        }
      }
      new_listPlaylist = [..._listPlaylist];
      this.setState({ selectedPhrasePlaylist: _listPlaylist });
      // }
    } else {
      if (isExist) {
        if (!isChildPhrase) {
          //update in main phrase
          // if (item.hasBackup && item.showBackupPhrase) {
          //   item.listBackup.map((element, idx) => {
          //     element.selectedPlaylist = false;
          //     element.isPlaying = false;
          //     return element;
          //   })
          // }
          if (item.listAnswer) {
            item.listAnswer.map((element, idx) => {
              element.selectedPlaylist = false;
              element.isPlaying = false;
              return element;
            });
          }
          let _listPharse = [...this.state.listPharse];
          _listPharse.map((el, index) => {
            if (el.index === item.index) {
              el.selectedPlaylist = false;
              el.isPlaying = false;
              el = item;
              this.setState({ listPharse: _listPharse });
            }
            return el;
          });
        } else {
          //update in child phrase
          let _listPharse = [...this.state.listPharse];
          _listPharse.map((el, index) => {
            if (el.index === item.parentIndex) {
              item.selectedPlaylist = false;
              item.isPlaying = false;
              // if (el.showBackupPhrase) {
              //   el.listBackup = this.updateItemList(el.listBackup, item);
              // }
              el.listAnswer = this.updateItemList(el.listAnswer, item);
              this.setState({ listPharse: _listPharse });
            }
            return el;
          });
        }
        if (isChildPhrase) {
          new_listPlaylist = _listPlaylist.filter((el) => {
            return el.phraseID !== item.phraseID;
          });
        } else {
          new_listPlaylist = _listPlaylist.filter((el) => {
            return el.index !== item.index;
          });
          new_listPlaylist = new_listPlaylist.filter((el) => {
            return el.parentIndex !== item.index;
          });
        }
        this.setState({ selectedPhrasePlaylist: new_listPlaylist });
      }
    }
    let _listPharseOrigin = [...this.state.listPharse];
    //Add Answer phrase to playlist
    let _listPharseOrigin_answers = _listPharseOrigin.flatMap((item) => {
      if (item.listAnswer && item.listAnswer.length > 0) {
        return [item, ...item.listAnswer];
      } else {
        return [item];
      }
    });
    if (new_listPlaylist.length === 0) {
      this.setState({ isPlayAll: 0 });
    } else if (new_listPlaylist.length < _listPharseOrigin_answers.length) {
      this.setState({ isPlayAll: 1 });
    } else {
      this.setState({ isPlayAll: 2 });
    }
  };
  tooglePlayReapeat = () => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    this.setState((prevState) => ({ isRepeat: !prevState.isRepeat }));
  };

  tooglePlayTrack = async (loop, currentPlay) => {

    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    const { selectedPhrasePlaylist } = this.state;

    if (selectedPhrasePlaylist.length > 0) {
      // if (CurrentPackageAmount === 0 && Trial) {
      //   let type = 6;
      //   if (loop) {
      //     this.updateTrialTimes(7);
      //   }
      //   let response = await this.updateTrialTimes(type);
      //   if (response) {
      //     if (response.useTrial) {
      //       this.playAudio();
      //       this.setState({
      //         isPlayingPlaylist: true,
      //         isPlayingPlaylistLoop: loop,
      //         isPlayingPlaylistLoopCount: 0,
      //         currentPlay: currentPlay,
      //       });
      //     }
      //   }
      // } else {
      this.playAudio();
      this.setState({
        isPlayingPlaylist: true,
        isPlayingPlaylistLoop: loop,
        isPlayingPlaylistLoopCount: 0,
        currentPlay: currentPlay,
      });
      // }
    }
  };
  tooglePauseTrack = () => {
    this.pauseAudio();
    this.setState({ isPlayingPlaylist: false });
  };
  resetAudio = () => {
    this.audio.load();
    this.audio.play();
  };
  // onLoadTrack = () => {
  //   const { isPlayingPlaylistLoop, isPlayingPlaylistLoopCount } = this.state;
  //   console.log(isPlayingPlaylistLoop, isPlayingPlaylistLoopCount);

  // }
  nextTrack = () => {
    const {
      selectedPhrasePlaylist,
      currentTrackIndex,
      isPlayingPlaylistLoop,
      isPlayingPlaylistLoopCount,
      isRepeat,
    } = this.state;
    if (isPlayingPlaylistLoop && isPlayingPlaylistLoopCount < 4) {
      this.setState({
        isPlayingPlaylistLoopCount: isPlayingPlaylistLoopCount + 1,
      });
    } else {
      this.setState({ isPlayingPlaylistLoopCount: 0, isWaiting: true });
      const nextIndex = (currentTrackIndex + 1) % selectedPhrasePlaylist.length;
      if (nextIndex === 0) {
        this.setState({ currentTrackIndex: 0, isWaiting: false });
        if (!isRepeat) {
          this.setState({
            isPlayingPlaylist: false,
            isPlayingPlaylistLoop: false,
          });
        }
      } else {
        setTimeout(() => {
          this.setState({ currentTrackIndex: nextIndex, isWaiting: false });
          this.resetAudio();
        }, 1000);
      }
    }
  };
  hanldeOpenModalPlaylist = (item) => {
    this.setState({
      selectedPhraseToAddPlaylist: item,
      showModalPlaylist: true,
      showCreatePlaylist: false,
    });
    this.hanldeLoadPlaylist(1);
  };
  hanldeLoadPlaylist = async (type) => {
    this.setState({ isLoading: true });
    try {
      let response = await get("/Users/GetPlaylist?PlaylistName=");
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          if (type === 0) {
            //get DS
            this.setState({ userPlaylist: response.data.data });
            if (response.data.data.length > 0) {
              if (this.state.selectedPlaylist) {
                this.hanldeLoadPharseByPlaylist(
                  this.state.selectedPlaylist.playlistID
                );
              } else {
                let _selectedPlaylist = response.data.data[0];
                this.setState({ selectedPlaylist: _selectedPlaylist });
                this.hanldeLoadPharseByPlaylist(_selectedPlaylist.playlistID);
              }
            } else {
              this.setState({ listPharse: [] });
            }
          } else {
            //get for modal
            this.setState({ modalUserPlaylist: response.data.data });
          }
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeShowCreatePlaylist = () => {
    this.setState({
      createPLName: "",
      showCreatePlaylist: true,
      errcreatePLName: "",
    });
  };
  handleCreateNewPlaylist = async () => {
    const { createPLName } = this.state;
    let formIsValid = true;
    let error = "";
    if (!createPLName) {
      formIsValid = false;
      error = t("Please enter playlist name.");
    }
    this.setState({ errcreatePLName: error });
    if (formIsValid) {
      this.setState({ isLoading: true });
      try {
        let params = {
          PlaylistName: createPLName,
        };
        const response = await post("/Users/CreatePlaylist", params);
        if (response.data.errorCode === 0) {
          this.setState({ isLoading: false, showCreatePlaylist: false });
          toast.success(t("Create the playlist successfully."), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
          this.hanldeLoadPlaylist(1);
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  resetCreateNewPlaylist = () => {
    this.setState({ createPLName: "", showCreatePlaylist: false });
  };
  hanldeAddToUserPlayList = async (e, item) => {
    let url = "/Users/AddPhraseToPlaylist";
    let title = t("The phrase has been added to the playlist successfully.");
    if (!e.target.checked) {
      title = t("The phrase has been removed from the playlist.");
      url = "/Users/DeletePhraseFromPlaylist";
    }
    this.setState({ isLoading: true });
    try {
      let params = {
        PlaylistID: item.playlistID,
        PhraseID: this.state.selectedPhraseToAddPlaylist.phraseID,
      };
      const response = await post(url, params);
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(title, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        // this.updateTrialTimes(1);
        this.hanldeLoadPlaylist(1);
        if (this.state.currentSearchType === 1) {
          this.hanldeLoadPharseByPlaylist();
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeToogleFavorite = async (item, isChildPhrase) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    // if (CurrentPackageAmount === 0 && Trial) {
    //   let response = await this.updateTrialTimes(1);
    //   if (response) {
    //     if (response.useTrial) {
    //       if (item.isFavourite) {
    //         this.handleRemovePhraseFromFavorite(item, isChildPhrase);
    //       } else {
    //         this.hanldeAddToFavorite(item, isChildPhrase);
    //       }
    //     }
    //   }
    // } else {
    if (item.isFavourite) {
      this.handleRemovePhraseFromFavorite(item, isChildPhrase);
    } else {
      this.hanldeAddToFavorite(item, isChildPhrase);
    }
    // }
  };
  handleRemovePhraseFromFavorite = async (item, isChildPhrase) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    // Xử lý khi người dùng chọn OK
    this.setState({ showConfirm: false });
    this.setState({ isLoading: true });
    let params = {
      PlaylistID: 0,
      PhraseID:
        item.phraseType && item.phraseType === 3
          ? item.phraseID
          : item.isDelete
            ? item.id
            : item.phraseID,
      PhraseType:
        item.phraseType && item.phraseType === 3 ? 3 : item.isDelete ? 2 : 1, //1: system phrase, 2: contribute phrase
    };
    try {
      let response = await post("/Users/DeletePhraseFromPlaylist", params);
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(t("The phrase has been removed from the favorites."), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        if (this.state.currentSearchType === 1) {
          this.hanldeLoadPharseByPlaylist();
        } else {
          let _listPharse = [...this.state.listPharse];
          item.isFavourite = false;
          item.playlistID = response.data.data.playlistID;
          _listPharse.map((el, index) => {
            if (isChildPhrase) {
              if (el.index === item.parentIndex) {
                el.listAnswer = this.updateItemList(el.listAnswer, item);
              }
            } else {
              if (el.index === item.index) {
                el.isFavourite = false;
                el.playlistID = 0;
              }
            }
            return el;
          });
          this.setState({ listPharse: _listPharse });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeAddToFavorite = async (item, isChildPhrase) => {
    this.setState({ isLoading: true });
    try {
      let params = {
        PhraseID:
          item.phraseType && item.phraseType === 3
            ? item.phraseID
            : item.isDelete
              ? item.id
              : item.phraseID,
        PhraseType:
          item.phraseType && item.phraseType === 3 ? 3 : item.isDelete ? 2 : 1, //1: system phrase, 2: contribute phrase
      };
      const response = await post("/Users/AddPhraseToPlaylistDefault", params);
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(
          t("The phrase has been added to the favorites successfully."),
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          }
        );
        let _listPharse = [...this.state.listPharse];
        if (!isChildPhrase) {
          _listPharse.map((el, index) => {
            if (el.index === item.index) {
              el.isFavourite = true;
              el.playlistID = 0;
            }
            return el;
          });
        } else {
          item.isFavourite = true;
          item.playlistID = response.data.data.playlistID;
          _listPharse.map((el, index) => {
            if (el.index === item.parentIndex) {
              // if (el.showBackupPhrase) {
              //   el.listBackup = this.updateItemList(el.listBackup, item);
              // }
              el.listAnswer = this.updateItemList(el.listAnswer, item);
            }
            return el;
          });
        }

        this.setState({ listPharse: _listPharse });
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeToogleEdit = (item) => {
    let _userPlaylist = [...this.state.userPlaylist];
    _userPlaylist.map((el, index) => {
      if (el.playlistID === item.playlistID) {
        el.toogleEdit = true;
        el.updateName = item.playlistName;
        el = item;
        this.setState({ userPlaylist: _userPlaylist });
      }
      return el;
    });
    this.setState({ selectedPlaylistToEdit: item });
  };
  handleInputUpdatePlaylist = (e, item) => {
    item.updateName = e.target.value;
    let _userPlaylist = [...this.state.userPlaylist];
    _userPlaylist.map((el, index) => {
      if (el.playlistID === item.playlistID) {
        el = item;
        this.setState({ userPlaylist: _userPlaylist });
      }
      return el;
    });
  };
  handleUpdatePlaylist = async (item, type) => {
    if (type === 0) {
      //submit
      //handle update status practive
      let formIsValid = true;
      let error = "";
      if (!item.updateName) {
        formIsValid = false;
        error = t("Please enter playlist name.");
      }
      if (!formIsValid) {
        item.errUpdateName = error;
        let _userPlaylist = [...this.state.userPlaylist];
        _userPlaylist.map((el, index) => {
          if (el.playlistID === item.playlistID) {
            el = item;
            this.setState({ userPlaylist: _userPlaylist });
          }
          return el;
        });
      } else {
        item.errUpdateName = error;
        // Submit form
        this.setState({ isLoading: true });
        try {
          let params = {
            PlaylistID: item.playlistID,
            PlaylistName: item.updateName,
          };
          const response = await post("/Users/UpdatePlaylist", params);
          if (response.data.errorCode === 0) {
            this.setState({ isLoading: false });
            toast.success(t("Update successfully."), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            });
            item.toogleEdit = false;
            item.playlistName = item.updateName;
            let _userPlaylist = [...this.state.userPlaylist];
            _userPlaylist.map((el, index) => {
              if (el.playlistID === item.playlistID) {
                el = item;
                this.setState({ userPlaylist: _userPlaylist });
              }
              return el;
            });
          } else {
            this.setState({ isLoading: false });
            toast.error(t(response.data.errorMessage), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
              onOpen: () => checkToken(response.data),
            });
          }
        } catch (error) {
          this.setState({ isLoading: false });
          let response = error.response;
          if (response.data) {
            toast.error(t(response.data.errorMessage), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              onOpen: () => checkToken(response.data),
            });
          } else {
            checkToken(response);
          }
        }
      }
    } else {
      //cancel
      item.toogleEdit = false;
      item.errUpdateName = "";
      let _userPlaylist = [...this.state.userPlaylist];
      _userPlaylist.map((el, index) => {
        if (el.playlistID === item.playlistID) {
          el = item;
          this.setState({ userPlaylist: _userPlaylist });
        }
        return el;
      });
    }
  };
  handleRemovePhraseFromPlaylist = async (item) => {
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    // Xử lý khi người dùng chọn OK
    this.setState({ showConfirm: false });
    this.setState({ isLoading: true });
    let params = {
      PlaylistID: item.playlistID,
      PhraseID: item.phraseID,
    };
    try {
      let response = await post("/Users/DeletePhraseFromPlaylist", params);
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(t("The phrase has been removed from the favorites."), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        this.hanldeLoadPharseByPlaylist(item.playlistID);
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  handleConfirm = async () => {
    // Xử lý khi người dùng chọn OK
    this.setState({ showConfirm: false });
    this.setState({ isLoading: true });
    const { modalType, selectedPhrase, selectedTopic } = this.state;
    let params = null;
    let url = "/Users/DeletePlaylist";
    if (modalType === 1) {
      url = "/Users/DeletePhrasesContribute";
      params = {
        ID: selectedPhrase.id,
      };
    } else if (modalType === 3) {
      url = "/Users/DeletePhrasesTopicUser";
      params = {
        TopicID: selectedTopic,
        PhraseID: selectedPhrase,
      };
    } else {
      params = {
        PlaylistID: this.state.selectedPlaylistToEdit.playlistID,
      };
    }
    try {
      let response = await post(url, params);
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (modalType === 1) {
          this.setState({
            PageIndex: 1,
            listPharse: [],
            TotalRows: 0,
            isPlayAll: 0,
            currentTrackIndex: 0,
            selectedPlaylist: [],
            showTopic: false,
            showSearchABC: false,
          }); //
          this.hanldeLoadPharse(selectedTopic.topicID, 1);
        } else if (modalType === 3) {
          this.hanldeLoadPharseUserTopic(selectedTopic);
        } else {
          this.hanldeLoadPlaylist(0);
        }
        toast.success(t("Delete successfully."), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  handleCancel = () => {
    // Xử lý khi người dùng chọn Close
    this.setState({ showConfirm: false });
  };
  handleCancelDelte = () => {
    // Xử lý khi người dùng chọn Close
    this.setState({ modalContributeDelete: false });
  };
  handleOpenModal = (item, type = 0) => {
    //0: remove playlist, 1: delete contribute
    // Mở modal confirm
    if (type === 1) {
      this.setState({
        confirmText: t("Are you sure to remove this phrase?"),
        modalType: type,
        selectedPhrase: item,
      });
    } else if (type === 3) {
      this.setState({
        confirmText: t("Are you sure to remove this phrase of topic?"),
        modalType: type,
        selectedPlaylistToEdit: item,
        selectedPhrase: item.phraseID,
        selectedTopic: item.topicID,
      });
    } else {
      this.setState({
        confirmText: t("Are you sure to remove this playlist?"),
        modalType: type,
        selectedPlaylistToEdit: item,
      });
    }
    this.setState((prevState) => ({
      showConfirm: !prevState.showConfirm,
    }));
  };
  // toogleVietnamese = () => {
  //   this.setState(prevState => ({
  //     showVietnamese: !prevState.showVietnamese
  //   }));
  // };
  toogleSearchABC = () => {
    this.setState((prevState) => ({
      showSearchABC: !prevState.showSearchABC,
    }));
    if (this.state.showSearchABC) {
      document.body.classList.remove("show-alphabet");
    } else {
      document.body.classList.add("show-alphabet");
    }
  };
  toogleSearchInput = () => {
    this.setState((prevState) => ({
      showSearchInput: !prevState.showSearchInput,
    }));
  };
  handleSearchABC = async (key) => {
    if (this.validateBuyPackage()) {
      this.setState((prevState) => ({
        // showSearchABC: !prevState.showSearchABC,
        alphabetSelected: key,
        PageIndex: 1,
        PageSize: 20,
        currentTrackIndex: 0,
        isPlayingSingleWord: false,
        isPlayingPlaylist: false,
        selectedPhrasePlaylist: [],
        selectedTopic: null,
        selectedPlaylist: null,
        showPlaylist: false,
        showTopic: false,
        isActiveTopic: false,
        listPharse: [],
        phraseRefs: [],
        TotalRows: 0,
        showSearchABC: prevState.isMobile ? false : prevState.showSearchABC,
        // showTopic: prevState.isMobile ? false : prevState.showTopic,
        isPlayAll: 0,
      }));
      this.hanldeLoadPharseByStartCharacter(key, 1);
    }
  };
  handleSearchInput = async () => {
    if (this.validateBuyPackage()) {
      this.setState((prevState) => ({
        // showSearchInput: !prevState.showSearchInput,
        PageIndex: 1,
        PageSize: 20,
        currentTrackIndex: 0,
        isPlayingSingleWord: false,
        isPlayingPlaylist: false,
        selectedPhrasePlaylist: [],
        selectedTopic: null,
        selectedPlaylist: null,
        listPharse: [],
        TotalRows: 0,
        showPlaylist: false,
        // showTopic: false,
        isActiveTopic: false,
        phraseRefs: null,
        TotalRows: 0,
        alphabetSelected: "",
        showSearchABC: prevState.isMobile ? false : prevState.showSearchABC,
        showTopic: false,
        isPlayAll: 0,
      }));
      this.hanldeLoadPharseBySearchKey(
        this.state.searchKey ? this.state.searchKey : "",
        1
      );
    }
  };
  scrollToCenter = (item) => {
    if (item && this.state.phraseRefs[item.index]) {
      const element = this.state.phraseRefs[item.index].current;
      // const canScroll = element.scrollHeight > element.clientHeight;
      // console.log(element);
      // console.log(canScroll);
      // if (element && canScroll) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // }
    }
  };
  loadMoreData = () => {
    const {
      currentSearchType,
      alphabetSelected,
      selectedTopic,
      PageIndex,
      TotalRows,
      PageSize,
      searchKey,
    } = this.state;
    let nextPage = PageIndex + 1;
    let maxPage = Math.ceil(TotalRows / PageSize);
    if (nextPage <= maxPage) {
      if (currentSearchType === 0) {
        this.setState({ PageIndex: nextPage });
        this.hanldeLoadPharse(selectedTopic.topicID, nextPage);
      }
      if (currentSearchType === 2) {
        this.setState({ PageIndex: nextPage });
        this.hanldeLoadPharseByStartCharacter(alphabetSelected, nextPage);
      }
      if (currentSearchType === 3) {
        this.setState({ PageIndex: nextPage });
        this.hanldeLoadPharseBySearchKey(searchKey ? searchKey : "", nextPage);
      }
    }
  };

  hanldeEnterSearch = (e) => {
    var key = e.keyCode || e.which;
    e.preventDefault();
    if (key === 13) {
      this.handleSearchInput();
    }
    if (e.target.value) {
      if (this.state.showTopic) {
        this.setState({ showTopic: false });
      }
    }
    return false;
  };
  hanldeEnterPractive = (e, item) => {
    var key = e.keyCode || e.which;
    e.preventDefault();
    if (key === 13) {
      this.handlePracticeWord(item, 0);
    }
    if (key === 27) {
      this.handlePracticeWord(item, 1);
    }
    return false;
  };
  hanldeEnterPlaylist = (e, item) => {
    var key = e.keyCode || e.which;
    e.preventDefault();
    if (key === 13) {
      this.handleCreateNewPlaylist();
    }
    if (key === 27) {
      this.resetCreateNewPlaylist();
    }
    return false;
  };
  hanldeEnterUpdatePlaylist = (e, item) => {
    var key = e.keyCode || e.which;
    e.preventDefault();
    if (key === 13) {
      this.handleUpdatePlaylist(item, 0);
    }
    if (key === 27) {
      this.handleUpdatePlaylist(item, 1);
    }
    return false;
  };
  updateTrialTimes = async (type) => {
    // TrialType = 1: add playlist, 2: watch video, 3: view image, 4: sound, 5: sound NPL, 6: sound all, 7: sound NPL all
    const { CurrentPackageAmount } = this.state;
    if (CurrentPackageAmount === 0) {
      let _params = {
        TrialType: type,
      };
      try {
        let response = await post("/Users/TrialLearn", _params);
        if (response.data.errorCode === 1) {
          this.setState({
            isLoading: true,
            Trial: response.data.data.useTrial,
            UseTrialPlayPlaylist: response.data.data.useTrialPlayPlaylist,
          });
          if (!response.data.data.useTrial) {
            toast.error(
              t(
                "Your trial has expired. Please purchase a package to continue learning."
              ),
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                onClose: () => redirectToURL("/membership"),
              }
            );
          }
        }
        return response.data.data;
      } catch (error) {
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  openVideoImage = async (url, type, phrase) => {
    const { Trial, CurrentPackageAmount, isPlayingPlaylist } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    // if (CurrentPackageAmount === 0 && Trial) {
    //   let response = await this.updateTrialTimes(type);
    //   if (response) {
    //     if (response.useTrial) {
    //       // if (type === 2) {
    //       //   window.open(url, "_blank", "noreferrer");
    //       // } else {
    //       this.handleGetVideoOrImage(type, phrase);
    //       // }
    //     }
    //   }
    // } else {
    // if (type === 2) {
    //   window.open(url, "_blank", "noreferrer");
    // } else {
    this.handleGetVideoOrImage(type, phrase);
    // }
    // }

    // if (CurrentPackageAmount === 0 && Trial) {
    //   let response = await this.updateTrialTimes(type);
    //   if (response) {
    //     if (response.useTrial) {
    //       window.open(url, "_blank", "noreferrer");
    //     }
    //   }
    // } else {
    //   window.open(url, "_blank", "noreferrer");
    // }
  };
  handleGetVideoOrImage = async (type, phrase) => {
    this.setState({ selectedPhrase: phrase });
    if (type === 2) {
      if (phrase.videos && phrase.videos != "") {
        //exist video
        let videos = JSON.parse(phrase.videos);
        let _listVideoID = videos.videoIDs;
        let _listStartTime = videos.startTime ? videos.startTime : [];
        if (_listVideoID.length > 0) {
          let _videoId = _listVideoID[0];
          let currentSeconds =
            _listStartTime.length > 0 ? _listStartTime[0] : 0;
          this.setState({
            showVideo: true,
            currentVideoIndex: 0,
            listVideoID: _listVideoID,
            videoId: _videoId,
            listStartTime: _listStartTime,
            currentSeconds: currentSeconds,
          });
        } else {
          toast.error(t("Waiting for update"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      } else {
        toast.error(t("Waiting for update"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    } else {
      console.log("phrase", phrase);
      //Images
      if (phrase.images && phrase.images !== "") {
        //exist images
        let _listImages = JSON.parse(phrase.images);
        // if (_listImages && _listImages.length > 0) {
        //   _listImages = await this.urlValidation(_listImages, phrase);
        // }
        this.setState({
          showImage: true,
          listImages: _listImages,
          currentImageIndex: 0,
          currentImage: _listImages[0],
        });
      } else {
        toast.error(t("Waiting for update"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    }
  }

  //====Video Relate===

  nextVideo = () => {
    const { listVideoID, currentVideoIndex, listStartTime } = this.state;
    if (currentVideoIndex < listVideoID.length - 1) {
      this.setState((prevState) => ({
        currentVideoIndex: prevState.currentVideoIndex + 1,
        videoId: listVideoID[prevState.currentVideoIndex + 1],
        currentSeconds:
          listStartTime.length > 0
            ? listStartTime[prevState.currentVideoIndex + 1]
            : 0,
      }));
    } else {
      this.setState({
        currentVideoIndex: 0,
        videoId: listVideoID[0],
        currentSeconds: listStartTime.length > 0 ? listStartTime[0] : 0,
      });
    }
  };
  prevVideo = () => {
    const { listVideoID, currentVideoIndex, listStartTime } = this.state;
    if (currentVideoIndex > 0) {
      this.setState((prevState) => ({
        currentVideoIndex: prevState.currentVideoIndex - 1,
        videoId: listVideoID[prevState.currentVideoIndex - 1],
        currentSeconds:
          listStartTime.length > 0
            ? listStartTime[prevState.currentVideoIndex - 1]
            : 0,
      }));
    } else {
      this.setState({
        currentVideoIndex: listVideoID.length - 1,
        videoId: listVideoID[listVideoID.length - 1],
        currentSeconds:
          listStartTime.length > 0
            ? listStartTime[listStartTime.length - 1]
            : 0,
      });
    }
  };
  replayVideo = () => {
    this.setState((prevState) => ({
      replayCount: prevState.replayCount + 1,
    }));
  };
  savePhraseVideos = async (params) => {
    // window.location.href = configs.WebRoot + "/transaction/4";
    this.setState({ isLoading: true });
    try {
      const response = await post("/Users/UpdatePhraseVideos", params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  urlValidation = async (_listImages, imglink) => {
    this.setState({ isLoading: true });
    let listImage = [..._listImages];
    const isValidImage = await validateImage(imglink);
    if (!isValidImage) {
      listImage = _listImages.filter(x => x !== imglink);
      this.handleUpdateErrorImage(this.state.selectedPhrase, listImage);
    }
    // for (var i in _listImages) {
    //   let imglink = _listImages[i];
    //   const isValidImage = await validateImage(imglink);
    //   if (!isValidImage) {
    //     listImage = _listImages.filter(x => x !== imglink);
    //     // this.handleUpdateErrorImage(phrase, listImage);
    //   } else {
    //     listImage.push(imglink);
    //   }
    // }
    this.setState({ isLoading: false });
    return isValidImage;
  };
  nextImage = async () => {
    const { listImages, currentImageIndex } = this.state;
    let isValidImage = true;
    if (currentImageIndex < listImages.length - 1) {
      let current_img = listImages[currentImageIndex + 1]
      if (current_img) {
        isValidImage = await this.urlValidation(listImages, current_img);
      }
      this.setState((prevState) => ({
        currentImageIndex: prevState.currentImageIndex + 1,
        currentImage: isValidImage ? current_img : require("assets/img/default_img.jpg"),
      }));
    } else {
      this.setState({ currentImageIndex: 0, currentImage: listImages[0] });
    }
  };
  prevImage = async () => {
    const { listImages, currentImageIndex } = this.state;
    let isValidImage = true;
    if (currentImageIndex > 0) {
      let current_img = listImages[currentImageIndex - 1]
      if (current_img) {
        isValidImage = await this.urlValidation(listImages, current_img);
      }
      this.setState((prevState) => ({
        currentImageIndex: prevState.currentImageIndex - 1,
        currentImage: isValidImage ? current_img : require("assets/img/default_img.jpg"),
      }));
    } else {
      let current_img = listImages[listImages.length - 1]
      if (current_img) {
        isValidImage = await this.urlValidation(listImages, current_img);
      }
      this.setState({
        currentImageIndex: listImages.length - 1,
        currentImage: isValidImage ? current_img : require("assets/img/default_img.jpg"),
      });
    }
  };

  savePhraseImages = async (params) => {
    // window.location.href = configs.WebRoot + "/transaction/4";
    this.setState({ isLoading: true });
    try {
      const response = await post("/Users/AutoUpdatePhraseImages", params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  changeFlag = (item) => {
    this.setState({ currentFlags: item });
    if (item.code !== "en") {
      this.setState({ currentLanguage: item });
    }
    localStorage.setItem("flag", JSON.stringify(item));
    // if (item.id === 1) {
    //   this.setState({ showVietnamese: true })
    // } else {
    //   this.setState({ showVietnamese: false })
    // }
  };
  //**************Sub Topic******************
  handleGetChild = async (item) => {
    if (!item.showchild) {
      item.showchild = true;
      let search = JSON.parse(localStorage.getItem("search"));
      let _selectedTopic = item.child.find(
        (el) => el.topicID === search.keyword
      );
      this.setState({ selectedTopic: _selectedTopic });
    } else {
      item.showchild = false;
    }
    this.updateChildData(item);
    // if (item.showchild) {//hide child
    //   item.showchild = false;
    //   this.updateChildData(item);
    // } else {//show child available
    //   if (item.child && item.child.length > 0) {
    //     item.showchild = true;
    //     this.updateChildData(item);
    //   } else {//get child
    //     try {
    //       let response = await get("/Users/GetSubTopic?TopicID=" + item.topicID);
    //       if (response.data.errorCode === 0) {
    //         item.child = response.data.data;
    //         item.showchild = false;
    //         let search = JSON.parse(localStorage.getItem("search"));
    //         if (search && search.type === 0) {
    //           let _selectedTopic = response.data.data.find(el => el.topicID === search.keyword);
    //           this.setState({ selectedTopic: _selectedTopic });
    //         }
    //         this.updateChildData(item);
    //       } else {
    //         toast.error(t(response.data.errorMessage), {
    //           position: toast.POSITION.TOP_CENTER,
    //           autoClose: 5000,
    //           onOpen: () => checkToken(response.data)
    //         });
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       let response = error.response;
    //       if (response && response.data) {
    //         toast.error(t(response.data.errorMessage), {
    //           position: toast.POSITION.TOP_CENTER,
    //           autoClose: 5000,
    //           onOpen: () => checkToken(response.data)
    //         });
    //       } else {
    //         checkToken(response)
    //       }
    //     }
    //   }
    // }
  };
  // Hàm đệ quy để tìm vị trí của phần tử dựa trên name
  findTopicPosition(data, topicID, parentIndices = []) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const currentIndices = [...parentIndices, i];
      if (item.topicID === topicID) {
        return { indices: currentIndices };
      } else if (item.child && item.child.length > 0) {
        const result = this.findTopicPosition(
          item.child,
          topicID,
          currentIndices
        );
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  // Hàm để cập nhật dữ liệu cây chủ đề
  updateChildData = (item) => {
    const { listTopic } = this.state;
    const position = this.findTopicPosition(listTopic, item.topicID);
    if (position) {
      const { indices } = position;
      let currentData = listTopic;
      // Truy cập dựa trên indices và cập nhật dữ liệu
      for (let i = 0; i < indices.length - 1; i++) {
        currentData = currentData[indices[i]].child;
      }
      currentData[indices[indices.length - 1]] = item;
      // Cập nhật state
      this.setState({ listTopic });
    }
  };
  renderSubData(data, prIdx) {
    const { currentFlags, selectedTopic, arrFlag } = this.state;
    return (
      <>
        {data.map((item, index) => (
          <React.Fragment key={`sub${prIdx}${index}`}>
            <ul className="sub-topic">
              <li
                className={
                  "sub-item " +
                  (selectedTopic && item.topicID === selectedTopic.topicID
                    ? "active"
                    : "")
                }
              >
                <div
                  className="topic-content"
                  onClick={() => this.handleSelectedTopic(item)}
                >
                  <div className="name">{item.name}</div>
                  <div className="word-trans">
                    <div>
                      {item[arrFlag[0].propertiesTopic]}
                    </div>
                    {currentFlags.id !== 0 && <div>
                      {item[currentFlags.propertiesTopic]}
                    </div>}
                  </div>
                  {/* {showVietnamese && <div className="word-trans">
                    {item.vietnameseName}
                  </div>}
                  {!showVietnamese && <div className="word-trans">
                    {item.pronounce}
                  </div>} */}
                </div>
                {item.topicID > -1 && (
                  <div
                    className={`btn-add pointer`}
                    onClick={() =>
                      this.toogleModalContribute(1, 0, item.topicID)
                    }
                    title={t("Contribute phrases")}
                  >
                    <i className={`fa fa-plus`}></i>
                  </div>
                )}
                {prIdx <= 1 && item.hasChild && (
                  <span
                    className="topic-caret"
                    onClick={() => this.handleGetChild(item)}
                  >
                    <i
                      className={`fa fa-caret-${item.showchild === true ? "up" : "down"
                        }`}
                      aria-hidden="true"
                    ></i>
                  </span>
                )}
              </li>
              {item.showchild === true &&
                item.child &&
                item.child.length > 0 &&
                this.renderSubData(item.child, prIdx + 1)}
            </ul>
          </React.Fragment>
        ))}
      </>
    );
  }
  clearPhraseChildFromSelected = (list) => {
    list.map((element, index) => {
      element.selectedPlaylist = false;
      element.loop = false;
      return element;
    });
  };
  //**************Backup Phrase*****************
  openModalBackup = async (item) => {
    // console.log("item main", item);
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    if (item.showBackupPhrase) {
      let _listPharse = [...this.state.listPharse];
      _listPharse.map((el, index) => {
        if (el.phraseID === item.phraseID && el.index === item.index) {
          el.showBackupPhrase = false;
          if (el.listBackup) {
            this.clearPhraseChildFromSelected(el.listBackup);
          }
        }
        return el;
      });
      this.setState({ listPharse: _listPharse });
    } else {
      this.setState({ selectedPhrase: item });
      try {
        let response = await get(
          "/Users/GetPhraseBackup?PhraseID=" + item.phraseID
        );
        if (response.data.errorCode === 0) {
          let _listPharse = [...this.state.listPharse];
          _listPharse.map((el, index) => {
            if (el.phraseID === item.phraseID && el.index === item.index) {
              el.listBackup = response.data.data.map((element, idx) => {
                element.parentIndex = item.index;
                return element;
              });
              el.showBackupPhrase = true;
              el.showAnswerPhrase = false;
            }
            return el;
          });
          this.setState({ listPharse: _listPharse });
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
          // this.setState({ listBackup: response.data.data, showBackupPhrase: true })
        } else {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  replacePhrase = (item) => {
    // console.log("item backup", item);
    const { selectedPhrase, listPharse, selectedPlaylist } = this.state;
    if (listPharse) {
      let _listPharse = [...listPharse];
      _listPharse.map((el, index) => {
        if (el.index === selectedPhrase.index) {
          el.phraseID = item.phraseID;
          el.topicID = item.topicID;
          el.phrase = item.phrase;
          el.phraseVietnameseMeans = item.phraseVietnameseMeans;
          el.soundPath = item.soundPath;
          el.pronounce = item.pronounce;
          el.isFavourite = item.isFavourite;
          el.playlistID = item.playlistID;
          el.showBackupPhrase = false;
          el.showAnswerPhrase = false;
          el.phraseGroupChinaMeans = item.phraseGroupChinaMeans;
          el.phraseGroupHindiMeans = item.phraseGroupHindiMeans;
          el.phraseGroupSpainMeans = item.phraseGroupSpainMeans;
          el.phraseGroupFranceMeans = item.phraseGroupFranceMeans;
          el.phraseGroupArabMeans = item.phraseGroupArabMeans;
          el.phraseGroupRussiaMeans = item.phraseGroupRussiaMeans;
          el.phraseGroupPortugalMeans = item.phraseGroupPortugalMeans;
          el.phraseGroupItalyMeans = item.phraseGroupItalyMeans;
          el.phraseGroupGermanyMeans = item.phraseGroupGermanyMeans;
          el.chinaMeans = item.chinaMeans;
          el.hindiMeans = item.hindiMeans;
          el.spainMeans = item.spainMeans;
          el.franceMeans = item.franceMeans;
          el.arabMeans = item.arabMeans;
          el.russiaMeans = item.russiaMeans;
          el.portugalMeans = item.portugalMeans;
          el.italyMeans = item.italyMeans;
          el.germanyMeans = item.germanyMeans;
          el.hasBackup = item.hasBackup;
          el.hasAnswer = item.hasAnswer;
          el.haveLearned = item.haveLearned;
        }
        return el;
      });
      this.handleRefData(_listPharse);
      this.setState({ showBackupPhrase: false, listPharse: _listPharse });
    }
    if (selectedPlaylist) {
      let _selectedPlaylist = [...selectedPlaylist];
      _selectedPlaylist.map((el, index) => {
        if (el.index === selectedPhrase.index) {
          el.phraseID = item.phraseID;
          el.topicID = item.topicID;
          el.phrase = item.phrase;
          el.phraseVietnameseMeans = item.phraseVietnameseMeans;
          el.soundPath = item.soundPath;
          el.pronounce = item.pronounce;
          el.isFavourite = item.isFavourite;
          el.playlistID = item.playlistID;
          el.showBackupPhrase = false;
          el.showAnswerPhrase = false;
          el.phraseGroupChinaMeans = item.phraseGroupChinaMeans;
          el.phraseGroupHindiMeans = item.phraseGroupHindiMeans;
          el.phraseGroupSpainMeans = item.phraseGroupSpainMeans;
          el.phraseGroupFranceMeans = item.phraseGroupFranceMeans;
          el.phraseGroupArabMeans = item.phraseGroupArabMeans;
          el.phraseGroupRussiaMeans = item.phraseGroupRussiaMeans;
          el.phraseGroupPortugalMeans = item.phraseGroupPortugalMeans;
          el.phraseGroupItalyMeans = item.phraseGroupItalyMeans;
          el.phraseGroupGermanyMeans = item.phraseGroupGermanyMeans;
          el.chinaMeans = item.chinaMeans;
          el.hindiMeans = item.hindiMeans;
          el.spainMeans = item.spainMeans;
          el.franceMeans = item.franceMeans;
          el.arabMeans = item.arabMeans;
          el.russiaMeans = item.russiaMeans;
          el.portugalMeans = item.portugalMeans;
          el.italyMeans = item.italyMeans;
          el.germanyMeans = item.germanyMeans;
          el.hasBackup = item.hasBackup;
          el.hasAnswer = item.hasAnswer;
          el.haveLearned = item.haveLearned;
        }
        return el;
      });
      this.setState({ selectedPlaylist: _selectedPlaylist });
    }
  };
  //**************Answer Phrase*****************
  openModalAnswer = async (item) => {
    // console.log("item main", item);
    const { Trial, CurrentPackageAmount } = this.state;
    // if (CurrentPackageAmount === 0 && !Trial) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    if (item.showAnswerPhrase) {
      let _listPharse = [...this.state.listPharse];
      _listPharse.map((el, index) => {
        if (el.phraseID === item.phraseID && el.index === item.index) {
          el.showAnswerPhrase = false;
          if (el.listAnswer) {
            this.clearPhraseChildFromSelected(el.listAnswer);
          }
        }
        return el;
      });
      this.setState({ listPharse: _listPharse });
    } else {
      this.setState({ selectedPhrase: item });
      try {
        let response = await get(
          "/Users/GetPhraseAnswer?PhraseID=" + item.phraseID
        );
        if (response.data.errorCode === 0) {
          let _listPharse = [...this.state.listPharse];
          _listPharse.map((el, index) => {
            if (el.phraseID === item.phraseID && el.index === item.index) {
              el.listAnswer = response.data.data.map((element, idx) => {
                element.parentIndex = item.index;
                return element;
              });
              el.showAnswerPhrase = true;
              el.showBackupPhrase = false;
            }
            return el;
          });
          this.setState({ listPharse: _listPharse });
          const audioUrls = response.data.data.map((item) => item.soundPath);
          caches.open("audio-cache").then((cache) => {
            audioUrls.forEach((url) => {
              cache.match(url).then((response) => {
                if (!response) {
                  fetch(url).then((response) => {
                    if (response.status === 200) {
                      cache.put(url, response);
                    }
                  });
                }
              });
            });
          });
          // this.setState({ listBackup: response.data.data, showBackupPhrase: true })
        } else {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        console.log(error);
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  handleGroupPhrase = (phraselist) => {
    const groupedDataMap = new Map();
    phraselist.map((el, index) => {
      if (el.phraseGroupID === 0) {
        el.phraseGroupNumberOrder = 99999;
      }
      return el;
    });
    phraselist.sort(
      (a, b) => a.phraseGroupNumberOrder - b.phraseGroupNumberOrder
    );
    let i = 1;
    phraselist.forEach((item) => {
      item.stt = i++;
      if (groupedDataMap.has(item.phraseGroupID)) {
        groupedDataMap.get(item.phraseGroupID).push(item);
      } else {
        groupedDataMap.set(item.phraseGroupID, [item]);
      }
    });
    // Chuyển từ Map thành mảng
    const groupedData = Array.from(groupedDataMap.values());
    // if (groupedData && groupedData.length > 0 && groupedData[0] && groupedData[0][0] && groupedData[0][0].phraseGroupID === 0) {
    //   const firstItem = groupedData.shift();
    //   // Thêm phần tử đã loại bỏ vào cuối mảng
    //   groupedData.push(firstItem);
    // }
    // this.setState({ listPharse: this.state.listPharse.sort((a, b) => a.phraseGroupNumberOrder - b.phraseGroupNumberOrder) })
    return groupedData;
    //
  };
  checkPlayAll = () => {
    const { Trial, CurrentPackageAmount, UseTrialPlayPlaylist } = this.state;
    // if (CurrentPackageAmount === 0 && (!Trial || !UseTrialPlayPlaylist)) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    const { isPlayAll } = this.state; //0: empty ,1: minux, 2 all
    this.setState((prevState) => ({
      isPlayAll: prevState.isPlayAll === 0 ? 2 : 0,
    }));
    if (isPlayAll === 0) {
      this.playSelected(0);
    } else {
      this.playSelected(2);
    }
  };
  toogleHidePhraseText = () => {
    this.setState((prevState) => ({
      isHidePhraseText: !prevState.isHidePhraseText,
    }));
  };
  hideFindF1 = () => {
    this.setState({ showFindF1: false });
  };
  // toogleHideBuyPackage = () => {
  //   this.setState({ showBackupPhrase: false });
  // }

  //Playlist Action
  handlePlayPlaylist = async (loop, currentPlay) => {
    const {
      Trial,
      CurrentPackageAmount,
      audioElement,
      selectedPhrasePlaylist,
      currentTrackIndex,
      UseTrialPlayPlaylist,
    } = this.state;
    // if (CurrentPackageAmount === 0 && (!Trial || !UseTrialPlayPlaylist)) {
    //   this.setState({ showBuyPackage: true });
    //   return false;
    // }
    let _listPharse = [...this.state.listPharse];
    _listPharse.map((el, index) => {
      el.completeListen = false
      return el;
    });
    this.setState({ listPharse: _listPharse })
    if (selectedPhrasePlaylist && selectedPhrasePlaylist.length > 0) {
      // if (CurrentPackageAmount === 0 && Trial) {
      //   let type = 6;
      //   if (loop) {
      //     type = 7;
      //   }
      //   let response = await this.updateTrialTimes(type);
      //   if (response) {
      //     if (response.useTrial) {
      //       this.setState({ isPlayingPlaylist: true });
      //       if (audioElement.paused) {
      //         this.setState({
      //           isPlayingPlaylistLoop: loop,
      //           isPlayingPlaylistLoopCount: 0,
      //           currentPlay: currentPlay,
      //         });
      //         audioElement.src =
      //           selectedPhrasePlaylist[currentTrackIndex].soundPath;
      //         audioElement.load();
      //         audioElement.play();
      //       }
      //     }
      //   }
      // } else {
      this.setState({ isPlayingPlaylist: true });
      if (audioElement.paused) {
        this.setState({
          isPlayingPlaylistLoop: loop,
          isPlayingPlaylistLoopCount: 0,
          currentPlay: currentPlay,
        });
        audioElement.src =
          selectedPhrasePlaylist[currentTrackIndex].soundPath;
        audioElement.load();
        audioElement.play();
      }
      // }
    }
  };
  handlePausePlaylist = () => {
    this.state.audioElement.pause();
    this.setState({ isPlayingPlaylist: false });
    this.handleBonusListenPlaylist();
  };

  handleNext = () => {
    const {
      currentTrackIndex,
      selectedPhrasePlaylist,
      isPlayingPlaylistLoop,
      isPlayingPlaylistLoopCount,
      isRepeat,
      isPlayingPlaylist,
    } = this.state;
    if (isPlayingPlaylist) {
      if (isPlayingPlaylistLoop && isPlayingPlaylistLoopCount < 5) {
        let _playlistArr = [...this.state.playlistArr];
        let audioItem = selectedPhrasePlaylist[currentTrackIndex];
        if (_playlistArr.findIndex(x => x.phraseID === audioItem.phraseID) === -1 && isPlayingPlaylistLoopCount === 4) {
          _playlistArr.push(audioItem);
          this.setState({ playlistArr: _playlistArr });
        }
        setTimeout(() => {
          this.setState(
            { isPlayingPlaylistLoopCount: isPlayingPlaylistLoopCount + 1 },
            this.updateAudio()
          );
        }, 1000);
      } else {
        // console.log("playlist " + currentTrackIndex, selectedPhrasePlaylist[currentTrackIndex]);
        this.setState({ isPlayingPlaylistLoopCount: 0 });
        if (currentTrackIndex < selectedPhrasePlaylist.length - 1) {
          setTimeout(() => {
            this.setState(
              (prevState) => ({
                currentTrackIndex: prevState.currentTrackIndex + 1,
              }),
              this.updateAudio
            );
          }, 2000);
        } else {
          // Nếu bạn muốn lặp lại danh sách phát, bạn có thể đặt lại currentTrackIndex về 0.
          // let playlistArr = selectedPhrasePlaylist && selectedPhrasePlaylist.length > 0 ? selectedPhrasePlaylist.map(x => x.phraseID).join(",") : "";
          this.handleBonusListenPlaylist();
          if (isRepeat) {
            setTimeout(() => {
              this.setState({ currentTrackIndex: 0 }, this.updateAudio);
            }, 2000);
          } else {
            this.setState({ isPlayingPlaylist: false, currentTrackIndex: 0 });
            if (selectedPhrasePlaylist && selectedPhrasePlaylist.length > 0) {
              this.scrollToCenter(selectedPhrasePlaylist[0]);
            }
          }
        }
      }
    }
  };
  updateAudio = () => {
    const {
      audioElement,
      currentTrackIndex,
      selectedPhrasePlaylist,
      isPlayingPlaylist,
    } = this.state;
    if (audioElement && isPlayingPlaylist) {
      audioElement.src = selectedPhrasePlaylist[currentTrackIndex].soundPath;
      audioElement.load();
      audioElement.play();
    }
  };
  validateBuyPackage = (item = null) => {
    // const { CurrentPackageAmount, listTopic } = this.state;
    // if (item) {
    //   if (CurrentPackageAmount === 0 && !item.isView) {
    //     this.setState({ showBuyPackage: true })
    //     return false;
    //   }
    // } else {
    //   if (CurrentPackageAmount === 0) {
    //     this.setState({ showBuyPackage: true })
    //     return false;
    //   }
    // }
    return true;
  };
  handleChangeTab = (tab) => {
    if (tab === "TransHistory") {
      redirectToURL("/transaction/1");
    } else if (tab === "ComHistory") {
      redirectToURL("/transaction/2");
    } else if (tab === "NLevel") {
      redirectToURL("/transaction/3");
    } else if (tab === "Profile") {
      redirectToURL("/transaction/4");
    }
  };
  toogleModalContribute = (type, phrase, topicID = 0) => {
    this.setState((prevState) => ({
      modalContribute: !prevState.modalContribute,
      contributeType: type,
      contributeValue: "",
      contributeValueVN: "",
      contributeByPhraseID: phrase ? phrase.phraseID : 0,
      contributeTopicID: topicID === 0 ? phrase.topicID : topicID,
      errorEditPhrase: "",
      errorEditPhraseVN: "",
      errorContribute: ""
    }));
  };

  toogleModalContributeDelete = (topicID, name) => {
    this.setState({ topicName: name });
    this.setState({ deleteTopic: Number(topicID) });
    this.setState((prevState) => ({
      modalContributeDelete: !prevState.modalContributeDelete,
    }));
  };

  toogleModalContributeAddSub = (topicID, name) => {
    this.setState({ topicName: name, phraseTopic: "" });
    this.setState({ addPhraseTopic: "" });
    this.setState({ addSubTopic: Number(topicID) });
    this.setState({ idTopicAddPhrase: Number(topicID) });
    this.setState((prevState) => ({
      modalContributeAddSub: !prevState.modalContributeAddSub,
    }));
  };
  toogleModalContributeAdd = () => {
    this.setState((prevState) => ({
      modalContributeAdd: !prevState.modalContributeAdd,
    }));
    this.setState((prevState) => ({ addtopic: "", errorAddTopic: "" }));
  };

  hanldeLoadTopicShare = async (id) => {
    this.setState({ isLoading: true });
    try {
      let response = await get(
        `/Users/GetTopicShareUser?PageSize=9999&PageIndex=1&TopicID=${id}`
      );
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          this.setState({ arrShareEmail: response.data.data });
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };

  toogleModalContributeShare = (name, id) => {
    this.setState({ topicName: name });
    this.setState({ idTopicShare: id });
    this.hanldeLoadTopicShare(id);
    this.setState((prevState) => ({
      modalContributeShare: !prevState.modalContributeShare,
    }));
    this.setState((prevState) => ({ emailShare: "", shareEmail: "" }));
  };
  handleContribute = async () => {
    const {
      contributeValue,
      contributeValueVN,
      contributeByPhraseID,
      contributeType,
      contributeTopicID,
      selectedTopic
    } = this.state;
    let formIsValid = true;
    if (!contributeValue) {
      // this.setState({ modalContribute: false });
      // return;
      formIsValid = false;
      this.setState({ errorContribute: t("Please enter the phrase.") });
    }
    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        let params = {
          Phrase: contributeValue,
          PhraseVietnameseMeans: contributeValueVN,
          PhraseAnswerOfID: contributeByPhraseID,
          // TopicID: contributeTopicID,
          // TopicName: selectedTopic.name
        };
        let url = "/Users/PhraseContribute";
        if (contributeType === 3) {
          //add anwser phrase của user Topic
          url = "/Users/AddAnswerPhraseUser";
        }
        const response = await post(url, params);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false, contributeValue: "" });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
          // this.hanldeLoadPharse(selectedTopic.topicID, 1);
          // this.hanldeLoadPharseUserTopic(contributeTopicID)
          this.hanldeLoadPharseByPlaylist();
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };

  handleAddEmailShareTopic = async () => {
    const { idTopicShare, emailShare, shareEmail } = this.state;
    let formIsValid = true;
    if (!emailShare) {
      formIsValid = false;
      this.setState({ shareEmail: t("Please enter the email share.") });
    }
    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        let params = {
          TopicID: idTopicShare,
          Email: emailShare,
        };
        const response = await post("/Users/AddUseShareTopic", params);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });

          this.setState({ emailShare: "" });
          this.hanldeLoadTopicShare(idTopicShare);
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };

  handleDeleteShareTopic = async (id) => {
    let formIsValid = true;

    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        let params = {
          ID: id,
        };
        const response = await post("/Users/UserDeleteShareTopic", params);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });

          this.hanldeLoadTopicShare(this.state.idTopicShare);
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  handleAddTopic = async () => {
    const { addtopic, errorAddTopic } = this.state;
    let formIsValid = true;
    if (!addtopic) {
      formIsValid = false;
      this.setState({ errorAddTopic: t("Please enter the topic.") });
    }
    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        const data = { TopicName: addtopic };
        const response = await post("/Users/AddTopicUser", data);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
          this.hanldeLoadUserTopic();
          this.setState({ modalContributeAdd: !this.state.modalContributeAdd });
          this.setState({ addtopic: "" });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  handleAddPhraseTopic = async () => {
    const { idTopicAddPhrase, addPhraseTopic, phraseTopic } = this.state;
    let formIsValid = true;
    if (!addPhraseTopic) {
      formIsValid = false;
      this.setState({ phraseTopic: t("Please enter the phrase.") });
    }
    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        const data = {
          TopicID: idTopicAddPhrase,
          Phrase: addPhraseTopic,
        };
        const response = await post("/Users/AddPhraseTopicUser", data);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });

          this.setState({
            modalContributeAddSub: !this.state.modalContributeAddSub,
          });
          this.setState({ addPhraseTopic: "" });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  handleDeleteTopic = async () => {
    const { deleteTopic } = this.state;
    let formIsValid = true;
    if (!deleteTopic) {
      formIsValid = false;
      this.setState({ errorContribute: t("Please enter the phrase.") });
    }
    // if (!contributeValueVN) {
    //   formIsValid = false;
    //   this.setState({ errorContributeVN: t('Please enter the phrase.') })
    // }
    if (formIsValid) {
      this.setState({ errorContribute: "", errorContributeVN: "" });
      this.setState({ isLoading: true });
      try {
        const data = { TopicID: deleteTopic };
        const response = await post("/Users/UserDeleteTopic", data);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalContribute: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
          this.hanldeLoadUserTopic();
          this.setState({
            modalContributeDelete: !this.state.modalContributeDelete,
          });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  toogleModalEditPhrase = (phrase) => {
    this.setState((prevState) => ({
      modalEditPhrase: !prevState.modalEditPhrase,
      editPhraseValue: phrase.phrase,
      editPhraseValueVN: phrase.phraseVietnameseMeans,
      EditPhraseID: phrase.phraseID,
      errorEditPhrase: "",
      errorEditPhraseVN: "",
    }));
  };
  handleEditPhrase = async () => {
    const { editPhraseValue, editPhraseValueVN, EditPhraseID } = this.state;
    let formIsValid = true;
    if (!editPhraseValue) {
      formIsValid = false;
      this.setState({ errorEditPhrase: t("Please enter the phrase.") });
    }
    if (!editPhraseValueVN) {
      formIsValid = false;
      this.setState({ errorEditPhraseVN: t("Please enter the phrase.") });
    }
    if (formIsValid) {
      this.setState({ errorEditPhrase: "", errorEditPhraseVN: "" });
      this.setState({ isLoading: true });
      try {
        let params = {
          Phrase: editPhraseValue,
          PhraseVietnameseMeans: editPhraseValueVN,
          PhraseID: EditPhraseID,
        };
        const response = await post("/Users/VIPEditPhrase", params);
        if (response && response.data.errorCode === 0) {
          this.setState({ isLoading: false, modalEditPhrase: false });
          toast.success(t("Requested successfully"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data) {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data),
          });
        } else {
          checkToken(response);
        }
      }
    }
  };
  handleUpdateErrorImage = async (phrase, images) => {
    let _listPharse = [...this.state.listPharse];
    phrase.images = JSON.stringify(images);
    _listPharse.map((el, index) => {
      if (el.phraseID === phrase.phraseID && el.index === phrase.index) {
        el = phrase;
        this.setState({ listPharse: _listPharse })
      }
      return el;
    });
    try {
      let params = {
        Images: JSON.stringify(images),
        PhraseID: phrase.phraseType && phrase.phraseType === 3
          ? phrase.phraseID
          : phrase.phraseType && phrase.phraseType === 2
            ? phrase.id
            : phrase.phraseID,
        PhraseType: phrase.phraseType ? phrase.phraseType : 1,
      };

      const response = await post("/Users/MarkErrorImage", params);
      if (response && response.data.errorCode === 0) {
        let _listPharse = [...this.state.listPharse];
        phrase.images = JSON.stringify(images);
        _listPharse.map((el, index) => {
          if (el.phraseID === phrase.phraseID && el.index === phrase.index) {
            el = phrase;
            this.setState({ listPharse: _listPharse })
          }
          return el;
        });
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  handleTrial = () => {
    let _freeTopic = this.state.listTopic.filter(x => x.freeTopic);
    if (_freeTopic && _freeTopic.length > 0) {
      let _selectedTopic = _freeTopic[0];
      this.setState({ selectedTopic: _selectedTopic, showModalNotify: false });
      this.hanldeLoadPharse(_selectedTopic.topicID, 1)
    }
  }
  handleBonusListenPlaylist = async () => {
    const { playlistArr } = this.state;
    if (!playlistArr || playlistArr.length <= 0) {
      return;
    }
    let phraselist = playlistArr && playlistArr.length > 0 ? playlistArr.map(x => x.phraseID).join(",") : "";
    try {
      let params = {
        PhraseIDLst: phraselist
      };
      const response = await post("/Users/SetLearnPlaylist", params);
      if (response && response.data.errorCode === 0) {
        this.setState({ playlistArr: [] })
        let message = response.data.data.errorMessage;
        if (response.data.data && response.data.data.amountBonus > 0) {
          this.showModalReceiveScholarShip(message, response.data.data.amountBonus);
        }
        else {
          if(response.data.data.notifyType===2){//fake
            this.showModalReceiveScholarShipFake(response.data.data.recommendAmount, response.data.data.inviteFriend);
          }
          else{//learnContinute
            this.showModalLearnNextPhrase();
          }
          // if (response.data.data.recommendAmount > 0) {
          //   if (response.data.data.totalTrans === 2 && response.data.data.amountReceived === 20) {
          //     this.showModalLearnNextPhrase();
          //   } else if ((response.data.data.totalTrans === 3 || response.data.data.totalTrans === 4) && response.data.data.amountReceived === 50) {
          //     this.showModalLearnNextPhrase();
          //   } else {
          //     let inviteFriend = 0;
          //     if (response.data.data.recommendAmount === 20) {
          //       inviteFriend = 2 - response.data.data.totalTrans;
          //     } else if (response.data.data.recommendAmount === 50) {
          //       inviteFriend = 3 - response.data.data.totalTrans;
          //     } else if (response.data.data.recommendAmount === 100) {
          //       inviteFriend = 5 - response.data.data.totalTrans;
          //     }
          //     if (inviteFriend > 0) {
          //       this.showModalReceiveScholarShipFake(response.data.data.recommendAmount, inviteFriend);
          //     } else {
          //       this.showModalLearnNextPhrase();
          //     }
          //   }
          // }
          // else {
          //   // if (response.data.data.totalTrans >= 0) {
          //   // if (response.data.data.totalTrans < 2) {
          //   //   // sửa lại popup nhận 3 cái xe
          //   //   this.showModalReceiveScholarShip_Car();
          //   // }
          //   // else
          //   // if (response.data.data.totalTrans === 2 && response.data.data.amountReceived >= 20 && response.data.data.amountReceived < 50) {
          //   //   this.showModaShare1Get300();
          //   // }
          //   // else if (response.data.data.totalTrans < 5 && response.data.data.amountReceived >= 50 && response.data.data.amountReceived < 100) {
          //   //   if (response.data.data.totalTrans === 4) {
          //   //     this.showModaShare1Get500();
          //   //   }
          //   //   else {
          //   //     this.showModaShare2Get500();
          //   //   }
          //   // }
          //   // else {
          //   this.showModalLearnNextPhrase();
          //   // }
          //   // }
          // }
        }
        this.hanldeLoadBasicInfo();
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  hanldeEnterContributePhrase = (e) => {
    var key = e.keyCode || e.which;
    e.preventDefault();
    if (key === 13) {
      this.handleContribute();
    }
    if (key === 27) {
      this.handleContribute();
    }
    return false;
  };
  hanldeCloseCongratulate = () => {
    document.body.classList.remove("modal-congratulate");
  }
  hanldeOpenCongratulate = () => {
    document.body.classList.add("modal-congratulate");
  }
  handleSetListenLearnedPhrase = async (item) => {
    try {
      let params = {
        PhraseID: item.phraseID,
        TopicID: item.topicID,
        WordPhrase: item.rewriteText,
      };
      this.setState({
        amountBonus_phrase: 0,
        inviteFriend_phrase: 0,
        recommendCar: 0,
        popupType: -1 //congra 
      })
      const response = await post("/Users/SetListenLearnedPhrase", params);
      if (response && response.data.errorCode === 0) {
        // response.data.data.amountBonus=100;   
        // response.data.data.recommendAmount=100;   
        // response.data.data.totalTrans=0;   
        // response.data.data.amountReceived=0;   
        // response.data.data.recommendCar=1;//Change image Car 
        if (response.data.data && response.data.data.amountBonus > 0) {
          this.setState({
            amountBonus_phrase: response.data.data.amountBonus,
            inviteFriend_phrase: 0,
            popupType: 1 //congra 
          })
          // this.showModalReceiveScholarShip(message, response.data.data.amountBonus);
        }
        else {
          if (response.data.data.recommendCar === 0) {
            if(response.data.data.notifyType===2){//fake
              this.setState({
                      amountBonus_phrase: response.data.data.recommendAmount,
                      inviteFriend_phrase: response.data.data.inviteFriend,
                      popupType: 2 //congra 
                    })
            }else{//learnContinute
              this.setState({
                    popupType: 0 //error
                  })
            }
            // if (response.data.data.totalTrans === 2 && response.data.data.amountReceived === 20) {
            //   this.setState({
            //     popupType: 0 //error
            //   })
            // } else if ((response.data.data.totalTrans === 3 || response.data.data.totalTrans === 4) && response.data.data.amountReceived === 50) {
            //   this.setState({
            //     popupType: 0 //error
            //   })
            // } else {
            //   let inviteFriend = 0;
            //   if (response.data.data.recommendAmount === 20) {
            //     inviteFriend = 2 - response.data.data.totalTrans;
            //   } else if (response.data.data.recommendAmount === 50) {
            //     inviteFriend = 3 - response.data.data.totalTrans;
            //   } else if (response.data.data.recommendAmount === 100) {
            //     inviteFriend = 5 - response.data.data.totalTrans;
            //   }
            //   if (inviteFriend > 0) {
            //     this.setState({
            //       amountBonus_phrase: response.data.data.recommendAmount,
            //       inviteFriend_phrase: inviteFriend,
            //       popupType: 2 //congra 
            //     })
            //   } else {
            //     this.setState({
            //       popupType: 0
            //     })
            //   }
            // }
          }
          else if (response.data.data.recommendCar > 0) {
            this.setState({
              recommendCar: response.data.data.recommendCar,
              popupType: 3 //car 
            })
          } else {
            this.setState({
              popupType: 0
            })
          }
        }
        this.hanldeLoadBasicInfo();
      } else {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      }
    } catch (error) {
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data),
        });
      } else {
        checkToken(response);
      }
    }
  };
  showCongratulate = (message) => {
    this.setState({ showModalreceiveBIT: true, textCongratulate: message });
    setTimeout(() => {
      this.setState({ showModalreceiveBIT: false, textCongratulate: "" });
    }, 3000)
  }
  showModalReceiveScholarShip = (message, amountScholarship) => {
    this.setState({ showModalReceiveScholarShip: true, textCongratulate: message, amountScholarship: amountScholarship });
    // setTimeout(() => {
    //   this.setState({ showModalReceiveScholarShip: false, textCongratulate: "" });
    // }, 5000)
  }
  showModalReceiveScholarShipFake = (amountScholarship, inviteFriend) => {
    this.setState({ showModalReceiveScholarShipFake: true, amountScholarship: amountScholarship, inviteFriend: inviteFriend });
    // setTimeout(() => {
    //   this.setState({ showModalReceiveScholarShip: false, textCongratulate: "" });
    // }, 5000)
  }
  showModalReceiveScholarShip_Car = () => {
    this.setState({ showModalReceiveScholarShip_Car: true });
    // setTimeout(() => {
    //   this.setState({ showModalReceiveScholarShip_Car: false });
    // }, 5000)
  }
  toggleModalCar = (modalName) => {
    this.setState({ [modalName]: !this.state[modalName] });
  };
  showModalLearnNextPhrase = () => {
    this.setState({ showModalLearnNextPhrase: true, });
  }
  showModaShare1Get300 = () => {
    this.setState({ showModaShare1Get300: true, });
  }
  showModaShare2Get500 = () => {
    this.setState({ showModaShare2Get500: true, });
  }
  showModaShare1Get500 = () => {
    this.setState({ showModaShare1Get500: true, });
  }
  tooglePopupBonus = (item) => {
    if (item.completeListen) {
      if (this.state.popupType === 3) {
        this.onTooglePolicyModal();
      }
      let _listPharse = [...this.state.listPharse];
      _listPharse.map((el, index) => {
        if (el.phraseID === item.phraseID) {
          el.completeListen = false;
          this.setState({ listPharse: _listPharse });
        }
        return el;
      });
    }

  }
  onTooglePolicyModal = () => {
    let arrLink = ["https://www.mbusa.com/en/vehicles/model/c-class/coupe/c300c",
      "https://www.mbusa.com/en/vehicles/model/e-class/sedan/e350w4",
      "https://www.mbusa.com/en/vehicles/model/s-class/sedan/s500v4"]
    if (this.state.recommendCar > 0) {
      this.fetchMetaDataLink(arrLink[this.state.recommendCar - 1])
    }
    this.setState((prevState) => ({ showPolicyModal: !prevState.showPolicyModal }));
  }
  fetchMetaDataLink = async (url) => {
    try {
      const response = await fetch(url);
      const body = await response.text();
      const $ = cheerio.load(body);
      const metaTags = {};
      $('meta').each((_, element) => {
        const name = $(element).attr('name') || $(element).attr('property');
        const content = $(element).attr('content');
        if (name && content) {
          metaTags[name] = content;
        }
      });
      metaTags.url = url;
      console.log("metaTags", metaTags)
      // const response = await axios.post(`/fetch-meta?url=${encodeURIComponent(url)}`);
      // console.log("response", response)
      this.setState({ metaDataLinkCar: metaTags });
    } catch (error) {
      this.setState({ metaDataLinkCar: null });
    }
  };
  preloadImages() {
    const images = [
      nextpharse,
     dollar20,
     dollar50,
     dollar100,
      // thêm các đường dẫn tới các ảnh GIF khác
    ];

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        // Khi ảnh được load xong, bạn có thể thực hiện các thao tác khác nếu cần
        // this.setState({ isImageLoaded: true });
        console.log("load img", src)
      };
    });
  }
  render() {
    const {
      info,
      isLoading,
      phraseRefs,
      currentSearchType,
      currentPlay,
      isRepeat,
      alphabet,
      arrFlag,
      alphabetSelected,
      searchKey,
      showSearchABC,
      showConfirm,
      showTopic,
      showPlaylist,
      isMobile,
      listTopic,
      listPharse,
      currentLanguage,
      selectedTopic,
      selectedPlaylist,
      selectedPhrasePlaylist,
      isPlayingSingleWord,
      soundPathSingleWord,
      currentTrackIndex,
      isPlayingPlaylist,
      showModalPlaylist,
      errcreatePLName,
      showCreatePlaylist,
      confirmText,
      modalUserPlaylist,
      currentFlags,
      isHidePhraseText,
      isPlayAll,
      showFindF1,
      videoId,
      showVideo,
      listVideoID,
      listImages,
      showImage,
      currentImage,
      selectedPhrase,
      currentSeconds,
      currentPhraseSpeech,
      replayCount,
      modalContribute,
      contributeType,
      contributeValue,
      errorContribute,
      modalEditPhrase,
      editPhraseValue,
      editPhraseValueVN,
      errorEditPhrase,
      errorEditPhraseVN,
      showModalNotify,
      showModalreceiveBIT,
      textCongratulate,
      showModalReceiveScholarShip,
      showModalReceiveScholarShip_Car,
      amountScholarship,
      showModalLearnNextPhrase,
      showModaShare1Get300,
      showModaShare2Get500,
      showModaShare1Get500,
      showModalReceiveScholarShipFake,
      inviteFriend,
      amountBonus_phrase,
      popupType,
      inviteFriend_phrase,
      recommendCar,
      showPolicyModal,
      metaDataLinkCar,
      ListCoin,
    } = this.state; //groupPhrase
    const { t } = this.props;
    const groupPhrase = this.handleGroupPhrase(listPharse);
    return (
      <>
        {/* <HeaderNavbar
          info={info}
          fluid={false}
          hanldeLoadBasicInfo={this.hanldeLoadBasicInfo}
          toogleModalContribute={this.toogleModalContribute}
          showFindF1={showFindF1}
          hideFindF1={this.hideFindF1}
        /> */}
        <main className="index-page" ref={this.IndexPageRef} >
          <section className="section">
            <Container className="container-lg">
              {/* <Tabs
                defaultActiveKey={""}
                className="justify-content-center tab-history"
                onSelect={(k) => this.handleChangeTab(k)}
              >
                <Tab eventKey="TransHistory" title={t("Wallet")}></Tab>
                <Tab eventKey="ComHistory" title={t("Bonus")}></Tab>
                <Tab eventKey="NLevel" title={t("Member")}></Tab>
                <Tab eventKey="Profile" title={t("Profile")}></Tab>
              </Tabs> */}
              <Row >
                {/* =======Header=========== */}
                {/* =======Left Side=========== */}
                <Col lg="12">
                  <div className="left-part" ref={this.targetTopicDrop}>
                    <div className="d-flex header">
                      <div className="flex-center item-header">
                        <div>
                          <img
                            src={require(`assets/img/BIT.png`)}
                            className="small-logo"
                          />
                        </div>
                        <b>&nbsp;:&nbsp;{info && info.wallet_BIT ? formatNumber(info.wallet_BIT, 3, 3) : 0}</b>
                      </div>
                      <div>
                        <img
                          src={require(`assets/img/shakehand.png`)}
                          className="small-logo"
                        />
                      </div>
                      <div className="flex-center item-header item-right">
                        <b>{info && info.balance_USDT ? formatNumber(info.balance_USDT, 3, 3) : 0} :&nbsp;</b>
                        <div>
                          <img
                            src={require(`assets/img/USDT.png`)}
                            className="small-logo"
                          />
                        </div>
                      </div>
                    </div>
                    {/*=========Search By Alphabet========== */}
                    {/* {showSearchABC && <>
                      <div className="title-intro text-center">{t("Search for the starting character")}</div> */}
                    {/*========Search By Input===============*/}

                    <div className="cover-search-input">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        className="flex-1 input-search"
                      >
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                {/* <img
                                  className="pointer icon-img"
                                  alt={t("Alphabet")}
                                  title={t("Alphabet")}
                                  src={require("assets/img/button/alphabet.png")}
                                  onClick={this.toogleSearchABC}
                                /> */}
                                <img
                                  className="pointer icon-img"
                                  alt={t("Tags")}
                                  title={t("Tags")}
                                  src={require("assets/img/button/tags.png")}
                                  onClick={showTopic
                                    ? this.toogleShowTopic
                                    : this.hanldeShowTopic
                                  }
                                />
                                {/* <span className="pointer text-success icon-btn" > <i className="fa fa-search"></i> </span> */}
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={selectedTopic ? selectedTopic.name : t("Enter keyword")}
                              type="text"
                              autoComplete="off"
                              value={currentSearchType === 4 ? "" : searchKey}
                              onChange={(e) =>
                                this.setState({ searchKey: e.target.value })
                              }
                              onClick={() => { this.setState({ showTopicUser: false }) }}
                              onKeyUp={this.hanldeEnterSearch}
                              // onBlur={this.toogleShowTopic}
                              onFocus={this.state.showTopicUser ? () => 1 : this.handleShowTopic}
                              ref={this.searchRef.ref}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText className="grp-multi-btn">
                                <span
                                  className="pointer icon-btn icon-search"
                                  onClick={this.handleSearchInput}
                                  title={t("Search")}
                                >
                                  {" "}
                                  <i className="fa fa-search"></i>{" "}
                                </span>
                                {/*&nbsp;|{" "}
                                <div
                                  className={`show-menu-search btn-caret mar-left ml-1 ${showTopic ? "active" : ""
                                    }`}
                                  onClick={
                                    showTopic
                                      ? this.toogleShowTopic
                                      : this.hanldeShowTopic
                                  }
                                  title={t("Topic")}
                                >
                                  <i
                                    className={`fa fa-caret-${showTopic ? "up" : "down"
                                      }`}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                |{" "}
                                <div
                                  className={`show-menu-search-1 btn-caret ml-1 ${this.state.showTopicUser ? "active" : ""
                                    }`}
                                  onClick={
                                    this.state.showTopicUser
                                      ? this.toogleShowTopicUser
                                      : this.hanldeShowTopicUser
                                  }
                                  title={t("My Topic")}
                                >
                                  <i
                                    className={`fa fa-caret-${this.state.showTopicUser ? "up" : "down"
                                      }`}
                                    style={{ color: "#ff6201" }}
                                    aria-hidden="true"
                                  ></i>
                                </div> */}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </div>
                    {showSearchABC && (
                      <div className="cover-group-button-alphabet">
                        <div className="cover-button-alphabet">
                          {alphabet &&
                            alphabet.map((item, index) => (
                              <React.Fragment key={`char${index}`}>
                                {index < 9 && (
                                  <div
                                    className={`${alphabetSelected === item ? "active" : ""
                                      } btn-char`}
                                    onClick={() => this.handleSearchABC(item)}
                                  >
                                    {item}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                        </div>
                        <div className="cover-button-alphabet">
                          {alphabet &&
                            alphabet.map((item, index) => (
                              <React.Fragment key={`char2${index}`}>
                                {index >= 9 && index < 18 && (
                                  <div
                                    className={`${alphabetSelected === item ? "active" : ""
                                      } btn-char`}
                                    onClick={() => this.handleSearchABC(item)}
                                  >
                                    {item}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                        </div>
                        <div className="cover-button-alphabet">
                          {alphabet &&
                            alphabet.map((item, index) => (
                              <React.Fragment key={`char3${index}`}>
                                {index >= 18 && index < 27 && (
                                  <div
                                    className={`${alphabetSelected === item ? "active" : ""
                                      } btn-char`}
                                    onClick={() => this.handleSearchABC(item)}
                                  >
                                    {item}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    )}
                    <Col lg="12" className="col-cover">
                      <div className="list-topic-cover">
                        {/* <div className="pointer topic-name" title={t("Topic")} >
                          <div className="cover-text-topic" onClick={
                            showTopic
                              ? this.toogleShowTopic
                              : this.hanldeShowTopic
                          }>Tags: {selectedTopic ? selectedTopic.name : ""}</div>
                          <div onClick={
                            showTopic
                              ? this.toogleShowTopic
                              : this.hanldeShowTopic
                          }
                            className={`btn-caret mar-left ml-1 ${showTopic ? "active" : ""
                              }`}
                          >
                            <i
                              className={`fa fa-caret-${showTopic ? "up" : "down"
                                }`}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div> */}
                        {currentSearchType === 1 && info.wallet_BIT > info.settingMinWalletBITToAddPhraseContribute && (
                          <div className={`word-practice contribute-phrase`}>
                            <Form onSubmit={(e) => { e.preventDefault() }}>
                              <FormGroup>
                                <InputGroup>
                                  <Input
                                    placeholder={t("Enter a phrase")}
                                    type="text"
                                    autoComplete="on"
                                    name="Contribute"
                                    onKeyUp={(e) => this.hanldeEnterContributePhrase(e)}
                                    autoFocus
                                    tabIndex="0"
                                    value={contributeValue}
                                    onChange={(e) =>
                                      this.setState({ contributeValue: e.target.value })
                                    }
                                  />
                                  {/* {transcript} */}
                                  <InputGroupAddon addonType="append"  >
                                    <InputGroupText >
                                      <span className="pointer text-success icon-btn" onClick={() => this.handleContribute()} title={t("Submit")}> <i className="fa fa-check-circle"></i></span>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                                {errorContribute && <div className="error">{errorContribute}</div>}
                              </FormGroup>
                            </Form>
                          </div>
                        )}
                        {showTopic && (
                          <div className={`cover-topic`}>
                            {
                              <div
                                className={
                                  showTopic
                                    ? "show-topic list-below"
                                    : "hide-topic list-below"
                                }
                              >
                                <div className="topic-list">
                                  {listTopic &&
                                    listTopic.map((item, index) => {
                                      return (
                                        <div
                                          className={"topic "}
                                          key={"topic" + index}
                                        >
                                          <div className="cover-topic-item">
                                            <div
                                              className={
                                                "topic-item " +
                                                (selectedTopic &&
                                                  item.topicID ===
                                                  selectedTopic.topicID
                                                  ? "active"
                                                  : "")
                                              }
                                            >
                                              {/* <div
                                                className="topic-img"
                                                onClick={() =>
                                                  this.handleSelectedTopic(item)
                                                }
                                              >
                                                {item.topicLevel}
                                              </div> */}
                                              <div className="topic-content">
                                                <div className="name">
                                                  <div
                                                    onClick={() =>
                                                      this.handleSelectedTopic(item)
                                                    }
                                                    className="name-part"
                                                  >
                                                    {item.name}
                                                  </div>
                                                </div>
                                                {/* <div
                                                  className="word-trans"
                                                  onClick={() =>
                                                    this.handleSelectedTopic(item)
                                                  }
                                                >
                                                  {
                                                    item[
                                                    currentLanguage
                                                      .propertiesTopic
                                                    ]
                                                  }
                                                </div> */}
                                                {/* {showVietnamese && <div className="word-trans">
                                        {item.vietnameseName}
                                      </div>}
                                      {!showVietnamese && <div className="word-trans">
                                        {item.pronounce}
                                      </div>} */}
                                              </div>
                                              {item.hasChild && (
                                                <span
                                                  className="topic-caret"
                                                  onClick={() =>
                                                    this.handleGetChild(item)
                                                  }
                                                >
                                                  <i
                                                    className={`fa fa-caret-${item.showchild === true
                                                      ? "up"
                                                      : "down"
                                                      }`}
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              )}
                                            </div>
                                            {item.showchild === true &&
                                              item.child &&
                                              item.child.length > 0 &&
                                              this.renderSubData(item.child, 0)}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {(!listTopic || listTopic.length === 0) && (
                                    <div className="text-center">
                                      {t("No data")}
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        )}
                      </div>

                    </Col>
                    {/* ===========List Topic==============  */}
                    {/* {showTopic && (
                      <div className={`cover-topic`}>
                        {
                          <div
                            className={
                              showTopic
                                ? "show-topic list-below"
                                : "hide-topic list-below"
                            }
                          >
                            <div className="topic-list">
                              {listTopic &&
                                listTopic.map((item, index) => {
                                  return (
                                    <div
                                      className={"topic "}
                                      key={"topic" + index}
                                    >
                                      <div className="cover-topic-item">
                                        <div
                                          className={
                                            "topic-item " +
                                            (selectedTopic &&
                                              item.topicID ===
                                              selectedTopic.topicID
                                              ? "active"
                                              : "")
                                          }
                                        >
                                          <div
                                            className="topic-img"
                                            onClick={() =>
                                              this.handleSelectedTopic(item)
                                            }
                                          >
                                            {item.topicLevel}
                                          </div>
                                          <div className="topic-content">
                                            <div className="name">
                                              <div
                                                onClick={() =>
                                                  this.handleSelectedTopic(item)
                                                }
                                                className="name-part"
                                              >
                                                {item.name}
                                              </div>
                                            </div>
                                            <div
                                              className="word-trans"
                                              onClick={() =>
                                                this.handleSelectedTopic(item)
                                              }
                                            >
                                              {
                                                item[
                                                currentLanguage
                                                  .propertiesTopic
                                                ]
                                              }
                                            </div>                                          
                                          </div>
                                          {item.topicID > -1 && (
                                            <div
                                              className={`btn-add pointer`}
                                              onClick={() =>
                                                this.toogleModalContribute(
                                                  1,
                                                  null,
                                                  item.topicID
                                                )
                                              }
                                              title={t("Contribute phrases")}
                                            >
                                              <i className={`fa fa-plus`}></i>
                                            </div>
                                          )}
                                          {item.hasChild && (
                                            <span
                                              className="topic-caret"
                                              onClick={() =>
                                                this.handleGetChild(item)
                                              }
                                            >
                                              <i
                                                className={`fa fa-caret-${item.showchild === true
                                                  ? "up"
                                                  : "down"
                                                  }`}
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          )}
                                        </div>
                                        {item.showchild === true &&
                                          item.child &&
                                          item.child.length > 0 &&
                                          this.renderSubData(item.child, 0)}
                                      </div>
                                    </div>
                                  );
                                })}
                              {(!listTopic || listTopic.length === 0) && (
                                <div className="text-center">
                                  {t("No data")}
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      </div>
                    )} */}
                    {/* ========List Playlist=============== */}

                    {/* ===========List Topic User==============  */}
                    {/* {this.state.showTopicUser && (
                      <div className={`cover-topic`} ref={this.formRef}>
                        {
                          <div
                            className={
                              this.state.showTopicUser
                                ? "show-topic list-below"
                                : "hide-topic list-below"
                            }
                          >
                            <div className="topic-list">

                              {this.state.listUserTopic &&
                                this.state.listUserTopic.map((item, index) => {
                                  return (
                                    <div
                                      className={"topic "}
                                      key={"topic" + index}
                                    >
                                      <div className="cover-topic-item">
                                        <div
                                          className={
                                            "topic-item " +
                                            (this.state.selectedTopicUser &&
                                              item.topicID ===
                                              this.state.selectedTopicUser.topicID
                                              ? "active"
                                              : "")
                                          }
                                          style={{ padding: "5px 0px" }}
                                        >
                                          <div
                                            className="topic-img"
                                            onClick={() =>
                                              this.handleSelectedUserTopic(
                                                item
                                              )
                                            }
                                          >
                                            {item.topicLevel}
                                          </div>
                                          <div className="topic-content">
                                            <div className="name">
                                              <div
                                                onClick={() =>
                                                  this.handleSelectedUserTopic(
                                                    item
                                                  )
                                                }
                                                className="name-part"
                                              >
                                                {item.name}
                                              </div>
                                            </div>
                                            <div
                                              className="word-trans"
                                              onClick={() =>
                                                this.handleSelectedUserTopic(
                                                  item
                                                )
                                              }
                                            >
                                              {
                                                item[
                                                currentLanguage
                                                  .propertiesTopic
                                                ]
                                              }
                                            </div>                                           
                                          </div>
                                          {item.isDelete ? (
                                            <div>
                                              {item.topicID > -1 && (
                                                <div
                                                  className={`btn-add pointer`}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.toogleModalContributeAddSub(
                                                      item.topicID,
                                                      item.name
                                                    )
                                                  }
                                                  title={t("Add Phrase")}
                                                >
                                                  <i
                                                    className={`fa fa-plus`}
                                                    style={{ color: "#25bf7f" }}
                                                  ></i>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                          {item.isDelete ? (
                                            <div>
                                              {item.topicID > -1 && (
                                                <div
                                                  className={`btn-add pointer`}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    this.toogleModalContributeDelete(
                                                      item.topicID,
                                                      item.name
                                                    )
                                                  }
                                                  title={t("Delete Topic")}
                                                >
                                                  <i
                                                    className={`fa fa-trash`}
                                                    style={{ color: "red" }}
                                                  ></i>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}

                                          {item.isDelete ? (
                                            <div>
                                              {item.topicID > -1 && (
                                                <div
                                                  style={{ marginRight: '4px' }}
                                                  className={`btn-add pointer`}
                                                  onClick={() =>
                                                    this.toogleModalContributeShare(
                                                      item.name,
                                                      item.topicID
                                                    )
                                                  }
                                                  title={t("Share Topic")}
                                                >
                                                  <i
                                                    className={`fa fa-share-alt`}
                                                    style={{ color: "#ff6201" }}
                                                  ></i>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                          {item.hasChild && (
                                            <span
                                              className="topic-caret"
                                              onClick={() =>
                                                this.handleGetChild(item)
                                              }
                                            >
                                              <i
                                                className={`fa fa-caret-${item.showchild === true
                                                  ? "up"
                                                  : "down"
                                                  }`}
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          )}
                                        </div>
                                        {item.showchild === true &&
                                          item.child &&
                                          item.child.length > 0 &&
                                          this.renderSubData(item.child, 0)}
                                      </div>
                                    </div>
                                  );
                                })}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <div> </div>
                                <button
                                  style={{
                                    borderRadius: "10px",
                                    marginRight: "14px",
                                    padding: "5px 10px",
                                    color: "white",
                                    marginBottom: "10px",
                                    border: "none",
                                    background: "#25bf7f",
                                  }}
                                  onClick={() =>
                                    this.toogleModalContributeAdd()
                                  }
                                  title="Add Topic"
                                >
                                  <i className="fa fa-plus" />
                                </button>
                                <div> </div>
                              </div>
                              {(!this.state.listUserTopic ||
                                this.state.listUserTopic.length === 0) && (
                                  <div className="text-center">
                                    {t("No data")}
                                  </div>
                                )}
                            </div>
                          </div>
                        }
                      </div>
                    )} */}

                    {/* ===========List Topic User==============  */}
                  </div>
                </Col>

                <Col lg="12">


                  {/* <span>{isLoaded ? "Loaded" : "Loading audio..."}</span> */}
                  {/* ========Right Content=============== */}

                  <div className="selected-playlist">
                    {/* ========Play Function=============== */}
                    <div
                      className="cbx-all pointer"
                      title={t("All")}
                      onClick={this.checkPlayAll}
                    >
                      {isPlayAll === 2 && (
                        <i className="fa fa-check-square"></i>
                      )}
                      {isPlayAll === 1 && (
                        <i className="fa fa-minus-square"></i>
                      )}
                      {isPlayAll === 0 && <i className="fa fa-square-o"></i>}
                    </div>
                    <div
                      className={`btn-eye pointer`}
                      onClick={() => this.toogleHidePhraseText()}
                      title={t(
                        isHidePhraseText ? "Show Phrase" : "Hide Phrase"
                      )}
                    >
                      <i
                        className={`fa fa-${isHidePhraseText ? "eye" : "eye-slash"
                          }`}
                      ></i>
                    </div>
                    {/* <Button className="play-selected btn-play" color="success" onClick={() => this.playSelected(0)} title={t("Selected All")}>{t("All")}</Button> */}
                    <div className="action-select">
                      <UncontrolledDropdown
                        direction={isMobile ? "up" : "down"}
                      >
                        <DropdownToggle color="default" className="btn-action">
                          <img
                            className={`icon-search ${currentFlags.id === 0 ? "icon-pronounce" : ""
                              }`}
                            alt="..."
                            src={require(`assets/img/flags/lang/${currentFlags.img}.png`)}
                          />
                        </DropdownToggle>
                        <DropdownMenu className="drop-flags">
                          {arrFlag &&
                            arrFlag.map((item, index) => (
                              <li key={`flag${index}`}>
                                <DropdownItem
                                  onClick={() => this.changeFlag(item)}
                                >
                                  <img
                                    className="icon-search"
                                    alt={item.name}
                                    title={item.name}
                                    src={require(`assets/img/flags/lang/${item.img}.png`)}
                                  />
                                  <div className="name">{item.name}</div>
                                </DropdownItem>
                              </li>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <Button
                        className={`btn-action ${showPlaylist ? "" : "btn-disable"
                          }`}
                        onClick={this.hanldeLoadPharseByPlaylist}
                        title={t("Favorite")}
                      >
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </Button>
                      <Button
                        className={`btn-action ${isRepeat ? "" : "btn-disable"
                          }`}
                        color="warning"
                        onClick={() => this.tooglePlayReapeat(false)}
                        title={t("Repeat")}
                      >
                        <i className="fa fa-repeat" aria-hidden="true"></i>
                      </Button>
                      <Button
                        className="btn-action"
                        color="warning"
                        onClick={() => {
                          isPlayingPlaylist && currentPlay === 1
                            ? this.handlePausePlaylist()
                            : this.handlePlayPlaylist(true, 1);
                        }}
                        title={t("Play")}
                      >
                        <i
                          className={
                            isPlayingPlaylist && currentPlay === 1
                              ? "fa fa-pause"
                              : "fa fa-volume-up"
                          }
                          aria-hidden="true"
                        ></i>
                      </Button>
                      {/* <Button
                        className="btn-action"
                        color="warning"
                        onClick={() => {
                          isPlayingPlaylist && currentPlay === 0
                            ? this.handlePausePlaylist()
                            : this.handlePlayPlaylist(false, 0);
                        }}
                        title={t("Play")}
                      >
                        <i
                          className={
                            isPlayingPlaylist && currentPlay === 0
                              ? "fa fa-pause"
                              : "fa fa-volume-up"
                          }
                          aria-hidden="true"
                        ></i>
                      </Button>
                      <Button
                        className="btn-action btn-nlp"
                        color="warning"
                        onClick={() => {
                          isPlayingPlaylist && currentPlay === 1
                            ? this.handlePausePlaylist()
                            : this.handlePlayPlaylist(true, 1);
                        }}
                        title={"NLP"}
                      >                      
                        {isPlayingPlaylist && currentPlay === 1 ? (
                          <i className="fa fa-pause" aria-hidden="true"></i>
                        ) : (
                          "NLP"
                        )}
                      </Button>*/}
                      {/* ({selectedPhrasePlaylist.length})*/}
                      {/* {selectedPhrasePlaylist.length > 0 && !isPlayingPlaylist && <Button className="play-selected btn-remove" color="danger" onClick={() => this.playSelected(2)} title={t("Clear All")}><i className="fa fa-trash" aria-hidden="true"></i> <span className="none-mobile">{t("Clear All")}</span></Button>} */}
                      {/* {isPlayingPlaylist && <Button className="btn-action" color="warning" onClick={() => this.tooglePauseTrack()} title={t("Play")}><i className={isPlayingPlaylist ? "fa fa-pause" : 'fa fa-volume-up'} aria-hidden="true"></i></Button>} */}
                    </div>
                  </div>
                  {/* ========List Phrase=============== */}
                  {currentSearchType < 4 && <div
                    className={`cover-list-words ${currentSearchType === 1 && info.wallet_BIT > info.settingMinWalletBITToAddPhraseContribute? "favorite-list":""}`}
                    ref={this.ListPhraseRef}
                    id="div_ListPhrase"
                  > 
                    <React.Fragment>
                      {currentSearchType === 0 && (
                        <div className="list-words">
                          {groupPhrase &&
                            groupPhrase.map((group, index) => (
                              <React.Fragment key={`grphrase${index}`}>
                                {group[0].phraseGroupID > 0 && (
                                  <div className="phrase-group">
                                    <div className="grp-info">
                                      <div className="grp-name">
                                        {group[0].phraseGroupName}
                                      </div>

                                      <div className="word-trans">
                                        <div>
                                          {group[0][arrFlag[0].propertiesGroup]}
                                        </div>
                                        {currentFlags.id !== 0 &&
                                          <div>
                                            {group[0][currentFlags.propertiesGroup]}
                                          </div>
                                        }
                                      </div>

                                      {/* {!showVietnamese && <div className="word-trans">
                                  {group[0].phraseGroupPronounce}
                                </div>}
                                {showVietnamese && <div className="word-trans">
                                  {group[0].phraseGroupVietnameseMeans}
                                </div>} */}
                                    </div>
                                    {group &&
                                      group.map((item, subIndex) => {
                                        let arrWord = item.phrase
                                          ? this.splitStringIntoWords(
                                            item.phrase
                                          )
                                          : [];
                                        return (
                                          <div
                                            className={`cover-words ${item.completeListen && popupType >= 0 ?
                                              popupType === 2 ? "cover-receivefake" + amountBonus_phrase :
                                                popupType === 1 ? "cover-receive" + amountBonus_phrase :
                                                  popupType === 3 ? "cover-recommend" + recommendCar : "cover-nextphrase" : ""}`}
                                            onClick={() => this.tooglePopupBonus(item)}
                                            key={`wrd${index}${subIndex}`}
                                          >
                                            <div
                                              className={`${(isPlayingPlaylist &&
                                                selectedPhrasePlaylist[
                                                currentTrackIndex
                                                ] &&
                                                selectedPhrasePlaylist[
                                                  currentTrackIndex
                                                ].index === item.index) ||
                                                (item.isPlaying && !item.mute)
                                                ? "phrase-active"
                                                : ""
                                                } item-words`}
                                              ref={phraseRefs[item.index]}
                                            >
                                              <div className="cbx-selected-ph">
                                                <div className="num-order">
                                                  {item.stt}
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                  <input
                                                    title={t("Add To Playlist")}
                                                    className="custom-control-input cbx-phrase"
                                                    id={`cbxphrase${index}${subIndex}`}
                                                    type="checkbox"
                                                    checked={
                                                      item.selectedPlaylist
                                                        ? item.selectedPlaylist
                                                        : false
                                                    }
                                                    onChange={(e) =>
                                                      this.hanldeAddPhraseToPlaylist(
                                                        e,
                                                        item,
                                                        false
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`cbxphrase${index}${subIndex}`}
                                                  ></label>
                                                </div>
                                              </div>
                                              <PhraseItem
                                                recommendCar={recommendCar}
                                                popupType={popupType}
                                                inviteFriend_phrase={inviteFriend_phrase}
                                                isChildPhrase={false}
                                                isHidePhraseText={
                                                  isHidePhraseText
                                                }
                                                item={item}
                                                currentFlags={currentFlags}
                                                arrFlag={arrFlag}
                                                arrWord={arrWord}
                                                index={index}
                                                handleOpenModal={
                                                  this.handleOpenModal
                                                }
                                                openModalBackup={
                                                  this.openModalBackup
                                                }
                                                openModalAnswer={
                                                  this.openModalAnswer
                                                }
                                                toogleModalContribute={
                                                  this.toogleModalContribute
                                                }
                                                VIP={info.memberVIP}
                                                toogleModalEditPhrase={
                                                  this.toogleModalEditPhrase
                                                }
                                                playSingleWord={
                                                  this.playSingleWord
                                                }
                                                handleInputPractice={
                                                  this.handleInputPractice
                                                }
                                                currentPhraseSpeech={
                                                  currentPhraseSpeech
                                                }
                                                handleSetCurrentPhraseSpeech={
                                                  this
                                                    .handleSetCurrentPhraseSpeech
                                                }
                                                hanldeEnterPractive={
                                                  this.hanldeEnterPractive
                                                }
                                                handlePracticeWord={
                                                  this.handlePracticeWord
                                                }
                                                hanldeToogleFavorite={
                                                  this.hanldeToogleFavorite
                                                }
                                                openVideoImage={
                                                  this.openVideoImage
                                                }
                                                playTrack={this.playTrack}
                                                onLoadLoopItem={
                                                  this.onLoadLoopItem
                                                }
                                                hanldeShowPractive={
                                                  this.hanldeShowPractive
                                                }
                                                handleUpdateErrorImage={this.handleUpdateErrorImage}
                                              />
                                            </div>
                                            {!isHidePhraseText && (
                                              <div className="backup-list">
                                                {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                      let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                      return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                        <div className="cbx-selected-ph">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              title={t("Add To Playlist")}
                                              className="custom-control-input cbx-phrase"
                                              id={`cbxphrase${index}${subIndex}_${idx}`}
                                              type="checkbox"
                                              checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                              onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                            />
                                            <label className="custom-control-label" htmlFor={`cbxphrase${index}${subIndex}_${idx}`}></label>
                                          </div>
                                        </div>
                                        <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                      </div>)
                                    }
                                    )} */}
                                                {item.listAnswer &&
                                                  item.listAnswer.map(
                                                    (answerItem, idx) => {
                                                      let arrWordAnswer =
                                                        answerItem.phrase
                                                          ? this.splitStringIntoWords(
                                                            answerItem.phrase
                                                          )
                                                          : [];
                                                      return (
                                                        <div
                                                          ref={phraseRefs[answerItem.index]}
                                                          key={`answer${idx}`}
                                                          className={`${(isPlayingPlaylist &&
                                                            selectedPhrasePlaylist[
                                                            currentTrackIndex
                                                            ] &&
                                                            selectedPhrasePlaylist[
                                                              currentTrackIndex
                                                            ].index ===
                                                            answerItem.index) ||
                                                            (answerItem.isPlaying &&
                                                              !answerItem.mute)
                                                            ? "active"
                                                            : ""
                                                            } item-words`}
                                                        >
                                                          <div className="cbx-selected-ph">
                                                            <div className="custom-control custom-checkbox">
                                                              <input
                                                                title={t(
                                                                  "Add To Playlist"
                                                                )}
                                                                className="custom-control-input cbx-phrase"
                                                                id={`cbx${answerItem.index}`}
                                                                type="checkbox"
                                                                checked={
                                                                  answerItem.selectedPlaylist
                                                                    ? answerItem.selectedPlaylist
                                                                    : false
                                                                }
                                                                onChange={(e) =>
                                                                  this.hanldeAddPhraseToPlaylist(
                                                                    e,
                                                                    answerItem,
                                                                    true
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`cbx${answerItem.index}`}
                                                              ></label>
                                                            </div>
                                                          </div>
                                                          <PhraseItem
                                                            isChildPhrase={true}
                                                            isHidePhraseText={
                                                              isHidePhraseText
                                                            }
                                                            item={answerItem}
                                                            currentFlags={
                                                              currentFlags
                                                            }
                                                            arrFlag={arrFlag}
                                                            arrWord={
                                                              arrWordAnswer
                                                            }
                                                            index={idx}
                                                            VIP={info.memberVIP}
                                                            handleOpenModal={
                                                              this
                                                                .handleOpenModal
                                                            }
                                                            toogleModalEditPhrase={
                                                              this
                                                                .toogleModalEditPhrase
                                                            }
                                                            playSingleWord={
                                                              this
                                                                .playSingleWord
                                                            }
                                                            handleInputPractice={
                                                              this
                                                                .handleInputPractice
                                                            }
                                                            currentPhraseSpeech={
                                                              currentPhraseSpeech
                                                            }
                                                            handleSetCurrentPhraseSpeech={
                                                              this
                                                                .handleSetCurrentPhraseSpeech
                                                            }
                                                            hanldeEnterPractive={
                                                              this
                                                                .hanldeEnterPractive
                                                            }
                                                            handlePracticeWord={
                                                              this
                                                                .handlePracticeWord
                                                            }
                                                            hanldeToogleFavorite={
                                                              this
                                                                .hanldeToogleFavorite
                                                            }
                                                            openVideoImage={
                                                              this
                                                                .openVideoImage
                                                            }
                                                            playTrack={
                                                              this.playTrack
                                                            }
                                                            onLoadLoopItem={
                                                              this
                                                                .onLoadLoopItem
                                                            }
                                                            hanldeShowPractive={
                                                              this
                                                                .hanldeShowPractive
                                                            }
                                                            replacePhrase={
                                                              this.replacePhrase
                                                            }
                                                            handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                          />
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                                {group &&
                                  group[0].phraseGroupID === 0 &&
                                  group.map((item, subIndex) => {
                                    let arrWord = item.phrase
                                      ? this.splitStringIntoWords(item.phrase)
                                      : [];
                                    return (
                                      <div
                                        className={`cover-words ${item.completeListen && popupType >= 0 ?
                                          popupType === 2 ? "cover-receivefake" + amountBonus_phrase :
                                            popupType === 1 ? "cover-receive" + amountBonus_phrase :
                                              popupType === 3 ? "cover-recommend" + recommendCar : "cover-nextphrase" : ""}`}
                                        onClick={() => this.tooglePopupBonus(item)}
                                        key={`wrd${subIndex}`}
                                      >
                                        <div
                                          className={`${(isPlayingPlaylist &&
                                            selectedPhrasePlaylist[
                                            currentTrackIndex
                                            ] &&
                                            selectedPhrasePlaylist[
                                              currentTrackIndex
                                            ].index === item.index) ||
                                            (item.isPlaying && !item.mute)
                                            ? "phrase-active"
                                            : ""
                                            } item-words`}
                                          ref={phraseRefs[item.index]}
                                        >
                                          <div className="cbx-selected-ph">
                                            <div className="num-order">
                                              {item.stt}
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                title={t("Add To Playlist")}
                                                className="custom-control-input cbx-phrase"
                                                id={`cbxphrase${index}${subIndex}`}
                                                type="checkbox"
                                                checked={
                                                  item.selectedPlaylist
                                                    ? item.selectedPlaylist
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this.hanldeAddPhraseToPlaylist(
                                                    e,
                                                    item,
                                                    false
                                                  )
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`cbxphrase${index}${subIndex}`}
                                              ></label>
                                            </div>
                                          </div>
                                          <PhraseItem
                                            popupType={popupType}
                                            inviteFriend_phrase={inviteFriend_phrase}
                                            isChildPhrase={false}
                                            isHidePhraseText={isHidePhraseText}
                                            item={item}
                                            currentFlags={currentFlags}
                                            arrFlag={arrFlag}
                                            arrWord={arrWord}
                                            index={index}
                                            handleOpenModal={
                                              this.handleOpenModal
                                            }
                                            openModalBackup={
                                              this.openModalBackup
                                            }
                                            openModalAnswer={
                                              this.openModalAnswer
                                            }
                                            toogleModalContribute={
                                              this.toogleModalContribute
                                            }
                                            VIP={info.memberVIP}
                                            toogleModalEditPhrase={
                                              this.toogleModalEditPhrase
                                            }
                                            playSingleWord={this.playSingleWord}
                                            handleInputPractice={
                                              this.handleInputPractice
                                            }
                                            currentPhraseSpeech={
                                              currentPhraseSpeech
                                            }
                                            handleSetCurrentPhraseSpeech={
                                              this.handleSetCurrentPhraseSpeech
                                            }
                                            hanldeEnterPractive={
                                              this.hanldeEnterPractive
                                            }
                                            handlePracticeWord={
                                              this.handlePracticeWord
                                            }
                                            hanldeToogleFavorite={
                                              this.hanldeToogleFavorite
                                            }
                                            openVideoImage={this.openVideoImage}
                                            playTrack={this.playTrack}
                                            onLoadLoopItem={this.onLoadLoopItem}
                                            hanldeShowPractive={
                                              this.hanldeShowPractive
                                            }
                                            handleUpdateErrorImage={this.handleUpdateErrorImage}
                                          />
                                        </div>
                                        {!isHidePhraseText && (
                                          <div className="backup-list">
                                            {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                      let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                      return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                        <div className="cbx-selected-ph">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              title={t("Add To Playlist")}
                                              className="custom-control-input cbx-phrase"
                                              id={`cbxphrase${index}${subIndex}_${idx}`}
                                              type="checkbox"
                                              checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                              onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                            />
                                            <label className="custom-control-label" htmlFor={`cbxphrase${index}${subIndex}_${idx}`}></label>
                                          </div>
                                        </div>
                                        <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                      </div>)
                                    }
                                    )} */}
                                            {item.listAnswer &&
                                              item.listAnswer.map(
                                                (answerItem, idx) => {
                                                  let arrWordAnswer =
                                                    answerItem.phrase
                                                      ? this.splitStringIntoWords(
                                                        answerItem.phrase
                                                      )
                                                      : [];
                                                  return (
                                                    <div
                                                      ref={phraseRefs[answerItem.index]}
                                                      key={`answer${idx}`}
                                                      className={`${(isPlayingPlaylist &&
                                                        selectedPhrasePlaylist[
                                                        currentTrackIndex
                                                        ] &&
                                                        selectedPhrasePlaylist[
                                                          currentTrackIndex
                                                        ].index ===
                                                        answerItem.index) ||
                                                        (answerItem.isPlaying &&
                                                          !answerItem.mute)
                                                        ? "active"
                                                        : ""
                                                        } item-words`}
                                                    >
                                                      <div className="cbx-selected-ph">
                                                        <div className="custom-control custom-checkbox">
                                                          <input
                                                            title={t(
                                                              "Add To Playlist"
                                                            )}
                                                            className="custom-control-input cbx-phrase"
                                                            id={`cbx${answerItem.index}`}
                                                            type="checkbox"
                                                            checked={
                                                              answerItem.selectedPlaylist
                                                                ? answerItem.selectedPlaylist
                                                                : false
                                                            }
                                                            onChange={(e) =>
                                                              this.hanldeAddPhraseToPlaylist(
                                                                e,
                                                                answerItem,
                                                                true
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={`cbx${answerItem.index}`}
                                                          ></label>
                                                        </div>
                                                      </div>
                                                      <PhraseItem
                                                        isChildPhrase={true}
                                                        isHidePhraseText={
                                                          isHidePhraseText
                                                        }
                                                        item={answerItem}
                                                        currentFlags={
                                                          currentFlags
                                                        }
                                                        arrFlag={arrFlag}
                                                        arrWord={arrWordAnswer}
                                                        index={idx}
                                                        VIP={info.memberVIP}
                                                        handleOpenModal={
                                                          this.handleOpenModal
                                                        }
                                                        toogleModalEditPhrase={
                                                          this
                                                            .toogleModalEditPhrase
                                                        }
                                                        playSingleWord={
                                                          this.playSingleWord
                                                        }
                                                        handleInputPractice={
                                                          this
                                                            .handleInputPractice
                                                        }
                                                        currentPhraseSpeech={
                                                          currentPhraseSpeech
                                                        }
                                                        handleSetCurrentPhraseSpeech={
                                                          this
                                                            .handleSetCurrentPhraseSpeech
                                                        }
                                                        hanldeEnterPractive={
                                                          this
                                                            .hanldeEnterPractive
                                                        }
                                                        handlePracticeWord={
                                                          this
                                                            .handlePracticeWord
                                                        }
                                                        hanldeToogleFavorite={
                                                          this
                                                            .hanldeToogleFavorite
                                                        }
                                                        openVideoImage={
                                                          this.openVideoImage
                                                        }
                                                        playTrack={
                                                          this.playTrack
                                                        }
                                                        onLoadLoopItem={
                                                          this.onLoadLoopItem
                                                        }
                                                        hanldeShowPractive={
                                                          this
                                                            .hanldeShowPractive
                                                        }
                                                        replacePhrase={
                                                          this.replacePhrase
                                                        }
                                                        handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </React.Fragment>
                            ))}
                          {(!groupPhrase || groupPhrase.length === 0) && (
                            <div className="text-center">{t("No data")}</div>
                          )}
                        </div>
                      )}
                      {currentSearchType !== 0 && (
                        <div className="list-words">
                          {listPharse &&
                            listPharse?.map((item, index) => {
                              let arrWord = item.phrase
                                ? this.splitStringIntoWords(item.phrase)
                                : [];
                              return (
                                <div
                                  className={`cover-words ${item.completeListen && popupType >= 0 ?
                                    popupType === 2 ? "cover-receivefake" + amountBonus_phrase :
                                      popupType === 1 ? "cover-receive" + amountBonus_phrase :
                                        popupType === 3 ? "cover-recommend" + recommendCar : "cover-nextphrase" : ""}`}
                                  onClick={() => this.tooglePopupBonus(item)}

                                  key={"wrd" + index}
                                >
                                  <div
                                    className={`${(isPlayingPlaylist &&
                                      selectedPhrasePlaylist[
                                      currentTrackIndex
                                      ] &&
                                      selectedPhrasePlaylist[
                                        currentTrackIndex
                                      ].index === item.index) ||
                                      (item.isPlaying && !item.mute)
                                      ? "phrase-active"
                                      : ""
                                      } item-words`}
                                    ref={phraseRefs[item.index]}
                                  >
                                    <div className="cbx-selected-ph">
                                      <div className="num-order">
                                        {item.stt}
                                      </div>
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          title={t("Add To Playlist")}
                                          className="custom-control-input cbx-phrase"
                                          id={`cbxphrase${index}`}
                                          type="checkbox"
                                          checked={
                                            item.selectedPlaylist
                                              ? item.selectedPlaylist
                                              : false
                                          }
                                          onChange={(e) =>
                                            this.hanldeAddPhraseToPlaylist(
                                              e,
                                              item,
                                              false
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`cbxphrase${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                    <PhraseItem
                                      popupType={popupType}
                                      inviteFriend_phrase={inviteFriend_phrase}
                                      isChildPhrase={false}
                                      isHidePhraseText={isHidePhraseText}
                                      item={item}
                                      currentFlags={currentFlags}
                                      arrFlag={arrFlag}
                                      arrWord={arrWord}
                                      index={index}
                                      handleOpenModal={this.handleOpenModal}
                                      openModalBackup={this.openModalBackup}
                                      openModalAnswer={this.openModalAnswer}
                                      toogleModalContribute={
                                        this.toogleModalContribute
                                      }
                                      VIP={info.memberVIP}
                                      toogleModalEditPhrase={
                                        this.toogleModalEditPhrase
                                      }
                                      playSingleWord={this.playSingleWord}
                                      handleInputPractice={
                                        this.handleInputPractice
                                      }
                                      currentPhraseSpeech={currentPhraseSpeech}
                                      handleSetCurrentPhraseSpeech={
                                        this.handleSetCurrentPhraseSpeech
                                      }
                                      hanldeEnterPractive={
                                        this.hanldeEnterPractive
                                      }
                                      handlePracticeWord={
                                        this.handlePracticeWord
                                      }
                                      hanldeToogleFavorite={
                                        this.hanldeToogleFavorite
                                      }
                                      openVideoImage={this.openVideoImage}
                                      playTrack={this.playTrack}
                                      onLoadLoopItem={this.onLoadLoopItem}
                                      hanldeShowPractive={
                                        this.hanldeShowPractive
                                      }
                                      handleUpdateErrorImage={this.handleUpdateErrorImage}
                                    />
                                  </div>
                                  {!isHidePhraseText && (
                                    <div className="backup-list">
                                      {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                  <div className="cbx-selected-ph">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        title={t("Add To Playlist")}
                                        className="custom-control-input cbx-phrase"
                                        id={`cbxphrase${index}_${idx}`}
                                        type="checkbox"
                                        checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                        onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                      />
                                      <label className="custom-control-label" htmlFor={`cbxphrase${index}_${idx}`}></label>
                                    </div>
                                  </div>
                                  <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                </div>)
                              }
                              )} */}
                                      {item.listAnswer &&
                                        item.listAnswer.map(
                                          (answerItem, idx) => {
                                            let arrWordAnswer =
                                              answerItem.phrase
                                                ? this.splitStringIntoWords(
                                                  answerItem.phrase
                                                )
                                                : [];
                                            return (
                                              <div
                                                ref={phraseRefs[answerItem.index]}
                                                key={`answer${idx}`}
                                                className={`${(isPlayingPlaylist &&
                                                  selectedPhrasePlaylist[
                                                  currentTrackIndex
                                                  ] &&
                                                  selectedPhrasePlaylist[
                                                    currentTrackIndex
                                                  ].index ===
                                                  answerItem.index) ||
                                                  (answerItem.isPlaying &&
                                                    !answerItem.mute)
                                                  ? "active"
                                                  : ""
                                                  } item-words`}
                                              >
                                                <div className="cbx-selected-ph">
                                                  <div className="custom-control custom-checkbox">
                                                    <input
                                                      title={t(
                                                        "Add To Playlist"
                                                      )}
                                                      className="custom-control-input cbx-phrase"
                                                      id={`cbx${answerItem.index}`}
                                                      type="checkbox"
                                                      checked={
                                                        answerItem.selectedPlaylist
                                                          ? answerItem.selectedPlaylist
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        this.hanldeAddPhraseToPlaylist(
                                                          e,
                                                          answerItem,
                                                          true
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`cbx${answerItem.index}`}
                                                    ></label>
                                                  </div>
                                                </div>
                                                <PhraseItem
                                                  isChildPhrase={true}
                                                  isHidePhraseText={
                                                    isHidePhraseText
                                                  }
                                                  item={answerItem}
                                                  currentFlags={currentFlags}
                                                  arrFlag={arrFlag}
                                                  arrWord={arrWordAnswer}
                                                  index={idx}
                                                  VIP={info.memberVIP}
                                                  handleOpenModal={
                                                    this.handleOpenModal
                                                  }
                                                  toogleModalEditPhrase={
                                                    this.toogleModalEditPhrase
                                                  }
                                                  playSingleWord={
                                                    this.playSingleWord
                                                  }
                                                  handleInputPractice={
                                                    this.handleInputPractice
                                                  }
                                                  currentPhraseSpeech={
                                                    currentPhraseSpeech
                                                  }
                                                  handleSetCurrentPhraseSpeech={
                                                    this
                                                      .handleSetCurrentPhraseSpeech
                                                  }
                                                  hanldeEnterPractive={
                                                    this.hanldeEnterPractive
                                                  }
                                                  handlePracticeWord={
                                                    this.handlePracticeWord
                                                  }
                                                  hanldeToogleFavorite={
                                                    this.hanldeToogleFavorite
                                                  }
                                                  openVideoImage={
                                                    this.openVideoImage
                                                  }
                                                  playTrack={this.playTrack}
                                                  onLoadLoopItem={
                                                    this.onLoadLoopItem
                                                  }
                                                  hanldeShowPractive={
                                                    this.hanldeShowPractive
                                                  }
                                                  replacePhrase={
                                                    this.replacePhrase
                                                  }
                                                  handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          {(!listPharse || listPharse.length === 0) && (
                            <div className="text-center">{t("No data")}</div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                    {/* ========Introduce Buy Package =============== */}
                    {/* {(CurrentPackageAmount === 0 && !Trial) &&
                      <div className="cover-intro-buy-pack">
                        <div>
                          <div className="title-intro">{t("Buy a package to start experiencing English learning through interesting topics.")}</div>
                          <div className="text-center mb-2 mt-3 div-btn-submit">
                            <Link
                              className="btn btn-success btn-submit"
                              to="/buy-package"
                            >
                              {t("Buy Package")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    } */}
                  </div>}


                  {/* ========List Phrase User=============== */}
                  {currentSearchType === 4 && <div
                    className={`cover-list-words`}
                    ref={this.ListPhraseRef}
                    id="div_ListPhrase"
                  >
                    <React.Fragment>
                      {currentSearchType === 0 && (
                        <div className="list-words">
                          {groupPhrase &&
                            groupPhrase.map((group, index) => (
                              <React.Fragment key={`grphrase${index}`}>
                                {group[0].phraseGroupID > 0 && (
                                  <div className="phrase-group">
                                    <div className="grp-info">
                                      <div className="grp-name">
                                        {group[0].phraseGroupName}
                                      </div>
                                      <div className="word-trans">
                                        {group[0][currentFlags.propertiesGroup]}
                                      </div>
                                      {/* {!showVietnamese && <div className="word-trans">
                                  {group[0].phraseGroupPronounce}
                                </div>}
                                {showVietnamese && <div className="word-trans">
                                  {group[0].phraseGroupVietnameseMeans}
                                </div>} */}
                                    </div>
                                    {group &&
                                      group.map((item, subIndex) => {
                                        let arrWord = item.phrase
                                          ? this.splitStringIntoWords(
                                            item.phrase
                                          )
                                          : [];
                                        return (
                                          <div
                                            className="cover-words"
                                            key={`wrd${index}${subIndex}`}
                                          >
                                            <div
                                              className={`${(isPlayingPlaylist &&
                                                selectedPhrasePlaylist[
                                                currentTrackIndex
                                                ] &&
                                                selectedPhrasePlaylist[
                                                  currentTrackIndex
                                                ].index === item.index) ||
                                                (item.isPlaying && !item.mute)
                                                ? "phrase-active"
                                                : ""
                                                } item-words`}
                                              ref={phraseRefs[item.index]}
                                            >
                                              <div className="cbx-selected-ph">
                                                <div className="num-order">
                                                  {item.stt}
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                  <input
                                                    title={t("Add To Playlist")}
                                                    className="custom-control-input cbx-phrase"
                                                    id={`cbxphrase${index}${subIndex}`}
                                                    type="checkbox"
                                                    checked={
                                                      item.selectedPlaylist
                                                        ? item.selectedPlaylist
                                                        : false
                                                    }
                                                    onChange={(e) =>
                                                      this.hanldeAddPhraseToPlaylist(
                                                        e,
                                                        item,
                                                        false
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`cbxphrase${index}${subIndex}`}
                                                  ></label>
                                                </div>
                                              </div>
                                              <PhraseItem
                                                isChildPhrase={false}
                                                isHidePhraseText={
                                                  isHidePhraseText
                                                }
                                                item={item}
                                                currentFlags={currentFlags}
                                                arrFlag={arrFlag}
                                                arrWord={arrWord}
                                                index={index}
                                                handleOpenModal={
                                                  this.handleOpenModal
                                                }
                                                openModalBackup={
                                                  this.openModalBackup
                                                }
                                                openModalAnswer={
                                                  this.openModalAnswer
                                                }
                                                toogleModalContribute={
                                                  this.toogleModalContribute
                                                }
                                                VIP={info.memberVIP}
                                                toogleModalEditPhrase={
                                                  this.toogleModalEditPhrase
                                                }
                                                playSingleWord={
                                                  this.playSingleWord
                                                }
                                                handleInputPractice={
                                                  this.handleInputPractice
                                                }
                                                currentPhraseSpeech={
                                                  currentPhraseSpeech
                                                }
                                                handleSetCurrentPhraseSpeech={
                                                  this
                                                    .handleSetCurrentPhraseSpeech
                                                }
                                                hanldeEnterPractive={
                                                  this.hanldeEnterPractive
                                                }
                                                handlePracticeWord={
                                                  this.handlePracticeWord
                                                }
                                                hanldeToogleFavorite={
                                                  this.hanldeToogleFavorite
                                                }
                                                openVideoImage={
                                                  this.openVideoImage
                                                }
                                                playTrack={this.playTrack}
                                                onLoadLoopItem={
                                                  this.onLoadLoopItem
                                                }
                                                hanldeShowPractive={
                                                  this.hanldeShowPractive
                                                }
                                                handleUpdateErrorImage={this.handleUpdateErrorImage}
                                              />
                                            </div>
                                            {!isHidePhraseText && (
                                              <div className="backup-list">
                                                {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                      let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                      return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                        <div className="cbx-selected-ph">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              title={t("Add To Playlist")}
                                              className="custom-control-input cbx-phrase"
                                              id={`cbxphrase${index}${subIndex}_${idx}`}
                                              type="checkbox"
                                              checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                              onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                            />
                                            <label className="custom-control-label" htmlFor={`cbxphrase${index}${subIndex}_${idx}`}></label>
                                          </div>
                                        </div>
                                        <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                      </div>)
                                    }
                                    )} */}
                                                {item.listAnswer &&
                                                  item.listAnswer.map(
                                                    (answerItem, idx) => {
                                                      let arrWordAnswer =
                                                        answerItem.phrase
                                                          ? this.splitStringIntoWords(
                                                            answerItem.phrase
                                                          )
                                                          : [];
                                                      return (
                                                        <div
                                                          ref={phraseRefs[answerItem.index]}
                                                          key={`answer${idx}`}
                                                          className={`${(isPlayingPlaylist &&
                                                            selectedPhrasePlaylist[
                                                            currentTrackIndex
                                                            ] &&
                                                            selectedPhrasePlaylist[
                                                              currentTrackIndex
                                                            ].index ===
                                                            answerItem.index) ||
                                                            (answerItem.isPlaying &&
                                                              !answerItem.mute)
                                                            ? "active"
                                                            : ""
                                                            } item-words`}
                                                        >
                                                          <div className="cbx-selected-ph">
                                                            <div className="custom-control custom-checkbox">
                                                              <input
                                                                title={t(
                                                                  "Add To Playlist"
                                                                )}
                                                                className="custom-control-input cbx-phrase"
                                                                id={`cbx${answerItem.index}`}
                                                                type="checkbox"
                                                                checked={
                                                                  answerItem.selectedPlaylist
                                                                    ? answerItem.selectedPlaylist
                                                                    : false
                                                                }
                                                                onChange={(e) =>
                                                                  this.hanldeAddPhraseToPlaylist(
                                                                    e,
                                                                    answerItem,
                                                                    true
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`cbx${answerItem.index}`}
                                                              ></label>
                                                            </div>
                                                          </div>
                                                          <PhraseItem
                                                            isChildPhrase={true}
                                                            isHidePhraseText={
                                                              isHidePhraseText
                                                            }
                                                            item={answerItem}
                                                            currentFlags={
                                                              currentFlags
                                                            }
                                                            arrFlag={arrFlag}
                                                            arrWord={
                                                              arrWordAnswer
                                                            }
                                                            index={idx}
                                                            VIP={info.memberVIP}
                                                            handleOpenModal={
                                                              this
                                                                .handleOpenModal
                                                            }
                                                            toogleModalEditPhrase={
                                                              this
                                                                .toogleModalEditPhrase
                                                            }
                                                            playSingleWord={
                                                              this
                                                                .playSingleWord
                                                            }
                                                            handleInputPractice={
                                                              this
                                                                .handleInputPractice
                                                            }
                                                            currentPhraseSpeech={
                                                              currentPhraseSpeech
                                                            }
                                                            handleSetCurrentPhraseSpeech={
                                                              this
                                                                .handleSetCurrentPhraseSpeech
                                                            }
                                                            hanldeEnterPractive={
                                                              this
                                                                .hanldeEnterPractive
                                                            }
                                                            handlePracticeWord={
                                                              this
                                                                .handlePracticeWord
                                                            }
                                                            hanldeToogleFavorite={
                                                              this
                                                                .hanldeToogleFavorite
                                                            }
                                                            openVideoImage={
                                                              this
                                                                .openVideoImage
                                                            }
                                                            playTrack={
                                                              this.playTrack
                                                            }
                                                            onLoadLoopItem={
                                                              this
                                                                .onLoadLoopItem
                                                            }
                                                            hanldeShowPractive={
                                                              this
                                                                .hanldeShowPractive
                                                            }
                                                            replacePhrase={
                                                              this.replacePhrase
                                                            }
                                                            handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                          />
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                                {group &&
                                  group[0].phraseGroupID === 0 &&
                                  group.map((item, subIndex) => {
                                    let arrWord = item.phrase
                                      ? this.splitStringIntoWords(item.phrase)
                                      : [];
                                    return (
                                      <div
                                        className="cover-words"
                                        key={`wrd${subIndex}`}
                                      >
                                        <div
                                          className={`${(isPlayingPlaylist &&
                                            selectedPhrasePlaylist[
                                            currentTrackIndex
                                            ] &&
                                            selectedPhrasePlaylist[
                                              currentTrackIndex
                                            ].index === item.index) ||
                                            (item.isPlaying && !item.mute)
                                            ? "phrase-active"
                                            : ""
                                            } item-words`}
                                          ref={phraseRefs[item.index]}
                                        >
                                          <div className="cbx-selected-ph">
                                            <div className="num-order">
                                              {item.stt}
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                title={t("Add To Playlist")}
                                                className="custom-control-input cbx-phrase"
                                                id={`cbxphrase${index}${subIndex}`}
                                                type="checkbox"
                                                checked={
                                                  item.selectedPlaylist
                                                    ? item.selectedPlaylist
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this.hanldeAddPhraseToPlaylist(
                                                    e,
                                                    item,
                                                    false
                                                  )
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`cbxphrase${index}${subIndex}`}
                                              ></label>
                                            </div>
                                          </div>
                                          <PhraseItem
                                            isChildPhrase={false}
                                            isHidePhraseText={isHidePhraseText}
                                            item={item}
                                            currentFlags={currentFlags}
                                            arrFlag={arrFlag}
                                            arrWord={arrWord}
                                            index={index}
                                            handleOpenModal={
                                              this.handleOpenModal
                                            }
                                            openModalBackup={
                                              this.openModalBackup
                                            }
                                            openModalAnswer={
                                              this.openModalAnswer
                                            }
                                            toogleModalContribute={
                                              this.toogleModalContribute
                                            }
                                            VIP={info.memberVIP}
                                            toogleModalEditPhrase={
                                              this.toogleModalEditPhrase
                                            }
                                            playSingleWord={this.playSingleWord}
                                            handleInputPractice={
                                              this.handleInputPractice
                                            }
                                            currentPhraseSpeech={
                                              currentPhraseSpeech
                                            }
                                            handleSetCurrentPhraseSpeech={
                                              this.handleSetCurrentPhraseSpeech
                                            }
                                            hanldeEnterPractive={
                                              this.hanldeEnterPractive
                                            }
                                            handlePracticeWord={
                                              this.handlePracticeWord
                                            }
                                            hanldeToogleFavorite={
                                              this.hanldeToogleFavorite
                                            }
                                            openVideoImage={this.openVideoImage}
                                            playTrack={this.playTrack}
                                            onLoadLoopItem={this.onLoadLoopItem}
                                            hanldeShowPractive={
                                              this.hanldeShowPractive
                                            }
                                            handleUpdateErrorImage={this.handleUpdateErrorImage}
                                          />
                                        </div>
                                        {!isHidePhraseText && (
                                          <div className="backup-list">
                                            {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                      let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                      return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                        <div className="cbx-selected-ph">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              title={t("Add To Playlist")}
                                              className="custom-control-input cbx-phrase"
                                              id={`cbxphrase${index}${subIndex}_${idx}`}
                                              type="checkbox"
                                              checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                              onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                            />
                                            <label className="custom-control-label" htmlFor={`cbxphrase${index}${subIndex}_${idx}`}></label>
                                          </div>
                                        </div>
                                        <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                      </div>)
                                    }
                                    )} */}
                                            {item.listAnswer &&
                                              item.listAnswer.map(
                                                (answerItem, idx) => {
                                                  let arrWordAnswer =
                                                    answerItem.phrase
                                                      ? this.splitStringIntoWords(
                                                        answerItem.phrase
                                                      )
                                                      : [];
                                                  return (
                                                    <div
                                                      ref={phraseRefs[answerItem.index]}
                                                      key={`answer${idx}`}
                                                      className={`${(isPlayingPlaylist &&
                                                        selectedPhrasePlaylist[
                                                        currentTrackIndex
                                                        ] &&
                                                        selectedPhrasePlaylist[
                                                          currentTrackIndex
                                                        ].index ===
                                                        answerItem.index) ||
                                                        (answerItem.isPlaying &&
                                                          !answerItem.mute)
                                                        ? "active"
                                                        : ""
                                                        } item-words`}
                                                    >
                                                      <div className="cbx-selected-ph">
                                                        <div className="custom-control custom-checkbox">
                                                          <input
                                                            title={t(
                                                              "Add To Playlist"
                                                            )}
                                                            className="custom-control-input cbx-phrase"
                                                            id={`cbx${answerItem.index}`}
                                                            type="checkbox"
                                                            checked={
                                                              answerItem.selectedPlaylist
                                                                ? answerItem.selectedPlaylist
                                                                : false
                                                            }
                                                            onChange={(e) =>
                                                              this.hanldeAddPhraseToPlaylist(
                                                                e,
                                                                answerItem,
                                                                true
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={`cbx${answerItem.index}`}
                                                          ></label>
                                                        </div>
                                                      </div>
                                                      <PhraseItem
                                                        isChildPhrase={true}
                                                        isHidePhraseText={
                                                          isHidePhraseText
                                                        }
                                                        item={answerItem}
                                                        currentFlags={
                                                          currentFlags
                                                        }
                                                        arrFlag={arrFlag}
                                                        arrWord={arrWordAnswer}
                                                        index={idx}
                                                        VIP={info.memberVIP}
                                                        handleOpenModal={
                                                          this.handleOpenModal
                                                        }
                                                        toogleModalEditPhrase={
                                                          this
                                                            .toogleModalEditPhrase
                                                        }
                                                        playSingleWord={
                                                          this.playSingleWord
                                                        }
                                                        handleInputPractice={
                                                          this
                                                            .handleInputPractice
                                                        }
                                                        currentPhraseSpeech={
                                                          currentPhraseSpeech
                                                        }
                                                        handleSetCurrentPhraseSpeech={
                                                          this
                                                            .handleSetCurrentPhraseSpeech
                                                        }
                                                        hanldeEnterPractive={
                                                          this
                                                            .hanldeEnterPractive
                                                        }
                                                        handlePracticeWord={
                                                          this
                                                            .handlePracticeWord
                                                        }
                                                        hanldeToogleFavorite={
                                                          this
                                                            .hanldeToogleFavorite
                                                        }
                                                        openVideoImage={
                                                          this.openVideoImage
                                                        }
                                                        playTrack={
                                                          this.playTrack
                                                        }
                                                        onLoadLoopItem={
                                                          this.onLoadLoopItem
                                                        }
                                                        hanldeShowPractive={
                                                          this
                                                            .hanldeShowPractive
                                                        }
                                                        replacePhrase={
                                                          this.replacePhrase
                                                        }
                                                        handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </React.Fragment>
                            ))}
                          {(!groupPhrase || groupPhrase.length === 0) && (
                            <div className="text-center">{t("No data")}</div>
                          )}
                        </div>
                      )}
                      {currentSearchType !== 0 && (
                        <div className="list-words">
                          {listPharse &&
                            listPharse.map((item, index) => {
                              let arrWord = item.phrase
                                ? this.splitStringIntoWords(item.phrase)
                                : [];
                              return (
                                <div
                                  className="cover-words"
                                  key={"wrd" + index}
                                >
                                  <div
                                    className={`${(isPlayingPlaylist &&
                                      selectedPhrasePlaylist[
                                      currentTrackIndex
                                      ] &&
                                      selectedPhrasePlaylist[
                                        currentTrackIndex
                                      ].index === item.index) ||
                                      (item.isPlaying && !item.mute)
                                      ? "phrase-active"
                                      : ""
                                      } item-words`}
                                    ref={phraseRefs[item.index]}
                                  >
                                    <div className="cbx-selected-ph">
                                      <div className="num-order">
                                        {item.stt}
                                      </div>
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          title={t("Add To Playlist")}
                                          className="custom-control-input cbx-phrase"
                                          id={`cbxphrase${index}`}
                                          type="checkbox"
                                          checked={
                                            item.selectedPlaylist
                                              ? item.selectedPlaylist
                                              : false
                                          }
                                          onChange={(e) =>
                                            this.hanldeAddPhraseToPlaylist(
                                              e,
                                              item,
                                              false
                                            )
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`cbxphrase${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                    <PhraseItem
                                      isChildPhrase={false}
                                      isHidePhraseText={isHidePhraseText}
                                      item={item}
                                      currentFlags={currentFlags}
                                      arrFlag={arrFlag}
                                      arrWord={arrWord}
                                      index={index}
                                      handleOpenModal={this.handleOpenModal}
                                      openModalBackup={this.openModalBackup}
                                      openModalAnswer={this.openModalAnswer}
                                      toogleModalContribute={
                                        this.toogleModalContribute
                                      }
                                      VIP={info.memberVIP}
                                      toogleModalEditPhrase={
                                        this.toogleModalEditPhrase
                                      }
                                      playSingleWord={this.playSingleWord}
                                      handleInputPractice={
                                        this.handleInputPractice
                                      }
                                      currentPhraseSpeech={currentPhraseSpeech}
                                      handleSetCurrentPhraseSpeech={
                                        this.handleSetCurrentPhraseSpeech
                                      }
                                      hanldeEnterPractive={
                                        this.hanldeEnterPractive
                                      }
                                      handlePracticeWord={
                                        this.handlePracticeWord
                                      }
                                      hanldeToogleFavorite={
                                        this.hanldeToogleFavorite
                                      }
                                      openVideoImage={this.openVideoImage}
                                      playTrack={this.playTrack}
                                      onLoadLoopItem={this.onLoadLoopItem}
                                      hanldeShowPractive={
                                        this.hanldeShowPractive
                                      }
                                      handleUpdateErrorImage={this.handleUpdateErrorImage}
                                    />
                                  </div>
                                  {!isHidePhraseText && (
                                    <div className="backup-list">
                                      {/* {item.showBackupPhrase && item.hasBackup && item.listBackup && item.listBackup.map((backupItem, idx) => {
                                let arrWordBK = backupItem.phrase ? this.splitStringIntoWords(backupItem.phrase) : [];
                                return (<div key={`backup${idx}`} className={`${(isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].phraseID === backupItem.phraseID) || (backupItem.isPlaying && !backupItem.mute) ? "active" : ""} item-words`}>
                                  <div className="cbx-selected-ph">
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        title={t("Add To Playlist")}
                                        className="custom-control-input cbx-phrase"
                                        id={`cbxphrase${index}_${idx}`}
                                        type="checkbox"
                                        checked={backupItem.selectedPlaylist ? backupItem.selectedPlaylist : false}
                                        onChange={(e) => this.hanldeAddPhraseToPlaylist(e, backupItem, true)}
                                      />
                                      <label className="custom-control-label" htmlFor={`cbxphrase${index}_${idx}`}></label>
                                    </div>
                                  </div>
                                  <PhraseItem isChildPhrase={true} isHidePhraseText={isHidePhraseText} item={backupItem} currentFlags={currentFlags} arrWord={arrWordBK} index={idx} playSingleWord={this.playSingleWord} handleInputPractice={this.handleInputPractice} currentPhraseSpeech={currentPhraseSpeech} handleSetCurrentPhraseSpeech={this.handleSetCurrentPhraseSpeech} hanldeEnterPractive={this.hanldeEnterPractive} handlePracticeWord={this.handlePracticeWord} hanldeToogleFavorite={this.hanldeToogleFavorite} openVideoImage={this.openVideoImage} playTrack={this.playTrack} onLoadLoopItem={this.onLoadLoopItem} hanldeShowPractive={this.hanldeShowPractive} replacePhrase={this.replacePhrase} />
                                </div>)
                              }
                              )} */}
                                      {item.listAnswer &&
                                        item.listAnswer.map(
                                          (answerItem, idx) => {
                                            let arrWordAnswer =
                                              answerItem.phrase
                                                ? this.splitStringIntoWords(
                                                  answerItem.phrase
                                                )
                                                : [];
                                            return (
                                              <div
                                                ref={phraseRefs[answerItem.index]}
                                                key={`answer${idx}`}
                                                className={`${(isPlayingPlaylist &&
                                                  selectedPhrasePlaylist[
                                                  currentTrackIndex
                                                  ] &&
                                                  selectedPhrasePlaylist[
                                                    currentTrackIndex
                                                  ].index ===
                                                  answerItem.index) ||
                                                  (answerItem.isPlaying &&
                                                    !answerItem.mute)
                                                  ? "active"
                                                  : ""
                                                  } item-words`}
                                              >
                                                <div className="cbx-selected-ph">
                                                  <div className="custom-control custom-checkbox">
                                                    <input
                                                      title={t(
                                                        "Add To Playlist"
                                                      )}
                                                      className="custom-control-input cbx-phrase"
                                                      id={`cbx${answerItem.index}`}
                                                      type="checkbox"
                                                      checked={
                                                        answerItem.selectedPlaylist
                                                          ? answerItem.selectedPlaylist
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        this.hanldeAddPhraseToPlaylist(
                                                          e,
                                                          answerItem,
                                                          true
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={`cbx${answerItem.index}`}
                                                    ></label>
                                                  </div>
                                                </div>
                                                <PhraseItem
                                                  isChildPhrase={true}
                                                  isHidePhraseText={
                                                    isHidePhraseText
                                                  }
                                                  item={answerItem}
                                                  currentFlags={currentFlags}
                                                  arrFlag={arrFlag}
                                                  arrWord={arrWordAnswer}
                                                  index={idx}
                                                  VIP={info.memberVIP}
                                                  handleOpenModal={
                                                    this.handleOpenModal
                                                  }
                                                  toogleModalEditPhrase={
                                                    this.toogleModalEditPhrase
                                                  }
                                                  playSingleWord={
                                                    this.playSingleWord
                                                  }
                                                  handleInputPractice={
                                                    this.handleInputPractice
                                                  }
                                                  currentPhraseSpeech={
                                                    currentPhraseSpeech
                                                  }
                                                  handleSetCurrentPhraseSpeech={
                                                    this
                                                      .handleSetCurrentPhraseSpeech
                                                  }
                                                  hanldeEnterPractive={
                                                    this.hanldeEnterPractive
                                                  }
                                                  handlePracticeWord={
                                                    this.handlePracticeWord
                                                  }
                                                  hanldeToogleFavorite={
                                                    this.hanldeToogleFavorite
                                                  }
                                                  openVideoImage={
                                                    this.openVideoImage
                                                  }
                                                  playTrack={this.playTrack}
                                                  onLoadLoopItem={
                                                    this.onLoadLoopItem
                                                  }
                                                  hanldeShowPractive={
                                                    this.hanldeShowPractive
                                                  }
                                                  replacePhrase={
                                                    this.replacePhrase
                                                  }
                                                  handleUpdateErrorImage={this.handleUpdateErrorImage}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          {(!listPharse || listPharse.length === 0) && (
                            <div className="text-center">{t("No data")}</div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                    {/* ========Introduce Buy Package =============== */}
                    {/* {(CurrentPackageAmount === 0 && !Trial) &&
                      <div className="cover-intro-buy-pack">
                        <div>
                          <div className="title-intro">{t("Buy a package to start experiencing English learning through interesting topics.")}</div>
                          <div className="text-center mb-2 mt-3 div-btn-submit">
                            <Link
                              className="btn btn-success btn-submit"
                              to="/buy-package"
                            >
                              {t("Buy Package")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    } */}
                  </div>}

                </Col>
                {/* ===========List Topic New==============  */}

              </Row>
            </Container>
          </section>
        </main>
        {/* ========Modal Add Phrase to Playlist =============== */}
        <Modal
          toggle={() =>
            this.setState({ showModalPlaylist: !showModalPlaylist })
          }
          isOpen={showModalPlaylist}
        >
          <div className=" modal-header">
            <h5 className=" modal-title">{t("Save the phrase to")}...</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({ showModalPlaylist: !showModalPlaylist })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            {modalUserPlaylist &&
              modalUserPlaylist.map((item, index) => (
                <div
                  className="cbx-selected-ph user-playlist"
                  key={"play" + index}
                >
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input cbx-phrase"
                      type="checkbox"
                      id={"cbplay" + index}
                      onChange={(e) => this.hanldeAddToUserPlayList(e, item)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"cbplay" + index}
                    >
                      {item.playlistName}
                    </label>
                  </div>
                </div>
              ))}
            {modalUserPlaylist.length === 0 && (
              <div className="text-center">{t("No playlist")}</div>
            )}
          </ModalBody>
          <ModalFooter>
            {showCreatePlaylist && (
              <div className="form-create-playlist">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup>
                    <label>{t("Name")}</label>
                    <InputGroup>
                      <Input
                        placeholder={t("Enter playlist name")}
                        type="text"
                        autoComplete="off"
                        name="createPLName"
                        onChange={(e) =>
                          this.setState({ createPLName: e.target.value })
                        }
                        onKeyUp={(e) => this.hanldeEnterPlaylist(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <span
                            className="pointer text-success icon-btn"
                            onClick={() => this.handleCreateNewPlaylist()}
                            title={t("Create")}
                          >
                            {" "}
                            <i className="fa fa-check-circle"></i>
                          </span>
                          <span
                            className="pointer text-danger icon-btn"
                            onClick={() => this.resetCreateNewPlaylist()}
                            title={t("Cancel")}
                          >
                            {" "}
                            <i className="fa fa-times"></i>
                          </span>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {errcreatePLName && (
                      <div className="error">{errcreatePLName}</div>
                    )}
                  </FormGroup>
                </Form>
              </div>
            )}
            {!showCreatePlaylist && (
              <Button onClick={this.hanldeShowCreatePlaylist} color="success">
                {t("Create new playlist")}
              </Button>
            )}
          </ModalFooter>
        </Modal>
        {/* <Modal isOpen={showBuyPackage} toggle={() => this.setState({ showBuyPackage: !showBuyPackage })} >
          <div className=" modal-header">
            <h5 className=" modal-title" >
              {t("Activate your account")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => this.setState({ showBuyPackage: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody >
            <div className="cover-intro-buy-pack">
              <div>
                <div className="title-intro">{t("Please become a member to enjoy the journey of exploring English.")}</div>
                <div className="text-center mb-2 mt-3 div-btn-submit">
                  <Link
                    className="btn btn-success btn-submit"
                    to="/transaction/1"
                  >
                    {t("Donate")}
                  </Link>
                </div>
              </div>
            </div>
          </ModalBody> 
        </Modal> */}
        <Modal
          isOpen={showVideo}
          toggle={() => this.setState({ showVideo: !showVideo })}
          size="lg"
          className="modal-video-img"
        >
          <div className=" modal-header">
            <h5 className=" modal-title">
              {t("How to pronounce")}{" "}
              <span className="text-highlight">
                "{selectedPhrase && <span>{selectedPhrase.phrase}</span>}"
              </span>{" "}
              {t("in English")}.
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => this.setState({ showVideo: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody className="modal-video">
            <div className="flex-center-center mt-1">
              {videoId && (
                <iframe
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}?start=${currentSeconds}&autoplay=1&vs=${replayCount}`}
                  frameBorder="0"
                  allowFullScreen
                  title="Embedded Video"
                  autoPlay={1}
                ></iframe>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="flex-center-center">
            {listVideoID.length > 1 && (
              <div className="video-caret" onClick={this.prevVideo}>
                <i className="fa fa-caret-left"></i>
              </div>
            )}
            <div className="replay youtube-refresh" onClick={this.replayVideo} style={{ cursor: 'pointer' }}>
              <i className="fa fa-refresh"></i>
            </div>
            {listVideoID.length > 1 && (
              <div className="video-caret" onClick={this.nextVideo}>
                <i className="fa fa-caret-right"></i>
              </div>
            )}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={showImage}
          toggle={() => this.setState({ showImage: !showImage })}
          size="lg"
          className="modal-video-img"
        >
          <div className=" modal-header">
            <h5 className=" modal-title">
              {t("Related images")}{" "}
              <span className="text-highlight">
                "{selectedPhrase && <span>{selectedPhrase.phrase}</span>}"
              </span>{" "}
              {t("in English")}.
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => this.setState({ showImage: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody className="modal-video">
            <div className="flex-center-center mt-1">
              {currentImage && (
                <img className="img-relate" src={currentImage}></img>
              )}
            </div>
          </ModalBody>
          {listImages.length > 1 && (
            <ModalFooter className="flex-center-center">
              <div className="video-caret" onClick={this.prevImage}>
                <i className="fa fa-caret-left"></i>
              </div>
              <div className="video-caret" onClick={this.nextImage}>
                <i className="fa fa-caret-right"></i>
              </div>
            </ModalFooter>
          )}
        </Modal>
        {/* ========Single Sound=============== */}
        {isPlayingSingleWord && (
          <ReactHowler
            src={soundPathSingleWord ? soundPathSingleWord : "audio1.mp3"}
            playing={isPlayingSingleWord}
            onEnd={() => this.setState({ isPlayingSingleWord: false })}
          />
        )}
        {/* ========Playlist Sound=============== */}
        {/* {isPlayingPlaylist && selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].soundPath && 
          <ReactHowler
            html5={true}
            src={selectedPhrasePlaylist[currentTrackIndex] && selectedPhrasePlaylist[currentTrackIndex].soundPath ? selectedPhrasePlaylist[currentTrackIndex].soundPath : "audio.mp3"}
            playing={isPlayingPlaylist && !isWaiting}
            // loop={isPlayingPlaylistLoop}
            onEnd={this.nextTrack}
            onLoad={() => this.scrollToCenter(selectedPhrasePlaylist[currentTrackIndex].phraseID)}
            ref={(ref) => (this.audio = ref)}
            // onPlay={this.onLoadTrack}
            preload={true}
            autoplay={false}
          />
        } */}
        <audio
          id="audioPlayist"
          controls
          hidden
          onLoad={() =>
            this.scrollToCenter(selectedPhrasePlaylist[currentTrackIndex])
          }
        />
        {/* <Modal
          toggle={() => this.setState({ modalContribute: !modalContribute })}
          isOpen={modalContribute}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="PWModalLabel">
              {t(
                contributeType === 1
                  ? "Contribute phrases"
                  : contributeType === 3
                    ? "User answer phrase"
                    : "Contribute answer phrase"
              )}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({ modalContribute: !modalContribute })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              <FormGroup className="mb-3">
                <Input
                  type="text"
                  name="reflink"
                  autoComplete="on"
                  placeholder={t("Enter a phrase")}
                  value={contributeValue}
                  onChange={(e) =>
                    this.setState({ contributeValue: e.target.value })
                  }
                />
                {errorContribute && (
                  <div className="error">{errorContribute}</div>
                )}
              </FormGroup>
      </Form>
          </ModalBody >
      <ModalFooter>
        <Button
          color="success"
          type="button"
          onClick={this.handleContribute}
        >
          {t("Submit")}
        </Button>
      </ModalFooter>
        </Modal > */}

        <Modal Modal
          toggle={() =>
            this.setState({
              modalContributeDelete: !this.state.modalContributeDelete,
            })
          }
          isOpen={this.state.modalContributeDelete}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="PWModalLabel">
              {t("Confirmation")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({
                  modalContributeDelete: !this.state.modalContributeDelete,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              {t("Are you sure to remove this topic?")}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type="button" onClick={this.handleCancelDelte}>
              {t("Cancel")}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={this.handleDeleteTopic}
            >
              {t("OK")}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          toggle={() =>
            this.setState({
              modalContributeShare: !this.state.modalContributeShare,
            })
          }
          isOpen={this.state.modalContributeShare}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="PWModalLabel">
              {t("Share Topic")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({
                  modalContributeShare: !this.state.modalContributeShare,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              <div style={{}}>
                {" "}
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginBottom: "20px",
                  }}
                >
                  <thead style={{ backgroundColor: "#f2f2f2" }}>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "12px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        Email
                      </th>
                      <th
                        style={{
                          backgroundColor: "#4CAF50",
                          color: "white",
                          padding: "12px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {t("Action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.arrShareEmail?.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            background: "#fff",
                            padding: "8px",
                            border: "1px solid #ddd",
                            textAlign: "left",
                          }}
                        >
                          <div style={{ margin: "10px 0" }}>{item.email}</div>
                        </td>
                        <td
                          style={{
                            background: "#fff",
                            padding: "8px",
                            border: "1px solid #ddd",
                            textAlign: "left",
                          }}
                        >
                          <i
                            className={`fa fa-trash`}
                            style={{
                              color: "red",
                              margin: "12px 20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.handleDeleteShareTopic(item.id);
                            }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <FormGroup className="col-10">
                  <Input
                    type="text"
                    name="reflink"
                    autoComplete="on"
                    placeholder={t("Enter email")}
                    value={this.state.emailShare}
                    onChange={(e) =>
                      this.setState({ emailShare: e.target.value })
                    }
                  />
                  {this.state.shareEmail && (
                    <div className="error">{this.state.shareEmail}</div>
                  )}
                </FormGroup>
                <div className="col-2">
                  <i
                    className="fa fa-floppy-o"
                    style={{
                      padding: "5px 10px",
                      fontSize: "25px",
                      marginTop: "4px",
                      color: "green",
                      border: "1px solid green",
                      borderRadius: "10px",
                      cursor: 'pointer'
                    }}
                    onClick={this.handleAddEmailShareTopic}
                  ></i>
                </div>
              </div>
              {/* <FormGroup className="mb-3">
                <Input type="text" name="reflink" autoComplete="on" placeholder={t("Enter a phrase") + " (Vietnamese)"} value={contributeValueVN} onChange={(e) => this.setState({ contributeValueVN: e.target.value })} />
                {errorContributeVN && <div className="error">{errorContributeVN}</div>}
              </FormGroup> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="button"
              onClick={() =>
                this.setState({
                  modalContributeShare: !this.state.modalContributeShare,
                })
              }
            >
              {t("Close")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() =>
            this.setState({
              modalContributeAdd: !this.state.modalContributeAdd,
            })
          }
          isOpen={this.state.modalContributeAdd}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="PWModalLabel">
              {t("Add Topic")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({
                  modalContributeAdd: !this.state.modalContributeAdd,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              <Input
                type="text"
                name="reflink"
                autoComplete="on"
                placeholder={t("Add your topic")}
                value={this.state.addtopic}
                onChange={(e) => this.setState({ addtopic: e.target.value })}
              />
              {this.state.errorAddTopic && (
                <div className="error">{this.state.errorAddTopic}</div>
              )}

              {/* <FormGroup className="mb-3">
                <Input type="text" name="reflink" autoComplete="on" placeholder={t("Enter a phrase") + " (Vietnamese)"} value={contributeValueVN} onChange={(e) => this.setState({ contributeValueVN: e.target.value })} />
                {errorContributeVN && <div className="error">{errorContributeVN}</div>}
              </FormGroup> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="button" onClick={this.handleAddTopic}>
              {t("Submit")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() =>
            this.setState({
              modalContributeAddSub: !this.state.modalContributeAddSub,
            })
          }
          isOpen={this.state.modalContributeAddSub}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="PWModalLabel">
              {t("Add Your Phrase")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({
                  modalContributeAddSub: !this.state.modalContributeAddSub,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              <Input
                type="text"
                name="reflink"
                autoComplete="on"
                placeholder={t("Enter your phrase")}
                value={this.state.addPhraseTopic}
                onChange={(e) =>
                  this.setState({ addPhraseTopic: e.target.value })
                }
              />
              {this.state.phraseTopic && (
                <div className="error">{this.state.phraseTopic}</div>
              )}

              {/* <FormGroup className="mb-3">
                <Input type="text" name="reflink" autoComplete="on" placeholder={t("Enter a phrase") + " (Vietnamese)"} value={contributeValueVN} onChange={(e) => this.setState({ contributeValueVN: e.target.value })} />
                {errorContributeVN && <div className="error">{errorContributeVN}</div>}
              </FormGroup> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="button"
              onClick={this.handleAddPhraseTopic}
            >
              {t("Submit")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() => this.setState({ modalEditPhrase: !modalEditPhrase })}
          isOpen={modalEditPhrase}
        >
          <div className=" modal-header">
            <h5 className=" modal-title title-mb" id="EditPhraseModalLabel">
              {t("Edit phrases")}
            </h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() =>
                this.setState({ modalEditPhrase: !modalEditPhrase })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form role="form">
              <FormGroup className="mb-3">
                <Input
                  type="text"
                  name="reflink"
                  autoComplete="on"
                  placeholder={t("Enter a phrase")}
                  value={editPhraseValue}
                  onChange={(e) =>
                    this.setState({ editPhraseValue: e.target.value })
                  }
                />
                {errorEditPhrase && (
                  <div className="error">{errorEditPhrase}</div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  type="text"
                  name="reflink"
                  autoComplete="on"
                  placeholder={t("Enter a phrase") + " (Vietnamese)"}
                  value={editPhraseValueVN}
                  onChange={(e) =>
                    this.setState({ editPhraseValueVN: e.target.value })
                  }
                />
                {errorEditPhraseVN && (
                  <div className="error">{errorEditPhraseVN}</div>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="button"
              onClick={this.handleEditPhrase}
            >
              {t("Submit")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalNotify} toggle={() => this.setState({ showModalNotify: !showModalNotify })} backdrop="static">
          <div className=" modal-header">
            <h5 className=" modal-title" >
              {t("Warning")}
            </h5>
            <button
              aria-label="Close"
              className="bn-close close"
              type="button"
              onClick={() => this.setState({ showModalNotify: !showModalNotify })}
            >
              <span aria-hidden={true}><i className="fa fa-times"></i></span>
            </button>

          </div>
          <ModalBody >
            <div className="cover-intro-buy-pack">
              <div>
                <div className="content-notify">
                  {t('You need to hold')} ${info ? FormatNumberNTC(info.conditionBITReceive) : ""} BIT ${t('to receive the')} ${info ? FormatNumberNTC(info.amountBITReceiveLearn) : ""} BIT ${t('learning reward. Try it out')}
                  <span className="link-trial" onClick={this.handleTrial}> {t("here.")}</span>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModalreceiveBIT} toggle={() => this.setState({ showModalreceiveBIT: !showModalreceiveBIT })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          <ModalBody>
            <div className="cover-congratulate">
              <img src={require(`assets/img/congratulate.gif`)} />
            </div>
            <div>
              <div className="text-center">
                <img src={require(`assets/img/receiveBIT.png`)} className="img-popup" />
              </div>
              <div className="text-center text-popup-1">{t("CONGRATULATIONS")}!</div>
              <div className="text-center text-popup-2 pb-3">{t(textCongratulate)}</div>
              {/* <div className=" text-center">
                <Button
                  aria-label="Close"
                  type="button"
                  className="btn-close-popup"
                  onClick={() => this.setState({ showModalreceiveBIT: !showModalreceiveBIT })}
                >
                  <span aria-hidden={true}>Close</span>
                </Button>
              </div> */}
            </div>
          </ModalBody>
        </Modal>
        {showModalreceiveBIT && <ReactHowler
          src={require(`assets/audio/cashiersound.mp3`)}
          playing={true}
          html5={true}

        />}
        <Modal isOpen={showModalReceiveScholarShip} toggle={() => this.setState({ showModalReceiveScholarShip: !showModalReceiveScholarShip })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModalReceiveScholarShip: !showModalReceiveScholarShip })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/sholarship_${amountScholarship}.jpg`)} />
            </div>
            <div className="text-scholarship-top">{t("Scholarship")}</div>
            <div className="text-scholarship-bottom">{t("Receive Reward")}</div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModalReceiveScholarShipFake} toggle={() => this.setState({ showModalReceiveScholarShipFake: !showModalReceiveScholarShipFake })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModalReceiveScholarShip: !showModalReceiveScholarShip })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/sholarship_${amountScholarship}.jpg`)} />
            </div>
            <div className="text-scholarship-top">{t("Scholarship")}</div>
            <div className="text-scholarship-bottom text-scholarship-bottom-small">{inviteFriend > 1 ? t("Invite {{amtf}} friends to study together to receive this scholarship.", { amtf: inviteFriend }) : t("Invite a friend to study together to receive this scholarship")}</div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModalReceiveScholarShip_Car} toggle={() => this.setState({ showModalReceiveScholarShip_Car: !showModalReceiveScholarShip_Car })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModalReceiveScholarShip_Car: !showModalReceiveScholarShip_Car })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/receive-car.jpg`)} />
            </div>
            <div className="text-receive-car-top">{t("Receive")}</div>
            <div className="text-receive-car-top-1">{t("Scholarship")}</div>
            <div className="text-receive-car-bottom">
              <div>{t("Successfully share 2 of your links to receive a $20 scholarship.")}</div><br />
              <div>{t("Successfully share 3 of your links to receive a $50 scholarship.")}</div><br />
              <div>{t("Successfully share 5 of your links to receive a $100 scholarship.")}</div>
            </div>
            <div className="flex-center cover-btn">
              <button className="btn-tab" onClick={() => this.toggleModalCar('showModalCar1')}>{t("MERCEDES C300 COUPLE")}</button>
              <button className="btn-tab" onClick={() => this.toggleModalCar('showModalCar2')}>{t("MERCEDES E350")}</button>
              <button className="btn-tab" onClick={() => this.toggleModalCar('showModalCar3')}>{t("MERCEDES S500")}</button>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showModalCar1} toggle={() => this.toggleModalCar('showModalCar1')} size="md">
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require('assets/img/C300-Couple.jpg')} />
            </div>
            <div className="text-car">{t("If the total scholarship you receive is $20,000")}</div>
            <div className="text-car-1">{t("Bonus")}</div>
            {/* <div className="text-car-2">{t("Details")}</div> */}
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.showModalCar2} toggle={() => this.toggleModalCar('showModalCar2')} size="md">
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require('assets/img/E350.jpg')} />
            </div>
            <div className="text-car">{t("If the total scholarship you receive is $50,000")}</div>
            <div className="text-car-1">{t("Bonus")}</div>
            {/* <div className="text-car-2">{t("Details")}</div> */}
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.showModalCar3} toggle={() => this.toggleModalCar('showModalCar3')} size="md">
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require('assets/img/S500.jpg')} />
            </div>
            <div className="text-car">{t("If the total scholarship you receive is $100,000")}</div>
            <div className="text-car-1">{t("Bonus")}</div>
            {/* <div className="text-car-2">{t("Details")}</div> */}
          </ModalBody>
        </Modal>
        <Modal isOpen={showModalLearnNextPhrase} toggle={() => this.setState({ showModalLearnNextPhrase: !showModalLearnNextPhrase })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModalLearnNextPhrase: !showModalLearnNextPhrase })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/errors.jpg`)} />
            </div>
            <div className="text-errors">{t("The scholarship information might be included in the following sentence.")}</div>
            <div className="text-errors-2" onClick={() => this.setState({ showModalReceiveScholarShip_Car: true, showModalLearnNextPhrase: false })}>{t("Learn more about the policy")}</div>
            <div className="text-errors-1" onClick={() => this.setState({ showModalLearnNextPhrase: !showModalLearnNextPhrase })}>{t("Please continue studying.")}</div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModaShare1Get300} toggle={() => this.setState({ showModaShare1Get300: !showModaShare1Get300 })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModaShare1Get300: !showModaShare1Get300 })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/errors.jpg`)} />
            </div>
            <div className="text-errors">{t("Invite a friend to study together to receive a $50 scholarship.")}</div>
            <div className="text-errors-2" onClick={() => this.setState({ showModalReceiveScholarShip_Car: true, showModaShare1Get300: false })}>{t("Learn more about the policy")}</div>
            <div className="text-errors-1" onClick={() => this.setState({ showModaShare1Get300: !showModaShare1Get300 })}>{t("Please continue studying.")}</div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModaShare2Get500} toggle={() => this.setState({ showModaShare2Get500: !showModaShare2Get500 })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModaShare2Get500: !showModaShare2Get500 })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/errors.jpg`)} />
            </div>
            <div className="text-errors">{t("Invite an additional two friends to study with you to receive a $100 scholarship.")}</div>
            <div className="text-errors-2" onClick={() => this.setState({ showModalReceiveScholarShip_Car: true, showModaShare2Get500: false })}>{t("Learn more about the policy")}</div>
            <div className="text-errors-1" onClick={() => this.setState({ showModaShare2Get500: !showModaShare2Get500 })}>{t("Please continue studying.")}</div>
          </ModalBody>
        </Modal>
        <Modal isOpen={showModaShare1Get500} toggle={() => this.setState({ showModaShare1Get500: !showModaShare1Get500 })} onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} size="md">
          {/* <ModalHeader toggle={() => this.setState({ showModaShare2Get500: !showModaShare2Get500 })}/> */}
          <ModalBody>
            <div className="cover-scholarship">
              <img src={require(`assets/img/errors.jpg`)} />
            </div>
            <div className="text-errors">{t("Invite an additional friend to study with you to receive a $100 scholarship.")}</div>
            <div className="text-errors-2" onClick={() => this.setState({ showModalReceiveScholarShip_Car: true, showModaShare1Get500: false })}>{t("Learn more about the policy")}</div>
            <div className="text-errors-1" onClick={() => this.setState({ showModaShare1Get500: !showModaShare1Get500 })}>{t("Please continue studying.")}</div>
          </ModalBody>
        </Modal>
        <Modal toggle={this.onTooglePolicyModal} isOpen={showPolicyModal} backdrop="static" onOpened={this.hanldeOpenCongratulate} onClosed={this.hanldeCloseCongratulate} >
          <div className=" modal-header">
            <h5 className=" modal-title">
              {t("Reward policy")}
            </h5>
            <button
              aria-label={t("Close")}
              className=" close"
              type="button"
              onClick={this.onTooglePolicyModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <div>
              {recommendCar === 1 &&
                <div className="text-justify">
                  {/* Nội dung chính sách xe 300 */}
                  <p>{t("If you mentor 5 members, each member receiving a scholarship of 20,000 USDT, you will be immediately rewarded with a C 300 Coupe Specifications car.")}</p>
                  <p>{t("The car price will be determined based on the link provided at the time you achieve the target.")}</p>
                  <a target="_blank" href="https://www.mbusa.com/en/vehicles/model/c-class/coupe/c300c">https://www.mbusa.com/en/vehicles/model/c-class/coupe/c300c</a>
                </div>
              }
              {recommendCar === 2 &&
                <div className="text-justify">
                  {/* Nội dung chính sách xe 450 */}
                  <p>{t("If you mentor 5 members, each member receiving a scholarship of 30,000 USDT, you will be immediately rewarded with a E 350 4MATIC Sedan Specifications car.")}</p>
                  <p>{t("The car price will be determined based on the link provided at the time you achieve the target.")}</p>
                  <a target="_blank" href="https://www.mbusa.com/en/vehicles/model/e-class/sedan/e350w4">https://www.mbusa.com/en/vehicles/model/e-class/sedan/e350w4</a>
                </div>
              }
              {recommendCar === 3 &&
                <div className="text-justify">
                  {/* Nội dung chính sách xe 500 */}
                  <p>{t("If you mentor 5 members, each member receiving a scholarship of 50,000 USDT, you will be immediately rewarded with a S 500 4MATIC Sedan Specifications car.")}</p>
                  <p>{t("The car price will be determined based on the link provided at the time you achieve the target.")}</p>
                  <a target="_blank" href="https://www.mbusa.com/en/vehicles/model/s-class/sedan/s500v4">https://www.mbusa.com/en/vehicles/model/s-class/sedan/s500v4</a>
                </div>
              }
              {metaDataLinkCar &&
                <a className="meta-info" href={metaDataLinkCar.url} target="_blank">
                  <div>
                    {metaDataLinkCar["og:title"] && <b>{metaDataLinkCar["og:title"]}</b>}
                    {metaDataLinkCar["og:description"] && <p>{metaDataLinkCar["og:description"]}</p>}
                  </div>
                  {metaDataLinkCar["og:image"] && <div>
                    <img src={metaDataLinkCar["og:image"]} />
                  </div>}
                </a>
              }
            </div>
          </ModalBody>
        </Modal>
        {isLoading && <Loading />}
        <ToastContainer pauseOnFocusLoss={false} />
        <ModalConfirm
          message={confirmText}
          onConfirm={() => this.handleConfirm()}
          onCancel={this.handleCancel}
          open={showConfirm}
        />

      </>
    );
  }
}
export default withTranslation()(Index);