import React from 'react';//, { useState }
import { Navigate, Outlet } from 'react-router-dom';
import configs from 'configs/configs';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Kiểm tra điều kiện đăng nhập ở đây
  // Ví dụ: Lưu thông tin đăng nhập vào localStorage và kiểm tra nó ở đây
  const checkAuthentication = () => {
    const token = localStorage.getItem('token');
    
    return token !== null && token !== undefined && token !== "";
  };
  let location = new URL(window.location.href);
  let domain = `${location.protocol}//${location.hostname}`;
  if(location.port){
      domain +=":"+location.port;
  }
  return checkAuthentication() ? <Outlet /> : <Navigate to={domain===configs.WebRootEmail?"/e-login":"/error"} />;
};

export default PrivateRoute;