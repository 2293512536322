
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/css/react-paginate.css?v1.1.0";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/scss/main.scss?v1.1.0";
import "assets/scss/theme.scss?v1.1.0";
import "assets/scss/index.scss?v1.1.0";
import "assets/scss/wallet.scss?v1.1.0";
import "assets/scss/transaction.scss?v1.1.0";
// import Index from "views/Home/Index.js";
import LoginByTelegram from "views/User/LoginByTelegram.js";
import LoginByTelegramChatID from "views/User/LoginByTelegramChatID.js";
import LoginByTelegramChatIDLearning from "views/User/LoginByTelegramChatIDLearning.js";
import UM from "views/User/UM.js";
import Index from "views/Home/Index.js";
import Wallet from "views/Home/Wallet.js";
import "./i18n/i18n"
import RPTHistoryCommon from "views/Report/RPTHistoryCommon";
import PolicyPage from "views/Home/PolicyPage";
import PrivateRoute from "components/Auth/Authentication";
import Support from "views/Home/Support";
import Error from "views/Home/Error";
import RegisterPage from "views/User/RegisterPage";
import ActivationPage from "views/User/ActivationPage";
import LoginPage from "views/User/LoginPage";
import ForgotPage from "views/User/ForgotPage";
const root = ReactDOM.createRoot(document.getElementById("root"));
if (window.Telegram && window.Telegram.WebApp) {
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.expand();
}
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<PrivateRoute />}>
        <Route exact path='/' element={<Wallet />} />
        <Route exact path='/learn' element={<Index />} />
        <Route exact path='/transaction/:type' element={<RPTHistoryCommon />} />
        <Route exact path='/support' element={<Support />} />
      </Route>
      <Route path="/:token" exact element={<LoginByTelegram />} />
      <Route path="/login" exact element={<LoginByTelegramChatID />} />
      <Route path="/e-login" exact element={<LoginPage />} />
      <Route path="/login/:lang" exact element={<LoginByTelegramChatID />} />
      <Route path="/login-learn" exact element={<LoginByTelegramChatIDLearning />} />
      <Route path="/login-learn/:lang" exact element={<LoginByTelegramChatIDLearning />} />
      <Route path="/register/:code/:lang" exact element={<RegisterPage />} />
      <Route path="/register" exact element={<RegisterPage />} />
      <Route path="/activation/:code" exact element={<ActivationPage />} />
      <Route path="/forgot" exact element={<ForgotPage />} />
      <Route path="/um" exact element={<UM />} />
      <Route path="/error" exact element={<Error />} />
      <Route path="/policy" exact element={<PolicyPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />

    </Routes>
  </BrowserRouter>
);
