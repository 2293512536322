import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./en.json";
import Vietnamese from "./vi.json";
import Hindi from "./hi.json";
import Arabic from "./ar.json";
import China from "./zh.json";
import France from "./fr.json";
import Germany from "./de.json";
import Italian from "./it.json";
import Portugal from "./pt.json";
import Russia from "./ru.json";
import Spanish from "./es.json";
var lang = localStorage.getItem("lang") && localStorage.getItem("lang") !== "null" ? localStorage.getItem("lang") : "en";
const resources = {
    en: {
        translation: English,
    },
    vi: {
        translation: Vietnamese,
    },
    ar: {
        translation: Arabic,
    },
    zh: {
        translation: China,
    },
    fr: {
        translation: France,
    },
    de: {
        translation: Germany,
    },
    it: {
        translation: Italian,
    },
    pt: {
        translation: Portugal,
    },
    ru: {
        translation: Russia,
    },
    es: {
        translation: Spanish,
    },
    hi: {
        translation: Hindi,
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
