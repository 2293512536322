import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Form
} from "reactstrap";
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken, postFormData } from "services/apiServices";
import { cutNumberFormat, FormatNumberCoin, formatNumber, FormatNumberAmount } from "services/common";
import configs from "configs/configs";
import { io } from "socket.io-client";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
import Resizer from "react-image-file-resizer";
class BuySellNowOTC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formDataOTCSellNow: {
                AmountCoin: 0,
                AmountOfLang: 0,
            },
            errors: {
                AmountCoin: "",
                AmountOfLang: "",
            },
        }
        this.inputRef = React.createRef();
        // this.socket = io(configs.SocketPrice);
    }

    clearForm = () => {
        //fill current ticket 
        // debugger;
        const { selectedTicketSellNowOTC, info, Coin } = this.props;
        // this.socket.disconnect();
        if (selectedTicketSellNowOTC) {
            let newerrors = {
                AmountCoin: '',
                AmountOfLang: '',
            };
            let _data = {
                AmountCoin: selectedTicketSellNowOTC.amount,
                AmountOfLang: 0,
            };
            this.setState({ formDataOTCSellNow: _data });
            this.setState({ errors: newerrors });
            // this.handleChangeAmount(selectedTicketSellNowOTC.amount, this.props.pricesLang, this.props.pricesAll)
        } else {
            // let newerrors = {
            //     AmountCoin: '',
            //     AmountOfLang: '',
            // };
            // let _data = {
            //     AmountCoin: 0,
            //     AmountOfLang: 0,
            // };
            // this.setState({ formDataOTCSellNow: _data });
            // this.setState({ errors: newerrors });
            let amount = info ? Number(info.wallet_TON).toFixed(3) : 0;
            if (Coin.symbol === "USDT") {
                amount = info ? Number(info.balance_USDT).toFixed(3) : 0;
            }
            this.handleChangeAmount(amount, this.props.pricesLang, this.props.pricesAll)
        }
        // else {
        //     this.socket = io(configs.SocketPrice);
        //     this.socket.on("connect", () => {
        //         // console.log("Price Connected");
        //     });
        //     this.socket.on("price", (msg) => {
        //         console.log("msg", msg);
        //         if (msg && msg.errorCode === 0) {
        //             let _price = 0;
        //             const { formDataOTCSellNow } = this.state;
        //             this.setState((prevState) => ({
        //                 formDataOTCSellNow: { ...prevState.formDataOTCSellNow, PriceOfLang: _price },
        //             }));
        //             this.setState({ pricesLang: msg.data.price, prices: msg.data.data })

        //         }
        //     });
        // }

    };

    handleBuySellOTC = async (e) => {
        const { formDataOTCSellNow, isFiat, Discount } = this.state;
        const { OTCInfo, remainTimeDiscount, OTCInfo_TON, Coin } = this.props;
        let formIsValid = true;
        const newErrors = {
            AmountCoin: "",
        };

        if (!formDataOTCSellNow.AmountCoin) {
            formIsValid = false;
            newErrors.AmountCoin = t('Please enter Coin amount.');
        }
        if (Coin) {
            if (Coin.symbol === "BIT") {
                if (isFiat && (!OTCInfo.parentOTCAccNumberBank || !OTCInfo.parentOTCNameBank || !OTCInfo.parentOTCCardholderNameBank)) {
                    toast.error(t("OTC does not provide banking information yet, so you cannot buy BIT with Fiat."), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000
                    });
                    return;
                }
            }
            else if (Coin.symbol === "TON") {
                if (!OTCInfo_TON.otcAccNumberBank || !OTCInfo_TON.otcNameBank || !OTCInfo_TON.otcCardholderNameBank) {
                    toast.error(t("OTC does not provide banking information yet, so you cannot buy TON."), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000
                    });
                    return;
                }
            }
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Amount: formDataOTCSellNow.AmountCoin ? Number(formDataOTCSellNow.AmountCoin) : 0,
                HasDiscount: remainTimeDiscount && remainTimeDiscount.total > 0 && Discount > 0 ? true : false
            };
            // if (!isFiat) {
            //     params = {
            //         AmountBITSwap: formDataOTCSellNow.AmountCoin ? Number(formDataOTCSellNow.AmountCoin) : 0,
            //         UserID: info.parentOTCID
            //     };
            // }
            this.props.hanldeBuyCoin(params, isFiat);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formDataOTCSellNow: { ...prevState.formDataOTCSellNow, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handleToogleModalBuySellOTC = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalBuySellOTC();
    };
    handleChangeAmount = async (value, pricesLang, prices) => {
        const { info, selectedTicketSellNowOTC, Coin } = this.props;
        value = value;
        let Amount = value ? Number(value) : 0;
        let amountReceive = 0;
        if (prices) {
            if (info && info.language) {//check thêm dk OTC đủ phí TON
                let price = pricesLang[info.language] * prices.TONUSDT;
                const currentSymbol = selectedTicketSellNowOTC ? selectedTicketSellNowOTC.symbol : Coin ? Coin.symbol : "";
                if (currentSymbol === "USDT") {
                    price = pricesLang[info.language];
                }
                amountReceive = Amount * price * (1 - prices.OTCRateFastSell);
            }
        }
        let _data = {
            AmountOfLang: amountReceive ? amountReceive.toFixed(3) : 0,
            AmountCoin: value,
        }
        this.setState({ formDataOTCSellNow: _data });
    };

    handleLoadModal = () => {
        this.clearForm();
    }
    handleCloseModal = () => {
        //disconnect socket price
        // if (this.socket) {
        //     this.socket.disconnect();
        // }
    }
    //Confirm
    handleOpenModalConfirmTransfer = (item) => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.showReceipt,
            errReceipt: "",
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            selectedTrans: item
        }));
    }
    onToogleConfirmTransfer = () => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
        }));
    }
    hanldeConfirmTransfer = async (confirmData) => {
        // const { confirmData } = this.state;
        const { selectedTicketSellNowOTC } = this.props;
        const { info } = this.props;
        let formIsValid = true;
        if (!confirmData.Receipt) {
            formIsValid = false;
            this.setState({ errReceipt: t('Please choose a receipt.') });
        }
        if (formIsValid) {
            this.setState({ errReceipt: "" });
            let _formData = new FormData();
            _formData.append('Receipt', confirmData.Receipt ? confirmData.Receipt : null);
            _formData.append('TransID', selectedTicketSellNowOTC.transID ? Number(selectedTicketSellNowOTC.transID) : 0);
            this.setState({ isLoading: true });
            let url = "/Purchase/ConfirmTransfer"
            if (selectedTicketSellNowOTC.otc) {
                url = "/Purchase/OTCConfirmTransfer"
            }
            try {
                const response = await postFormData(url, _formData);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Requested successfully"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    this.setState(prevState => ({
                        modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
                    }));
                    // this.handleToogleModalBuySellOTC();
                    this.props.hanldeLoadBasicInfo();
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    }
    handleFileSelected = (e) => {
        // debugger
        const files = Array.from(e.target.files);
        //resize image
        if (files && files.length > 0 && files[0]) {
            const fileExtension = files[0].name.split('.').pop().toLowerCase();;
            const allowedExtensions = ['png', 'jpeg', 'jpg'];
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(t('Only PNG, JPEG, and JPG files are allowed.'), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                return false;
            }
            try {
                Resizer.imageFileResizer(
                    files[0],
                    768,
                    768,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        const imageUrl = URL.createObjectURL(uri);
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, Receipt: uri },
                        }));
                        this.hanldeConfirmTransfer({ Receipt: uri });
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, ReceiptURL: imageUrl }, errReceipt: ""
                        }));
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }
        }

        // console.log("img url", imageUrl);

    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        const { info, selectedTicketSellNowOTC } = this.props;
        let params = {
            TransID: selectedTicketSellNowOTC.transID
        };
        this.setState({ isLoading: true });
        let url = "";
        if (this.state.currentType === 1) {
            url = "/Purchase/OTCAccecptedBuySell";
        } else if (this.state.currentType === 2) {
            if (selectedTicketSellNowOTC.otc) {
                url = "/Purchase/OTCConfirmReceiveTransfer"
            }
            else {
                url = "/Purchase/ConfirmReceiveTransfer"
            }
        } else if (this.state.currentType === 6) {
            url = "/Purchase/OTCReportFakeBillBuyUSDT";
        }
        else {
            url = "/Purchase/CancelBuySellUSDT";
        }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                // this.handleToogleModalBuySellOTC();
                this.props.handleGetListBuySellPending();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item, type) {
        // Mở modal confirm
        let _confirmText = "";
        let _confirmTitle = "";
        switch (type) {
            case 1: {//OTC Accept
                _confirmText = "Are you sure you want to confirm this transaction?";
                break;
            }
            case 2: {//OTC Confirm received payment
                if (item.typeBuy === 1) {//Fiat
                    _confirmText = "I have received payment.";
                } else {//swap TON
                    _confirmText = "I confirm the sale of BIT to the buyer.";
                }
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 3: {//Seller cancel
                _confirmText = "Are you sure you want to cancel this transaction?";
                _confirmTitle = "Cancel transaction";
                break;
            }
            case 5: {//OTC report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
            case 6: {//User report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
        }
        this.setState({ selectedTrans: item, confirmText: t(_confirmText), confirmTitle: _confirmTitle, currentType: type });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    componentDidUpdate(prevProps) {
        // Close popup when complete transaction
        if (this.props.selectedTicketSellNowOTC !== prevProps.selectedTicketSellNowOTC && !this.props.selectedTicketSellNowOTC && this.props.modalBuySellOTC) {
            if (this.props.ListTicketBuy && this.props.ListTicketBuy.length === 0) {
                this.handleToogleModalBuySellOTC();
                this.props.hanldeLoadBasicInfo();
                if (this.props.TypeTransaction === 1) {
                    this.props.handleReloadTransaction();
                }
                if (this.props.modalList_Swap) {
                    this.props.onToogleModalList_Swap();
                }
                if (this.props.modalListBuyTON) {
                    this.props.onToogleModalListBuyTON();
                }
            }
        }
        // // Close popup when disputed transaction
        // if (this.props.selectedTicketSellNowOTC !== prevProps.selectedTicketSellNowOTC && this.props.selectedTicketSellNowOTC && this.props.selectedTicketSellNowOTC.status === 2 && (!this.props.selectedTicketSellNowOTC.timeRemain || this.props.selectedTicketSellNowOTC.timeRemain.total <= 0) && this.props.modalBuySellOTCOpen) {
        //     this.handleToogleModalBuySellOTC();
        // }

        // Open popup when exist transaction
        //&& this.props.info.otc === false
        if (this.props.selectedTicketSellNowOTC !== prevProps.selectedTicketSellNowOTC && this.props.selectedTicketSellNowOTC && this.props.selectedTicketSellNowOTC.type === 1 && !this.props.modalBuySellOTC && this.props.selectedTicketSellNowOTC.status <= 3) {
            // debugger
            this.handleToogleModalBuySellOTC();
        }
        if ((this.props.pricesLang !== prevProps.pricesLang || this.props.pricesAll !== prevProps.pricesAll) && this.props.selectedTicketSellNowOTC) {
            this.handleChangeAmount(this.state.formDataOTCSellNow.AmountCoin, this.props.pricesLang, this.props.pricesAll)
        }
        if (this.props.modalBuySellOTC !== prevProps.modalBuySellOTC && this.props.modalBuySellOTC) {
            this.handleCheckDiscount()
        }
    }
    transferCurrency = (lang) => {
        switch (lang) {
            case "en": return "USD"
            case "vi": return "VND"
            case "ar": return "AED"
            case "de": return "EUR"
            case "es": return "EUR"
            case "fr": return "EUR"
            case "hi": return "INR"
            case "pt": return "EUR"
            case "it": return "EUR"
            case "ru": return "RUB"
            case "zh": return "CNY"
            case "usdt": return "USDT"
            case "bit": return "BIT"
        }
    }
    getUsername = (item) => {
        let name = item.buyerChatID;
        if (item.buyerUsername) {
            name = item.buyerUsername;
        }
        else if (item.buyerFullname) {
            name = item.buyerFullname;
        }
        return name + " (" + item.symbol + ")";
    }
    checkCurrency = () => {
        const { info } = this.props;
        let currency = "";
        if (info) {
            currency = this.transferCurrency(info.language);
        }
        return currency;
    }
    handleChangeTicket = (item) => {
        this.setState((prevState) => ({
            formDataOTCSellNow: {
                AmountCoin: item.amountUSDT,
            },
        }));
        this.props.handleChangeTicket(item)
    }
    handleCheckDiscount = () => {
        const { selectedTicketSellNowOTC, Coin, OTCInfo } = this.props;
        this.setState({ Discount: 0 })
        if (!selectedTicketSellNowOTC) {
            if (Coin) {
                if (Coin.symbol === "BIT" && OTCInfo && OTCInfo.parentOTCDiscount) {
                    this.setState({ Discount: OTCInfo.parentOTCDiscountPercent })
                }
            }
        }
        return "";
    }
    handleToogleModalSellNowOTC = () => {
        this.props.onToogleModalSellNowOTC();
    }
    handleConfirmSellNowOTC = async () => {
        const { formDataOTCSellNow } = this.state;
        let formIsValid = true;
        const newErrors = {
            AmountCoin: "",
        };
        if (!formDataOTCSellNow.AmountCoin) {
            formIsValid = false;
            newErrors.AmountCoin = t('Please enter Coin amount.');
        }


        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            let params = {
                "Amount": formDataOTCSellNow.AmountCoin,
            }
            this.props.handleConfirmSellNowOTC(params);
        }
    }
    handleCancelSellNowOTC = async () => {
        this.setState({ isLoading: true });
        let params = {
            "TransID": this.props.selectedTicketSellNowOTC.transID,
        }
        try {
            const response = await post('/Purchase/OTCCancelFastSell', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.handleToogleModalSellNowOTC();
                toast.success(t("Cancel successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleFillMaxAmount = () => {
        const { selectedTicketSellNowOTC, Coin } = this.props;
        const currentSymbol = selectedTicketSellNowOTC ? selectedTicketSellNowOTC.symbol : Coin ? Coin.symbol : "";
        let amount = currentSymbol === "TON" ? (this.props.info.wallet_TON ? this.props.info.wallet_TON : 0) : (this.props.info.balance_USDT ? this.props.info.balance_USDT : 0);
        this.setState((prevState) => ({
            formDataOTCSellNow: { ...prevState.formDataOTCSellNow, AmountCoin: Number(amount).toFixed(3) },
        }));
        this.handleChangeAmount(Number(amount).toFixed(3), this.props.pricesLang, this.props.pricesAll);
    };

    render() {
        const { selectedTicketSellNowOTC, info, pricesLang, pricesAll, modalBuySellNowOTC, Coin } = this.props;
        const { isLoading, formDataOTCSellNow, errors } = this.state;
        // console.log("selectedTicketSellNowOTC", selectedTicketSellNowOTC);
        // console.log("formDataOTCSellNow", formDataOTCSellNow);
        const currentSymbol = selectedTicketSellNowOTC ? selectedTicketSellNowOTC.symbol : Coin ? Coin.symbol : "";
        return (
            <>
                <Modal toggle={this.handleToogleModalSellNowOTC} isOpen={modalBuySellNowOTC} backdrop="static" onOpened={this.handleLoadModal} >
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Sell")} {currentSymbol}
                        </h5>
                        {!selectedTicketSellNowOTC &&
                            <button
                                aria-label={t("Close")}
                                className=" close  ml-1"
                                type="button"
                                onClick={this.handleToogleModalSellNowOTC}
                            >

                                <span aria-hidden={true}>×</span>
                            </button>
                        }

                    </div>
                    <ModalBody>
                        <Form role="form">
                            <FormGroup >
                                <div className="d-flex align-items-center">
                                    <label htmlFor="Amount">{t("You pay")} </label>
                                    <small className="ml-auto txt-small text-main-color">{t("Available")}: {FormatNumberAmount(info && currentSymbol === "TON" ? info.wallet_TON : info.balance_USDT)}  {currentSymbol}</small>

                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <input
                                        type="number"
                                        value={formDataOTCSellNow.AmountCoin}
                                        readOnly={selectedTicketSellNowOTC ? true : false}
                                        onChange={(e) => this.handleChangeAmount(e.target.value, pricesLang, pricesAll)}
                                        className="borderless-input input-amount"
                                    />
                                    <span className="unit-price pointer" onClick={this.handleFillMaxAmount}>&nbsp;MAX&nbsp;|</span> &nbsp;<div className="unit-price">{currentSymbol}</div>

                                </div>
                                {errors.AmountCoin && <div className="error">{errors.AmountCoin}</div>}
                            </FormGroup>
                            <div className="cover-icon mt-4">
                                <div className="icon-arrow">
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <FormGroup className="">
                                <div className="d-flex align-items-end">
                                    <label>{t("You receive")} ({this.checkCurrency()})</label>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <div>
                                        <div>{this.props.hanldeFormatLang(formDataOTCSellNow.AmountOfLang, info.language)}</div>
                                    </div>
                                    <div className="copy-price">
                                        <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(this.props.hanldeFormatLang(formDataOTCSellNow.AmountOfLang, info.language))}>
                                            <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                        </span>
                                    </div>
                                </div>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    {!selectedTicketSellNowOTC &&
                        <ModalFooter className="btn-confirm" onClick={this.handleConfirmSellNowOTC}>
                            <div>{t("Sell Now")}</div>
                        </ModalFooter>
                    }
                    {selectedTicketSellNowOTC &&
                        <ModalFooter className="btn-cancel-modal" onClick={this.handleCancelSellNowOTC}>
                            <div>{t("Cancel")}</div>
                        </ModalFooter>
                    }
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(BuySellNowOTC);
