import React from "react";
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import ReactPaginate from 'react-paginate';
import { formatDate } from "services/common";
import Loading from "../../components/Loading/Loading"
import { FormatNumberUSDT, formatDate2, nextDays, formatNumber, calculateDayRemaining2 } from "services/common";
import { get, post, checkToken } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import {
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Button,
    Modal,
    ModalBody,
    FormGroup,
    Form,
    Input
} from "reactstrap";
import ReactDatetime from "react-datetime";
import ModalConfirm from "../../components/ConfirmModal/Confirm"
import configs from "configs/configs";

import moment from 'moment';
// import 'moment/locale/vi';
if (localStorage.getItem("lang") === "vi") {
    moment.locale('vi');
    moment.updateLocale('vi', {
        months: [
            'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6',
            'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'
        ],
        monthsShort: [
            '01', '02', '03', '04', '05', '06',
            '07', '08', '09', '10', '11', '12'
        ],
        weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
    });
}
// import DateTimeField from 
const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]

class RPTCommission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                FromDate: moment().subtract(1, 'days').format('yyyy-MM-DD'),
                ToDate: moment().format('yyyy-MM-DD'),
                PageIndex: 0,
                PageSize: 10,
                type: 1
            },
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            data: [],
            TotalRows: 0,
            isLoading: false,
            confirmText: "",
            showConfirm: false,
            showMenu: false,
            showconfirm: false,
            modalShare: false,
            modalPolicy: false,
            dataComDefault: [],
            dataComDefaultTotal: {
                totalMember: 1048575,
                totalTurnover: 20971500,
                totalEstimate: 1048575
            },
            showComRPT: true
        };
    }
    componentDidMount() {
        if (this.props.type === "2") {
            // var _params = {
            //     FromDate: moment().subtract(1, 'days').format('yyyy-MM-DD'),
            //     ToDate: moment().format('yyyy-MM-DD'),
            //     PageIndex: 1,
            //     PageSize: 10
            // }
            // this.handleGetList(_params);
            //this.handleGetListEstimateCom();
        }
    }
    handleMappingDataEstimateCom = (data) => {
        let _dataComDefault = [];
        let currentDate = "01/10/2023";
        let start_index = data ? data.length : 1;
        if (data && data.length > 0) {
            currentDate = formatDate2(data[data.length - 1].date);
            _dataComDefault = data.map((el, i) => {
                let item = {
                    date: formatDate2(el.date),
                    member: 2 ** (i),
                    actualMember: el.actualMember,
                    // turnover: 20 * (2 ** (i)),
                    // actualTurnover: el.actualTurnover,
                    // estimate: 2 ** (i),
                    actual: el.actualBonus,
                    actualBonusCutOff: el.actualBonusCutOff,
                    type: el.type,
                    isWithdrawCom: false
                }
                if (this.props.info) {
                    let remainDays = calculateDayRemaining2(item.date, this.props.info.remainWithdrawBonusDate);
                    item.isWithdrawCom = remainDays.total > 0;
                    // console.log("remainDays " + i, remainDays);
                }
                return item;
            });
        }
        for (let i = start_index; i < 21; i++) {
            let item = {
                date: "N/A",
                member: 2 ** i,
                actualMember: null,
                // turnover: 20 * (2 ** i),
                // actualTurnover: null,
                // estimate: 2 ** i,
                actual: null,
                actualBonusCutOff: null,
                type: false,
                isWithdrawCom: false
            }
            if (i === start_index && !(data && data.length > 0)) {
                item.date = currentDate;
            } else {
                item.date = nextDays(currentDate, 10);
            }
            if (this.props.info) {
                let remainDays = calculateDayRemaining2(item.date, this.props.info.remainWithdrawBonusDate);
                item.isWithdrawCom = remainDays.total > 0;
                // console.log("remainDays " + i, remainDays);
            }
            currentDate = item.date;
            _dataComDefault.push(item)
        }

        this.setState({ dataComDefault: _dataComDefault })
    }
    handlePageChange = (pageNumber) => {
        // Xử lý sự kiện chuyển trang ở đây
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: pageNumber.selected },
        }));
        let _params = { ...this.state.params };
        _params.PageIndex = pageNumber.selected + 1;
        this.handleGetList(_params)
    };
    handleSelectChange = (e) => {
        let _params = { ...this.state.params };
        if (e === null) {
            this.setState((prevState) => ({
                params: { ...prevState.params, Status: 0 },
            }));
            _params.Status = 0;
        } else {
            this.setState((prevState) => ({
                params: { ...prevState.params, Status: e.value },
            }));
            _params.Status = e.value;
        }
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.handleGetList(_params)
    };
    handleChangeSize = (e) => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageSize: e.value },
        }));
        _params.PageSize = e.value;
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.handleGetList(_params)
    };
    handleGetList = async (_params) => {
        this.setState({ showComRPT: true })
        let url = "/UserReport/Commission"
        if (_params.type === 2) {
            url = "/UserReport/ComTransfer"
        } else if (_params.type === 3) {
            url = "/UserReport/ComCutOff"
        }
        this.setState({ isLoading: true });
        try {
            let response = await get(url, _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.setState({
                    data: response.data.data,
                    TotalRows: response.data.totalRows
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    componentDidUpdate(prevProps) {
        // Kiểm tra xem props có thay đổi không
        // if (this.props.info !== prevProps.info && this.props.info) {
        //     // this.handleGetListEstimateCom();
        //     this.handleGetListCom(1);
        // }
        if (this.props.currentTab !== prevProps.currentTab && this.props.currentTab === "ComHistory") {
            // var _params = {
            //     FromDate: moment().subtract(1, 'days').format('yyyy-MM-DD'),
            //     ToDate: moment().format('yyyy-MM-DD'),
            //     PageIndex: 1,
            //     PageSize: 10
            // }
            //this.handleGetList(_params);
            let params = {
                FromDate: moment().subtract(1, 'days').format('yyyy-MM-DD'),
                ToDate: moment().format('yyyy-MM-DD'),
                PageIndex: 0,
                PageSize: 10
            }
            this.setState({
                params: params,
                data: [],
                TotalRows: 0,
                startDate: moment().subtract(1, 'days'),
                endDate: moment(),
                showComRPT: true
            })
            // this.handleGetListEstimateCom();
            this.handleGetListCom(1);
        }
    }
    hanldeChangeDate(e, type) {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        let fromdate = { ...this.state.startDate };
        let todate = { ...this.state.endDate };
        if (type === 1) {
            this.setState({ startDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, FromDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.FromDate = moment(e).format('yyyy-MM-DD');
            fromdate = e;
        } else {
            this.setState({ endDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, ToDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.ToDate = moment(e).format('yyyy-MM-DD');
            todate = e;
        }

        if (fromdate && todate) {
            if (moment(fromdate).isAfter(moment(todate))) {
                toast.error(t("'To Date' must be greater than 'From Date'"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                return false;
            }
        }
        this.handleGetList(_params);
    }
    handleChange = (newDate) => {
        return this.setState({ date: newDate });
    }

    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        this.setState({ showConfirm: false });
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/WithdrawCom');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Withdraw commission successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({
                    Balance: response.data.data.balance,
                    ComPending: response.data.data.comPending,
                })
                let _params = { ...this.state.params };
                //reset pageindex
                this.setState((prevState) => ({
                    params: { ...prevState.params, PageIndex: 0 },
                }));
                _params.PageIndex = 1;
                this.handleGetList(_params);
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal = () => {
        // Mở modal confirm
        this.setState({ confirmText: t("Are you sure to withdraw the pending commission?") })
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    handleSearchAction = () => {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.handleGetList(_params);
    }
    handleGetListCom = (type) => {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.setState((prevState) => ({
            params: { ...prevState.params, type: type },
        }));
        _params.type = type;
        this.handleGetList(_params);
    }
    handleGetListEstimateCom = async () => {
        this.setState({ isLoading: true });
        this.setState({ showComRPT: false })
        try {
            let response = await get("UserReport/ActualBonus");
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.handleMappingDataEstimateCom(response.data.data);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    render() {
        const { t, info } = this.props;
        const { params, isLoading, data, TotalRows, confirmText, showConfirm, modalShare, modalPolicy, showComRPT, dataComDefault, dataComDefaultTotal } = this.state;
        // let remain = info.expiredWithdrawComDateString ? calculateDayRemaining(info.expiredWithdrawComDateString).days : 0;
        const reflink = configs.WebRoot + "/s/" + info.refCode;

        return (
            <>
                <div className="cover-rpt rpt-com ">
                    <div className="cover-fund">
                        <div className="com-pending">{/*onClick={this.handleOpenModal}*/}
                            {info && <div className="text-primary-color href-link com-num "  >
                                <span title={t("Total Commission")}> <span className="pointer" onClick={() => this.handleGetListCom(1)} title={t("Pending Commission")}>{FormatNumberUSDT(info.comPending)}</span>
                                    &nbsp;<span className="text-high">+</span> <span className="pointer" onClick={() => this.handleGetListCom(2)} title={t("Transfer Commission")} >{info.comTransfer ? FormatNumberUSDT(info.comTransfer) : 0} </span>
                                    {/* <span className="text-high">+</span> <span className="" title={t("Cutoff Commission")}>{info.comCutOff ? FormatNumberUSDT(info.comCutOff) : 0}</span>  */}
                                    <span className="text-high">=</span>&nbsp; <span className="" title={t("Total")}>{info.totalCom ? FormatNumberUSDT(info.totalCom) : 0} </span></span> &nbsp;
                                {/* /<span className="f1 remain-day">{info.remainWithdrawComDay}D</span>
                                /<span className="f1">{info.totalF1BuyPackageDue}<i className="ni ni-single-02"></i></span> */}
                            </div>}
                            &nbsp;
                            {/* {info.refCode && <div className="share-reflink pointer" title={t("Share")} onClick={() => this.setState({ modalShare: !modalShare })}>
                                <img
                                    className=""
                                    alt={t("Share")}
                                    title={t("Share")}
                                    src={require("assets/img/button/share_green.png")}
                                />
                            </div>} */}

                            {/* <span className="text-primary-color" title={t("Policy")} onClick={() => this.setState({ modalPolicy: !modalPolicy })}> <img
                                className="img-policy"
                                alt={t("Policy")}
                                title={t("Policy")}
                                src={require("assets/img/button/policy.png")}
                            /></span> */}
                        </div>
                        {/* <Button className="btn-withdraw btn-fund" color="secondary" type="button" title={t("Withdraw Commission")}
                            onClick={this.handleOpenModal}> <i className="img-icon fa fa-minus"></i>
                        </Button> */}
                    </div>
                    {!showComRPT && <Table striped bordered responsive className="rpt-table mt-3" >
                        <thead>
                            <tr>
                                <th className=" text-center num-stt ">{t("Round")}</th>
                                <th >{t("Date")}</th>
                                <th >{t("Estimated Members")}</th>
                                <th >{t("Actual Members")}</th>
                                <th >{t("Actual Bonus")}</th>
                                <th >{t("Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataComDefault && dataComDefault.map((item, index) => {
                                let isStatusActive = index > 0 && item.type && (item.actual && item.actual >= item.member) && (item.actualMember && item.actualMember >= item.member);
                                return (
                                    <tr key={index} className={index === 20 ? "r-total" : ""}>
                                        <td className={`text-center`}>{index > 0 ? index : ""}</td>
                                        <td >{item.date}</td>
                                        <td className={`num `}>{formatNumber(item.member)}</td>
                                        <td className={`num ${item.actualMember && item.actualMember >= item.member ? "txt-success" : ""}`}>{item.actualMember !== null ? formatNumber(item.actualMember) : "-"}</td>
                                        <td className={`num `}>
                                            {item.actual !== null && item.actual > 0 && <div className={(item.type && item.actual && item.member && item.actual >= item.member) ? "txt-success" : ""}> {item.actual !== null ? FormatNumberUSDT(item.actual) : "-"}</div>}
                                            {!item.type && item.actual === null && <div>-</div>}
                                            {item.actualBonusCutOff > 0 && <div className="status-warn">{item.actualBonusCutOff !== null ? FormatNumberUSDT(item.actualBonusCutOff) : "-"}</div>}
                                        </td>
                                        <td className={`text-center td-icon-check`} >{index > 0 && <i className={isStatusActive ? "fa fa-check-square" : "fa fa-square-o"}></i>}</td>
                                    </tr>
                                )
                            })}
                            {/* <tr className="r-total">
                                <td colSpan={2} className="text-center">{t("Total")}</td>
                                <td className="num">{formatNumber(dataComDefaultTotal.totalMember)}</td>
                                <td className="num">{formatNumber(dataComDefaultTotal.totalTurnover)}</td>
                                <td className="num">{formatNumber(dataComDefaultTotal.totalEstimate)}</td>
                                <td className="num"></td>
                                <td className="num"></td>
                            </tr> */}
                        </tbody>
                    </Table>}
                    {showComRPT && <>
                        <div className="title-rpt">{t(params.type === 3 ? "Cutoff Commission" : params.type === 2 ? "Transfer Commission" : "Pending Commission")}</div>
                        <div className="filter-group mb-3">
                            <div className="grp-daterange">
                                <div className="pr-1">
                                    <label className="text-bold">{t("From")}</label>
                                    <InputGroup className="grp-datetime">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: t("From"),
                                                readOnly: true
                                            }}
                                            value={this.state.startDate}
                                            dateFormat="DD/MM/yyyy"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    this.state.startDate &&
                                                    this.state.endDate &&
                                                    this.state.startDate._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    this.state.startDate &&
                                                    this.state.endDate &&
                                                    new Date(this.state.startDate._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(this.state.endDate._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    this.state.endDate &&
                                                    this.state.endDate._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={(e) => this.hanldeChangeDate(e, 1)}
                                            closeOnSelect={true}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="pl-1">
                                    <label className="text-bold">{t("To")}</label>
                                    <InputGroup className="grp-datetime todate-frame">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: t("To"),
                                                readOnly: true
                                            }}
                                            value={this.state.endDate}
                                            dateFormat="DD/MM/yyyy"
                                            timeFormat={false}
                                            renderDay={(props, currentDate, selectedDate) => {
                                                let classes = props.className;
                                                if (
                                                    this.state.startDate &&
                                                    this.state.endDate &&
                                                    this.state.startDate._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " start-date";
                                                } else if (
                                                    this.state.startDate &&
                                                    this.state.endDate &&
                                                    new Date(this.state.startDate._d + "") <
                                                    new Date(currentDate._d + "") &&
                                                    new Date(this.state.endDate._d + "") >
                                                    new Date(currentDate._d + "")
                                                ) {
                                                    classes += " middle-date";
                                                } else if (
                                                    this.state.endDate &&
                                                    this.state.endDate._d + "" === currentDate._d + ""
                                                ) {
                                                    classes += " end-date";
                                                }
                                                return (
                                                    <td {...props} className={classes}>
                                                        {currentDate.date()}
                                                    </td>
                                                );
                                            }}
                                            onChange={(e) => this.hanldeChangeDate(e, 2)}
                                            closeOnSelect={true}
                                        />
                                    </InputGroup>
                                </div>

                            </div>
                            {/* <div className="filter-select-md ">
                            <Button className="btn-rpt-search"
                                color="success"
                                onClick={this.handleSearchAction}
                            >
                                <i className="fa fa-search" /> {t("Search")}
                            </Button>
                        </div> */}
                            {/* <div className="filter-select-lg">
                            <Button
                                color="success"
                                type="button"
                                title={t("Wihtdraw Commission")}
                                onClick={this.handleOpenModal}
                            >
                                {t("Withdraw Commission")}
                            </Button>
                        </div> */}
                        </div>

                        <Table striped bordered responsive className="rpt-table" >
                            <thead>
                                <tr>
                                    <th className=" text-center num-stt ">#</th>
                                    <th >{t("Date")}</th>
                                    {/* <th >{t("Email")}</th>
                                <th >{t("Type")}</th>
                                <th >{t("Commission Type")}</th>
                                <th >{t("Level")}</th> */}
                                    <th >{t("Amount")}</th>
                                    <th >{t("Current Balance")}</th>
                                    <th >{t("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((item, index) => {
                                    var startIndex = params.PageSize * params.PageIndex + 1;
                                    return (
                                        <tr key={index}>
                                            <td className=" text-center ">{index + startIndex}</td>
                                            <td >{formatDate(item.dateLog)}</td>
                                            {/* <td className="text-left" title={item.email}>{item.refEmail}</td>
                                        <td >{t(item.comTypeText)}</td>
                                        <td >{t(item.typeReceivedText)}</td>
                                        <td >{t(item.comLevel)}</td> */}
                                            <td className="text-right">{FormatNumberUSDT(item.amount)}</td>
                                            <td className="num">{FormatNumberUSDT(item.currentBalance ? item.currentBalance : 0)}</td>
                                            <td >{t(item.statusText)}</td>
                                        </tr>
                                    )
                                })}
                                {(!data || data.length === 0) && <>
                                    <tr className="text-center"><td colSpan={5}>{t("No data")}</td></tr>
                                </>}
                            </tbody>
                        </Table>
                        {TotalRows > 0 && params.PageSize > 0 &&
                            <>
                                <div className="paganition-custom">
                                    <ReactPaginate
                                        pageCount={Math.ceil(TotalRows / params.PageSize)}
                                        pageRangeDisplayed={2}
                                        marginPagesDisplayed={1}
                                        onPageChange={this.handlePageChange}
                                        forcePage={params.PageIndex}
                                        className="react-paginate"
                                        activeClassName={'active'}
                                        previousLabel={<i className="fa fa-angle-left"></i>}
                                        nextLabel={<i className="fa fa-angle-right"></i>}
                                    />
                                    <Select
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        options={sizeOptions}
                                        formatGroupLabel="Size"
                                        placeholder="Size"
                                        isClearable={false}
                                        defaultValue={sizeOptions[0]}
                                        onChange={this.handleChangeSize}
                                        classNamePrefix="pagesize-select"
                                    />
                                </div>
                            </>}
                    </>}

                </div>
                <Modal toggle={() => this.setState({ modalShare: !modalShare })} isOpen={modalShare}>
                    <div className=" modal-header">
                        <h5 className=" modal-title title-mb" id="PWModalLabel">
                            {t("Copy this link to invite your friends")}
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ modalShare: !modalShare })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup  >
                                    <Input type="text" name="reflink" autoComplete="on" defaultValue={reflink} readOnly />
                                    <InputGroupAddon addonType="append" title={t("Copy")} >
                                        <InputGroupText >
                                            <span className="pointer" onClick={() => this.handleCopyClick(reflink)}> <span className="btn-inner--text btn-span"><i className="fa fa-copy"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                        <div className="cover-policy">
                            <p><b>{t("Let's share and join hands on a rewarding language learning journey!")}</b></p>
                            <ol className="policy">
                                <li>{t("For every English sentence you learn, you receive a $1 bonus, up to a maximum of $5 per day.")}</li>
                                <li>{t("Package $20: Receive $1 when there is a new member joining the team up to 5 levels.")}</li>
                                <li>{t("Package $50: Receive $1 when there is a new member joining the team of $20 package, receive $2 when there is a new member joining the team of $50 and $100 packages, up to 10 levels.")}</li>
                                <li>{t("Package $100: Receive $1 when there is a new member joining the team of $20 package, receive $2 when there is a new member joining the team of $50 package, receive $3 when there is a new member joining the team of $100 package, up to 20 levels.")}</li>
                                <li>{t("Bonuses will be accumulated and transferred to your Wallet if, within 48 hours, you assist a new member in joining the study group with a Premium account.")}</li>
                            </ol>
                            <p><b>{t("Seize the opportunity to enhance your language skills and unlock incredible rewards as you learn and grow together in our dedicated language learning community!")}</b></p>
                        </div>
                    </ModalBody>
                </Modal>
                {/* <Modal toggle={() => this.setState({ modalPolicy: !modalPolicy })} isOpen={modalPolicy} size="lg">
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Policy")}
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ modalPolicy: !modalPolicy })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <ol className="policy">
                            <li>
                                <div className="policy-title">{t("What is a Learning Bonus?")}</div>
                                <ul>
                                    <li>{t("Of course, it's a reward for your learning achievements: the more you learn, the more rewards you earn, and the rewards are limitless.")}</li>
                                </ul>
                            </li>
                            <li>
                                <div className="policy-title">{t("How is the reward calculated? Can you explain it more clearly?")}</div>
                                <ul>
                                    <li>{t("Individual learning bonus: complete 1 task to receive $1 immediately, up to $5 per day.")}</li>
                                    <li>{t("Team learning bonus: 5% to 20% of the total Premium amount across your team, including individual study bonuses, paid at the end of the day in UTC time.")}</li>
                                </ul>
                            </li>
                            <li>
                                <div className="policy-title">{t("Where will I receive the reward?")}</div>
                                <ul>
                                    <li>{t("The rewards will accumulate in your learning bonus wallet and will be transferred to your main wallet every 48 hours.")}</li>
                                    <li>{t("Please download the Telegram bot from your Profile to receive notification messages whenever rewards are sent to you.")}</li>
                                </ul>
                            </li>
                            <li>
                                <div className="policy-title">{t("Why do you reward me when learning English is already a benefit for me?")}</div>
                                <ul>
                                    <li>{t("Having additional rewards will motivate you to stay committed to your studies.")}</li>
                                    <li>{t("After achieving strong communication skills, with the extra rewards, you'll find it easier to purchase plane tickets to English-speaking countries for further language practice and exploring new destinations.")}</li>
                                    <li>{t("In general, besides learning English, having more money is always a good thing.")}</li>
                                </ul>
                            </li>
                            <li>
                                <div className="policy-title">{t("What makes the content on Bit English special compared to other English learning websites and apps worldwide?")}</div>
                                <ul>
                                    <li>{t("Bit English consists of 1000 common sentences that make up approximately 89% of everyday English communication in developed countries.")}</li>
                                    <li>{t("Bit English follows a daily learning method, with each day focusing on a different topic.")}</li>
                                    <li>{t("Bit English employs an NLP-based learning technique developed by experts to help English become ingrained in your subconscious.")}</li>
                                    <li>{t("Bit English includes images and videos accompanying each sentence or phrase, aiding in rapid comprehension.")}</li>
                                    <li>{t("And, of course, the unique feature is the reward system, which no other English learning website or app has ever had before.")}</li>
                                </ul>
                            </li>
                            <li>
                                <div className="policy-title">{t("How can I join and receive rewards for learning?")}</div>
                                <ul>
                                    <li>{t("Register for a Premium.")}</li>
                                    <li>{t("Turn on listening or answering questions on the website.")}</li>
                                    <li>{t("Assist a new learning partner for 48 hours.")}</li>
                                    <li>{t("If I don't claim my reward, what will the money be used for?")} &nbsp;
                                        {t("If you don't claim your reward, the money will be used as a reward for other members in the community.")}</li>
                                </ul>
                            </li>
                        </ol>
                    </ModalBody>
                </Modal> */}
                {isLoading && <Loading />}
                <ModalConfirm
                    message={confirmText}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
            </>
        );
    }
}

export default withTranslation()(RPTCommission);
