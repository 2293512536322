import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Container,
    Button,
    Modal,
    ModalBody
} from "reactstrap";
import { post, get, checkToken } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import HeaderNavbar from "components/Navbars/HeaderNavbar";
import { formatDate2 } from "services/common";
import ModalConfirm from "../../components/ConfirmModal/Confirm"
// import RPTBuyPackage from "views/Report/RPTBuyPackage";
import Deposit from "./Deposit";
import configs from "configs/configs";

class BuyPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPackage: props && props.info && props.info.currentPackageAmount ? props.info.currentPackageAmount : localStorage.getItem("cur_pack"),
            isLoading: false,
            Balance: 0,
            ExpiredDate: '',
            SelectedPack: 0,
            confirmText: "",
            showConfirm: false,
            data: [],
            TotalRows: 0,
            info: {
                "twoFA": true,
                "refCode": "",
                "balance": 0,
                "comPending": 0,
                "currentPackageAmount": 0,
                "totalF1BuyPackageDue": 0
            },
            modalDepositOpen: false,
            showNotify: false,
            mustBuyUSDT: false,
            Price: 0
        }
    }

    componentDidMount = () => {
        // document.body.classList.add('bg-other');
        // document.body.classList.remove('bg-index');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        var _params = {
            PageIndex: 1,
            PageSize: 10
        }
        this.handleGetList(_params);
    }
    handleConfirm = () => {
        // Xử lý khi người dùng chọn OK
        this.setState({ showConfirm: false });
        const { mustBuyUSDT } = this.state;
        if (mustBuyUSDT) {
            this.handleBuyUSDT();
        } else {
            this.handleBuyPackage();
        }
    };
    handleBuyPackage = async () => {
        // let _params = {
        //     "PackageAmount": this.state.SelectedPack,
        // }
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/Donate');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Donate successfully."), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                setTimeout(res => {
                    window.location.reload(false);
                }, 3000)
                // this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                if (response.data.errorMessage === "You need to deposit USDT before becoming an active member.") {
                    this.setState({ showNotify: true })
                } else {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }

            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                if (response.data.errorMessage === "You need to deposit USDT before becoming an active member.") {
                    this.setState({ showNotify: true })
                } else {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } else {
                checkToken(response)
            }
        }
    }
    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(pck) {
        // Mở modal confirm
        const { info } = this.props;
        let msg = t("Are you sure you want to donate?");
        let price = pck;
        this.setState({ Price: pck })
        if (info.balance < price && !info.donateFree) {
            msg = t("BuyUSDTCommunity", { amount: price });
            this.setState({ mustBuyUSDT: true, Price: price })
        }
        //else {
        //     if (currentPackage === 0) {
        //         msg = t("Are you sure you want to become a member?");
        //     } else {
        //         msg = t("Are you sure you want to upgrade the package for") + " $" + price + "?"
        //     }
        //     this.setState({ mustBuyUSDT: false, Price: price })
        // }
        this.setState({ confirmText: msg })
        this.setState({ SelectedPack: price });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    handleGetList = async (_params) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/UserReport/BuyPackage', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.setState({
                    data: response.data.data,
                    TotalRows: response.data.totalRows,
                })
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    openDeposit = () => {
        localStorage.setItem("openDeposit", true);
        window.location.href = configs.WebRoot + "/transaction/1";
        // this.setState({ showNotify: false, modalDepositOpen: true });
    }
    onToogleModalDeposit = () => {
        this.setState((prevState) => ({ modalDepositOpen: !prevState.modalDepositOpen }));
    }
    handleBuyUSDT = async () => {
        // window.location.href = configs.WebRoot + "/transaction/4";
        this.setState({ isLoading: true });
        try {
            let params = {
                AmountUSDT: Number(this.state.Price),
            };
            const response = await post('/Purchase/BuyUSDT', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    render() {
        const { info } = this.props;
        const { isLoading, confirmText, showConfirm, modalDepositOpen, showNotify } = this.state;
        var currentPackage = info.currentPackageAmount;
        return (
            <>
                {/* <HeaderNavbar info={info} fluid={false} /> */}
                <div className="cover-package" >
                    <section className="section">
                        <Container className=" " style={{ width: "100%", marginLeft: "auto", marginRight: 'auto' }}>
                            {/* <div className="big-title">{currentPackage === 0 ? t("Join us to enjoy the journey of exploring English.") : ""}</div> */}
                            <div className="cover-buy-package" >
                                {/* <div className="text-standard mb-3">
                                    {t("Premium")}
                                </div> */}
                                <div className="package-item">
                                    {/* <div className="pck-img">
                                        <img
                                            className="logo-img"
                                            alt="WE"
                                            title="WE"
                                            src={require("assets/img/user/standard.png")}
                                        />
                                    </div> */}

                                    {/* {currentPackage === 0 && <span className="fa fa-circle-thin pck-disable" ></span>} */}
                                    {/* {currentPackage === 20 && <span className="fa fa-check-circle pck-active"></span>} */}
                                    <Button
                                        className={!info.isDonate ? "btn-buy-pack" : "btn-disable-pack"}
                                        color="success"
                                        type="button"
                                        onClick={() => this.handleOpenModal(50)}
                                        disabled={!info.isDonate ? false : true}
                                    >
                                        {t("Donate")} <br /> 50$
                                    </Button>
                                    <Button
                                        className={!info.isDonate ? "btn-buy-pack" : "btn-disable-pack"}
                                        color="success"
                                        type="button"
                                        onClick={() => this.handleOpenModal(50)}
                                        disabled={!info.isDonate ? false : true}
                                    >
                                        {t("Donate")} <br />100$
                                    </Button>
                                    <Button
                                        className={!info.isDonate ? "btn-buy-pack" : "btn-disable-pack"}
                                        color="success"
                                        type="button"
                                        onClick={() => this.handleOpenModal(50)}
                                        disabled={!info.isDonate ? false : true}
                                    >
                                        {t("Donate")} <br />200$
                                    </Button>
                                    {/* <div>
                                        <b>{t("Ideal starter plan")}</b>
                                        <div className="list-policy">
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("1 year of study.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{info.totalPhrase} {t("samples of professional communication.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$5 study bonus every day for 1 year.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$1 for each new member, up to 5 levels.")}</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* {currentPackage === 20 && <div className="expire-date">
                                        {t("Expire after") + " " + formatDate2(info.expiredDatePackageString)}
                                    </div>} */}
                                </div>
                                {/* <div className="package-item ">
                                    <div className="package-name">
                                        <div className="pck-info">
                                            <span className="pck-price">$50</span>
                                            <span className="pck-time">/ 2 {t("Years")}</span>
                                        </div>
                                    </div>
                                    <Button
                                        className={currentPackage < 50 ? "btn-buy-pack" : "btn-disable-pack"}
                                        color="success"
                                        type="button"
                                        onClick={() => this.handleOpenModal(50)}
                                        disabled={currentPackage < 50 ? false : true}
                                    >
                                        {t(currentPackage !== 0 && currentPackage < 50 ? "Upgrade" : "ACTIVATE NOW")}
                                    </Button>
                                    <div>
                                        <b>{t("For professional learners")}</b>
                                        <div className="list-policy">
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("2 year of study.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{info.totalPhrase} {t("samples of professional communication.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$5 study bonus every day for 2 year.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$1 for each new member for the $20 package, up to 10 levels.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$2 for each new member for the $50 and $100 packages, up to 10 levels.")}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {currentPackage === 50 && <div className="expire-date">
                                        {t("Expire after") + " " + formatDate2(info.expiredDatePackageString)}
                                    </div>}
                                </div>
                                <div className="package-item">
                                    <div className="package-name">
                                        <div className="pck-info">
                                            <span className="pck-price">$100</span>
                                            <span className="pck-time">/ 3 {t("Years")}</span>
                                        </div>
                                    </div>
                                    <Button
                                        className={currentPackage < 100 ? "btn-buy-pack" : "btn-disable-pack"}
                                        color="success"
                                        type="button"
                                        onClick={() => this.handleOpenModal(100)}
                                        disabled={currentPackage < 100 ? false : true}
                                    >
                                        {t(currentPackage !== 0 && currentPackage < 100 ? "Upgrade" : "ACTIVATE NOW")}
                                    </Button>
                                    <div>
                                        <b>{t("For entrepreneurs")}</b>
                                        <div className="list-policy">
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("3 year of study.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{info.totalPhrase} {t("samples of professional communication.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$5 study bonus every day for 3 year.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$1 for each new member for the $20 package, up to 20 levels.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$2 for each new member for the $50 package, up to 20 levels.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("$3 for each new member for the $100 package, up to 20 levels.")}</div>
                                            </div>
                                            <div className="item-policy">
                                                <div className="icon-check"><i className="fa fa-check-circle"></i></div>
                                                <div>{t("Earning additional income through OTC trading.")}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {currentPackage === 100 && <Button className={`btn-rpt-search`} color={info.otc ? "success" : "secondary"} type="button" title={info.otc ? t("Cancel registration as OTC") : t("Register as OTC")} onClick={this.props.onOpenRegisterOTC}>OTC</Button>
                                    }
                                    {currentPackage === 100 && <div className="expire-date">
                                        {t("Expire after") + " " + formatDate2(info.expiredDatePackageString)}
                                    </div>}
                                </div> */}
                            </div>
                            {/* <RPTBuyPackage data={data} TotalRows={TotalRows} handleGetList={this.handleGetList} /> */}
                        </Container>
                    </section>
                </div>
                {isLoading && <Loading />}
                <ModalConfirm
                    message={confirmText}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                <ToastContainer pauseOnFocusLoss={false} />
                <Modal isOpen={showNotify} toggle={() => this.setState({ showNotify: !showNotify })} >
                    <div className=" modal-header">
                        <h5 className=" modal-title" >
                            {t("Activate your account")}
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ showNotify: false })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody >
                        <div className="cover-intro-buy-pack">
                            <div>
                                <div className="title-intro">{t("You need to deposit USDT before becoming an active member.")}</div>
                                <div className="text-center mb-2 mt-3 div-btn-submit">
                                    <Button
                                        className="btn btn-success btn-submit"
                                        onClick={this.openDeposit}
                                    >
                                        {t("Deposit")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Deposit info={this.props.info} modalDepositOpen={modalDepositOpen} onToogleModalDeposit={this.onToogleModalDeposit} />
            </>
        );
    }
}
export default withTranslation()(BuyPackage);
