import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/common/withRouter";
import { getNoAuth, redirectToURL } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import i18n from "i18next";
import Loading from "../../components/Loading/Loading";
import CryptoJS from 'crypto-js';
import { postNoAuth } from "services/apiServices";
class LoginByTelegramChatIDLearning extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    };
  }

  async componentDidMount() {
    // if (this.props.params.lang) {
    //   localStorage.setItem("lang", this.props.params.lang);
    // } else {
    //   localStorage.setItem("lang", "en");
    // }
    localStorage.clear();

    let user = null;
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
      user = window.Telegram.WebApp.initDataUnsafe.user;
    }
    if (user) {
      try {
        const hashPwd = CryptoJS.MD5(user.id.toString()).toString();
        let params = {
          "TelegramChatID": user.id.toString(),
          "Password": hashPwd,
          "Username": user.username,
          "Fullname": user.first_name + " " + user.last_name,
          "Country": this.props.params.lang ? this.props.params.lang : "en",
        };
        // alert(JSON.stringify(params));
        // return
        const response = await postNoAuth('/Users/LoginLearning', params);
        if (response.data.errorCode === 0) {
          this.setState({ isLoading: false });
          response.data.data.isLearning = true;
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("userId", response.data.data.userID)
          localStorage.setItem("lang", response.data.data.language ? response.data.data.language : "en")
          if (response.data.data.isLearning) { //thõa điều kiện học
            redirectToURL('/learn')
          } else {
            redirectToURL('/')
          }
        } else {
          this.setState({ isLoading: false });
          redirectToURL("/error")
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data && response.data.errorCode === 503) {
          redirectToURL("/um")
        } else {
          redirectToURL("/error")
        }
      }
    } else {
      redirectToURL("/error")
    }
  }
  render() {
    const { t, isLoading } = this.props;
    return (
      <>
        <main ref="main" className="outside">
          <section className="section section-shaped section-lg">
          </section>
        </main>
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(withRouter(LoginByTelegramChatIDLearning));
