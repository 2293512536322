import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Container,
    Button,
    Modal,
    ModalBody,
    FormGroup,
    Input,
    ModalFooter,
    Form,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from "reactstrap";
import { post, get, checkToken, postFormData } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import HeaderNavbar from "components/Navbars/HeaderNavbar";
import { formatDate2, formatDate } from "services/common";
import ModalConfirm from "../../components/ConfirmModal/Confirm"
import configs from "configs/configs";
import "assets/scss/chat.scss?v1.1.0";
import Resizer from "react-image-file-resizer";
import Select from 'react-select'

// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modalAddQuestionOpen: false,
            info: {
                "twoFA": true,
                "refCode": "",
                "balance": 0,
                "comPending": 0,
                "currentPackageAmount": 0,
                "totalF1BuyPackageDue": 0
            },
            data: [],
            TotalRows: 0,
            dataReply: [],
            params: {
                PageIndex: 1,
                PageSize: 12,
                Status: 0
            },
            formAdd: {
                Title: "",
                Message: "",
                Image: null,
                ImageURL: ""
            },
            errorsFormAdd: {
                Title: "",
                Message: "",
            },
            isOpenImage: false,
            currentQuestion: null,
            ReplyMessage: "",
            isMobile: window.innerWidth < 768,
            showQuestion: false,
            loadingReply: false,
            intervalLoadReply: null,
            DeleteQuestion: null,
            countdownInterval: null

        }
        this.ListQuestion = React.createRef();
        this.ListMessage = React.createRef();
    }

    componentDidMount = () => {
        document.body.classList.remove('bg-other');
        document.body.classList.remove('bg-index');
        document.body.classList.add('bg-support');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        const userAgent = window.navigator.userAgent.toLowerCase();
        let isChrome = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent);
        let isSafari = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent);
        if (isSafari || isChrome) {
            document.body.classList.add('is-safari');
        }
        this.ListQuestion.current.addEventListener('scroll', this.handleScroll);
        this.ListMessage.current.addEventListener('scroll', this.handleScroll);
        this.hanldeLoadBasicInfo();
        var _params = {
            PageIndex: 1,
            PageSize: 12,
            Status: 0
        }
        this.handleGetList(_params, true);
    }
    componentWillUnmount = () => {
        this.ListQuestion.current.removeEventListener('scroll', this.handleScroll);
        if (this.state.countdownInterval) {
            clearInterval(this.state.countdownInterval);
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.dataReply !== prevState.dataReply) {
            setTimeout(() => {
                if (this.ListMessage.current) {
                    this.ListMessage.current.scrollTop = this.ListMessage.current.scrollHeight + 100;
                }
            }, 500)
        }
    }
    handleScroll = () => {
        // debugger       
        const refContainer = this.ListQuestion.current;
        if (refContainer) {
            const { scrollTop, scrollHeight, clientHeight } = refContainer;
            if (scrollHeight - scrollTop === clientHeight) {
                this.loadMoreData();
            }
        }
    };
    loadMoreData = () => {
        const { TotalRows, params } = this.state;
        let nextPage = params.PageIndex + 1;
        let maxPage = Math.ceil(TotalRows / params.PageSize);
        this.setState({ PageIndex: nextPage });
        if (nextPage <= maxPage) {
            let _params = {
                PageIndex: nextPage,
                PageSize: params.PageSize,
                Status: params.Status
            }
            this.setState((prevState) => ({
                params: { ...prevState.params, PageIndex: nextPage }
            }));
            this.handleGetList(_params);
        }
    };
    hanldeLoadBasicInfo = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/UserInfoBasic');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                if (response.data.data != null) {
                    this.setState({
                        currentPackage: response.data.data.userInfo.currentPackageAmount,
                        ExpiredDate: formatDate2(response.data.data.userInfo.expiredDatePackageString),
                        info: response.data.data.userInfo
                    })
                    // if (!this.state.countdownInterval) {
                    //     let countdownInterval = setInterval(() => {
                    //         let { info } = this.state;
                    //         this.setState((prevState) => ({
                    //             info: { ...prevState.info, countdown: info.countdown === undefined ? 0 : info.countdown + 1 },
                    //         }));
                    //     }, 1000);
                    //     this.setState({ countdownInterval: countdownInterval });
                    // }
                    localStorage.setItem("refCode", response.data.data.userInfo.refCode);
                    localStorage.setItem("TwoFA", response.data.data.userInfo.twoFA);
                    localStorage.setItem("Balance", response.data.data.userInfo.balance);
                    localStorage.setItem("CurrentPackageAmount", response.data.data.userInfo.currentPackageAmount);
                    localStorage.setItem("ComPending", response.data.data.userInfo.comPending);
                    localStorage.setItem("TotalF1", response.data.data.userInfo.totalF1BuyPackageDue);
                }
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        this.setState({ showConfirm: false });
        this.setState({ isLoading: true });
        try {
            let params = {
                QuestionID: this.state.DeleteQuestion ? this.state.DeleteQuestion.questionID : 0,
            };
            const response = await post('/ChatSupport/QuestionDelete', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false, DeleteQuestion: null });
                toast.success(t("Deleted successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                var _params = {
                    PageIndex: 1,
                    PageSize: this.state.params.PageSize,
                    Status: this.state.params.Status
                }
                this.handleGetList(_params, true);

            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item) {
        // Mở modal confirm
        this.setState({ DeleteQuestion: item })
        this.setState({ confirmText: t("Are you sure to delete this question?") })
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    handleGetList = async (_params, reload = false) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/ChatSupport/QuestionGetList', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let newDataPharse = [...this.state.data, ...response.data.data];
                this.setState({
                    data: newDataPharse,
                    TotalRows: response.data.totalRows,
                })
                if (reload) {
                    this.setState({
                        data: response.data.data,
                        TotalRows: response.data.totalRows,
                    })
                    if (!response.data.data || response.data.data.length === 0) {
                        this.setState({
                            dataReply: [],
                            currentQuestion: null
                        })
                    }
                }
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleChangeQuestion(item) {
        this.handleLoadReplyList(item, true);
        this.setState({ showQuestion: false, ReplyMessage: "" })
    }
    handleLoadReplyList = async (item, loading = false) => {
        if (item) {
            this.setState({ isLoading: loading, currentQuestion: item });
            let _params = {
                QuestionID: item.questionID ? Number(item.questionID) : 0,
                PageSize: 999999,
                PageIndex: 1
            }
            try {
                let response = await get('/ChatSupport/QuestionGetReply', _params);
                if (response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    this.setState({
                        dataReply: response.data.data
                    });

                    if (this.state.intervalLoadReply) {
                        this.setState({ intervalLoadReply: null });
                        clearInterval(this.state.intervalLoadReply);
                    } else {
                        setInterval(() => {
                            this.handleLoadReplyList(this.state.currentQuestion)
                        }, 60000 * 5)
                    }
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    };
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    openDeposit = () => {
        this.setState({ showNotify: false, modalDepositOpen: true })
    }
    onToogleModalDeposit = () => {
        this.setState((prevState) => ({ modalDepositOpen: !prevState.modalDepositOpen }));
    }
    handleAddQuestion = async (_formData, clearMsg) => {
        this.setState({ isLoading: true });
        try {
            const response = await postFormData('/ChatSupport/QuestionRequest', _formData);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false, modalAddQuestionOpen: false });
                if (clearMsg) {
                    this.setState({ ReplyMessage: "" })
                }
                toast.success(t("Your question has been sent."), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                var _params = {
                    PageIndex: 1,
                    PageSize: this.state.params.PageSize,
                    Status: this.state.params.Status
                }
                this.handleGetList(_params, true);
                this.setState({ currentQuestion: response.data.data });
                this.handleLoadReplyList(response.data.data, true);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleReplyMessage = async () => {
        const { ReplyMessage, currentQuestion } = this.state;
        let _formData = new FormData();
        if (currentQuestion) { //Reply message
            this.setState({ loadingReply: true });
            _formData.append('QuestionID', currentQuestion.questionID);
            _formData.append('Message', ReplyMessage);
            _formData.append('Image', null);
            this.replyMessage(_formData);
        } else { //Add new Question
            if (!ReplyMessage) {
                toast.error(t('Please enter the message.'), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                _formData.append('Title', ReplyMessage);
                _formData.append('Message', ReplyMessage);
                _formData.append('Image', null);
                this.handleAddQuestion(_formData, true)
            }

        }
    }
    replyMessage = async (_formData) => {
        const { params, currentQuestion } = this.state;
        try {
            const response = await postFormData("/ChatSupport/QuestionReply", _formData);
            if (response && response.data.errorCode === 0) {
                this.setState({ loadingReply: false, ReplyMessage: "" })
                if (currentQuestion) {
                    this.handleLoadReplyList(currentQuestion, false);
                } else {
                    var _params = {
                        PageIndex: 1,
                        PageSize: params.PageSize,
                        Status: params.Status
                    }
                    this.handleGetList(_params, true);
                }

            } else {
                this.setState({ loadingReply: false })
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ loadingReply: false })
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleReplyMessageImage = async (_formData) => {
        const { currentQuestion } = this.state;
        this.setState({ loadingReply: true });
        try {
            const response = await postFormData('/ChatSupport/QuestionReply', _formData);
            if (response && response.data.errorCode === 0) {
                this.setState({ loadingReply: false });
                this.handleLoadReplyList(currentQuestion, false);
            } else {
                this.setState({ loadingReply: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ loadingReply: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleChangeInputQuestion = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formAdd: { ...prevState.formAdd, [name]: value },
        }));
    };
    handleFileSelected = (e, type) => {
        // debugger
        const files = Array.from(e.target.files);
        //resize image
        if (files[0]) {
            try {
                Resizer.imageFileResizer(
                    files[0],
                    1024,
                    1024,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        const imageUrl = URL.createObjectURL(uri);
                        if (type === 0) {
                            this.setState((prevState) => ({
                                formAdd: { ...prevState.formAdd, Image: uri },
                            }));
                            this.setState((prevState) => ({
                                formAdd: { ...prevState.formAdd, ImageURL: imageUrl }
                            }));
                        }
                        if (type === 1) {
                            let _formData = new FormData();
                            if (this.state.currentQuestion) {
                                _formData.append('QuestionID', this.state.currentQuestion.questionID);
                                _formData.append('Message', "");
                                _formData.append('Image', uri ? uri : null);
                                this.handleReplyMessageImage(_formData);
                            } else {
                                _formData.append('Title', "Image question");
                                _formData.append('Message', "Image question");
                                _formData.append('Image', uri ? uri : null);
                                this.handleAddQuestion(_formData, false);
                            }
                        }
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }
        }

        // console.log("img url", imageUrl);

    }
    clearForm = () => {
        let _formAdd = {
            Title: "",
            Message: "",
            Image: null,
            ImageURL: ""
        }
        let _errorsFormAdd = {
            Title: "",
            Message: "",
        }
        this.setState({ formAdd: _formAdd });
        this.setState({ errorsFormAdd: _errorsFormAdd });
    };
    handleAddQuestionOpen = () => {
        this.setState({ currentQuestion: null, dataReply: [] })
    }
    onToogleAddQuestion = () => {
        this.setState(prevState => ({
            modalAddQuestionOpen: !prevState.modalAddQuestionOpen
        }));
    }
    onToogleOpenQuestionMB = () => {
        this.setState(prevState => ({
            showQuestion: !prevState.showQuestion
        }));
    }
    onToogleUnread = () => {
        this.setState((prevState) => ({
            params: { ...prevState.params, Status: prevState.params.Status === 0 ? 1 : 0 },
        }));
        let _params = { ...this.state.params };
        _params.Status = this.state.params.Status === 0 ? 1 : 0;
        this.handleGetList(_params, true)
    }
    openImage = (imgURL) => {
        this.setState({
            isOpenImage: true,
            currentImg: imgURL
        });
    }
    handleInputBlur = () => {
        // Không cần phải làm gì cả khi trường input không còn tập trung
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }
    render() {
        const options = [
            { value: 0, label: t('All') },
            { value: 1, label: t('Unread') }
        ]
        const { info, isLoading, loadingReply, confirmText, showConfirm, data, dataReply, ReplyMessage, params, formAdd, isMobile, showQuestion, modalAddQuestionOpen, errorsFormAdd, isOpenImage, currentImg, currentQuestion } = this.state;
        return (
            <>
                {/* <HeaderNavbar info={info} fluid={true} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} /> */}
                <main ref="main" className="cover-content-continer" >
                    <section className="section content">
                        <Container fluid={true} className="content-rpt chat-frame">
                            <div className={`list-question-container ${isMobile && showQuestion ? "show-question" : ""}`}>
                                <div className="header">
                                    <span onClick={this.onToogleOpenQuestionMB} className="btn-close-question btn-menu-ques pointer " title={t("Close")} ><i className="fa fa-times"></i></span>
                                    <span className="btn-addquestion none-mobile pointer " title={t("Add Question")} onClick={this.handleAddQuestionOpen}> <img
                                        className="icon-btn"
                                        alt={t("Add Question")}
                                        title={t("Add Question")}
                                        src={require("assets/img/button/Add.png")}
                                    /></span>
                                    {/* <span className="btn-reload ml-auto" id="reloadQuestion" onClick={() => this.handleGetList(params, true)} title={t("Refresh")}><i className="fa fa-undo"></i></span> */}
                                    <div className="cover-sp-filter ml-auto">
                                        <Select
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            isSearchable={false}
                                            options={options}
                                            formatGroupLabel="Filter"
                                            placeholder={t("Filter")}
                                            defaultValue={options[0]}
                                            value={options.find(x => x.value === params.Status)}
                                            onChange={this.onToogleUnread}
                                            classNamePrefix="sp-filter pointer custom-filter-select "
                                        />
                                    </div>

                                    {/* <span id="filterNonReply" name="filterNonReply" className={`btn-filter ${params.Status === 1 ? "active" : ""}`} title={t("Unread")} onClick={this.onToogleUnread}>
                                        {params.Status !== 1 &&
                                            <img
                                                className="icon-read"
                                                alt={t("Unread")}
                                                title={t("Unread")}
                                                src={require("assets/img/button/unread.png")}
                                            />}
                                        {params.Status === 1 &&
                                            <img
                                                className="icon-read"
                                                alt={t("All")}
                                                title={t("All")}
                                                src={require("assets/img/button/all.png")}
                                            />}

                                    </span> */}
                                </div>
                                <div className="list-question" ref={this.ListQuestion}>
                                    {data && data.map((item, index) => {
                                        return (
                                            <div className={`${currentQuestion && currentQuestion.questionID === item.questionID ? "active" : ""} items-question pointer`} key={`qr${index}`}>
                                                {/* <div className="img-avartar"><img src="/images/avatar/avatar_question.png" /></div> */}
                                                <div className="question-text" onClick={() => this.handleChangeQuestion(item)} title={item.titleQuestion}>
                                                    <div className="txt_user">{item.email}</div>
                                                    <div className="text" title={item.titleQuestion}>{item.titleQuestion}</div>
                                                    <div className="date">{formatDate(item.createdDate)}</div>
                                                </div>
                                                <UncontrolledDropdown className="sp-drop" direction={"left"}>
                                                    <DropdownToggle color="" className="sp-drop-toggle">
                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem title={t("Delete")} onClick={() => this.handleOpenModal(item)}>
                                                            {t("Deleted")}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                {/* <div className="left-icon delete-question" title={t("Delete")} onClick={() => this.handleOpenModal(item)}><i className="fa fa-trash"></i></div> */}
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="chat-container">
                                <div className="header">
                                    <span className="btn btn-light btn-menu-ques pointer" onClick={this.onToogleOpenQuestionMB} title="Question list"><i className="fa fa-list-ul"></i></span>
                                    &nbsp;
                                    <img
                                        className="sp-title-ico"
                                        alt={t("Title")}
                                        title={t("Title")}
                                        src={require("assets/img/button/request.png")}
                                    />&nbsp;<span id="txt-title-ques"> {currentQuestion && currentQuestion.titleQuestion} </span>
                                    &nbsp;
                                    <span className="btn-addquestion pointer" title={t("Add Question")} onClick={this.handleAddQuestionOpen}> <img
                                        className="icon-btn block-mobile"
                                        alt={t("Add Question")}
                                        title={t("Add Question")}
                                        src={require("assets/img/button/Add_White.png")}
                                    /></span>&nbsp;
                                    {currentQuestion && <span className="btn-reload ml-auto mr-3 pointer" id="reloadChat" onClick={() => this.handleLoadReplyList(currentQuestion, true)} title={t("Refresh")}><i className="fa fa-undo"></i></span>}
                                </div>
                                <div className="chat-box">
                                    <ul id="messages" className="arr-messages" ref={this.ListMessage}>
                                        {dataReply && dataReply.map((item, index) => {
                                            return (
                                                <React.Fragment key={`rep${index}`}>
                                                    {item.replyByType === 1 && <>
                                                        {item.replyMessage && <li>
                                                            <div className="cover-message">
                                                                <span className="message">{item.replyMessage}</span>
                                                                <div className="text-left-me">
                                                                    <span className="txt-date"> {formatDate(item.repliedDate)} </span>
                                                                </div>
                                                            </div>
                                                        </li>}
                                                        {item.imagePath && <li>
                                                            <div className="cover-message">
                                                                <div className="img-message" onClick={() => this.openImage(item.imagePath)}>
                                                                    <img src={item.imagePath} className="img-message" />
                                                                </div>
                                                                <div className="text-left-me">
                                                                    <span className="txt-date"> {formatDate(item.repliedDate)} </span></div>
                                                            </div>
                                                        </li>}
                                                    </>}
                                                    {item.replyByType !== 1 && <>
                                                        {item.replyMessage && <li className="sp-message">
                                                            <img
                                                                className="sp-avt"
                                                                alt={t("Support")}
                                                                title={t("Support")}
                                                                src={require("assets/img/user/support_avt.png")}
                                                            />
                                                            <div className="cover-message">
                                                                <span className="message-reply">{item.replyMessage}</span>
                                                                <div className="text-left-friends"><span className="txt-date"> {formatDate(item.repliedDate)}</span></div>
                                                            </div></li>}
                                                        {item.imagePath && <li className="sp-message">
                                                            <img
                                                                className="sp-avt"
                                                                alt={t("Support")}
                                                                title={t("Support")}
                                                                src={require("assets/img/user/support_avt.png")}
                                                            />
                                                            <div className="cover-message">
                                                                <div className="img-reply" onClick={() => this.openImage(item.imagePath)}>
                                                                    <img src={item.imagePath} className="img-reply" />
                                                                </div>
                                                                <div className="text-left-friends">
                                                                    <span className="txt-date"> {formatDate(item.repliedDate)} </span></div>
                                                            </div>
                                                        </li>}
                                                    </>}
                                                </React.Fragment >
                                            )
                                        })}
                                        {loadingReply && <li><div className="cover-message"><div className="text-left-me"><span className="text-sending">{t("Sending...")}</span></div></div></li>}
                                    </ul>
                                    <div className="input-form">
                                        <textarea id="mess" className="input-mess" autoComplete="off" placeholder="Enter message..." rows={1} value={ReplyMessage} onChange={(e) => this.setState({ ReplyMessage: e.target.value })} ></textarea>
                                        <label className="attach-file" htmlFor="fileImageReply"><i className="fa fa-image"></i></label>
                                        <Input type="file" onChange={(e) => this.handleFileSelected(e, 1)} id="fileImageReply" className="file-input" accept="image/gif, image/jpg, image/jpeg, image/png" />
                                        <button className=" btn-send" type="button" onClick={this.handleReplyMessage} id="send-message" title={t("Send")}>
                                            <img
                                                className="ico-send"
                                                alt={t("Send")}
                                                title={t("Send")}
                                                src={require("assets/img/button/send_green.png")}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                </main>

                <Modal toggle={this.handleAddQuestionOpen} isOpen={modalAddQuestionOpen} >
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Add Question")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleAddQuestion}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            {/* <FormGroup>
                                <Input
                                    placeholder={t("Title")}
                                    type="text"
                                    autoComplete="off"
                                    name={"Title"} value={formAdd.Title} onChange={this.handleChangeInputQuestion}
                                />
                                {errorsFormAdd.Title && <div className="error">{errorsFormAdd.Title}</div>}
                            </FormGroup> */}
                            <FormGroup>
                                <Input type="textarea" name={"Message"} value={formAdd.Message} onChange={this.handleChangeInputQuestion} className="input-mess" autoComplete="off" placeholder={t("Enter message...")} rows="2" spellCheck={false} onBlur={this.handleInputBlur} />
                                {errorsFormAdd.Message && <div className="error">{errorsFormAdd.Message}</div>}
                            </FormGroup>
                            <FormGroup>
                                <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                    {t("Choose Image")}
                                </label></div>
                                <Input type="file" onChange={(e) => this.handleFileSelected(e, 0)} id="fileImage" className="file-input" accept="image/gif, image/jpg, image/jpeg, image/png" />
                                {formAdd.ImageURL && <div className="text-center">
                                    <img
                                        className="img-receipt"
                                        alt={formAdd.ImageURL}
                                        title={formAdd.ImageURL}
                                        src={formAdd.ImageURL}
                                    />
                                </div>}
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.onToogleAddQuestion}
                        > {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.handleAddQuestion}>
                            {t("Submit")}
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal toggle={() => this.setState({ isOpenImage: false })} isOpen={isOpenImage} size="lg">
                    <div className=" modal-header">
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ isOpenImage: false })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="text-center">
                        <img src={currentImg} className="img-show"></img>
                    </ModalBody>
                </Modal>

                {isLoading && <Loading />}
                <ModalConfirm
                    message={confirmText}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                <ToastContainer pauseOnFocusLoss={false} />
            </>
        );
    }
}
export default withTranslation()(Support);
