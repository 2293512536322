import React from "react";
// reactstrap components
import {
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Input,
    Container,
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import ReactPaginate from 'react-paginate';
import configs from 'configs/configs';
import moment from 'moment';
import { get, post, checkToken, redirectToURL } from "services/apiServices";
import { formatDate, FormatNumberAmount, FormatNumberAmount2, formatNumber, hideCharacters, calculateTimeRemaining } from "services/common";
import Loading from "../../components/Loading/Loading";
import Deposit from "../../views/Home/Deposit"
import WithdrawTransfer from "../../views/Home/WithdrawTransfer"
import RegisterOTC from "views/Home/RegisterOTC";
import RPTBuySellUSDT from "./RPTBuySellUSDT";
import BuyPackage from "views/Home/BuyPackage";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
import i18n from "i18next";
// import "moment/locale/vi";
if (localStorage.getItem("lang") === "vi") {
    moment.locale('vi');
    moment.updateLocale('vi', {
        months: [
            'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6',
            'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'
        ],
        monthsShort: [
            '01', '02', '03', '04', '05', '06',
            '07', '08', '09', '10', '11', '12'
        ],
        weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
    });
}

// import DateTimeField from 
const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]


class RPTTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                Type: 1,
                PageIndex: 0,
                PageSize: 10,
                Symbol: props.Coin ? props.Coin.symbolID : 0
            },
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            dataTrans: [],
            TotalRows: 0,
            isLoading: false,
            WithdrawTransfer: false,
            modalDepositOpen: localStorage.getItem("openDeposit") !== "null" && localStorage.getItem("openDeposit") === "true" ? true : false,
            modalRegisterOTCOpen: false,
            // showRPTBuySell: props && props.type === "4" ? true : false,
            showConfirm: false,
            confirmText: "",
            data: [],
            total: null,
            // dataDone: [],           
            // totalDone: null,
            // TotalRowsBuySell: 0,
            expireTime: '',
            timeRemain: null,
            timer: null,
            // showFormBuySell: localStorage.getItem("showFormBuySell") ? localStorage.getItem("showFormBuySell") : 0,
            modalSellUSDTOpen: false,
            modalBuyUSDTOpen: false,
            intervalGetList: null,
            modalDetailOpen: false,
            selectedItem: null
        };

    }
    componentDidMount = () => {
        // if (this.props.type === "1") {
        //     var _params = {
        //         Type: 1,
        //         PageIndex: 1,
        //         PageSize: 10,
        //         Symbol: this.props.Coin ? this.props.Coin.symbolID : 0
        //     }
        //     this.setState({
        //         dataTrans: [],
        //         TotalRows: 0,
        //         startDate: moment().subtract(1, 'days'),
        //         endDate: moment(),

        //     });
        //     this.props.handleGetAllTransaction(_params);
        //     this.handleGetListBuySellPending();
        //     let _interval = setInterval(() => {
        //         this.handleGetListBuySellPending()
        //     }, 5000);
        //     this.setState({ intervalGetList: _interval })
        //     localStorage.setItem("openDeposit", false);
        // }
    }

    handlePageChange = (pageNumber) => {
        // Xử lý sự kiện chuyển trang ở đây
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: pageNumber.selected },
        }));
        let _params = { ...this.state.params };
        _params.PageIndex = pageNumber.selected + 1;
        this.props.handleGetAllTransaction(_params)
    };

    handleSearchChange = (e, type) => {
        if (type === 1) {
            this.setState((prevState) => ({
                params: { ...prevState.params, Address: e.target.value },
            }));
        } else {
            this.setState((prevState) => ({
                params: { ...prevState.params, TxnID: e.target.value },
            }));
        }
    };

    handleSelectChange = (e, type) => {
        let _params = { ...this.state.params };
        if (type === 1) {
            if (e === null) {
                this.setState((prevState) => ({
                    params: { ...prevState.params, Type: 0 },
                }));
                _params.Type = 0;
            } else {
                this.setState((prevState) => ({
                    params: { ...prevState.params, Type: e.value },
                }));
                _params.Type = e.value;
            }
        } else {
            if (e === null) {
                this.setState((prevState) => ({
                    params: { ...prevState.params, Status: -1 },
                }));
                _params.Status = -1;
            } else {
                this.setState((prevState) => ({
                    params: { ...prevState.params, Status: e.value },
                }));
                _params.Status = e.value;
            }
        }
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetAllTransaction(_params)
    };
    handleChangeSize = (e) => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageSize: e.value },
        }));
        _params.PageSize = e.value;
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetAllTransaction(_params)
    };
    handleSearchAction = () => {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetAllTransaction(_params);
    }

    hanldeChangeDate(e, type) {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        let fromdate = { ...this.state.startDate };
        let todate = { ...this.state.endDate };
        if (type === 1) {
            this.setState({ startDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, FromDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.FromDate = moment(e).format('yyyy-MM-DD');
            fromdate = e;
        } else {
            this.setState({ endDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, ToDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.ToDate = moment(e).format('yyyy-MM-DD');
            todate = e;
        }

        if (fromdate && todate) {
            if (moment(fromdate).isAfter(moment(todate))) {
                toast.error(t("'To Date' must be greater than 'From Date'"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                return false;
            }
        }
        this.props.handleGetAllTransaction(_params);
    }

    getType = (type) => {
        switch (type) {
            case 1: return "Deposit"
            case 2: return "Withdraw"
            case 3: return "Transfer In"
            case 4: return "Transfer Out"
            case 5: return "Reward"
            case 6: return "Invest Package"
            default: return "N/A"
        }
    }
    getStatus = (status) => {
        switch (status) {
            case 2: return "Running"
            case 3: return "Failed"
            case 4: return "Success"
            case 5: return "Cancel"
            default: return "N/A"
        }
    }
    handleToogleModalTransaction = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalTransaction();
    };


    handleChange = (newDate) => {
        return this.setState({ date: newDate });
    }
    // hanldeMoveRight = (e) => {
    //     const { mx } = this.state;
    //     var mx2 = e.pageX - this.offsetLeft;
    //     if (mx) this.scrollLeft = this.sx + mx - mx2;
    // };
    // hanldeMoveLeft = (e) => {
    //     this.sx = this.scrollLeft;
    //     let _mx = e.pageX - this.offsetLeft;
    //     this.setState({ mx: _mx })
    // };
    hanldeEnter = (e) => {
        var key = e.keyCode || e.which;
        e.preventDefault();
        if (key === 13) {
            this.handleSearchAction();
        }
        return false;
    }
    handlePasteToInput = (e, type) => {
        e.preventDefault();
        let inputRef = type === 0 ? this.inputRef1 : this.inputRef;
        if (inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 0) {
                        this.setState({ qrcode: pastedText });
                    } else if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, otp: pastedText },
                        }));
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, gacode: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    tooglePWMobile = () => {
        this.setState((prevState) => ({ showChangePWMB: !prevState.showChangePWMB }));
    }
    onToogleModalDeposit = () => {
        this.setState((prevState) => ({ modalDepositOpen: !prevState.modalDepositOpen }));
    }
    onToogleModalWithdrawTransfer = () => {
        this.setState((prevState) => ({ modalWithdrawTransferOpen: !prevState.modalWithdrawTransferOpen }));
    }
    onToogleModalRegisterOTC = () => {
        this.setState((prevState) => ({ modalRegisterOTCOpen: !prevState.modalRegisterOTCOpen }));
    }
    onOpenRegisterOTC = () => {
        const { info } = this.props;
        if (!info.otc) {
            this.setState((prevState) => ({ modalRegisterOTCOpen: !prevState.modalRegisterOTCOpen }));
        } else {
            this.setState({ confirmText: t("Are you sure you want to cancel your OTC registration?") })
            this.setState(prevState => ({
                showConfirm: !prevState.showConfirm
            }));
        }
    }
    hanldeWithdrawTransfer = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Fund/USDTTRC20WalletWithdraw', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Successfully created a withdrawal order!"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState(prevState => ({
                    modalWithdrawTransferOpen: !prevState.modalWithdrawTransferOpen
                }));
                let _params = { ...this.state.params };
                //reset pageindex
                this.setState((prevState) => ({
                    params: { ...prevState.params, PageIndex: 0 },
                }));
                _params.PageIndex = 1;
                this.props.handleGetAllTransaction(_params);
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleRegisterOTC = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Purchase/RegisterOTC', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ modalRegisterOTCOpen: false });
                // let _params = { ...this.state.params };
                // //reset pageindex
                // this.setState((prevState) => ({
                //     params: { ...prevState.params, PageIndex: 0 },
                // }));
                // _params.PageIndex = 1;
                // this.props.handleGetAllTransaction(_params);
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.state.timer);
        clearInterval(this.state.intervalGetList);
    }
    // componentDidUpdate(prevProps) {
    //     // Kiểm tra xem props có thay đổi không
    //     if (this.props.currentTab !== prevProps.currentTab) {
    //         if (this.props.currentTab === "TransHistory") {
    //             var _params = {
    //                 Type: 1,
    //                 PageIndex: 1,
    //                 PageSize: 10,
    //                 Symbol: this.props.Coin ? this.props.Coin.symbolID : 0
    //             }
    //             let params = {
    //                 Type: 1,
    //                 PageIndex: 0,
    //                 PageSize: 10,
    //                 Symbol: this.props.Coin ? this.props.Coin.symbolID : 0
    //             }
    //             this.setState({
    //                 params: params,
    //                 dataTrans: [],
    //                 TotalRows: 0,
    //                 startDate: moment().subtract(1, 'days'),
    //                 endDate: moment()
    //             })
    //             this.props.handleGetAllTransaction(_params);
    //             this.handleGetListBuySellPending();
    //             let _interval = setInterval(() => {
    //                 this.handleGetListBuySellPending()
    //             }, 5000);
    //             this.setState({ intervalGetList: _interval })
    //         } else {
    //             this.setState({
    //                 showRPTBuySell: false
    //             })
    //             clearInterval(this.state.intervalGetList);
    //         }

    //     }
    // }
    handleshowFromorAddress = (item, type) => {//1 from, 2 to
        if (type === 1) {
            if (!item.showFrom) {
                item.showFrom = true;
            } else {
                item.showFrom = false;
            }
        } else {
            if (!item.showTo) {
                item.showTo = true;
            } else {
                item.showTo = false;
            }
        }
        let _data = [...this.state.dataTrans];
        _data.map((el, index) => {
            if (el.transId === item.transId) {
                el = item;
                this.setState({ dataTrans: _data });
            }
            return el;
        });
    }
    onToogleRPTBuySell = () => {
        // this.setState((prevState) => ({ showRPTBuySell: !prevState.showRPTBuySell }));
        if (this.state.showRPTBuySell) {
            redirectToURL("/transaction/1")
        } else {
            redirectToURL("/transaction/4")
        }
    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        this.setState({ isLoading: true });
        try {
            let response = await post('/Purchase/AbortOTC');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Cancel successfully."), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleGetListBuySellPending = async () => {
        // this.setState({ isLoading: true });
        const { info } = this.props;
        try {
            let response = await get('/UserReport/BuySellPendingHistory');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let old_pending = [...this.state.data];
                let new_pending = response.data.data.pendingLst.map((el, index) => {
                    let old_item = old_pending && old_pending.find(x => x.transID === el.transID);
                    if (old_item && old_item.showEmail) {
                        el.showEmail = true;
                    }
                    if (old_item && old_item.timeRemain) {
                        el.timeRemain = old_item.timeRemain;
                    }
                    if (old_item && old_item.timeRemainReport) {
                        el.timeRemainReport = old_item.timeRemainReport;
                    }
                    return el;
                });
                this.setState({
                    data: new_pending,
                    total: response.data.data.totalPendingLst,
                })
                if (!this.state.timer) {
                    //timmer
                    let need_timer = response.data.data.pendingLst.some(x => x.status === 2 || x.status === 3);
                    if (need_timer) {
                        let _timer = setInterval(() => {
                            let data_pending = [...this.state.data];
                            data_pending.map((el, index) => {
                                if (el.status === 2 || el.status === 3) {
                                    let timeRemain = calculateTimeRemaining(el.expiredDate);
                                    el.timeRemain = timeRemain.total > 0 ? timeRemain : null;
                                }
                                if (el.status === 3 && el.type === 2 && !el.timeRemain && el.waittingDoneExpiredDate2) {// time out of receive money, start countdown to report
                                    let expiredDateReport = el.waittingDoneExpiredDate2;
                                    // if (!el.waittingDoneExpiredDate2) {
                                    //     expiredDateReport = moment(el.expiredDate).add(15, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSS");
                                    // }
                                    let timeRemain = calculateTimeRemaining(expiredDateReport);
                                    el.timeRemainReport = timeRemain.total > 0 ? timeRemain : null
                                }
                                return el;
                            });
                            this.setState({ data: data_pending });
                            // console.log("data_pending", data_pending);
                        }, 1000);
                        this.setState({ timer: _timer });
                    }
                }

                if (localStorage.getItem("fromBuyPackage") === "true") {
                    this.onToogleModalBuyUSDT();
                    localStorage.setItem("fromBuyPackage", false)
                }
            } else {
                // this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            // this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    // handleGetListBuySell = async (_params) => {
    //     // this.setState({ isLoading: true });
    //     try {
    //         let response = await get('/UserReport/BuySellHistory', _params);
    //         if (response.data.errorCode === 0) {
    //             this.setState({ isLoading: false });
    //             let old_done = [...this.state.dataDone];
    //             let new_done = response.data.data.doneLst.map((el, index) => {
    //                 let old_item = old_done && old_done.find(x => x.transID === el.transID);
    //                 if (old_item && old_item.showEmail) {
    //                     el.showEmail = true;
    //                 }
    //                 return el;
    //             });
    //             this.setState({
    //                 dataDone: new_done,
    //                 totalDone: response.data.data.totalDoneLst,
    //                 TotalRowsBuySell: response.data.totalRows,
    //             })
    //         } else {
    //             // this.setState({ isLoading: false });
    //             toast.error(t(response.data.errorMessage), {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 5000,
    //                 onOpen: () => checkToken(response.data)
    //             });
    //         }
    //     } catch (error) {
    //         // this.setState({ isLoading: false });
    //         let response = error.response;
    //         if (response && response.data) {
    //             toast.error(t(response.data.errorMessage), {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 5000,
    //                 onOpen: () => checkToken(response.data)
    //             });
    //         } else {
    //             checkToken(response)
    //         }
    //     }
    // };
    handleshowEmail = (item, type) => {//1 RPT pending, 2 RPT Done
        if (!item.showEmail) {
            item.showEmail = true;
        } else {
            item.showEmail = false;
        }
        if (type === 1) {
            let _data = [...this.state.data];
            _data.map((el, index) => {
                if (el.transID === item.transID) {
                    el = item;
                }
                return el;
            });
            this.setState({ data: _data });
        } else {
            let _data = [...this.state.dataDone];
            _data.map((el, index) => {
                if (el.transID === item.transID) {
                    el = item;
                }
                return el;
            });
            this.setState({ dataDone: _data });
        }
    }

    /*================RPT Buy Sell================== */
    onToogleModalSellUSDT = () => {
        this.setState(prevState => ({
            modalSellUSDTOpen: !prevState.modalSellUSDTOpen, modalWithdrawTransferOpen: false
        }));
        if (localStorage.getItem("lang") !== "vi") {
            localStorage.setItem("lang", "vi");
            i18n.changeLanguage("vi");
        }
    }
    hanldeSellUSDT = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post("/Purchase/SellUSDT", params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                // this.setState({
                //     modalSellUSDTOpen: false
                // });
                this.handleGetListBuySellPending();
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest USDT you can buy is")) {
                    message = message.replace("The lowest USDT you can buy is", t("The lowest USDT you can buy is"))
                }
                if (message && message.includes("The lowest USDT you can sell is")) {
                    message = message.replace("The lowest USDT you can sell is", t("The lowest USDT you can sell is"))
                }
                toast.error(t(message), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest USDT you can buy is")) {
                    message = message.replace("The lowest USDT you can buy is", t("The lowest USDT you can buy is"))
                }
                if (message && message.includes("The lowest USDT you can sell is")) {
                    message = message.replace("The lowest USDT you can sell is", t("The lowest USDT you can sell is"))
                }
                toast.error(t(message), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    onToogleModalBuyUSDT = () => {
        this.setState(prevState => ({
            modalBuyUSDTOpen: !prevState.modalBuyUSDTOpen, modalDepositOpen: false
        }));
        if (localStorage.getItem("lang") !== "vi") {
            localStorage.setItem("lang", "vi");
            i18n.changeLanguage("vi");
        }
    }
    hanldeBuyUSDT = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post("/Purchase/BuyUSDT", params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                // this.setState({
                //     modalBuyUSDTOpen: false
                // });
                this.handleGetListBuySellPending();
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest USDT you can buy is")) {
                    message = message.replace("The lowest USDT you can buy is", t("The lowest USDT you can buy is"))
                }
                if (message && message.includes("The lowest USDT you can sell is")) {
                    message = message.replace("The lowest USDT you can sell is", t("The lowest USDT you can sell is"))
                }
                toast.error(t(message), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest USDT you can buy is")) {
                    message = message.replace("The lowest USDT you can buy is", t("The lowest USDT you can buy is"))
                }
                if (message && message.includes("The lowest USDT you can sell is")) {
                    message = message.replace("The lowest USDT you can sell is", t("The lowest USDT you can sell is"))
                }
                toast.error(t(message), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleToogleDetailModal = () => {
        this.setState((prevState) => ({ modalDetailOpen: !prevState.modalDetailOpen }));
    }
    onOpenDetailModal = (item) => {
        this.setState((prevState) => ({ modalDetailOpen: !prevState.modalDetailOpen, selectedItem: item }));
    }
    shortTenAddress = (address) => {
        let shortenedAddress = address;
        if (address && address.length > 8) {
            shortenedAddress = address.replace(/^(.{2}).*(.{5})$/, '$1...$2');
        }
        return shortenedAddress;
    }
    transferCurrency = (lang) => {
        switch (lang) {
            case "en": return "USD"
            case "vi": return "VND"
            case "ar": return "AED"
            case "de": return "EUR"
            case "es": return "EUR"
            case "fr": return "EUR"
            case "hi": return "INR"
            case "pt": return "EUR"
            case "it": return "EUR"
            case "ru": return "RUB"
            case "zh": return "CNY"
            case "ton": return "TON"
            case "usdt": return "USDT"
            case "bit": return "BIT"
        }
    }
    hanldeFormatLang = (amount, lang) => {
        if (lang) {
            if (lang === "vi" || lang === "hi") {
                return formatNumber(amount, 0, 0)
            }
        }
        return formatNumber(amount)
    }
    render() {
        const { t, info, bankInfo, dataTrans, TotalRows, TypeTransaction, Coin } = this.props;
        const { params, data, dataDone, total, totalDone, TotalRowsBuySell, isLoading,
            modalDepositOpen, modalWithdrawTransferOpen, modalRegisterOTCOpen, showRPTBuySell,
            confirmText, showConfirm, modalSellUSDTOpen, modalBuyUSDTOpen,
            modalDetailOpen, selectedItem } = this.state;
        const optionsType = [
            { value: 0, label: t('All') },
            { value: 1, label: t('Deposit') },
            { value: 2, label: t('Withdraw') },
            { value: 3, label: t('Transfer In') },
            { value: 4, label: t('Transfer Out') },
            { value: 5, label: t('Commission transfer') },
            { value: 6, label: t('Buy USDT') },
            { value: 7, label: t('Sell USDT') },
            { value: 8, label: t('Membership') }
        ]
        const optionsStatus = [
            { value: -1, label: t('All') },
            { value: 0, label: t('Pending') },
            { value: 2, label: t('Running') },
            { value: 3, label: t('Failed') },
            { value: 4, label: t('Success') },
            { value: 5, label: t('Cancel') }
        ]
        return (
            <>
                <Container fluid>
                    {/* <div className="cover-fund mb-3">
                        <Button className="btn-withdraw btn-fund" color="secondary" type="button" title={t("Withdraw")} onClick={this.onToogleModalWithdrawTransfer}> <i className="img-icon fa fa-minus"></i></Button>
                        <div className="href-link not-pointer balance-num" title={t("Balance")}>
                            {FormatNumberAmount(info.balance)}
                        </div>
                        <Button className="btn-deposit m-0 btn-fund" color="success" type="button" title={t("Deposit")} onClick={this.onToogleModalDeposit} > <i className="img-icon fa fa-plus"></i></Button>
                    </div> */}
                    {/* <RPTBuySellUSDT data={data} dataDone={dataDone} total={total} totalDone={totalDone} TotalRows={TotalRowsBuySell} handleGetList={this.handleGetListBuySell} handleGetListBuySellPending={this.handleGetListBuySellPending} onToogleRPTBuySell={this.onToogleRPTBuySell} info={info} bankInfo={bankInfo} hanldeLoadBasicInfo={this.props.hanldeLoadBasicInfo} type={this.props.type} handleshowEmail={this.handleshowEmail} onOpenRegisterOTC={this.onOpenRegisterOTC} modalSellUSDTOpen={modalSellUSDTOpen} onToogleModalSellUSDT={this.onToogleModalSellUSDT} hanldeSellUSDT={this.hanldeSellUSDT} modalBuyUSDTOpen={modalBuyUSDTOpen} onToogleModalBuyUSDT={this.onToogleModalBuyUSDT} hanldeBuyUSDT={this.hanldeBuyUSDT} /> */}
                    <div className="filter-group mb-3 mt-3 grp-no-wrap">
                        {/* <div className="filter-select-lg">
                            <label className="text-bold">{t("Address")} {t("or")} Email</label>
                            <InputGroup className="input-search">
                                <Input
                                    placeholder={`${t("Address")} ${t("or")} Email`}
                                    type="text"
                                    autoComplete="off"
                                    name="Address" value={params.WalletAddress} onChange={(e) => this.handleSearchChange(e, 1)}
                                    onKeyUp={this.hanldeEnter}
                                />
                                <InputGroupAddon addonType="append" >
                                    <InputGroupText onClick={this.handleSearchAction}>
                                        <i className="fa fa-search" />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <div className="filter-select-lg">
                            <label className="text-bold">TxnID</label>
                            <InputGroup className="input-search">
                                <Input
                                    placeholder="TxnID"
                                    type="text"
                                    autoComplete="off"
                                    name="TxnID" value={params.TxnID} onChange={(e) => this.handleSearchChange(e, 2)}
                                    onKeyUp={this.hanldeEnter}
                                />
                                <InputGroupAddon addonType="append" >
                                    <InputGroupText onClick={this.handleSearchAction}>
                                        <i className="fa fa-search" />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <div className="d-flex wdt-100">
                            <div className="filter-select-lg pr-1">
                                <label className="text-bold">{t("Type")}</label>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isSearchable={false}
                                    options={optionsType}
                                    formatGroupLabel="Type"
                                    placeholder={t("Type")}
                                    defaultValue={optionsType[0]}
                                    onChange={(e) => this.handleSelectChange(e, 1)}
                                    classNamePrefix="custom-filter-select"
                                />
                            </div>
                            <div className="filter-select-lg pl-1">
                                <label className="text-bold">{t("Status")}</label>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isSearchable={false}
                                    options={optionsStatus}
                                    formatGroupLabel="Status"
                                    placeholder={t("Status")}
                                    defaultValue={optionsStatus[0]}
                                    onChange={(e) => this.handleSelectChange(e, 2)}
                                    classNamePrefix="custom-filter-select"
                                />
                            </div>
                        </div> 
                        <div className="grp-daterange">
                            <div className="pr-1">
                                <label className="text-bold">{t("From")}</label>                               
                                <InputGroup className="grp-datetime">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: t("From"),
                                            readOnly: true
                                        }}
                                        value={this.state.startDate}
                                        dateFormat="DD/MM/yyyy"
                                        timeFormat={false}
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            if (
                                                this.state.startDate &&
                                                this.state.endDate &&
                                                this.state.startDate._d + "" === currentDate._d + ""
                                            ) {
                                                classes += " start-date";
                                            } else if (
                                                this.state.startDate &&
                                                this.state.endDate &&
                                                new Date(this.state.startDate._d + "") <
                                                new Date(currentDate._d + "") &&
                                                new Date(this.state.endDate._d + "") >
                                                new Date(currentDate._d + "")
                                            ) {
                                                classes += " middle-date";
                                            } else if (
                                                this.state.endDate &&
                                                this.state.endDate._d + "" === currentDate._d + ""
                                            ) {
                                                classes += " end-date";
                                            }
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                        onChange={(e) => this.hanldeChangeDate(e, 1)}
                                        closeOnSelect={true}
                                    />
                                </InputGroup>
                            </div>
                            <div className="pl-1">
                                <label className="text-bold">{t("To")}</label>
                                <InputGroup className="grp-datetime todate-frame">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: t("To"),
                                            readOnly: true
                                        }}
                                        value={this.state.endDate}
                                        dateFormat="DD/MM/yyyy"
                                        timeFormat={false}
                                        renderDay={(props, currentDate, selectedDate) => {
                                            let classes = props.className;
                                            if (
                                                this.state.startDate &&
                                                this.state.endDate &&
                                                this.state.startDate._d + "" === currentDate._d + ""
                                            ) {
                                                classes += " start-date";
                                            } else if (
                                                this.state.startDate &&
                                                this.state.endDate &&
                                                new Date(this.state.startDate._d + "") <
                                                new Date(currentDate._d + "") &&
                                                new Date(this.state.endDate._d + "") >
                                                new Date(currentDate._d + "")
                                            ) {
                                                classes += " middle-date";
                                            } else if (
                                                this.state.endDate &&
                                                this.state.endDate._d + "" === currentDate._d + ""
                                            ) {
                                                classes += " end-date";
                                            }
                                            return (
                                                <td {...props} className={classes}>
                                                    {currentDate.date()}
                                                </td>
                                            );
                                        }}
                                        onChange={(e) => this.hanldeChangeDate(e, 2)}
                                        closeOnSelect={true}
                                    />
                                </InputGroup>
                            </div>
                        </div>*/}
                        {/* {info.isDonate && <div className="filter-select-md ">
                            <Button className={`btn-rpt-search`} color={info.otc ? "success" : "secondary"} type="button" title={info.otc ? t("Cancel registration as OTC") : t("Register as OTC")} onClick={this.onOpenRegisterOTC}>OTC</Button>
                        </div>} */}
                    </div>
                    {/*8: Swap người mua(+BIT, -TON), 10: swap người bán (+TON, -BIT)*/}
                    {/*
                        BIT:
                        5:  User Nhận BIT 
                        11: OTC trừ BIT

                        TON:
                        6: OTC Cộng TON 
                        12:  User Trừ TON
                        13: Gas fee sell TON
                        14: user buy TON
                        15: otc sell TON
                        16: scholarship
                        17: swap nhận usdt
                        18: swap trừ usdt
                        19: Gas fee
                    */}
                    <div className="transaction-history">
                        <div className="list-transaction" >
                            {dataTrans && dataTrans.map((item, index) => {
                                let type_img = item.type === 8 || item.type === 10 ? "fa fa-exchange" : (item.type === 1 || item.type === 3 || item.type === 5 || item.type === 6 || (item.type === 7 && item.amount > 0) || item.type === 9 || item.type === 14 || item.type === 16 || item.type === 17 || item.type === 20) ? "fa fa-arrow-down" : "fa fa-arrow-up";
                                let type_text = item.type === 2 || (item.type === 8 && Coin.symbol === "TON") || (item.type === 8 && Coin.symbol === "USDT") || (item.type === 10 && Coin.symbol === "BIT") || (item.type === 12 && Coin.symbol === "USDT") || item.type === 4 || (item.type === 7 && item.amount < 0) || item.type === 13 || item.type === 11 || item.type === 15 || item.type === 18 || item.type === 19 || item.type === 21 ? "Sent" : "Received";
                                let class_amount = (item.type === 1 || item.type === 3 || item.type === 5 || item.type === 6 || (item.type === 7 && item.amount > 0) || item.type === 9 || (item.type === 8 && Coin.symbol === "BIT") || (item.type === 10 && Coin.symbol === "TON")) || (item.type === 10 && Coin.symbol === "USDT") || item.type === 14 || item.type === 16 || item.type === 17 || item.type === 20 ? "Receive" : "Send";
                                return (<div key={index} className="item-transaction" onClick={() => this.onOpenDetailModal(item)}>
                                    <div className="img-type">
                                        <i className={type_img} aria-hidden="true"></i>
                                    </div>
                                    <div className="trans-info">
                                        <div className="trans-name">
                                            {(item.type === 3 || item.type === 5 || item.type === 6 || item.type === 14 || item.type === 20) && <div className="text-name">{t("Receive from")} {this.shortTenAddress(item.from)}</div>}
                                            {(item.type === 2 || item.type === 4 || item.type === 11 || item.type === 12 || item.type === 15 || item.type === 21) && <div className="text-name">{t("Send to")} {this.shortTenAddress(item.to)}</div>}
                                            {(item.type === 13) && <div className="text-name">{t("Gas Fee of BIT sell order")}</div>}
                                            {(item.type === 19) && <div className="text-name">{t("Gas Fee")}</div>}

                                            {(item.type === 7 || item.type === 8 || item.type === 9 || item.type === 10 || item.type === 16 || item.type === 17 || item.type === 18) && <div className="text-name">{t(item.typeText)}</div>}
                                            {item.type === 1 && <div className="text-name">{t("Deposit")}</div>}
                                            <div className={`right-text ${class_amount}`}>{class_amount === "Send" ? item.amount > 0 ? "-" : "" : class_amount === "Receive" ? "+" : ""} {FormatNumberAmount2(TypeTransaction === 3 ? item.fee : item.amount)} {TypeTransaction === 3 ? "TON" : Coin.symbol}</div>
                                        </div>
                                        <div className="trans-date">
                                            <div>{formatDate(item.dateLog)}</div>
                                            <div className={`right-text ${class_amount}`}>{t(type_text)}</div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                    {/* <Table striped bordered responsive className="rpt-table" >
                        <thead>
                            <tr>
                                <th className=" text-center num-stt ">#</th>
                                <th >{t("Amount")}</th>
                                <th >{t("Current Balance")}</th>
                                <th >{t("Network Fee")}</th>
                                <th >{t("Address")}</th>
                                <th >{t("Date")}</th>
                                <th >{t("Type")}</th>
                                <th >{t("TxnID")}</th>
                                <th >{t("Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTrans && dataTrans.map((item, index) => {
                                var startIndex = params.PageSize * params.PageIndex + 1;
                                return (
                                    <tr key={index}>
                                        <td className=" text-center ">{index + startIndex}</td>
                                        <td className={(item.type === 1 || item.type === 3 || item.type === 5 || item.type === 6 || item.type === 9 ? "text-success" : "text-danger") + " num"}>{(item.type === 1 || item.type === 3 || item.type === 5 || item.type === 6 || item.type === 9 ? "+" : "-") + FormatNumberAmount(item.amount)}</td>
                                        <td className="num">{FormatNumberAmount(item.currentBalance ? item.currentBalance : 0)}</td>
                                        <td className="num">{FormatNumberAmount(item.fee)}</td>
                                        <td className="text ">
                                            <div className="d-flex align-items-center">
                                                {item.from && (item.type === 1 || item.type === 3 || item.type === 5 || item.type === 6 || item.type === 9) && <>
                                                    <span className="show-email pointer" onClick={() => this.handleshowFromorAddress(item, 1)}> <span className="btn-inner--text btn-span"><i className={!item.showFrom ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                                    &nbsp;&nbsp;
                                                    <span className="address-trunc" title={t(item.from)}>{item.showFrom ? t(item.from) : hideCharacters(item.from)}</span>
                                                    &nbsp;
                                                    <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(item.from)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                                </>
                                                }
                                                {item.to && (item.type === 2 || item.type === 4 || item.type === 7 || item.type === 8) && <>
                                                    <span className="show-email pointer" onClick={() => this.handleshowFromorAddress(item, 2)}> <span className="btn-inner--text btn-span"><i className={!item.showTo ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                                    &nbsp;&nbsp;
                                                    <span className="address-trunc" title={t(item.to)}>{item.showTo ? t(item.to) : hideCharacters(item.to)}</span>
                                                    &nbsp;
                                                    <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(item.to)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                                </>}
                                            </div>
                                        </td>
                                        <td >{formatDate(item.dateLog)}</td>
                                        <td >{t(item.typeText)}</td>
                                        <td className="text th-input ">
                                            <div className="d-flex align-items-center">
                                                <a className="address-trunc" href={configs.DetailWalletTRON + "/#/transaction/" + item.txnID} target="_blank" rel="noreferrer" title={item.txnID}>{item.txnID}</a>
                                                {item.txnID && <>
                                                    &nbsp;
                                                    <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(item.txnID)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                                </>}
                                            </div>
                                        </td>
                                        <td >{t(item.statusText)}</td>
                                    </tr>
                                )
                            })}
                            {(!dataTrans || dataTrans.length === 0) && <>
                                <tr className="text-center"><td colSpan={11}>{t("No data")}</td></tr>
                            </>}
                        </tbody>
                    </Table>
                    {TotalRows > 0 && params.PageSize > 0 &&
                        <>
                            <div className="paganition-custom">
                                <ReactPaginate
                                    pageCount={Math.ceil(TotalRows / params.PageSize)}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    onPageChange={this.handlePageChange}
                                    forcePage={params.PageIndex}
                                    className="react-paginate"
                                    activeClassName={'active'}
                                    previousLabel={<i className="fa fa-angle-left"></i>}
                                    nextLabel={<i className="fa fa-angle-right"></i>}
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={sizeOptions}
                                    formatGroupLabel="Size"
                                    placeholder="Size"
                                    isClearable={false}
                                    defaultValue={sizeOptions[0]}
                                    onChange={this.handleChangeSize}
                                    classNamePrefix="pagesize-select"
                                />
                            </div>
                        </>} */}
                </Container>
                {isLoading && <Loading />}
                <ModalConfirm
                    message={confirmText}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                {/* <ToastContainer pauseOnFocusLoss={false} /> */}
                {/* <BuyPackage info={info} handleChangeTab={this.props.handleChangeTab} hanldeLoadBasicInfo={this.props.hanldeLoadBasicInfo} onOpenRegisterOTC={this.onOpenRegisterOTC} /> */}
                <Deposit modalDepositOpen={modalDepositOpen} onToogleModalDeposit={this.onToogleModalDeposit} onToogleModalBuyUSDT={this.onToogleModalBuyUSDT} info={info} />
                <WithdrawTransfer modalWithdrawTransferOpen={modalWithdrawTransferOpen} onToogleModalWithdrawTransfer={this.onToogleModalWithdrawTransfer} BalanceUSDT={info.balance} TwoFA={info.twoFA} hanldeWithdrawTransfer={this.hanldeWithdrawTransfer} onToogleModalSellUSDT={this.onToogleModalSellUSDT} info={info} />
                <RegisterOTC modalRegisterOTCOpen={modalRegisterOTCOpen} onToogleModalRegisterOTC={this.onToogleModalRegisterOTC} handleRegisterOTC={this.handleRegisterOTC} bankInfo={bankInfo} />
                <Modal toggle={this.handleToogleDetailModal} isOpen={modalDetailOpen}>
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Detail")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleDetailModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody >
                        {selectedItem && (() => {
                            let type_img = selectedItem.type === 8 || selectedItem.type === 10 ? "fa fa-exchange" : (selectedItem.type === 1 || selectedItem.type === 3
                                || selectedItem.type === 5 || selectedItem.type === 6 || (selectedItem.type === 7 && selectedItem.amount > 0) || selectedItem.type === 9
                                || selectedItem.type === 14 || selectedItem.type === 16 || selectedItem.type === 17 || selectedItem.type === 20)
                                ? "fa fa-arrow-down" : "fa fa-arrow-up";
                            let class_amount = (selectedItem.type === 1 || selectedItem.type === 3 || selectedItem.type === 5 || selectedItem.type === 6
                                || (selectedItem.type === 7 && selectedItem.amount > 0) || selectedItem.type === 9 || (selectedItem.type === 8 && Coin.symbol === "BIT")
                                || (selectedItem.type === 10 && Coin.symbol === "TON")) || (selectedItem.type === 10 && Coin.symbol === "USDT")
                                || selectedItem.type === 14 || selectedItem.type === 16 || selectedItem.type === 17 || selectedItem.type === 20 ? "Receive" : "Send";

                            return (<div className="detail-content">
                                <div className="dt-title">
                                    <div className="img-type">
                                        <i className={type_img} aria-hidden="true"></i>
                                        {/* <img
                                            className="img-icon"
                                            src={require(`assets/img/wallet/${type_img}.png`)}
                                        /> */}
                                    </div>
                                    {(selectedItem.type === 3 || selectedItem.type === 5 || selectedItem.type === 6 || selectedItem.type === 14 || selectedItem.type === 20) && <div className="text-name">{t("Receive from")} {this.shortTenAddress(selectedItem.from)}</div>}
                                    {(selectedItem.type === 2 || selectedItem.type === 4 || selectedItem.type === 11 || selectedItem.type === 12 || selectedItem.type === 15 || selectedItem.type === 21) && <div className="text-name">{t("Send to")} {this.shortTenAddress(selectedItem.to)}</div>}
                                    {(selectedItem.type === 7 || selectedItem.type === 8 || selectedItem.type === 9 || selectedItem.type === 10 || selectedItem.type === 16 || selectedItem.type === 17 || selectedItem.type === 18) && <div className="text-name">{t(selectedItem.typeText)}</div>}
                                    {selectedItem.type === 1 && <div className="text-name">{t("Deposit")}</div>}
                                    {(selectedItem.type === 13) && <div className="text-name">{t("Gas Fee of BIT sell order")}</div>}
                                    {(selectedItem.type === 19) && <div className="text-name">{t("Gas Fee")}</div>}
                                </div>
                                <div className={`dt-amount ${class_amount}`}>{class_amount === "Send" ? selectedItem.amount > 0 ? "-" : "" : class_amount === "Receive" ? "+" : ""} {FormatNumberAmount2(TypeTransaction === 3 ? selectedItem.fee : selectedItem.amount)} {TypeTransaction === 3 ? "TON" : Coin.symbol}</div>
                                <div className="dt-date">{formatDate(selectedItem.dateLog)}</div>
                                <div className="cover-info-trans">
                                    <div className="info">
                                        <div><span>{t("Type")}:</span> <b>{t(selectedItem.typeText)}</b></div>
                                    </div>
                                    <div className="info">
                                        <div><span>{t("Status")}:</span> <b>{t(selectedItem.statusText)}</b></div>
                                    </div>
                                    <div className="info">
                                        <span>{t("Network")}: </span>
                                        <b>{selectedItem.symbol}</b>
                                    </div>
                                    {selectedItem.type !== 8 && selectedItem.type !== 7 && selectedItem.type !== 13 && selectedItem.type !== 16 && selectedItem.type !== 17 && selectedItem.type !== 18 && selectedItem.type !== 19 && <div className="info">
                                        <span>{type_img === "fa fa-arrow-up" ? t("To") : t("From")}: </span>
                                        <b>
                                            {type_img === "fa fa-arrow-up" ? selectedItem.to : selectedItem.from}
                                            &nbsp;
                                            <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(type_img === "fa fa-arrow-up" ? selectedItem.to : selectedItem.from)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                        </b>
                                    </div>}
                                    {(selectedItem.type == 13) && <div className="info">
                                        <span>{t("From")}: </span>
                                        <b>
                                            {selectedItem.to}
                                            &nbsp;
                                            <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedItem.to)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                        </b>
                                    </div>}
                                    {TypeTransaction === 1 && (selectedItem.type === 5 || selectedItem.type === 11 || selectedItem.type === 14 || selectedItem.type === 15 || selectedItem.type === 20) && <>
                                        <div className="info">
                                            <div className="d-flex align-items-center">
                                                <span>{t("Transfer Code")}: </span>
                                                &nbsp;
                                                <b className="address-trunc" title={selectedItem.transferCode}>{selectedItem.transferCode}</b>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {TypeTransaction === 1 && (selectedItem.type === 5 || selectedItem.type === 14 || selectedItem.type === 20) && selectedItem.typeBuy === 1 && <>
                                        <div className="info">
                                            <div className="d-flex align-items-center">
                                                <span>{t("Account number")}: </span>
                                                &nbsp;
                                                <b className="address-trunc" title={selectedItem.accNumberBank}>{selectedItem.accNumberBank}</b>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="d-flex align-items-center">
                                                <span>{t("Cardholder name")}: </span>
                                                &nbsp;
                                                <b className="address-trunc" title={selectedItem.cardholderNameBank}>{selectedItem.cardholderNameBank}</b>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="d-flex align-items-center">
                                                <span>{t("Bank name")}: </span>
                                                &nbsp;
                                                <b className="address-trunc" title={selectedItem.nameBank}>{selectedItem.nameBank}</b>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {TypeTransaction !== 3 && TypeTransaction !== 19 && <div className="info">
                                        {this.props.Coin.symbol === "BIT" || selectedItem.type === 5 || selectedItem.type === 6 || selectedItem.type === 8 || selectedItem.type === 10 || selectedItem.type === 11 || selectedItem.type === 12 || selectedItem.type === 13 ? <span>{t("Gas Fee")}: </span> : <span>{t("Fee")}: </span>}
                                        <b>{FormatNumberAmount(selectedItem.fee)} {selectedItem.symbolFee} </b>
                                    </div>}
                                    {TypeTransaction === 1 && (selectedItem.type === 5 || selectedItem.type === 11 || selectedItem.type === 14 || selectedItem.type === 15 || selectedItem.type === 20 || selectedItem.type === 21) && <>
                                        {/* {Coin.symbol !== "USDT" && <div className="info">
                                            <span>{t("Price")} USDT: </span>
                                            <b>{formatNumber(selectedItem.priceUSDT)}  </b>
                                        </div>} */}
                                        <div className="info">
                                            <span>{t("Price")} {this.transferCurrency(selectedItem.otcLanguage)}: </span>
                                            <b>{formatNumber(selectedItem.priceVND, 3, 0)}</b>
                                        </div>
                                        {selectedItem.type !== 14 && selectedItem.type !== 15 && selectedItem.type !== 20 && selectedItem.type !== 21 && <div className="info">
                                            <span>{t("Discount")} (%): </span>
                                            <b>{FormatNumberAmount(selectedItem.discountPercent)}</b>
                                        </div>}
                                        <div className="info">
                                            <span>{selectedItem.type === 5 || selectedItem.type === 14 || selectedItem.type === 20 ? t("Amount Paid") : t("Amount Received")}: </span>
                                            <b>{this.hanldeFormatLang(selectedItem.amountVND, selectedItem.otcLanguage)} {this.transferCurrency(selectedItem.otcLanguage)} </b>
                                        </div>
                                    </>
                                    }

                                    {selectedItem.memo &&
                                        <div className="info">
                                            <div className="d-flex align-items-center">
                                                <span>Memo: </span>
                                                &nbsp;
                                                {/*href={configs.DetailWalletTRON + "/#/transaction/" + selectedItem.txnID} target="_blank" rel="noreferrer"  */}
                                                <b className="address-trunc" title={selectedItem.memo}>{selectedItem.memo}</b>
                                                &nbsp;
                                                <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedItem.memo)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>
                                            </div>
                                        </div>
                                    }

                                    {selectedItem.txnID && selectedItem.type === 2 &&
                                        <div className="info pt-2">
                                            <a href={configs.DetailWalletTON + "/transaction/" + selectedItem.txnID} target="_blank" rel="noreferrer" title={t("View in Blockchain Explorer")}>{t("View in Blockchain Explorer")}</a>

                                        </div>
                                    }
                                    {!selectedItem.txnID && selectedItem.to && selectedItem.type === 2 &&
                                        <div className="info  pt-2">
                                            <a href={configs.DetailWalletTON + "/" + selectedItem.to + "?section=transactions"} target="_blank" rel="noreferrer" title={t("View in Blockchain Explorer")}>{t("View in Blockchain Explorer")}</a>
                                        </div>
                                    }
                                    {TypeTransaction === 1 && (selectedItem.type === 5 || selectedItem.type === 11 || selectedItem.type === 14 || selectedItem.type === 15 || selectedItem.type === 20 || selectedItem.type === 21) && <>
                                        <div className="info">
                                            <div className="info  pt-2">
                                                <div className="cs-link" title={t("Show receipt")} onClick={() => this.props.handleshowReceipt(selectedItem.receiptPath)}> <i className="fa fa-image pointer"></i> {t("Show receipt")}</div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>)
                        })()}

                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(RPTTransaction);
