import React from "react";
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter

} from "reactstrap";
import { Dropdown, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken, get } from "services/apiServices";
import { FormatNumberAmount } from "services/common";


const options = [
    { value: "TON", label: 'TON' },
]

const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]

class List_Swap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                Network: null,
                AmountTON: 0,
                AmountBIT: 100,
            },
            intervalFee: null,
            IsInternalTransfer: false,
            modalSwap: false,
            data: [],
            TotalRows: 0,
            params: {
                PageIndex: 1,
                PageSize: 500,
                Username: "",
            },
            selectedUser: null,
            selectedSymbolSwap: "USDT",
            InputType: 2
        }
        this.ListUserRef = React.createRef();
    }

    clearForm = () => {
        let _data = {
            Network: null,
            AmountTON: 0,
            AmountBIT: 100,
        };
        this.setState({ formData: _data });
    };

    handleSwap = async (e) => {
        const { selectedUser, formData, selectedSymbolSwap, InputType } = this.state;
        if (selectedUser) {
            let params = {
                UserID: selectedUser.userID,
                AmountBITSwap: InputType === 1 ? formData.AmountTON : formData.AmountBIT,
                Symbol: selectedSymbolSwap, //Kiểu symbol swap với BIT
                Type: InputType //Type = 1 thì lấy AmountBITSwap là amount TON/USDT, Type = 2 thì lấy AmountBITSwap là amount BIT
            };
            this.setState({ isLoading: true });
            try {
                const response = await post('/Users/SwapTONToBIT', params);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Buy Successfully!"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    this.setState(prevState => ({
                        modalSwap: !prevState.modalSwap
                    }));
                    //get lại info balance
                    this.props.hanldeLoadBasicInfo();
                    //get lại ds user
                    let _params = { ...this.state.params };
                    this.setState((prevState) => ({
                        params: { ...prevState.params, PageIndex: 0 },
                    }));
                    _params.PageIndex = 1;
                    this.props.handleGetList(_params);
                } else {
                    this.setState({ isLoading: false });
                    let message = response.data.errorMessage;
                    if (message && message.includes("Min swap BIT is")) {
                        message = message.replace("Min swap BIT is", t("Min swap BIT is"))
                    } else {
                        message = t(message)
                    }
                    toast.error(message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });

                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    let message = response.data.errorMessage;
                    if (message && message.includes("Min swap BIT is")) {
                        message = message.replace("Min swap BIT is", t("Min swap BIT is"))
                    } else {
                        message = t(message)
                    }
                    toast.error(message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    };

    handleLoadModal = () => {
        this.clearForm()
    }
    handleCloseModal = () => {
        const { intervalFee } = this.state;
        if (intervalFee) {
            clearInterval(intervalFee);
        }
    }

    handleToogleModalList_Swap = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalList_Swap();
        //gọi api get list
        let _params = { ...this.state.params };
        _params.PageIndex = 1;
        this.props.handleGetList(this.state.params);
    };

    handleToogleModalSwap = () => {
        // Xử lý khi người dùng chọn Close
        this.onToogleModalSwap();
    };

    onToogleModalSwap = (item) => {
        const { conditionBITReceive, wallet_BIT } = this.props.info;
        let amountBitDefault = conditionBITReceive - wallet_BIT;
        this.handleChangeAmount(amountBitDefault > 0 ? amountBitDefault : 0, 2, this.state.selectedSymbolSwap);
        this.setState((prevState) => ({ modalSwap: !prevState.modalSwap, selectedUser: item }));
    }
    handleFillMaxAmount = () => {

        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountTON: this.props.Coin.balance ? this.props.Coin.balance : 0 },
        }));
        this.handleChangeAmount(this.props.Coin.balance, 1, this.state.selectedSymbolSwap);
    };
    handleChangeAmount = (value, type, selectedSymbolSwap) => {
        // debugger
        const { prices } = this.props;
        let amountBIT = value;
        let amountTON = value;
        if (selectedSymbolSwap === "TON") {
            let rate = prices.TONUSDT / prices.BITUSDT;
            // console.log("rate TON/BIT", rate);
            if (type === 1) { //Nhập TON
                amountBIT = Number(amountTON * rate).toFixed(3);
            } else if (type === 2) {//Nhập BIT
                amountTON = Number(amountBIT / rate).toFixed(3);
            }
        } else {//USDT
            if (type === 1) { //Nhập TON
                amountBIT = Number(amountTON / prices.BITUSDT).toFixed(3);
            } else if (type === 2) {//Nhập BIT
                amountTON = Number(amountBIT * prices.BITUSDT).toFixed(3);
            }
        }
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountTON: amountTON, AmountBIT: amountBIT },
        }));
        this.setState({ InputType: type })
    };


    componentDidUpdate(prevProps) {
        // Kiểm tra xem props có thay đổi không
        if (JSON.stringify(this.props.prices) !== JSON.stringify(prevProps.prices)) {
            // console.log("this.props.prices", this.props.prices)
            this.handleChangeAmount(this.state.InputType === 1 ? this.state.formData.AmountTON : this.state.formData.AmountBIT, this.state.InputType, this.state.selectedSymbolSwap);
        }
    }
    handleSearchInput = async () => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetList(_params);
    };

    handlePageChange = (pageNumber) => {
        // Xử lý sự kiện chuyển trang ở đây
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: pageNumber.selected },
        }));
        let _params = { ...this.state.params };
        _params.PageIndex = pageNumber.selected + 1;
        this.props.handleGetList(_params)
    };

    handleChangeSize = (e) => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageSize: e.value },
        }));
        _params.PageSize = e.value;
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetList(_params)
    };
    handleSearchChange = (e) => {
        this.setState((prevState) => ({
            params: { ...prevState.params, Username: e.target.value },
        }));
    };
    hanldeEnter = (e) => {
        var key = e.keyCode || e.which;
        e.preventDefault();
        if (key === 13) {
            this.handleSearchInput();
        }
        return false;
    }
    handleScroll = () => {
        const refContainer = this.ListUserRef.current;
        if (refContainer) {
            const { scrollTop, scrollHeight, clientHeight } = refContainer;
            if (scrollHeight - scrollTop === clientHeight) {
                this.loadMoreData();
            }
        }
    };
    loadMoreData = () => {
        const {
            params
        } = this.state;
        let nextPage = params.PageIndex + 1;
        let maxPage = Math.ceil(this.props.TotalRows / params.PageSize);
        if (nextPage <= maxPage) {
            this.setState((prevState) => ({
                params: { ...prevState.params, PageIndex: nextPage },
            }));
            let _params = {
                Username: params.Username,
                PageIndex: nextPage,
                PageSize: params.PageSize,
            }
            this.props.handleGetList(_params, false)
        }
    };
    handleOpenModalListSwap = () => {
        document.body.classList.add('bg-wallet');
        this.ListUserRef.current.addEventListener("scroll", this.handleScroll);
    }
    handleCloseModalListSwap = () => {
        document.body.classList.remove('bg-wallet');
        this.ListUserRef.current.removeEventListener("scroll", this.handleScroll);
    }
    hanldeChangeSymbolSwap = (symbol) => {
        this.setState({ selectedSymbolSwap: symbol });
        this.handleChangeAmount(this.state.formData.AmountBIT, 2, symbol);
    }
    render() {
        const { modalList_Swap, Coin, data, TotalRows, prices, info } = this.props;
        const { isLoading, formData, errors, params, selectedUser, selectedSymbolSwap } = this.state;
        // console.log("formData", formData)
        return (
            <>
                <Modal toggle={this.handleToogleModalList_Swap} isOpen={modalList_Swap} onOpened={this.handleOpenModalListSwap} onClosed={this.handleCloseModalListSwap} className="user-swap-modal"  >
                    <div className=" modal-header align-items-started d-block">
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalList_Swap}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        <br />
                        <h5 className="title-buy  modal-title">
                            {t("To learn English with AI and receive Bit token Airdrop, you need to HODL at least 100 Bits in your wallet. You can buy Bits, using TON coins, from any of the below sellers:")}
                            <div className="title-prices"> 1 BIT = {FormatNumberAmount(prices.BITUSDT / prices.TONUSDT)} TON = {FormatNumberAmount(prices.BITUSDT)} USDT</div>
                        </h5>

                    </div>
                    <ModalBody className="body-modal" >
                        <div ref={this.ListUserRef} className="cover-list-user">
                            <div className="input-search form-search">
                                <FormGroup >
                                    <InputGroup>
                                        <Input
                                            placeholder={t("Username")}
                                            type="text"
                                            autoComplete="off"
                                            value={params.Username}
                                            onChange={(e) => this.handleSearchChange(e)}
                                            onKeyUp={this.hanldeEnter}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText className="grp-multi-btn">
                                                <span
                                                    className="pointer text-success icon-btn icon-search icon-search-list"
                                                    onClick={this.handleSearchInput}
                                                    title={t("Search")}
                                                >
                                                    <i className="fa fa-search"></i>
                                                </span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="rpt-listswap" >
                                <div className="flex-center header-list">
                                    <div className="flex-center text-item">
                                        <div className="item-header">{t("Username")}</div>
                                        <div className="item-header-1">{t("Available BIT")}</div>
                                    </div>
                                    <div className="item-header-2">{t("Action")}</div>
                                </div>
                                {data && data.map((item, index) => {
                                    return (
                                        <Form role="form" key={index}>
                                            <div className="flex-center cover-list">
                                                <div className="flex-center">
                                                    <div className="item-list">
                                                        {item.username ? (item.username).substring(0, 6) + '...': ''}
                                                    </div>
                                                    <div className="text-center item-list-2">{FormatNumberAmount(item.wallet_BIT)}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text cover-button-buy" onClick={() => this.onToogleModalSwap(item)}>
                                                    <div type="button" title={t("Buy")} className="button-buy">
                                                        {/* <i className="img-icon fa fa-exchange"></i> */}
                                                        {t("Buy")}
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                })}

                                {/* {TotalRows > 0 && params.PageSize > 0 &&
                                    <>
                                        <div className="paganition-custom">
                                            <ReactPaginate
                                                pageCount={Math.ceil(TotalRows / params.PageSize)}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={this.handlePageChange}
                                                forcePage={params.PageIndex}
                                                className="react-paginate"
                                                activeClassName={'active'}
                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                            />
                                            <Select
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                options={sizeOptions}
                                                formatGroupLabel="Size"
                                                placeholder="Size"
                                                isClearable={false}
                                                defaultValue={sizeOptions[0]}
                                                onChange={this.handleChangeSize}
                                                classNamePrefix="pagesize-select"
                                            />
                                        </div>
                                    </>
                                } */}
                            </div>
                        </div>
                    </ModalBody>
                    {/* <ModalFooter className="footer-modal">
                        <div onClick={this.handleToogleModalList_Swap}>close</div>
                    </ModalFooter> */}
                </Modal>

                <Modal toggle={this.handleToogleModalSwap} isOpen={this.state.modalSwap} >
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Buy")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalSwap}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup className="mb-4">
                                <div className="d-flex align-items-center">
                                    <img
                                        className="img-icon-swap"
                                        src={require("assets/img/TON.png")}
                                    />
                                    <label htmlFor="Amount">{t("You pay")}</label>
                                    <small className="ml-auto txt-small text-main-color"><span className="label-max">{t("Max")}</span>: {selectedSymbolSwap === "TON" ? (FormatNumberAmount(info.wallet_TON ? info.wallet_TON : 0)) + " TON" : (FormatNumberAmount(info.balance_USDT ? info.balance_USDT : 0) + " USDT")} </small>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <div>
                                        <Input
                                            type="number"
                                            value={formData.AmountTON}
                                            onChange={(e) => this.handleChangeAmount(e.target.value, 1, selectedSymbolSwap)}
                                            className="borderless-input"
                                        /></div>
                                    <div className="unit-price">
                                        <Dropdown className="custom-dropdown-symbol">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {selectedSymbolSwap}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => this.hanldeChangeSymbolSwap("TON")} > {t("TON")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.hanldeChangeSymbolSwap("USDT")} > {t("USDT")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>
                            </FormGroup>
                            <div className="cover-icon">
                                <div className="icon-arrow">
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </div>
                            </div>

                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <img
                                        className="img-icon-swap"
                                        src={require("assets/img/BIT.png")}
                                    />
                                    <label htmlFor="Amount">{t("You receive")}</label>
                                    <small className="ml-auto txt-small text-main-color">
                                        {t("from")} {(selectedUser && selectedUser.username && selectedUser.username.length > 6)
                                            ? selectedUser.username.substring(0, 6) + '...'
                                            : (selectedUser && selectedUser.username) || t("noname")}
                                    </small>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    {/* <div>{formData.AmountBIT}</div> */}
                                    <Input
                                        type="number"
                                        value={formData.AmountBIT}
                                        onChange={(e) => this.handleChangeAmount(e.target.value, 2, selectedSymbolSwap)}
                                        className="borderless-input"
                                    />
                                    <div className="unit-price">{t("BIT")}</div>
                                </div>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="btn-confirm" onClick={this.handleSwap}>
                        <div>{t("Confirm & Buy")}</div>
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(List_Swap);
