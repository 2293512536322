import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    FormGroup,
    // Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken } from "services/apiServices";
import { FormatNumberAmount, FormatNumberNTC } from "services/common";

const options = [
    { value: "TON", label: 'TON' },
    // { value: "BEP20", label: 'BNB Smart Chain (BEP20)' },
]

class Send extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                AmountTON: 0,
                AmountUSDT: 0
            },
            errors: {
                Network: '',
                AmountUSDT: '',
            },
            intervalFee: null,
            IsInternalTransfer: false,
            InputType: 2
        }
        this.inputRef = React.createRef();
    }
    clearForm = () => {
        let newerrors = {
            AmountTON: '',
            AmountUSDT: '',
        };
        let _data = {
            AmountTON: 0,
            AmountUSDT: 0,
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleSwapTONtoUSDT = async (e) => {
        const { formData, InputType } = this.state;
        let formIsValid = true;
        const newErrors = {
            AmountUSDT: "",
        };
        if (!formData.AmountUSDT) {
            formIsValid = false;
            newErrors.AmountUSDT = t('Please enter your Amount USDT.');
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Symbol: InputType === 1 ? "TON" : "USDT",
                Amount: InputType === 1 ? Number(formData.AmountTON) : Number(formData.AmountUSDT),
            };
            this.props.handleSwapTONtoUSDT(params);
            this.clearForm();
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handlePasteToInput = (type) => {
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AddressOrEmail: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };

    handleToogleModalSwap = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalSwap();
    };

    handleFillMaxAmount = () => {

        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountTON: this.props.Coin.balance ? this.props.Coin.balance : 0 },
        }));
        this.handleChangeAmount(this.props.Coin.balance, 1);
    };

    handleChangeAmount = (value, type) => {
        const { prices } = this.props;
        let rate = Number(prices.TONUSDT);
        let amountTON = value ? value : undefined;
        let amountUSDT = value ? value : undefined;
        if (type === 1) { //Nhập TON
            amountUSDT = (Number(amountTON) * rate * (1 - prices.SwapTONToUSDTFee)).toFixed(3);
        } else if (type === 2 && amountUSDT) {//Nhập USDT
            amountTON = (Number(amountUSDT) / (rate * (1 - prices.SwapTONToUSDTFee))).toFixed(3);
        }
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountTON: amountTON, AmountUSDT: amountUSDT },
        }));
        this.setState({ InputType: type })
    };

    handleLoadModal = () => {
        this.clearForm()
    }
    handleCloseModal = () => {
        const { intervalFee } = this.state;
        if (intervalFee) {
            clearInterval(intervalFee);
        }
    }
    componentDidUpdate(prevProps) {
        // Kiểm tra xem props có thay đổi không
        if (this.props.modalSwap !== prevProps.modalSwap) {
            this.clearForm()
        }
        if (this.props.prices !== prevProps.prices && this.props.modalSwap) {
            this.handleChangeAmount(this.state.InputType === 1 ? this.state.formData.AmountTON : this.state.formData.AmountUSDT, this.state.InputType);
        }
    }
    render() {
        const { modalSwap, info } = this.props;
        const { isLoading, formData, errors } = this.state;

        return (
            <>
                <Modal toggle={this.handleToogleModalSwap} isOpen={modalSwap} >
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Swap")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalSwap}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup className="mb-4">
                                <div className="d-flex align-items-center">
                                    <img
                                        className="img-icon-swap"
                                        src={require("assets/img/TON.png")}
                                    />
                                    <label htmlFor="Amount">{t("You pay")}</label>
                                    <small className="ml-auto txt-small text-main-color"><span className="label-max">{t("Max")}</span>: {FormatNumberAmount(info.wallet_TON ? info.wallet_TON : 0)} TON</small>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <Input
                                        type="number"
                                        value={formData.AmountTON}
                                        onChange={(e) => this.handleChangeAmount(e.target.value, 1)}
                                        className="borderless-input"
                                    />
                                    {/* <div>{FormatNumberNTC(formData.AmountTON)}</div> */}
                                    <div className="unit-price">{t("TON")}</div>
                                </div>
                            </FormGroup>
                            <div className="cover-icon">
                                <div className="icon-arrow">
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <img
                                        className="img-icon-swap"
                                        src={require("assets/img/USDT.png")}
                                    />
                                    <label htmlFor="Amount">{t("You receive")}</label>
                                    {/* <small className="ml-auto txt-small text-main-color">
                                        {t("from")} {(selectedUser && selectedUser.username && selectedUser.username.length > 6)
                                            ? selectedUser.username.substring(0, 6) + '...'
                                            : (selectedUser && selectedUser.username) || t("noname")}
                                    </small> */}
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <Input
                                        type="number"
                                        value={formData.AmountUSDT}
                                        onChange={(e) => this.handleChangeAmount(e.target.value, 2)}
                                        className="borderless-input"
                                    />
                                    <div className="unit-price">{t("USDT")}</div>
                                </div>
                            </FormGroup>
                            {errors.AmountUSDT && <div className="error">{errors.AmountUSDT}</div>}
                        </Form>
                    </ModalBody>
                    <ModalFooter className="btn-confirm" onClick={this.handleSwapTONtoUSDT}>
                        <div>{t("Swap")}</div>
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(Send);
