import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/common/withRouter";
import { getNoAuth, redirectToURL } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import i18n from "i18next";
import Loading from "../../components/Loading/Loading";

class LoginByTelegram extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      userData: null
    };
  }

  async componentDidMount() {
    // Lắng nghe các sự kiện
    localStorage.clear();
    if (this.props.params.token) {
      try {
        let params = {
          "ShortToken": this.props.params.token
        };
        const response = await getNoAuth('/Users/GetToken', params);
        if (response.data.errorCode === 0) {
          // debugger
          this.setState({ isLoading: false });
          localStorage.setItem("token", response.data.data.token)
          // localStorage.setItem("userID", response.data.data.userID)
          localStorage.setItem("userId", response.data.data.userID)
          // localStorage.setItem("chatid", response.data.data.chatid)
          // localStorage.setItem("role", response.data.data.userRoleText)
          redirectToURL('/')
        } else {
          this.setState({ isLoading: false });
          redirectToURL("/error")
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        if (response && response.data && response.data.errorCode === 503) {
          redirectToURL("/um")
        } else {
          redirectToURL("/error")
        }
      }
    } else {
      redirectToURL("/error")
    }
  }

  render() {
    const { t, isLoading } = this.props;
    return (
      <>
        <main ref="main" className="outside">
          <section className="section section-shaped section-lg">
          </section>
        </main>
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(withRouter(LoginByTelegram));
