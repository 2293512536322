import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken } from "services/apiServices";
import { validateEmail, cutNumberFormat, FormatNumberAmount } from "services/common";
import QRCode from "react-qr-code";
import configs from "configs/configs";

const options = [
    { value: "TON", label: 'TON' },
    // { value: "BEP20", label: 'BNB Smart Chain (BEP20)' },
]

class Send extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                Network: null,
                AddressOrEmail: '',
                Amount: 0,
                ReceiveAmount: "",
                FeeAmount: 0,
                GasFee: 0,
                Memo: "",
                GACode: ""
            },
            errors: {
                Network: '',
                AddressOrEmail: '',
                Amount: '',
                FeeAmount: '',
                GACode:""
            },
            intervalFee: null,
            IsInternalTransfer: false,
            showPolicyModal: false,
            Symbol: this.props.Coin ? this.props.Coin.symbol : "",
            TwoFA: localStorage.getItem("2FA") === "true" ? true : false,
            modal2FAOpen: false,
            qrsecrectImg: "https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth://totp/124883?secret=XL47V2BD3DAVY3WB&issuer=BitLearing",
            qrsecrect: "",
            qrcode: "",
            qrcodeErr: "",
        }
        this.inputRef = React.createRef();
    }
    clearForm = () => {
        let newerrors = {
            Network: '',
            AddressOrEmail: '',
            Amount: '',
            FeeAmount: '',
            GACode: '',
        };
        let _data = {
            Network: null,
            AddressOrEmail: '',
            Amount: 0,
            ReceiveAmount: "",
            FeeAmount: 0,
            GasFee: 0,
            Memo: "",
            GACode: '',
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleSend = async (e) => {
        const { formData } = this.state;
        let formIsValid = true;
        const newErrors = {
            AddressOrEmail: '',
            Amount: '',
            GACode: '',
        };
        if (!formData.AddressOrEmail) {
            formIsValid = false;
            newErrors.AddressOrEmail = t('Please enter your Address.');
        }
        if (!formData.Amount) {
            formIsValid = false;
            newErrors.ToAddress = t('Please enter your Withdraw Amount.');
        }
        if (formData.Amount <= 0) {
            formIsValid = false;
            newErrors.Amount = t('Input value must be greater than 0.');
        }
        if (!formData.GACode) {
            formIsValid = false;
            newErrors.GACode = t('Enter Google Authenticator Code');
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Symbol: this.props.Coin ? this.props.Coin.symbol : "",
                Address: formData.AddressOrEmail,
                Amount: Number(formData.Amount),
                Memo: formData.Memo,
                GACode: formData.GACode
            };
            this.props.handleSend(params);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handlePasteToInput = (type) => {
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AddressOrEmail: pastedText },
                        }));
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, Memo: pastedText },
                        }));
                    }
                    else if (type === 3) {
                        this.setState({qrcode:pastedText });
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };

    handleToogleModalSend = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalSend();
    };

    onTooglePolicyModal = () => {
        this.setState((prevState) => ({ showPolicyModal: !prevState.showPolicyModal }));
    }

    handleFillMaxAmount = () => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: this.props.Coin.balance ? this.props.Coin.balance.toFixed(3) : 0 },
        }));
        this.handleChangeAmount(this.props.Coin.balance ? this.props.Coin.balance.toFixed(3) : 0, this.state.formData.FeeAmount);
    };
    checkValidateAddress = (address) => {
        if (/^(T)([a-km-zA-HJ-NP-Z0-9]){33}$/.test(String(address))) {
            return "TRC20";
        }
        else if (/^0x[0-9a-fA-F]{40}$/.test(String(address))) {
            return "BEP20";
        }
        return "";
    }
    handleCheckAddress = (value, IsInternalTransfer) => {
        const { intervalFee, formData } = this.state;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, AddressOrEmail: value },
        }));
        let network = this.checkValidateAddress(value);
        let select_option = options.find(x => x.value === network);
        if (select_option) {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: select_option },
            }));
        } else {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: null },
            }));
        }
        if (!IsInternalTransfer) {
            if (select_option) {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.handleGetFee(network);
                let _intervalFee = setInterval(() => {
                    this.handleGetFee(network);
                }, 5000);
                this.setState({ intervalFee: _intervalFee });
            } else {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.setState((prevState) => ({
                    formData: { ...prevState.formData, FeeAmount: 0 },
                }));
                this.handleChangeAmount(formData.Amount, 0)
            }
        } else {
            if (intervalFee) {
                clearInterval(intervalFee);
            }
            this.setState((prevState) => ({
                formData: { ...prevState.formData, FeeAmount: 0 },
            }));
            this.handleChangeAmount(formData.Amount, 0)
        }
        let errors = ""
        if (!formData.AddressOrEmail) {
            errors = t('Please enter your Address.');
        }
        if (!validateEmail(formData.AddressOrEmail) && !select_option) {
            errors = t('Please enter your address or a valid email.');
        }
        if (!IsInternalTransfer && validateEmail(formData.AddressOrEmail)) {
            errors = t('Email does not exist in the system.');
        }
        this.setState((prevState) => ({
            errors: { ...prevState.errors, AddressOrEmail: errors },
        }));
    };
    handleChangeAmount = (value, fee) => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: value },
        }));
        let amountReceive = cutNumberFormat(value - fee, 0.01);
        this.setState((prevState) => ({
            formData: { ...prevState.formData, ReceiveAmount: amountReceive },
        }));
        this.handleCalcGasFee(this.state.IsInternalTransfer, value);
    };

    handleCheckInternal = async (value) => {
        try {
            let response = await post('/Users/CheckInternalTransfer', { AddressOrEmail: value });
            if (response.data.errorCode === 0) {
                this.setState({ IsInternalTransfer: response.data.data.isInternalTransfer });
                // this.handleCheckAddress(value, response.data.data.isInternalTransfer);
                this.handleCalcGasFee(response.data.data.isInternalTransfer, this.state.formData.Amount);
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    handleLoadModal = () => {
        this.clearForm()
    }
    handleCloseModal = () => {
        const { intervalFee } = this.state;
        if (intervalFee) {
            clearInterval(intervalFee);
        }
    }
    handleCalcGasFee = (isInternal, amount) => {
        let gasFee = 0;
        let Amount = Number(amount);
        if (isInternal) {
            if (this.props.Coin.symbol === "BIT" && this.props.info.isFreeGasFee === false) {
                if (Amount > this.props.info.settingMaxAmountBITWithdraw) {
                    gasFee = ((Amount / 100) * this.props.info.settingMinGasFee);// / this.props.prices.TONUSDT;
                }
                else {
                    gasFee = this.props.info.settingMinGasFee;// / this.props.prices.TONUSDT;
                }
            }
        }
        else {
            if (this.props.Coin.symbol === "BIT") {
                if (Amount > this.props.info.settingMaxAmountBITWithdraw) {
                    gasFee = ((Amount / 100) * this.props.info.settingMinGasFee);// / this.props.prices.TONUSDT;
                }
                else {
                    gasFee = this.props.info.settingMinGasFee;// / this.props.prices.TONUSDT;
                }
            }
            else if (this.props.Coin.symbol === "TON") {
                gasFee = this.props.info.settingFeeWithdrawTON;
            }
            else if (this.props.Coin.symbol === "USDT") {
                gasFee = this.props.info.settingFeeWithdrawUSDT;
            }
        }
        this.setState((prevState) => ({
            formData: { ...prevState.formData, GasFee: gasFee },
        }));
    }
    handleChangeQRCode = (e) => {
        const { value } = e.target;
        this.setState({ qrcode: value });
    };
    handleGetGACode = async (e) => {
        const { info } = this.props;
        this.setState({ modal2FAOpen: true })
        this.setState({ qrcode: "" })
        this.setState({ qrcodeErr: "" })
        if (!info.twoFA) {
            this.setState({ isLoading: true });
            try {
                let response = await post('/Users/GoogleAuth');
                if (response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    // let otpAuthURL = "otpauth://totp/" + localStorage.getItem("userId") + "?secret=" + response.data.data.secretKey;
                    let otpAuthURL = "otpauth://totp/" + `${configs.SiteName} (${info.email ? info.email : info.telegramInfo}) ` + "?secret=" + response.data.data.secretKey;
                    this.setState({ qrsecrectImg: otpAuthURL })
                    this.setState({ qrsecrect: response.data.data.secretKey })
                   
                 
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    };
    handleGetOTP = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/GetOTP', { Type: 3 });
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t(response.data.data.Notify), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleSetTwoFA = async (e) => {
        this.setState({ qrcodeErr: "" });
        const { info } = this.props;
        if (!this.state.qrcode) {
            this.setState({ qrcodeErr: t("Please enter your Code.") });
            return;
        }
        let _params = {
            "Enable2FA": !info.twoFA,
            "GACode": this.state.qrcode
        }
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/SetTwoFA', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success((_params.Enable2FA ? t("Enable") : t("Disable")) + " " + t("Two-Factor Authentication").toLowerCase() + " " + t("successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                localStorage.setItem("2FA", _params.Enable2FA);
                this.props.hanldeLoadBasicInfo();
                this.setState({ qrcode: "", modal2FAOpen: false });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleReset2FA = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/RequestReset2FA',);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ qrcode: "", modal2FAOpen: !this.state.modal2FAOpen });
                var _params = {
                    Status: 0,
                    PageIndex: 1,
                    PageSize: 10
                }
                this.handleGetList(_params);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
   
    render() {
        const { modalSend, Coin, info } = this.props;
        const { isLoading, formData, errors, showPolicyModal, modal2FAOpen, qrcode, qrsecrectImg, qrcodeErr } = this.state;
        const TwoFA = info ? info.twoFA : false;
        return (
            <>
                <Modal toggle={this.handleToogleModalSend} isOpen={modalSend} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal}>
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Send")} {Coin ? Coin.symbolName : "Coin"}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalSend}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="body-modal">
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="AddressOrEmail">{t("Address")}</label>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Address")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="AddressOrEmail"
                                        value={formData.AddressOrEmail}
                                        onChange={(e) => this.handleChange(e)}
                                        ref={this.inputRef}
                                        onBlur={(e) => this.handleCheckInternal(e.target.value)}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className="unit-input">
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(1)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AddressOrEmail && <div className="error">{errors.AddressOrEmail}</div>}
                            </FormGroup>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="Amount">{t("Amount")}</label>
                                    <small className="ml-auto txt-small text-main-color">{t("Available")}: {FormatNumberAmount(Coin ? Coin.balance : 0)} {Coin ? Coin.symbolName : "Coin"}</small>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder="0"
                                        type="number"
                                        autoComplete="off"
                                        name="Amount" value={formData.Amount} onChange={(e) => this.handleChangeAmount(e.target.value, formData.FeeAmount)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="unit-input">
                                            <span className=" pointer" onClick={this.handleFillMaxAmount}>&nbsp;MAX</span> &nbsp;| &nbsp;{Coin ? Coin.symbolName : "Coin"}&nbsp;
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.Amount && <div className="error">{errors.Amount}</div>}
                            </FormGroup>
                            <FormGroup>
                                {this.props.Coin && this.props.Coin.symbol === "BIT" ? (
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="GasFee">{t("Gas Fee")}</label>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="GasFee">{t("Fee")}</label>
                                    </div>
                                )}
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder="0"
                                        autoComplete="off"
                                        name="GasFee"
                                        value={FormatNumberAmount(formData.GasFee)}
                                        className="input-amount"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className="unit-input">
                                            {this.props.Coin && this.props.Coin.symbol === "TON" ? (
                                                <span className="pointer">&nbsp;TON</span>
                                            ) : (
                                                <span className="pointer">&nbsp;USDT</span>
                                            )} &nbsp;|&nbsp;
                                            <span className="flex-center pointer icon-question" onClick={this.onTooglePolicyModal}><i className="fa fa-question"></i></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.GasFee && <div className="error">{errors.GasFee}</div>}
                            </FormGroup>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="Memo">{t("Memo")}</label>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Memo")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="Memo"
                                        value={formData.Memo}
                                        onChange={(e) => this.handleChange(e)}
                                        ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className="unit-input">
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(2)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="GACode">{t("Google Authenticator Code")}</label>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Google Authenticator Code")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="GACode"
                                        value={formData.GACode}
                                        onChange={(e) => this.handleChange(e)}
                                        ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className="unit-input">
                                            <div title={`${(TwoFA ? t("Disable") : t("Enable"))} ${t("Two-Factor Authentication").toLowerCase()}`} className=" cover-2fa">
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="TwoFA"
                                                    checked={TwoFA}//
                                                    onChange={this.handleGetGACode}
                                                />
                                            </div>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.GACode && <div className="error">{errors.GACode}</div>}
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="btn-confirm" onClick={this.handleSend}>
                        <div>{t("Send")}</div>
                    </ModalFooter>
                </Modal>
                <Modal toggle={this.onTooglePolicyModal} isOpen={showPolicyModal} onClosed={this.loadGasFee}>
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Policy")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onTooglePolicyModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <div>
                            <p>- {t("Gas Fee is the fee for converting Bit tokens, calculated in USDT equivalent to 10% of the number of Bit tokens moved,with a minimum of 20 USDT.")}</p>
                            <p>- {t("The total gas fee collected will be allocated into scholarships of $200, $300, and $500 mixed into English sentences to randomly reward active learning members.")}</p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
                <Modal toggle={() => this.setState({ modal2FAOpen: !modal2FAOpen })} isOpen={modal2FAOpen}>
                    <div className=" modal-header">
                        <h5 className=" modal-title" id="exampleModalLabel">
                            {(TwoFA ? t("Disable") : t("Enable"))} {t("Two-Factor Authentication").toLowerCase()}
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ modal2FAOpen: !modal2FAOpen })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="body-modal">
                        {!TwoFA && <>
                            <div className="bold text-center mb-2">{t("Scan this QR and enter the 2FA code from your Google Authenticator App to confirm")}</div>
                            <div className="text-center">
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                            <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={qrsecrectImg}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                            
                                <br />
                                <br />
                                <Button color="light" className="btn-icon btn-copy" onClick={() => this.handleCopyClick(this.state.qrsecrect)}><span className="icon-text">{this.state.qrsecrect} <i className="ni ni-single-copy-04"></i></span></Button>&nbsp;
                            </div>

                            <FormGroup>
                                <InputGroup className="input-group mt-4">
                                    <Input
                                        placeholder={t("Enter Google Authenticator Code")}
                                        type="text"
                                        autoComplete="off"
                                        name="qrcode" value={qrcode} onChange={this.handleChangeQRCode} ref={this.inputRef1}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} onClick={(e) => this.handlePasteToInput(3)}>
                                        <InputGroupText>
                                            <i className="fa fa-paste"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {qrcodeErr && <div className="error">{qrcodeErr}</div>}
                            </FormGroup>
                        </>}
                        {TwoFA && <>
                            <div className="bold mb-2">{t("Enter your code in Google Authenticator App")}</div>
                            <FormGroup>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={t("Google Authenticator Code")}
                                        type="text"
                                        autoComplete="off"
                                        name="qrcode" value={qrcode} onChange={this.handleChangeQRCode} ref={this.inputRef1}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-sm pointer" onClick={(e) => this.handlePasteToInput(3)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText> 
                                    </InputGroupAddon>
                                </InputGroup>
                                {qrcodeErr && <div className="error">{qrcodeErr}</div>}
                            </FormGroup>
                            <div>{t("Click")} <span className="pointer text-underline text-highlight" onClick={this.handleReset2FA}>{t("here")}</span> {t("to reset your two-factor authentication")}</div>
                        </>}
                    </ModalBody>
                    <ModalFooter className={!TwoFA ? "btn-confirm" : "btn-cancel-modal"} onClick={this.handleSetTwoFA}>
                        {t(!TwoFA ? "Enable" : "Disable")}
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(Send);
