import React from "react";
import { postNoAuth, redirectToURL } from "services/apiServices";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import i18n from "i18next";
import Loading from "../../components/Loading/Loading"
import configs from "configs/configs";
import { io } from "socket.io-client";
class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
      },
      errors: {
        email: '',
      },
      isLoading: false,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    };
    this.socket = io(configs.SocketUM);
  }
  componentDidMount() {
    localStorage.setItem("token", "")
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });

    this.socket.on("UM", (msg) => {
      // console.log("Received message:", msg);
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
  }

  componentWillUnmount() {
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { t } = this.props;
    const { formData } = this.state;
    let formIsValid = true;
    const newErrors = {
      email: '',
    };
    if (!formData.email) {
      formIsValid = false;
      newErrors.email = t('Please enter your email.');
    }
    // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn

    if (!formIsValid) {
      this.setState({ errors: newErrors });
    } else {
      this.setState({ errors: newErrors });
      // Submit form nếu hợp lệ
      this.setState({ isLoading: true });
      try {
        //const response = await get('/users?page=1');
        let params = {
          "Email": formData.email,
        };
        const response = await postNoAuth('/Users/ForgotPassword', params);
        if (response.data.errorCode === 0) {
          this.setState({ isLoading: false });
          toast.success(t(response.data.data.notify), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            onClose: () => redirectToURL('/e-login')
          });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        });
      }
    }
  };
  changeLanguage = (lang) => {
    this.setState({ lang: lang });
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  }
  render() {
    const { lang, formData, errors, isLoading } = this.state;
    const { t } = this.props;
    const lang_change = lang === "en" ? "vi" : "en";
    return (
      <>
        <main ref="main" className="outside login">
          <section className="section section-shaped section-lg">
            {/* <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div> */}
            <Container >
              <Row className="justify-content-center">
                <Col lg="6" className="flex-center-center" >
                  <div className="bg-login">
                    <div className="px-lg-5 py-lg-5">
                      <div className="text-muted mb-3 mt-6 title-outside">
                        {/* <img
                          className="icon-lang absolute-lang pointer"
                          alt={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          title={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          src={require(`assets/img/flags/${lang_change}.png`)}
                          onClick={() => this.changeLanguage(lang_change)}
                        /> */}
                        <div className="txt-title">{t("Forgot Password")}</div>
                        <div className="small-title mb-3">{t("Enter your email and we'll send you a reset.")}</div>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-4 mt-4 form-outside">
                          <Input placeholder="Email" className="input-outside" name="email" value={formData.email} onChange={this.handleChange} />
                          {errors.email && <div className="error">{errors.email}</div>}
                        </FormGroup>
                        <div>
                          <Button
                            className="button-outside"
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            {t("Submit")}
                          </Button>
                        </div>
                      </Form>
                      <Row className="mt-3">
                        <Col xs="12" className="text-center">
                          <a
                            className="text-login-cover"
                            href="/e-login"
                          >
                            <small>{t("Already have an account?")} <span className="text-login">{t("Sign in")}</span></small>
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </div>

                </Col>
                <Col lg="6" className="flex-center-center item-login">
                 <img src={require("assets/img/item-login.png")}  />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <ToastContainer pauseOnFocusLoss={false} />
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(ForgotPassword);
