import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
class ModalConfirm extends Component {
    handleConfirm = () => {
        // Xử lý khi người dùng chọn OK
        this.props.onConfirm();
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onCancel();
    };

    render() {
        const { message, open, title } = this.props;

        return (
            <Modal isOpen={open} toggle={this.handleCancel} >
                <div className=" modal-header">
                    <h5 className=" modal-title" id="Confirmation">
                        {title ? t(title) : t("Confirmation")}
                    </h5>
                    <button
                        aria-label="Close"
                        className=" close"
                        type="button"
                        onClick={this.handleCancel}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter className="btn-confirm" onClick={this.handleConfirm} >
                    {/* <Button color="secondary" type="button" onClick={this.handleCancel}>
                        {t("Cancel")}
                    </Button> */}
                    {t("OK")}
                </ModalFooter>
            </Modal>
        );
    }
}
export default withTranslation()(ModalConfirm)