import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken } from "services/apiServices";
import { cutNumberFormat, FormatNumberUSDT, formatNumber } from "services/common";
import configs from "configs/configs";
import { io } from "socket.io-client";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
class SellUSDT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                AmountUSDT: "",
                PriceVND: 0,
                AmountVND: 0,
                OTP: '',
                GACode: '',
                NameBank: '',
                AccNumberBank: '',
                CardholderNameBank: '',
            },
            errors: {
                AmountUSDT: '',
                AmountVND: '',
                OTP: '',
                GACode: '',
                NameBank: '',
                AccNumberBank: '',
                CardholderNameBank: ''
            },
            intervalFee: null,
            IsInternalTransfer: false,
            showConfirm: false,
            confirmText: "",
            confirmTitle: "",
            currentType: 0
        }
        this.inputRef = React.createRef();
        this.socket = io(configs.SocketPrice);
    }
    componentDidMount() {

    }

    componentWillUnmount() {
        // Ngắt kết nối khi component bị hủy
        this.socket.disconnect();
    }
    clearForm = () => {
        let newerrors = {
            AmountUSDT: '',
            AmountVND: '',
            OTP: '',
            GACode: '',
            NameBank: '',
            AccNumberBank: '',
            CardholderNameBank: '',
        };
        const { bankInfo, currentTicket } = this.props;
        this.socket.disconnect();
        if (!currentTicket) {
            this.socket = io(configs.SocketPrice);
            this.socket.on("connect", () => {
                // console.log("Price Connected");
            });
            this.socket.on("VNDUSDT", (msg) => {
                if (msg.priceSell) {
                    let _price = msg.priceSell ? Number(msg.priceSell) : 0;
                    this.setState((prevState) => ({
                        formData: { ...prevState.formData, PriceVND: _price },
                    }));
                    this.handleChangeAmount(this.state.formData.AmountUSDT, _price)
                }
            });
        }
        let _data = {
            NameBank: bankInfo ? bankInfo.nameBank : "",
            AccNumberBank: bankInfo ? bankInfo.accNumberBank : "",
            CardholderNameBank: bankInfo ? bankInfo.cardholderNameBank : "",
            AmountUSDT: currentTicket ? currentTicket.amountUSDT : "",
            PriceVND: currentTicket ? currentTicket.priceVND : 0,
            AmountVND: currentTicket ? formatNumber(currentTicket.amountVND) : 0,
            OTP: '',
            GACode: ''
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleSellUSDT = async (e) => {
        const { formData } = this.state;
        let formIsValid = true;
        const newErrors = {
            AmountUSDT: "",
            // AmountVND: '',
            OTP: '',
            GACode: '',
            NameBank: '',
            AccNumberBank: '',
            CardholderNameBank: ''
        };

        if (!formData.AmountUSDT) {
            formIsValid = false;
            newErrors.AmountUSDT = t('Please enter USDT amount.');
        }
        if (formData.Amount <= 0) {
            formIsValid = false;
            newErrors.Amount = t('Input value must be greater than 0.');
        }
        if (this.props.info.twoFA) {
            if (!formData.GACode) {
                formIsValid = false;
                newErrors.GACode = t('Please enter your Code.');
            }
        } else {
            if (!formData.OTP) {
                formIsValid = false;
                newErrors.OTP = t('Please enter OTP.');
            }
        }
        if (!formData.NameBank) {
            formIsValid = false;
            newErrors.NameBank = t('Please enter the bank name.');
        }

        if (!formData.AccNumberBank) {
            formIsValid = false;
            newErrors.AccNumberBank = t('Please enter your account number.');
        }

        if (!formData.CardholderNameBank) {
            formIsValid = false;
            newErrors.CardholderNameBank = t("Please enter the cardholder's name.");
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                AmountUSDT: formData.AmountUSDT ? Number(formData.AmountUSDT) : 0,
                // PriceVND: formData.PriceVND,
                // AmountVND: formData.AmountVND,
                OTP: formData.OTP,
                GACode: formData.GACode,
                NameBank: formData.NameBank,
                AccNumberBank: formData.AccNumberBank,
                CardholderNameBank: formData.CardholderNameBank
            };

            this.props.hanldeSellUSDT(params);
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handlePasteToInput = (type) => {
        if (this.props.currentTicket) {
            return;
        }
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, NameBank: pastedText },
                        }));
                        this.handleCheckInternal(pastedText);
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AccNumberBank: pastedText },
                        }));
                    } else if (type === 3) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, CardholderNameBank: pastedText },
                        }));
                    } else if (type === 4) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, OTP: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };
    handleGetOTP = async () => {
        if (this.props.currentTicket) {
            return;
        }
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/GetOTP', { Type: 2 });
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t(response.data.data.notify), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleToogleModalSellUSDT = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalSellUSDT();
    };
    handleFillMaxAmount = () => {
        if (this.props.currentTicket) {
            return;
        }
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountUSDT: this.props.BalanceUSDT },
        }));
        this.handleChangeAmount(this.props.info.balance, this.state.formData.PriceVND);
    };
    handleChangeAmount = (value, price) => {
        value = value ? Number(value) : "";
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountUSDT: value },
        }));
        let amountReceive = formatNumber(value * price, 0, 0);
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountVND: amountReceive },
        }));
    };
    handleCheckInternal = async (value) => {
        //get socket update price VND
    }
    handleLoadModal = () => {
        this.clearForm();

    }
    handleCloseModal = () => {
        //disconnect socket price
        if (this.socket) {
            this.socket.disconnect();
        }
    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        const { info, currentTicket } = this.props;
        let params = {
            TransID: currentTicket.transID
        };
        this.setState({ isLoading: true });
        let url = "";
        if (this.state.currentType === 1) {
            url = "/Purchase/OTCAccecptedBuySell";
        } else if (this.state.currentType === 2) {
            if (info.otc) {
                url = "/Purchase/OTCConfirmReceiveTransfer"
            }
            else {
                url = "/Purchase/ConfirmReceiveTransfer"
            }
        } else if (this.state.currentType === 6) {
            url = "/Purchase/ReportFakeBillSellUSDT";
        }
        else {
            url = "/Purchase/CancelBuySellUSDT";
        }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                // this.handleToogleModalSellUSDT();
                this.props.handleGetListBuySellPending();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item, type) {
        // Mở modal confirm
        let _confirmText = "";
        let _confirmTitle = "";
        switch (type) {
            case 1: {//OTC Accept
                _confirmText = "Are you sure you want to confirm this transaction?";
                break;
            }
            case 2: {//OTC Confirm received payment
                _confirmText = "I have received payment.";
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 3: {//Seller cancel
                _confirmText = "Are you sure you want to cancel this transaction?";
                _confirmTitle = "Cancel transaction";
                break;
            }
            case 5: {//OTC report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
            case 6: {//User report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
        }
        this.setState({ confirmText: t(_confirmText), confirmTitle: _confirmTitle, currentType: type });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    componentDidUpdate(prevProps) {
        // Close popup when complete transaction
        if (this.props.currentTicket !== prevProps.currentTicket && !this.props.currentTicket && this.props.modalSellUSDTOpen) {
            this.handleToogleModalSellUSDT();
        }
        // // Close popup when disputed transaction
        // if (this.props.currentTicket !== prevProps.currentTicket && this.props.currentTicket && this.props.currentTicket.status >= 5 && this.props.modalSellUSDTOpen) {
        //     this.handleToogleModalSellUSDT();
        // }
        //Open popup when exist transaction
        if (this.props.currentTicket !== prevProps.currentTicket && this.props.currentTicket && this.props.currentTicket.type === 2 && this.props.info && this.props.info.otc === false && !this.props.modalSellUSDTOpen && (this.props.currentTicket.status <= 3 || this.props.currentTicket.status === 8)) {
            this.handleToogleModalSellUSDT();
        }
    }
    checkStatusText = (ticket) => {
        const { info } = this.props;
        let statusText = ticket.statusText;
        if (ticket.type === 1) { //buyer
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for sellers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "The invoice has not been uploaded yet";
                if (info.otc) {
                    statusText = "Waiting for the money transfer";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Waiting for the seller to confirm";
                if (info.otc) {
                    statusText = "Confirmation of receipt of money";
                }
            }
        } else {//seller
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for buyers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "Waiting for the money transfer";
                if (info.otc) {
                    statusText = "The invoice has not been uploaded yet";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Confirmation of receipt of money";
                if (info.otc) {
                    statusText = "Waiting for the seller to confirm";
                }
            }
        }
        return statusText;
    }
    render() {
        const { modalSellUSDTOpen, info, currentTicket } = this.props;
        const { isLoading, formData, errors, confirmData, confirmText, showConfirm, confirmTitle } = this.state;
        return (
            <>
                <Modal toggle={this.handleToogleModalSellUSDT} isOpen={modalSellUSDTOpen} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal} backdrop="static">
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title flex-100 ">
                            {t("Sell USDT")}
                        </h5>
                        {/* <button
                            aria-label={t("Close")}
                            className=" close flex-100 flex-end"
                            type="button"
                            onClick={this.handleToogleModalSellUSDT}
                        >
                            <span aria-hidden={true}>×</span>
                        </button> */}
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="Amount">{t("Quantity")}</label>
                                    <small className="ml-auto txt-small text-main-color">{t("Available Balance")}: {FormatNumberUSDT(info.balance)} USDT</small>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder="0"
                                        type="number"
                                        autoComplete="off"
                                        name="AmountUSDT" value={formData.AmountUSDT} onChange={(e) => this.handleChangeAmount(e.target.value, formData.PriceVND)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className={currentTicket ? "btn-disable" : ""} >
                                            <span className="text-success pointer" onClick={this.handleFillMaxAmount}>&nbsp;{t("MAX")}</span> &nbsp;| &nbsp;USDT&nbsp;
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AmountUSDT && <div className="error">{errors.AmountUSDT}</div>}
                            </FormGroup>
                            {/* <FormGroup >
                                <label>{t("Price")} VND/USDT</label>
                                <Input placeholder='0' type="text" value={formatNumber(formData.PriceVND)} disabled />
                            </FormGroup> */}
                            <FormGroup >
                                <label>{t("Receive Amount")} (VND)</label>
                                <Input placeholder='0' type="text" value={currentTicket ? formatNumber(currentTicket.amountVND) : formData.AmountVND} disabled />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="AccNumberBank">{t("Transfer to account number")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder={`${t("Transfer to account number")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="AccNumberBank" value={formData.AccNumberBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className={currentTicket ? "btn-disable" : ""}>
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(2)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AccNumberBank && <div className="error">{errors.AccNumberBank}</div>}
                                {currentTicket && currentTicket.status === 8 && <div className="error">{t("Bank account information is incorrect.")}</div>}
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="CardholderNameBank">{t("Cardholder name")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder={`${t("Cardholder name")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="CardholderNameBank" value={formData.CardholderNameBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className={currentTicket ? "btn-disable" : ""}>
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(3)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.CardholderNameBank && <div className="error">{errors.CardholderNameBank}</div>}
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="NameBank">{t("Bank name")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder={`${t("Bank name")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="NameBank" value={formData.NameBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText className={currentTicket ? "btn-disable" : ""}>
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(1)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.NameBank && <div className="error">{errors.NameBank}</div>}
                            </FormGroup>
                            {!info.twoFA && !currentTicket && <FormGroup>
                                <label>OTP</label>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder={t("OTP")}
                                        type="text"
                                        autoComplete="off"
                                        name="OTP" value={formData.OTP} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText >
                                            <span className="btn-sm pointer" onClick={() => this.handlePasteToInput(4)} title={t("Paste")}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>&nbsp;
                                            &nbsp;| &nbsp;<span className="btn-sm pointer" onClick={this.handleGetOTP} title={t("Get OTP")}> {t("Get OTP")}</span>&nbsp;
                                            {/* <Button
                                                className="btn-sm"
                                                color="success"
                                                type="button"
                                                onClick={this.handleGetOTP}
                                                title={t("Get OTP")}
                                            >
                                                {t("Get OTP")}
                                            </Button> */}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.OTP && <div className="error">{errors.OTP}</div>}
                            </FormGroup>}
                            {info.twoFA && !currentTicket &&
                                <FormGroup>
                                    <label>{t("Google Authenticator Code")}</label>
                                    <InputGroup className="input-group">
                                        <Input
                                            disabled={currentTicket ? true : false}
                                            placeholder={t("Google Authenticator Code")}
                                            type="text"
                                            autoComplete="off"
                                            name="GACode" value={formData.GACode} onChange={this.handleChange} ref={this.inputRef}
                                        />
                                        <InputGroupAddon addonType="append" title={t("Paste")} >
                                            <InputGroupText >
                                                <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(3)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {errors.GACode && <div className="error">{errors.GACode}</div>}
                                </FormGroup>
                            }
                        </Form>
                        {currentTicket && currentTicket.buySellName && <FormGroup>
                            <label htmlFor="Buyer">{t("Buyer")}</label>
                            <Input
                                disabled={true}
                                placeholder={`${t("Buyer")}`}
                                type="text"
                                autoComplete="off"
                                name="Buyer" value={currentTicket.buySellName}
                            />
                        </FormGroup>}
                        {currentTicket && currentTicket.transferCode && <FormGroup>
                            <div className="d-flex align-items-end">
                                <label htmlFor="transferCode">{t("Transfer content")}</label>
                            </div>
                            <InputGroup className="input-group">
                                <Input
                                    disabled
                                    placeholder=""
                                    type="text"
                                    autoComplete="off"
                                    name="transferCode" value={currentTicket.transferCode}
                                />
                                <InputGroupAddon addonType="append" title={t("Copy")} >
                                    <InputGroupText className="btn-disable" >
                                        <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.transferCode)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>}
                        <div className="bs-time text-center flex-1" >
                            {currentTicket && <div className="text-center">{t("Status")}: {t(this.checkStatusText(currentTicket))}  {currentTicket && currentTicket.receiptPath && <span title={t("Show receipt")} className="text-primary pointer" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                <i className="fa fa-image pointer"></i>
                            </span>}</div>}
                            {currentTicket && (currentTicket.status === 2 || currentTicket.status === 3) && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <div className="text-alert pt-1 text-center flex-1">  {t("Waiting Time")}: {(currentTicket.timeRemain.minutes > 9 ? currentTicket.timeRemain.minutes + "" : "0" + currentTicket.timeRemain.minutes) + ":" + (currentTicket.timeRemain.seconds > 9 ? currentTicket.timeRemain.seconds + "" : "0" + currentTicket.timeRemain.seconds)}</div>}
                            {currentTicket && currentTicket.status === 3 && currentTicket.timeRemainReport && currentTicket.timeRemainReport.total > 0 && <div className="text-alert pt-1"> {(currentTicket.timeRemainReport.minutes > 9 ? currentTicket.timeRemainReport.minutes + "" : "0" + currentTicket.timeRemainReport.minutes) + ":" + (currentTicket.timeRemainReport.seconds > 9 ? currentTicket.timeRemainReport.seconds + "" : "0" + currentTicket.timeRemainReport.seconds)}</div>}
                        </div>
                    </ModalBody>
                    {currentTicket && currentTicket.status !== 2 && <>
                        <ModalFooter>
                            {/*Pending*/}
                            {(currentTicket.status === 1 || currentTicket.status === 8) &&
                                <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 3)}>
                                    {t("Cancel")}
                                </Button>}
                            {/*Accept*/}
                            {currentTicket.status === 3 && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <>
                                <Button color="primary" type="button" onClick={() => this.handleOpenModal(currentTicket, 2)}>
                                    {t("Confirm")}
                                </Button>
                            </>}
                            {currentTicket.status === 3 && currentTicket.timeRemainReport && currentTicket.timeRemainReport.total > 0 && <>
                                {currentTicket.timeRemainReport && <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 6)}>
                                    {t("Report")}
                                </Button>}
                            </>}
                            {currentTicket.status >= 5 && <Button
                                color="secondary"
                                type="button"
                                onClick={this.handleToogleModalSellUSDT}
                            >  {t("Close")}
                            </Button>}
                        </ModalFooter>
                    </>}
                    {!currentTicket && <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalSellUSDT}
                        >  {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.handleSellUSDT}>
                            {t("Sell")}
                        </Button>
                    </ModalFooter>}
                </Modal>
                <ModalConfirm
                    message={confirmText}
                    title={confirmTitle}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(SellUSDT);
