import React, { Fragment, useEffect, useState } from 'react'
import {
    Button,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation } from "react-i18next";
import ReactHowler from 'react-howler';
import { useTranslation } from "react-i18next";
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from 'react-toastify';
import { validateImage } from "image-validator";


const PhraseItem = (props) => {
    const { t } = useTranslation();
    let _listImages = props.item && props.item.images ? JSON.parse(props.item.images) : [];
    const urlValidation = async (phrase, url) => {
        const isValidImage = await validateImage(url);
        if (!isValidImage) {
            // console.log("phrase",);
            // console.log("_listImages", _listImages);
            // if (phrase.phraseID === 1081) {
            //     props.handleUpdateErrorImage(phrase, ["https://planmyaffairs.com/wp-content/uploads/2017/09/Lost-wallet.jpg"]);
            // }
            let images = _listImages.filter(x => x !== url);
            props.handleUpdateErrorImage(phrase, images);
        }
    };

    let curr_img = _listImages && _listImages.length > 0 ? _listImages[0] : null;
    if (curr_img) {
        urlValidation(props.item, curr_img);
    }
    let textpopup= "";
    if(props.popupType===0){
        textpopup=t("In the following sentence. Please continue studying.");
    } else if(props.popupType===1){
        textpopup=t("The scholarship has been sent to your wallet.")
    }else{
        textpopup = props.inviteFriend_phrase > 1 ? t("Invite {{amtf}} friends to study together to receive this scholarship.", { amtf: props.inviteFriend_phrase }) : t("Invite a friend to study together to receive this scholarship")
    }
    return (
        <>  
            {/* Next Pharse */}
            {/* <div className='cover-words cover-nextphrase'>
                <div className='text-notify-1'>{t("The scholarship")}</div>
                <div className='text-notify-2'>{t("May be")}</div>
                <div className='line'></div>
                <div className='text-notify-3'>{t("In the following sentence. Please continue studying.")}</div>
            </div> */}

            {/* Receive 20 50 100 */}
            {/* <div className='cover-words receive20'>
                <div className='text-receive-1'>{t("The scholarship")}</div>
                <div className='text-receive-2'>{t("Congratulation")}</div>
                <div className='line'></div>
                <div className='text-receive-3'>{t("The scholarship has been sent to your wallet.")}</div>
            </div> */}

            {/* Receive FAKE */}
            {props.item.completeListen&&props.popupType>=0&&props.popupType<3&&
            <div className='cover-words receivefake'>
                <div className='text-notify-1'>{t("The scholarship")}</div>
                <div className='text-notify-2'>{props.popupType===0?t("May be"):t("Congratulation")}</div>
                {/* {props.popupType!==0&&<div className='line'></div>}                 */}
                <div className='text-notify-3'>{textpopup}</div>
            </div>}            
            {/*Car */}
            {props.item.completeListen&&props.popupType===3&&
            <div className='cover-words receivefake'>
                <div className='recomended-car-1'>{t("Bonus")}</div>
                <div className='recomended-car-2'>{t("Detail")}...</div>
            </div>}
             {/*Loading */}
            {props.item.completeListen&&props.popupType===-1&&
            <div className='cover-words cover-loading'>
                <div className='loading-notify'>{t("Waiting")}...</div>
            </div>}
            {/* {props.item.haveLearned && <span className="fa fa-check-circle have-learn"></span>} */}
            {!props.isHidePhraseText && !props.item.completeListen && <>
                <div className="word-info">
                    <div className="name" >
                        {props.item.phrase && props.arrWord.map((word, idx) => (
                            <span className="single-word" key={props.index + "w" + idx} onClick={() => props.playSingleWord(word)}> {word}</span>
                        ))}
                        {/* {!props.isChildPhrase && !props.item.isDelete && props.item.phraseType !== 3 && <>
                            <span className="info-contribute" onClick={() => props.toogleModalContribute(2, props.item, 0)}><i className={`fa fa-plus`}></i></span>
                        </>} */}
                        {/* &nbsp;{props.item.hasAnswer && <span className="info-answer" onClick={() => props.openModalAnswer(props.item)}><i className={`fa fa-comments`}></i></span>}
                            {props.item.hasBackup && <span className="info-backup" onClick={() => props.openModalBackup(props.item)}><i className={`fa fa-caret-${props.item.showBackupPhrase ? "up" : "down"}`}></i></span>} */}

                        {/* {props.item.phraseType === 3 ?
                            <Fragment>
                                {props.item.isDelete && <span className="info-contribute text-danger" onClick={() => props.handleOpenModal(props.item, 3)}><i className={`fa fa-trash`}></i></span>}
                            </Fragment>
                            : <Fragment> {props.item.isDelete &&
                                <span className="info-contribute text-danger" onClick={() => props.handleOpenModal(props.item, 1)}><i className={`fa fa-trash`}></i></span>
                            }
                            </Fragment>
                        } */}
                        {props.VIP && !props.item.isDelete && <>
                            <span className="info-editphrase" onClick={() => props.toogleModalEditPhrase(props.item)}><i className={`fa fa-edit`}></i></span>
                        </>}
                    </div>

                    <div className="word-trans">
                        <div>
                            {props.item[props.arrFlag[0].propertiesPhrase]}
                        </div>
                        {props.currentFlags.id !== 0 && <div>
                            {props.item[props.currentFlags.propertiesPhrase]}
                        </div>}
                    </div>
                </div>
                {curr_img && <div className="image-word pointer" onClick={() => props.openVideoImage("", 3, props.item)}>
                    <img src={curr_img} />
                </div>}
            </>}
            {/* {props.item.completeListen && <div className={`word-practice`}>
                <Form onSubmit={(e) => { e.preventDefault() }}>
                    <FormGroup>
                        <InputGroup>
                            <Input
                                placeholder={t("Enter text for review")}
                                type="text"
                                autoComplete="on"
                                name="rewriteText" onChange={(e) => props.handleInputPractice(e.target.value, props.item)}
                                onKeyUp={(e) => props.hanldeEnterPractive(e, props.item)}
                                id={`input${props.item.phraseID}${props.item.index}`}
                                autoFocus
                                tabIndex="0"
                                value={props.item && props.item.rewriteText ? props.item.rewriteText : ""}
                            />
                            {/* {transcript}
                            <InputGroupAddon addonType="append"  >
                                <InputGroupText >
                                    {/* {browserSupportsSpeechRecognition && <span title={t("Practice using your voice")} className={`pointer icon-record icon-btn ${listening && currentItem && props.currentPhraseSpeech && currentItem.index === props.currentPhraseSpeech.index ? "listen" : ""}`}
                                        onClick={() => handleStartListening(props.item)}
                                    ><i className={listening && currentItem && props.currentPhraseSpeech && currentItem.index === props.currentPhraseSpeech.index ? "fa fa-microphone-slash" : "fa fa-microphone"}></i></span>}
                                    <span className="pointer text-success icon-btn" onClick={() => props.handlePracticeWord(props.item, 0, props.isChildPhrase)} title={t("Submit")}> <i className="fa fa-check-circle"></i></span>
                                    {/* <span className="pointer text-danger icon-btn" onClick={() => props.handlePracticeWord(props.item, 1, props.isChildPhrase)} title={t("Cancel")}> <i className="fa fa-times"></i></span> 
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        {props.item.errRewriteText && <div className="error">{props.item.errRewriteText}</div>}
                    </FormGroup>
                </Form>
            </div>} */}
            <div className="action-word">
                {/* {props.isChildPhrase && <Button className="play-audio" onClick={() => props.replacePhrase(props.item)} title={t("Replace")}><i className="fa fa-exchange" aria-hidden="true"></i></Button>} */}
                <Button className="play-audio " onClick={() => { props.hanldeToogleFavorite(props.item, props.isChildPhrase) }} title={t("Add to favorites")}>
                    <i className={`fa fa-star${props.item.isFavourite ? "" : " disable"}`}></i>
                </Button>
                {/* <Button className="play-audio" onClick={() => props.openVideoImage("https://www.google.com/search?q=" + props.item.phrase + "&tbm=isch&source=lnms", 3, props.item)} title={t("Image")}><i className="fa fa-picture-o" aria-hidden="true"></i></Button> */}
                <Button className="play-audio" onClick={() => props.openVideoImage("https://youglish.com/pronounce/" + props.item.phrase + "/english?", 2, props.item)} title={t("Video")}><i className="fa fa-video-camera" aria-hidden="true"></i></Button>
                <Button className="play-audio " onClick={() => props.playTrack(props.item, true, props.isChildPhrase)} title={t("Play")}><i className={`fa ${props.item.isPlaying ? 'fa fa-pause' : 'fa-volume-up'} `} aria-hidden="true"></i></Button>
                {/* <Button className="play-audio " onClick={() => props.playTrack(props.item, false, props.isChildPhrase)} title={t("Play")}><i className="fa fa-volume-up " aria-hidden="true"></i></Button> */}
                {/* <Button className="play-audio btn-nlp" onClick={() => props.playTrack(props.item, true, props.isChildPhrase)} title="NLP">NLP</Button> */}
                {props.item.isPlaying && <ReactHowler
                    src={props.item.soundPath ? props.item.soundPath : "audio.mp3"}
                    playing={props.item.isPlaying && !props.item.isWaiting}
                    html5={true}
                    onPlay={() => props.onLoadLoopItem(props.item)}
                    onEnd={() => props.hanldeShowPractive(props.item)}
                />}
            </div>
        </>
    );
}

export default withTranslation()(PhraseItem);
