import React from "react";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Container,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form
} from "reactstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { post, get, redirectToURL, checkToken } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation } from "react-i18next";
import Loading from "../../components/Loading/Loading"
import { t } from "i18next";
import i18n from "i18next";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { formatNumber, FormatNumberNTC, hideCharacters } from "services/common";
import configs from "configs/configs";
import { io, Manager } from "socket.io-client";
import ModalConfirm from "../../components/ConfirmModal/Confirm"
import BuyPackage from "views/Home/BuyPackage";
import BuyCoin from "views/Home/BuyCoin";
import { NavLink } from 'react-router-dom';
class HeaderNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: localStorage.getItem("email"),
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      modalShare: false,
      countdownInterval: null,
      remainTimeString: "00:00:00",
      firstLoad: true,
      listTopic: [],
      totalSeconds: null,
      seconds: 0,
      minutes: 0,
      hours: 0,
      confirmText: "",
      showConfirm: false,
      showBonus: false,
      bonusValue: 0,
      showBuyPackage: false,
      modalType: 0,// 0: donate, 1: withdraw,
      mustBuyUSDT: false,
      contentReminDonate: '',
      showCloseRemind: false,
      ComPending: 0,
      ListBonusNotify: [],
      remainCountDown: 0,
      invCountdown: null,
      invRunningBonus: null,
      isMobile: window.innerWidth < 992,
      bonusText: '',
      isFindF1: false,
      modalBuyCoinOpen: false,
      currentSymbol: ""
    };
    const params = { userId: localStorage.getItem("userId") ? Number(localStorage.getItem("userId")) : 0 };
    this.socket = io(configs.SocketUM);
    this.socketBonus = io(configs.SocketBonus, { auth: params });
    this.socketUserNotify = io(configs.SocketUserNotify, { auth: params });
  }
  componentDidMount() {
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });
    this.socket.on("UM", (msg) => {
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
    let params = { userId: localStorage.getItem("userId") ? Number(localStorage.getItem("userId")) : 0 };//localStorage.getItem("userId") ? Number(localStorage.getItem("userId")) : 0 
    //socket Bonus
    this.socketBonus.on("connect", () => {
      console.log("Bonus Connected");
    });
    this.socketUserNotify.emit("PayoutBonus", params);
    this.socketBonus.on("resultMsgPayoutBonus", (msg) => {
      console.log("Bonus msg", msg);
      //Thêm queue để lưu lại ds notify và hiển thị lần lượt. Check nếu trùng userID thì cập nhật lại compending
      if (msg) {
        let ListBonusNotify = [...this.state.ListBonusNotify, ...msg];
        this.setState({ showBonus: true, ListBonusNotify: ListBonusNotify });
        let item = msg.filter(x => x.UserId === Number(localStorage.getItem("userId")));
        console.log("item", item);
        if (item && item.length > 0) this.setState({ ComPending: item[0].ComPending });
        const { isMobile } = this.state;
        if (!this.state.invRunningBonus) {
          //first
          if (ListBonusNotify && ListBonusNotify.length > 0) {
            let current_bonus = ListBonusNotify[0];
            let text = hideCharacters(current_bonus.Email) + " " + t("received a bonus of") + " $" + current_bonus.Bonus;
            this.setState({ showBonus: true, ListBonusNotify: ListBonusNotify.slice(1), bonusText: text });
          }
          //interval
          let invRunningBonus = setInterval(res => {
            const { ListBonusNotify, invRunningBonus } = this.state;
            if (ListBonusNotify && ListBonusNotify.length > 0) {
              let _ListBonusNotify = [...ListBonusNotify];
              let current_bonus = _ListBonusNotify[0];
              let text = hideCharacters(current_bonus.Email) + " " + t("received a bonus of") + " " + current_bonus.Bonus;
              this.setState({ showBonus: true, ListBonusNotify: _ListBonusNotify.slice(1), bonusText: text });
            } else {
              this.setState({ showBonus: false, bonusText: '' });
              clearInterval(invRunningBonus);
            }
          }, (isMobile ? 10 : 25) * 1000);
          this.setState({ invRunningBonus: invRunningBonus, })
        }
      }
    });
    //Socket Notify
    this.socketUserNotify.on("connect", () => {
      console.log("Notify Connected");
    });
    //Socket Notify
    this.socketUserNotify.emit("UserNotify", params);
    this.socketUserNotify.on("resultUserNotify", (msg) => {
      console.log("Notify msg", msg);
      if (msg) {
        this.setState({ showBuyPackage: true, contentReminDonate: msg.Content, showCloseRemind: false, remainCountDown: msg.SecondCloseNotify })
        // setTimeout(res => {
        //   this.setState({ showCloseRemind: true })
        // }, msg.SecondCloseNotify * 1000)
        // if (msg.SecondCloseNotify) {
        //   let inv = setInterval(() => {
        //     const { remainCountDown } = this.state;
        //     let remainSecond = remainCountDown - 1;
        //     if (remainSecond > 0) {
        //       this.setState({ remainCountDown: remainSecond });
        //     } else {
        //       if (this.state.invCountdown) {
        //         clearInterval(this.state.invCountdown);
        //       }
        //       this.setState({ showCloseRemind: true, invCountdown: null, remainCountDown: 0 })
        //     }
        //   }, 1000);
        //   this.setState({ invCountdown: inv });
        // }
      }
    });
    this.hanldeLoadTopic();
  }
  componentWillUnmount() {
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();
    this.socketUserNotify.disconnect();
    this.socketBonus.disconnect();
    if (this.state.invCountdown) {
      clearInterval(this.state.invCountdown);
    }
  }
  handleShowMenu = () => {
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }));
  }
  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  handleLogout = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await post('/Users/Logout');
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        let _lang = localStorage.getItem("lang") && localStorage.getItem("lang") !== "null" ? localStorage.getItem("lang") : "en";
        localStorage.clear();
        localStorage.setItem("lang", _lang);
        redirectToURL("/login")
      } else {
        this.setState({ isLoading: false });
        checkToken(response.data)
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      } else {
        checkToken(response)
      }
    }
  };

  handlePasteToInput = (e, type) => {
    e.preventDefault();
    let inputRef = type === 0 ? this.inputRef1 : this.inputRef;
    if (inputRef.current) {
      navigator.clipboard.readText()
        .then((pastedText) => {
          if (type === 0) {
            this.setState({ qrcode: pastedText });
          } else if (type === 1) {
            this.setState((prevState) => ({
              formData: { ...prevState.formData, otp: pastedText },
            }));
          } else if (type === 2) {
            this.setState((prevState) => ({
              formData: { ...prevState.formData, gacode: pastedText },
            }));
          }
        })
        .catch((error) => {
          console.log('Error reading clipboard data:', error);
        });
    }
  };
  handleCopyClick = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success(t("Copy successfully!"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.Balance !== prevProps.Balance) {
      this.setState({ Balance: this.props.Balance });
    }
    if (this.props.Balance !== prevProps.Balance) {
      this.setState({ ComPending: this.props.ComPending });
    }
    if (this.props.info.comPending !== prevProps.info.comPending) {
      this.setState({ ComPending: this.props.info.comPending });
    }
    if (this.props.showFindF1 !== prevProps.showFindF1) {
      if (!this.state.modalShare) {
        this.setState({ modalShare: this.props.showFindF1, isFindF1: true });
      }
    }
    // if (this.props.info.countdown !== prevProps.info.countdown) {
    //   // console.log(this.props.info);
    //   if (!this.props.info.inHistory) {
    //     if (this.props.info.remainWithdrawHours) {
    //       if (this.props.info.remainWithdrawHours != "00:00:00") {
    //         this.countDown(this.props.info.remainWithdrawHours);
    //       }
    //       // else {
    //       //   this.setState({ remainTimeString: this.props.info.remainWithdrawHours })
    //       // }
    //     } else {//in history countdown in RPTHistoryCommon.js
    //       this.setState({ remainTimeString: "00:00:00" })
    //     }
    //   } else {
    //     this.setState({ remainTimeString: this.props.info.remainTimeString })
    //   }
    // }
  }

  changeLanguage = (lang) => {
    this.setState({ lang: lang });
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  }
  countDown = (timeString) => {
    if (timeString) {
      var { totalSeconds, hours, minutes, seconds } = this.state;
      if (totalSeconds === null) {
        let [hours, minutes, seconds] = timeString.split(':').map(Number);
        let _totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
        this.setState({
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          totalSeconds: _totalSeconds
        })
      }
      // const countdownInterval = setInterval(() => {
      if (totalSeconds <= 0) {
        // if (this.state.countdownInterval) {
        //   clearInterval(this.state.countdownInterval);
        // }
        this.setState({ remainTimeString: "00:00:00" })
        return;
      }
      seconds--;

      if (seconds < 0) {
        minutes--;
        seconds = 59;
      }

      if (minutes < 0) {
        hours--;
        minutes = 59;
      }

      totalSeconds--;

      const countdownString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      this.setState({
        remainTimeString: countdownString,
        totalSeconds: totalSeconds,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      })
      // }, 1000);
      // this.setState({ countdownInterval: countdownInterval });
    }

  }
  hanldeLoadTopic = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await get('/Users/GetTopic?PageSize=9999&PageIndex=1');
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        this.setState({ listTopic: response.data.data });
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      } else {
        checkToken(response)
      }
    }
  }
  loadPhraseOfTopic = (item) => {
    if (item.isView) {
      let search = {
        type: 0,
        keyword: item.topicID
      }
      localStorage.setItem("search", JSON.stringify(search));
      redirectToURL("/")
    } else {
      this.setState({ modalShare: this.props.showFindF1, isFindF1: true });
      // this.setState({ showBuyPackage: true, showCloseRemind: true, contentReminDonate: t("Please become a member to enjoy the journey of exploring English.") });
    }
  }
  withdrawBonus = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await post('/Users/WithdrawBonusDonate');
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false, ComPending: response.data.data.comPending });
        toast.success(t("Withdraw successfully"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        this.props.hanldeLoadBasicInfo();
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      } else {
        checkToken(response)
      }
    }
  };
  handleCancel = () => {
    // Xử lý khi người dùng chọn Close
    this.setState({ showConfirm: false });
  };
  handleConfirm = () => {
    // Xử lý khi người dùng chọn OK
    this.setState({ showConfirm: false });
    const { modalType, mustBuyUSDT } = this.state;
    if (modalType === 1) {
      this.withdrawBonus();
    } else {
      if (mustBuyUSDT) {
        this.handleBuyCoin();
      } else {
        this.handleBuyPackage();
      }
    }
  };
  handleOpenModal = (type) => {//0: donate, 1: withdraw, 
    if (type === 1) {
      this.setState({ confirmText: t("Are you sure you want to withdraw the Donate Bonus?"), modalType: type })
      this.setState(prevState => ({
        showConfirm: !prevState.showConfirm
      }));
    } else {
      const { info } = this.props;
      let msg = t("Are you sure you want to donate?");
      let price = 50;
      if (info.balance < price && !info.donateFree) {
        msg = t("BuyUSDTCommunity", { amount: price });
        this.setState({ mustBuyUSDT: true, Price: price })
      }
      this.setState({ confirmText: msg, modalType: type })
      this.setState({ SelectedPack: price });
      this.setState(prevState => ({
        showConfirm: !prevState.showConfirm
      }));
    }
  };

  //==============Donate============
  handleBuyPackage = async () => {
    // let _params = {
    //     "PackageAmount": this.state.SelectedPack,
    // }
    this.setState({ isLoading: true });
    try {
      let response = await post('/Users/Donate');
      if (response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(t("Donate successfully."), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        setTimeout(res => {
          window.location.reload(false);
        }, 3000)
        // setTimeout(res => {
        //   window.location.href = configs.WebRoot + "/transaction/1";
        // }, 3000)
      } else {
        this.setState({ isLoading: false });
        if (response.data.errorMessage === "You need to deposit USDT before becoming an active member.") {
          this.setState({ showNotify: true })
        } else {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data)
          });
        }

      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        if (response.data.errorMessage === "You need to deposit USDT before becoming an active member.") {
          this.setState({ showNotify: true })
        } else {
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            onOpen: () => checkToken(response.data)
          });
        }
      } else {
        checkToken(response)
      }
    }
  }
  handleBuyCoin = async (params) => {
    // window.location.href = configs.WebRoot + "/transaction/4";
    this.setState({ isLoading: true });
    try {
      let _params = {
        Amount: Number(params.AmountCoin),
        Symbol: this.state.currentSymbol
      };
      const response = await post('/Purchase/BuyCoin', _params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        toast.success(t("Requested successfully"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        window.location.href = configs.WebRoot + "/transaction/1";
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      if (response && response.data) {
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          onOpen: () => checkToken(response.data)
        });
      } else {
        checkToken(response)
      }
    }
  }
  hanldeContribute = (item) => {
    this.setState({ showMenu: false });
    this.props.toogleModalContribute(1, 0, item.topicID);
  }
  hideModalBuyPackage = () => {
    this.setState({ showBuyPackage: false });
    // this.props.hideModalBuyPackage();
  }
  hideModalShare = () => {
    this.setState({ modalShare: false, isFindF1: false });
    try {
      this.props.hideFindF1();
    } catch { }

  }
  handelLoadTop = () => {
    localStorage.setItem('search', null)
    redirectToURL('/')
  }
  onToogleModalBuyCoin = (symbol) => {
    this.setState(prevState => ({
      modalBuyCoinOpen: !prevState.modalBuyCoinOpen
    }));
    this.setState({ currentSymbol: symbol })
    if (localStorage.getItem("lang") !== "vi") {
      localStorage.setItem("lang", "vi");
      i18n.changeLanguage("vi");
    }
  }
  render() {
    const expand = "lg";
    const { info, fluid } = this.props;
    const { modalBuyCoinOpen, isLoading, modalShare, remainCountDown, showConfirm, confirmText, showBonus, bonusText, showBuyPackage, showCloseRemind, contentReminDonate, ComPending, isFindF1 } = this.state;
    const reflink = configs.WebRoot + "/s/" + (info ? info.refCode : "");
    return (
      <>
        {showBonus && <div className="notify-bonus" >
          <div id="scroll-container">
            {bonusText && <div className={bonusText ? "scroll-text " : ""}>{bonusText}</div>}
          </div>
        </div>}
        <header className="header-global">
          <Navbar key={expand} expand={expand} variant="light" >
            <Container fluid={fluid ? fluid : false}>
              <Navbar.Brand onClick={() => { this.handelLoadTop() }}>
                <img
                  className="logo-img"
                  alt={configs.SiteName}
                  title={configs.SiteName}
                  src={require("assets/img/logo/logo_yellow_sm.png")}
                />
              </Navbar.Brand>
              <div className="cover-fund">
                <div className="com-pending balance-cover">
                  <NavLink className="menu-btn" activeclassname="active" to="/learn" title={t("Learning")}>
                    <i className={`ni ni-hat-3 `}></i>
                  </NavLink>
                  <NavLink className="menu-btn" activeclassname="active" to="/" title={t("Wallet")}>
                    <i className={`ni ni-money-coins`}></i>
                  </NavLink>
                  {/* <div className="balance-item">
                    <Button className="btn-buy-icon top-btn" onClick={() => this.onToogleModalBuyCoin("TONUSDT")} title={t("Request buy TON")}>
                      <i className={`ni ni-cart `}></i>
                    </Button>
                    <span className="ton-balance">{FormatNumberNTC(info.wallet_TON)}</span> &nbsp;<small>(TON)</small>
                  </div>
                  <div className="balance-item">
                    <Button className="btn-buy-icon " onClick={() => this.onToogleModalBuyCoin("BITUSDT")} title={t("Request buy BIT")}>
                      <i className={`ni ni-cart `}></i>
                    </Button>
                    <span className="bit-balance">{FormatNumberNTC(info.wallet_BIT)}</span> &nbsp;<small>(BIT)</small>
                  </div> */}
                  {/* <div className="t-link" title={t("Learning Bonus")} onClick={() => this.handleOpenModal(1)}><span className={`${info.isWithdrawBonus ? "is-withdraw" : ""} num`}>{FormatNumberUSDT(ComPending)}</span></div> */}
                  {/* <span className="num">/</span>
                  <a className={`t-link ${info.totalF1BuyPackageDue <= 0 ? "no-f1" : ""}`} title={t("Members")} href="/transaction/3"><span className="f1 remain-day">{remainTimeString}</span></a> */}
                  {/* {info.refCode && <span className="share-reflink pointer" title={t("Share")} onClick={() => this.setState({ modalShare: !modalShare, isFindF1: false })}>
                    <img
                      className=""
                      alt={t("Share")}
                      title={t("Share")}
                      src={require("assets/img/button/share_green.png")}
                    />
                  </span>} */}
                </div>
              </div>
              {/* <Button color="warning" className="btn-show-menu" onClick={this.handleLogout}>
                <span className="fnt-bold ni ni-button-power "></span>
              </Button> */}
              {/* <Button color="warning" className="btn-show-menu" onClick={this.handleShowMenu}>
                <span className="navbar-toggler-icon"></span>
              </Button>
              <Offcanvas
                show={showMenu} onHide={this.handleShowMenu}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} >
                    <div className="div-menu-btn div-btn-logout mt-4">
                      <Button className="btn-logout"
                        color="warning"
                        type="button" onClick={this.handleLogout}><i className="img-icon ni ni-button-power fnt-bold"></i> {t("Logout")}
                      </Button>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="u-header-nav flex-grow-1">
                    <div className="child-menu user-dropdown">
                      <ol className="ol-menu">
                        {listTopic && listTopic.map((item, index) => {
                          return (<li key={index} className="pointer menu-item">
                            <span onClick={() => this.loadPhraseOfTopic(item)}>{item.name} </span> &nbsp;
                            <span className={`btn-add pointer`} onClick={() => this.hanldeContribute(item)} title={t("Contribute phrases")}>
                              <i className={`fa fa-plus`}></i>
                            </span>
                          </li>)
                        })}
                      </ol>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Offcanvas> */}
              <Modal toggle={() => this.setState({ modalShare: !modalShare })} isOpen={modalShare} backdrop="static" >
                <div className=" modal-header">
                  <h5 className=" modal-title title-mb" id="PWModalLabel">
                    {t(isFindF1 ? "Please share with your friends to learn this topic." : "Policy")}
                  </h5>
                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={this.hideModalShare}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <ModalBody>
                  <div className="cover-policy">
                    <p>Học tiếng Anh không chỉ là việc mở rộng kiến thức mà còn là chìa khóa mở cửa cho những mối quan hệ mới.</p>
                    <p>Mỗi từ vựng mới, mỗi ngữ cảnh mới, mỗi người bạn học tập mới đều  là một bước tiến đưa bạn gần hơn đến mục tiêu thành thạo tiếng Anh. </p>
                    <p>Đừng ngần ngại thách thức bản thân mà hãy nói với người khác về quyết tâm của bạn, nhìn nhận chúng như cơ hội để trưởng thành về ngôn ngữ và văn hóa cộng đồng.</p>
                    <p>Mỗi “chủ đề” được thiết kế hoàn toàn khác biệt và thú vị. Bằng cách chia sẻ với người thân để họ trải nghiệm học miễn phí, bạn sẽ được mở thêm chủ đề mới. Cứ mỗi thành viên mới bạn sẽ được mở thêm một chủ đề. Link giới thiệu bên dưới: </p>
                    {/* <p className="text-center"><img style={{width:'170px'}} src={require("assets/img/987845.png")}/></p> */}
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <InputGroup  >
                          <Input type="text" name="reflink" autoComplete="on" defaultValue={reflink} readOnly />
                          <InputGroupAddon addonType="append" title={t("Copy")} >
                            <InputGroupText >
                              <span className="pointer" onClick={() => this.handleCopyClick(reflink)}> <span className="btn-inner--text btn-span"><i className="fa fa-copy"></i></span></span>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                    <p>Việc học miễn phí cho toàn bộ thành viên sẽ được nỗ lực duy trì mãi mãi. Trường hợp bạn có điều kiện hãy cùng tài trợ chi phí để duy trì nền tảng. </p>
                    <p>Khi trở thành NHÀ TÀI TRỢ bạn sẽ được mở toàn bộ “chủ đề” trên nền tảng mãi mãi. Chúng tôi sẽ ghi nhận sự tài trợ của bạn bằng cách chuyển lại cho bạn $1/25 cấp độ thành viên của bạn. </p>
                    <p>Điều này chỉ mang ý nghĩa tinh thần dành cho những người dám cho đi. Đây không phải là một mô hình kinh doanh vì nó được nỗ lực duy trì miễn phí mãi mãi.</p>
                  </div>
                  <BuyPackage info={info} hanldeLoadBasicInfo={this.props.hanldeLoadBasicInfo} />

                </ModalBody>
              </Modal>
            </Container>
          </Navbar>
          {/* <ChangePassword modalPWOpen={modalPWOpen} /> */}
        </header>
        <ModalConfirm
          message={confirmText}
          onConfirm={() => this.handleConfirm()}
          onCancel={this.handleCancel}
          open={showConfirm}
        />
        <Modal isOpen={showBuyPackage} toggle={() => this.setState({ showBuyPackage: !showBuyPackage })} backdrop="static">
          <div className=" modal-header">
            <h5 className=" modal-title" >
              {t("Donate")}
            </h5>
            {remainCountDown > 0 && !showCloseRemind && <span className="cl-countdown">{remainCountDown}</span>}
            {showCloseRemind && <button
              aria-label="Close"
              className="bn-close close"
              type="button"
              onClick={this.hideModalBuyPackage}
            >
              <span aria-hidden={true}><i className="fa fa-times"></i></span>
            </button>}

          </div>
          <ModalBody >
            <div className="cover-intro-buy-pack">
              <div>
                <div className="content-notify" dangerouslySetInnerHTML={{ __html: contentReminDonate }}></div>
                <div className="text-center mb-2 mt-3 div-btn-submit">
                  <Button
                    className="btn btn-success btn-submit"
                    onClick={() => this.handleOpenModal(0)}
                  >
                    {t("Donate")}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <BuyCoin modalBuyCoinOpen={modalBuyCoinOpen} onToogleModalBuyCoin={this.onToogleModalBuyCoin} hanldeBuyCoin={this.handleBuyCoin} currentTicket={null} info={info} hanldeLoadBasicInfo={this.props.hanldeLoadBasicInfo} />

        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(HeaderNavbar);
