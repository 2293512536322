import React from "react";
// reactstrap components
import {
    Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { t } from "i18next";
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import ReactPaginate from 'react-paginate';
import { post, checkToken } from "services/apiServices";
import { formatDate } from "services/common";
import Loading from "../../components/Loading/Loading"

// import DateTimeField from 
const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]
const optionsStatus = [
    { value: 0, label: t('All') },
    { value: 1, label: t('Pending') },
    { value: 2, label: t('Approved') },
    { value: 3, label: t('Canceled') }
]
class RPTReset2FA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                Status: 0,
                PageIndex: 0,
                PageSize: 10
            },
            isLoading: false
        };
    }
    // componentDidMount = () => {
    //     var _params = {
    //         Status: 0,
    //         PageIndex: 1,
    //         PageSize: 10
    //     }
    //     this.setState({
    //         dataTrans: [],
    //         TotalRows: 0,
    //     })
    //    this.props.handleGetList(_params);
    // }
    handlePageChange = (pageNumber) => {
        // Xử lý sự kiện chuyển trang ở đây
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: pageNumber.selected },
        }));
        let _params = { ...this.state.params };
        _params.PageIndex = pageNumber.selected + 1;
        this.props.handleGetList(_params)
    };
    handleSelectChange = (e) => {
        let _params = { ...this.state.params };
        if (e === null) {
            this.setState((prevState) => ({
                params: { ...prevState.params, Status: 0 },
            }));
            _params.Status = 0;
        } else {
            this.setState((prevState) => ({
                params: { ...prevState.params, Status: e.value },
            }));
            _params.Status = e.value;
        }
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetList(_params)
    };
    handleChangeSize = (e) => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageSize: e.value },
        }));
        _params.PageSize = e.value;
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.props.handleGetList(_params)
    };
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    handleToogleModalTransaction = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalTransaction();
    };


    handleCancelReset2FA = async (item) => {
        let _params = {
            TransId: Number(item.transId)
        }
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/CancelReset2FA', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Canceled successfully."), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                let _params = { ...this.state.params };
                _params.PageIndex = _params.PageIndex + 1;
                this.props.handleGetList(_params)
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    render() {
        const { t, data, TotalRows } = this.props;
        const { params, isLoading } = this.state;
        return (
            <>
                <div className="cover-rpt ">
                    {/* <h5 className=" text-title">
                        {t("Reset Request")}
                    </h5> */}
                    <div className="filter-group mb-3">
                        <div className="filter-select-md pl-1">
                            <label className="text-bold">{t("Status")}</label>
                            <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isSearchable={false}
                                options={optionsStatus}
                                formatGroupLabel="Status"
                                placeholder={t("Status")}
                                defaultValue={optionsStatus[0]}
                                onChange={(e) => this.handleSelectChange(e)}
                                classNamePrefix="custom-filter-select"
                            />
                        </div>
                        <div className="filter-select-md pl-1">
                            <label className="text-bold">{t("")}</label>
                            <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isSearchable={false}
                                options={optionsStatus}
                                formatGroupLabel="Status"
                                placeholder={t("Status")}
                                defaultValue={optionsStatus[0]}
                                onChange={(e) => this.handleSelectChange(e)}
                                classNamePrefix="custom-filter-select"
                            />
                        </div>
                    </div>
                    <Table striped bordered responsive className="rpt-table" >
                        <thead>
                            <tr>
                                <th className=" text-center num-stt ">#</th>
                                <th >{t("Type")}</th>
                                <th >{t("Date")}</th>
                                <th >{t("Status")}</th>
                                <th >{t("Action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => {
                                var startIndex = params.PageSize * params.PageIndex + 1;
                                return (
                                    <tr key={index}>
                                        <td className=" text-center ">{index + startIndex}</td>
                                        <td >{t(item.typeText)}</td>
                                        <td >{formatDate(item.requestedDate)}</td>
                                        <td >{t(item.statusText)}</td>
                                        <td>
                                            {item.status === 1 && <Button
                                                className="btn-sm"
                                                color="danger"
                                                type="button"
                                                onClick={() => this.handleCancelReset2FA(item)}
                                                title={t("Cancel")}
                                            >
                                                {t("Cancel")}
                                            </Button>}
                                        </td>
                                    </tr>
                                )
                            })}
                            {(!data || data.length === 0) && <>
                                <tr className="text-center"><td colSpan={5}>{t("No data")}</td></tr>
                            </>}
                        </tbody>
                    </Table>
                    {TotalRows > 0 && params.PageSize > 0 &&
                        <>
                            <div className="paganition-custom">
                                <ReactPaginate
                                    pageCount={Math.ceil(TotalRows / params.PageSize)}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    onPageChange={this.handlePageChange}
                                    forcePage={params.PageIndex}
                                    className="react-paginate"
                                    activeClassName={'active'}
                                    previousLabel={<i className="fa fa-angle-left"></i>}
                                    nextLabel={<i className="fa fa-angle-right"></i>}
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={sizeOptions}
                                    formatGroupLabel="Size"
                                    placeholder="Size"
                                    isClearable={false}
                                    defaultValue={sizeOptions[0]}
                                    onChange={this.handleChangeSize}
                                    classNamePrefix="pagesize-select"
                                />
                            </div>
                        </>}
                </div>

                {isLoading && <Loading />}
            </>
        );
    }
}

export default withTranslation()(RPTReset2FA);
