import React from "react";
import { getNoAuth } from "services/apiServices";
// reactstrap components
import {
  Button,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
// import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { t } from "i18next";
import configs from "configs/configs";

class Topic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showTopic: false,
      isMobile: window.innerWidth < 992,
      listTopic: []
    };
    window.addEventListener('resize', this.handleResize);
  }
  componentDidMount() {
    this.hanldeLoadTopic();
  }
  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 992
    });
  };
  toogleShowTopic = () => {
    this.setState((prevState) => ({ showTopic: !prevState.showTopic }));
  };
  handleShowPass = () => {
    this.setState({
      showpass: !this.state.showpass,
      typepass: this.state.showpass ? "password" : "text",
    });
  };

  hanldeLoadTopic = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await getNoAuth('/Users/GetTopic?PageSize=9999&PageIndex=1');
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          this.setState({ listTopic: response.data.data })
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      let response = error.response;
      toast.error(t(response.data.errorMessage), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      });
    }
  }
  render() {
    const { isLoading, showTopic, isMobile, listTopic } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="left-part">
          <div className="image-above">
            <img
              className="banner-img"
              alt={configs.SiteName}
              title={configs.SiteName}
              src={require("assets/img/bg/banner_english.jpg")}
            />
            <div className="block-mobile">
              <div className=" absolute-action">
                {!showTopic && <div className="show-topic-mb" onClick={this.toogleShowTopic}>
                  {t("Show Topic")}
                </div>}
              </div>
            </div>
          </div>
          {<div className={(!isMobile || (isMobile && showTopic)) ? "show-topic list-below" : "hide-topic list-below"}>
            <div className="topic-list">
              <h3 className="text-highlight text-center">{t("Topic")}</h3>
              {listTopic && listTopic.map((item, index) => (
                <div className="topic" key={"topic" + index}>
                  <div className="topic-item">
                    <div className="topic-img">
                      <img
                        className="banner-img"
                        alt={item.name}
                        title={item.name}
                        src={item.imagePath ? item.imagePath : require("assets/img/bg/banner_english3.jpg")}
                      />
                    </div>
                    <div className="topic-content">
                      <div className="name">
                        {item.name}
                      </div>
                      <div className="topic-trans">
                        {item.vietnameseName}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* {listTopic &&
                <div className="text-center">
                  No data
                </div>} */}
            </div>
            <div className=" block-mobile">
              <div className=" absolute-action">
                <Button className="hide-topic-mb" onClick={this.toogleShowTopic}>
                  {t("Hide Topic")}
                </Button>
                {/* {showTopic && <div className="hide-topic-mb" onClick={(e) => this.toogleShowTopic(e)}>
                          Hide Topic
                        </div>} */}
              </div>
            </div>
          </div>}
        </div>
        <ToastContainer pauseOnFocusLoss={false} />
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(Topic);
