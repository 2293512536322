import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { Dropdown, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken, postFormData } from "services/apiServices";
import { cutNumberFormat, FormatNumberCoin, formatNumber, FormatNumberAmount } from "services/common";
import configs from "configs/configs";
import { io } from "socket.io-client";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
import Resizer from "react-image-file-resizer";
class BuySellOTC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                AmountCoin: 100,
                PriceOfLang: 0,
                AmountOfLang: 0,
                AmountAfterDiscount: 0,
            },
            errors: {
                AmountCoin: '',
                AmountOfLang: '',
            },
            intervalFee: null,
            IsInternalTransfer: false,
            modalConfirmTransferOpen: false,
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            errReceipt: "",
            showConfirm: false,
            confirmText: "",
            confirmTitle: "",
            currentType: 0,
            // pricesLang: null,
            // prices: null,
            isFiat: true,
            Discount: false
        }
        this.inputRef = React.createRef();
        // this.socket = io(configs.SocketPrice);
    }

    clearForm = () => {

        //fill current ticket 
        const { currentTicket, info } = this.props;
        // this.socket.disconnect();
        if (currentTicket) {
            let newerrors = {
                AmountCoin: '',
                AmountOfLang: '',
            };
            // let _data = {
            //     AmountCoin: 100,
            //     PriceOfLang: 0,
            //     AmountOfLang: 0,
            //     AmountAfterDiscount: 0,
            // };
            let _data = {
                AmountCoin: currentTicket.amountUSDT,
                PriceOfLang: currentTicket ? currentTicket.priceVND : 0,
                AmountOfLang: currentTicket ? formatNumber(currentTicket.amountVND) : 0,
                AmountAfterDiscount: currentTicket ? formatNumber(currentTicket.amountVND) : 0
            };
            this.setState({ formData: _data });
            this.setState({ errors: newerrors });
        } else {
            this.handleChangeAmount(100, this.props.pricesLang, this.props.pricesAll)
        }
        // else {
        //     this.socket = io(configs.SocketPrice);
        //     this.socket.on("connect", () => {
        //         // console.log("Price Connected");
        //     });
        //     this.socket.on("price", (msg) => {
        //         console.log("msg", msg);
        //         if (msg && msg.errorCode === 0) {
        //             let _price = 0;
        //             const { formData } = this.state;
        //             this.setState((prevState) => ({
        //                 formData: { ...prevState.formData, PriceOfLang: _price },
        //             }));
        //             this.setState({ pricesLang: msg.data.price, prices: msg.data.data })

        //         }
        //     });
        // }

    };

    handleBuySellOTC = async (e) => {
        const { formData, isFiat, Discount } = this.state;
        const { OTCInfo, remainTimeDiscount, OTCInfo_TON, Coin } = this.props;
        let formIsValid = true;
        const newErrors = {
            AmountCoin: "",
        };

        if (!formData.AmountCoin) {
            formIsValid = false;
            newErrors.AmountCoin = t('Please enter Coin amount.');
        }
        if (Coin) {
            if (Coin.symbol === "BIT") {
                if (isFiat && (!OTCInfo.parentOTCAccNumberBank || !OTCInfo.parentOTCNameBank || !OTCInfo.parentOTCCardholderNameBank)) {
                    toast.error(t("OTC does not provide banking information yet, so you cannot buy BIT with Fiat."), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000
                    });
                    return;
                }
            }
            else if (Coin.symbol === "TON" || Coin.symbol === "USDT") {
                if (!OTCInfo_TON.otcAccNumberBank || !OTCInfo_TON.otcNameBank || !OTCInfo_TON.otcCardholderNameBank) {
                    toast.error(t("OTC does not provide banking information yet, so you cannot buy coin."), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000
                    });
                    return;
                }
            }
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Amount: formData.AmountCoin ? Number(formData.AmountCoin) : 0,
                HasDiscount: remainTimeDiscount && remainTimeDiscount.total > 0 && Discount > 0 ? true : false
            };
            // if (!isFiat) {
            //     params = {
            //         AmountBITSwap: formData.AmountCoin ? Number(formData.AmountCoin) : 0,
            //         UserID: info.parentOTCID
            //     };
            // }
            this.props.hanldeBuyCoin(params, isFiat);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handleToogleModalBuySellOTC = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalBuySellOTC();
    };
    handleToogleModalCloseFastSell = async () => {
        // Xử lý khi người dùng chọn Close       
        const { currentTicket, Coin, PageSize } = this.props;
        let params = {
            "TransID": currentTicket.refTransID
        }
        try {
            const response = await post("/Purchase/OTCClosePopupFastSell", params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.props.onToogleModalBuySellOTC();
                let _params = {
                    Type: 1,
                    PageIndex: 1,
                    PageSize: PageSize,
                    Symbol: Coin ? Coin.symbolID : 0
                };
                this.props.handleGetAllTransaction(_params, true);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleChangeAmount = async (value, pricesLang, prices) => {
        const { info, OTCInfo, remainTimeDiscount, Coin, OTCInfo_TON } = this.props;
        value = value ? value : undefined;
        let Amount = value ? Number(value) : 0;
        let amountReceive = 0;
        let gasFee = 0;
        let amountAfterDiscount = 0;
        let amountReceiveString = "0";
        let amountAfterDiscountString = "0";
        if (prices) {
            if (Coin && Coin.symbol === "TON") {
                if (OTCInfo_TON && OTCInfo_TON.otcLanguage) {
                    let price = pricesLang[OTCInfo_TON.otcLanguage] * prices.TONUSDT;
                    amountReceive = Amount * price * (1 + prices.OTCRate);
                    amountReceiveString = this.props.hanldeFormatLang(amountReceive ? amountReceive.toFixed(3) : 0, OTCInfo_TON.otcLanguage)
                    this.setState({ isFiat: true })
                }
                amountAfterDiscount = amountReceive;
                amountAfterDiscountString = amountReceiveString;
                // if (selectedOTCTON && selectedOTCTON.discount && remainTimeDiscount && amountReceive) {
                //     amountAfterDiscount = (amountReceive * (1 - selectedOTCTON.discount));
                // }
            }
            else if (Coin && Coin.symbol === "USDT") {
                if (OTCInfo_TON && OTCInfo_TON.otcLanguage) {
                    let price = pricesLang[OTCInfo_TON.otcLanguage];
                    amountReceive = Amount * price * (1 + prices.OTCRate);
                    amountReceiveString = this.props.hanldeFormatLang(amountReceive ? amountReceive.toFixed(3) : 0, OTCInfo_TON.otcLanguage)
                    this.setState({ isFiat: true })
                }
                amountAfterDiscount = amountReceive;
                amountAfterDiscountString = amountReceiveString;
                // if (selectedOTCTON && selectedOTCTON.discount && remainTimeDiscount && amountReceive) {
                //     amountAfterDiscount = (amountReceive * (1 - selectedOTCTON.discount));
                // }
            }
            else { //BIT
                if (Amount > info.settingMaxAmountBITWithdraw) {
                    gasFee = ((Amount / 100) * info.settingMinGasFee);// prices.TONUSDT;
                }
                else {
                    gasFee = info.settingMinGasFee;// / prices.TONUSDT;
                }
                let lang = "";
                // debugger
                if (OTCInfo && OTCInfo.parentOTCID !== 0 && OTCInfo.parentOTCLanguage && OTCInfo.bitlearningInfo >= gasFee) {//check thêm dk OTC đủ phí TON
                    let price = pricesLang[OTCInfo.parentOTCLanguage] * prices.BITUSDT;
                    amountReceive = Amount * price * (1 + prices.OTCRate);
                    amountReceiveString = this.props.hanldeFormatLang(amountReceive ? amountReceive.toFixed(3) : 0, OTCInfo.parentOTCLanguage);
                    lang = OTCInfo.parentOTCLanguage;
                    this.setState({ isFiat: true })
                } else { //not fiat buy with USDT
                    lang = "en";
                    // let rate = prices.TONUSDT / prices.BITUSDT;
                    // let amountBIT = Number(Amount);
                    // amountReceive = Number(amountBIT / rate);
                    amountReceive = Amount * prices.BITUSDT * (1 + prices.OTCRate);
                    amountReceiveString = this.props.hanldeFormatLang(amountReceive ? amountReceive.toFixed(3) : 0, lang);
                    this.setState({ isFiat: false })
                }
                amountAfterDiscount = amountReceive;
                amountAfterDiscountString = this.props.hanldeFormatLang(amountReceive ? amountReceive.toFixed(3) : 0, lang)
                if (OTCInfo && OTCInfo.parentOTCDiscount && remainTimeDiscount && amountReceive) {
                    amountAfterDiscount = (amountReceive * (1 - OTCInfo.parentOTCDiscount));
                    amountAfterDiscountString = this.props.hanldeFormatLang(amountAfterDiscount ? amountAfterDiscount.toFixed(3) : 0, lang)
                }
            }
        }
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                AmountOfLang: amountReceiveString,
                AmountCoin: value,
                AmountAfterDiscount: amountAfterDiscountString
            },
        }));
    };

    handleLoadModal = () => {
        this.clearForm();
    }
    handleCloseModal = () => {
        //disconnect socket price
        // if (this.socket) {
        //     this.socket.disconnect();
        // }
    }
    //Confirm
    handleOpenModalConfirmTransfer = (item) => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.showReceipt,
            errReceipt: "",
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            selectedTrans: item
        }));
    }
    onToogleConfirmTransfer = () => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
        }));
    }
    hanldeConfirmTransfer = async (confirmData) => {
        // const { confirmData } = this.state;
        const { currentTicket } = this.props;
        const { info } = this.props;
        let formIsValid = true;
        if (!confirmData.Receipt) {
            formIsValid = false;
            this.setState({ errReceipt: t('Please choose a receipt.') });
        }
        if (formIsValid) {
            this.setState({ errReceipt: "" });
            let _formData = new FormData();
            _formData.append('Receipt', confirmData.Receipt ? confirmData.Receipt : null);
            _formData.append('TransID', currentTicket.transID ? Number(currentTicket.transID) : 0);
            this.setState({ isLoading: true });
            let url = "/Purchase/ConfirmTransfer"
            if (currentTicket.otc) {
                url = "/Purchase/OTCConfirmTransfer"
            }
            try {
                const response = await postFormData(url, _formData);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Requested successfully"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    this.setState(prevState => ({
                        modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
                    }));
                    // this.handleToogleModalBuySellOTC();
                    this.props.hanldeLoadBasicInfo();
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    }
    handleFileSelected = (e) => {
        // debugger
        const files = Array.from(e.target.files);
        //resize image
        if (files && files.length > 0 && files[0]) {
            const fileExtension = files[0].name.split('.').pop().toLowerCase();;
            const allowedExtensions = ['png', 'jpeg', 'jpg'];
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(t('Only PNG, JPEG, and JPG files are allowed.'), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                return false;
            }
            try {
                Resizer.imageFileResizer(
                    files[0],
                    768,
                    768,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        const imageUrl = URL.createObjectURL(uri);
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, Receipt: uri },
                        }));
                        this.hanldeConfirmTransfer({ Receipt: uri });
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, ReceiptURL: imageUrl }, errReceipt: ""
                        }));
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }
        }

        // console.log("img url", imageUrl);

    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        const { info, currentTicket } = this.props;
        let params = {
            TransID: currentTicket.transID
        };
        this.setState({ isLoading: true });
        let url = "";
        if (this.state.currentType === 1) {
            url = "/Purchase/OTCAccecptedBuySell";
        } else if (this.state.currentType === 2) {
            if (currentTicket.otc) {
                url = "/Purchase/OTCConfirmReceiveTransfer"
            }
            else {
                url = "/Purchase/ConfirmReceiveTransfer"
            }
        } else if (this.state.currentType === 6) {
            url = "/Purchase/OTCReportFakeBillBuyUSDT";
        }
        else {
            url = "/Purchase/CancelBuySellUSDT";
        }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                // this.handleToogleModalBuySellOTC();
                this.props.handleGetListBuySellPending();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item, type) {
        // Mở modal confirm
        let _confirmText = "";
        let _confirmTitle = "";
        switch (type) {
            case 1: {//OTC Accept
                _confirmText = "Are you sure you want to confirm this transaction?";
                break;
            }
            case 2: {//OTC Confirm received payment
                if (item.typeBuy === 1) {//Fiat
                    _confirmText = "I have received payment.";
                } else {//swap TON
                    _confirmText = "I confirm the sale of BIT to the buyer.";
                }
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 3: {//Seller cancel
                _confirmText = "Are you sure you want to cancel this transaction?";
                _confirmTitle = "Cancel transaction";
                break;
            }
            case 5: {//OTC report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
            case 6: {//User report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
        }
        this.setState({ selectedTrans: item, confirmText: t(_confirmText), confirmTitle: _confirmTitle, currentType: type });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    componentDidUpdate(prevProps, prevState) {
        // Close popup when complete transaction
        if (this.props.currentTicket !== prevProps.currentTicket && !this.props.currentTicket && this.props.modalBuySellOTC) {
            if (this.props.ListTicketBuy && this.props.ListTicketBuy.length === 0) {
                this.handleToogleModalBuySellOTC();
                this.props.hanldeLoadBasicInfo();
                if (this.props.TypeTransaction === 1) {
                    this.props.handleReloadTransaction();
                }
                if (this.props.modalList_Swap) {
                    this.props.onToogleModalList_Swap();
                }
                if (this.props.modalListBuyTON) {
                    this.props.onToogleModalListBuyTON();
                }
            }
        }
        // // Close popup when disputed transaction
        // if (this.props.currentTicket !== prevProps.currentTicket && this.props.currentTicket && this.props.currentTicket.status === 2 && (!this.props.currentTicket.timeRemain || this.props.currentTicket.timeRemain.total <= 0) && this.props.modalBuySellOTCOpen) {
        //     this.handleToogleModalBuySellOTC();
        // }

        // Open popup when exist transaction
        //&& this.props.info.otc === false
        if (this.props.currentTicket !== prevProps.currentTicket && this.props.currentTicket && !this.props.modalBuySellOTC && (this.props.currentTicket.type === 3 || (this.props.currentTicket.type === 1 && this.props.currentTicket.status <= 3))) {
            // debugger
            this.handleToogleModalBuySellOTC();
        }
        if ((this.props.pricesLang !== prevProps.pricesLang || this.props.pricesAll !== prevProps.pricesAll) && this.props.modalBuySellOTC) {
            this.handleChangeAmount(this.state.formData.AmountCoin, this.props.pricesLang, this.props.pricesAll)
        }
        if (this.props.modalBuySellOTC !== prevProps.modalBuySellOTC && this.props.modalBuySellOTC) {
            this.handleCheckDiscount();
        }
        if (this.props.remainTimeDiscount !== prevProps.remainTimeDiscount && this.props.remainTimeDiscount && this.props.remainTimeDiscount.total > 0) {
            if (this.props.modalBuySellOTC) {
                this.handleChangeAmount(this.state.formData.AmountCoin, this.props.pricesLang, this.props.pricesAll)
            }
        }
    }
    checkStatusText = (ticket) => {
        const { info } = this.props;
        let statusText = ticket.statusText;
        if (ticket.type === 1) { //buyer
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for sellers";
                if (ticket.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "The invoice has not been uploaded yet";
                if (ticket.otc) {
                    statusText = "Waiting for the money transfer";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Waiting for the seller to confirm";
                if (ticket.otc) {
                    if (ticket.typeBuy === 1) {//Fiat
                        statusText = "Confirmation of receipt of money";
                    } else {//swap TON
                        statusText = "Confirm selling BIT to buyers";
                    }
                }
            }
        } else {//seller
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for buyers";
                if (ticket.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "Waiting for the money transfer";
                if (ticket.otc) {
                    statusText = "After transferring money, upload the money transfer receipt and wait for the seller to confirm receipt of the money.";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Confirmation of receipt of money";
                if (ticket.otc) {
                    statusText = "Waiting for the seller to confirm";
                }
            }
        }
        return statusText;
    }
    transferCurrency = (lang) => {
        switch (lang) {
            case "en": return "USD"
            case "vi": return "VND"
            case "ar": return "AED"
            case "de": return "EUR"
            case "es": return "EUR"
            case "fr": return "EUR"
            case "hi": return "INR"
            case "pt": return "EUR"
            case "it": return "EUR"
            case "ru": return "RUB"
            case "zh": return "CNY"
            case "usdt": return "USDT"
            case "bit": return "BIT"
        }
    }
    getUsername = (item, type = 0) => {
        let name = item.buyerChatID;

        if (item.buyerUsername) {
            name = item.buyerUsername;
        }
        else if (item.buyerFullname) {
            name = item.buyerFullname;
        }
        if (type === 1) {
            return name
        }
        if (type === 2) {
            return name.length>6? name.substring(0, 6) + '...':name;
        }
        return name + " (" + item.symbol + ")";
    }
    checkCurrency = () => {
        const { currentTicket, OTCInfo, OTCInfo_TON, Coin } = this.props;
        const { isFiat } = this.state;
        let currency = "";
        if (!currentTicket) {
            if (Coin && Coin.symbol === "BIT") {
                if (isFiat && OTCInfo) {
                    currency = this.transferCurrency(OTCInfo.parentOTCLanguage);
                } else {
                    currency = "USDT"
                }
            } else {
                currency = OTCInfo_TON ? this.transferCurrency(OTCInfo_TON.otcLanguage) : "";
            }
        } else {
            if (currentTicket.typeBuy === 1) {
                currency = this.transferCurrency(currentTicket.otcLanguage);
            } else {
                currency = "USDT"
            }
        }
        return currency;
    }
    handleChangeTicket = (item) => {
        this.setState((prevState) => ({
            formData: {
                AmountCoin: item.amountUSDT,
            },
        }));
        this.props.handleChangeTicket(item)
    }
    handleCheckDiscount = () => {
        const { currentTicket, Coin, OTCInfo, pricesLang, pricesAll } = this.props;
        let Discount = 0;
        if (!currentTicket) {
            if (Coin) {
                if (Coin.symbol === "BIT" && OTCInfo && OTCInfo.parentOTCDiscount) {
                    Discount = OTCInfo.parentOTCDiscountPercent;
                }
            }
        }
        this.setState({ Discount: Discount })
        return "";
    }

    render() {
        const { modalBuySellOTC, currentTicket, Coin, info, OTCInfo, remainTimeDiscount, pricesLang, pricesAll, ListTicketBuy, selectedOTCTON } = this.props;
        const { isLoading, formData, errors, errReceipt, confirmData, confirmText,
            showConfirm, confirmTitle, modalConfirmTransferOpen, isFiat, Discount } = this.state;
        // this.handleCheckDiscount()
        // console.log("currentTicket", currentTicket)
        return (
            <>
                <Modal toggle={this.handleToogleModalBuySellOTC} isOpen={this.props.modalBuySellOTC} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal} backdrop="static">
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title flex-100">
                            {currentTicket && currentTicket.otc ? t("Sell") : t("Buy")} {currentTicket && currentTicket.symbol ? currentTicket.symbol : Coin ? Coin.symbol : "BIT"}
                        </h5>
                        {ListTicketBuy && ListTicketBuy.length > 1 && <Dropdown className="custom-dropdown-menu  modal-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {this.getUsername(currentTicket, 2)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {ListTicketBuy && ListTicketBuy.map((item, index) => {
                                    return <Dropdown.Item key={index} onClick={() => this.handleChangeTicket(item)} >{this.getUsername(item)}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>}

                        {!currentTicket && <button
                            aria-label={t("Close")}
                            className=" close flex-100 flex-end"
                            type="button"
                            onClick={this.handleToogleModalBuySellOTC}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>}

                    </div>
                    <ModalBody>
                        <Form role="form">
                            <FormGroup >
                                <div className="d-flex align-items-center">
                                    <label htmlFor="Amount">{currentTicket && currentTicket.otc ? t("You pay") : t("You receive")} </label>
                                    {/* <small className="ml-auto txt-small text-main-color"><span className="label-max">{t("Max")}</span>: {FormatNumberAmount(info.wallet_TON ? info.wallet_TON : 0)} TON</small> */}
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <input
                                        type="number"
                                        value={!currentTicket ? formData.AmountCoin : currentTicket.amountUSDT}
                                        readOnly={currentTicket ? true : false}
                                        onChange={(e) => this.handleChangeAmount(e.target.value, pricesLang, pricesAll)}
                                        className="borderless-input input-amount"
                                    />
                                    <div className="unit-price">{currentTicket && currentTicket.symbol ? currentTicket.symbol : Coin ? Coin.symbol : "BIT"}</div>
                                </div>
                                {errors.AmountCoin && <div className="error">{errors.AmountCoin}</div>}
                            </FormGroup>
                            <div className="cover-icon mt-4">
                                <div className="icon-arrow">
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <FormGroup className="">
                                <div className="d-flex align-items-end">
                                    <label>{currentTicket && currentTicket.otc ? t("You receive") : t("You pay")} ({this.checkCurrency()})
                                        {!currentTicket && remainTimeDiscount && Discount > 0 && <span className="pill-discount">-{Discount}%</span>}
                                        {/* {!currentTicket && remainTimeDiscount && remainTimeDiscount.total > 0 && ((Coin && Coin.symbol === "BIT" && OTCInfo && OTCInfo.parentOTCDiscount > 0))
                                            || (Coin && Coin.symbol === "TON" && selectedOTCTON && selectedOTCTON.discount > 0) &&
                                            <span className="pill-discount">-{Coin.symbol === "BIT" ? OTCInfo.parentOTCDiscountPercent : selectedOTCTON.discount}%</span>} */}
                                    </label>
                                </div>
                                <div className="flex-center mt-1 price-buy ">
                                    <div>
                                        <div className="price-buy-small">{currentTicket ? (currentTicket.typeBuy === 1 ? this.props.hanldeFormatLang(currentTicket.amountVND, currentTicket.otcLanguage) : formatNumber(currentTicket.amountVND)) : remainTimeDiscount ? (formData.AmountAfterDiscount) : (formData.AmountOfLang)}</div>
                                        {!currentTicket && remainTimeDiscount && Discount > 0 &&
                                            <div className="old-amount">{formData.AmountOfLang}</div>
                                        }
                                    </div>
                                    <div className="copy-price">
                                        <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket ? currentTicket.amountVND : remainTimeDiscount ? formatNumber(formData.AmountAfterDiscount) : formatNumber(formData.AmountOfLang))}>
                                            <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                        </span>
                                    </div>
                                </div>
                            </FormGroup>
                            {/* {!currentTicket && OTCInfo && OTCInfo.parentOTCDiscount > 0 && <>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Disount")} (%)</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{OTCInfo.parentOTCDiscountPercent}</div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Total Amount")} ({isFiat ? this.transferCurrency(currentTicket && currentTicket.otc ? info.language : OTCInfo ? OTCInfo.parentOTCLanguage : "") : "TON"})</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{formatNumber(formData.AmountAfterDiscount)}</div>
                                        <div className="copy-price">
                                            <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(formData.AmountAfterDiscount)}>
                                                <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </>} */}
                            {currentTicket && currentTicket.accNumberBank && <>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Transfer to account number")}</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{currentTicket.accNumberBank}</div>
                                        <div className="copy-price">
                                            <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.accNumberBank)}>
                                                <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Cardholder name")}</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{currentTicket.cardholderNameBank}</div>
                                        <div className="copy-price">
                                            <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.cardholderNameBank)}>
                                                <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Bank name")}</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{currentTicket.nameBank}</div>
                                        <div className="copy-price">
                                            <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.nameBank)}>
                                                <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </>}
                            {currentTicket && currentTicket.transferCode &&
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="transferCode">{t("Transfer content")}</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{currentTicket.transferCode}</div>
                                        <div className="copy-price">
                                            <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.transferCode)}>
                                                <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>}
                            {currentTicket && currentTicket.buySellName && <>
                                <FormGroup>
                                    <div className="cover-icon pt-1"></div>
                                    <div className="d-flex align-items-end">
                                        <label htmlFor="Seller">{t("Seller")}</label>
                                    </div>
                                    <div className="flex-center price-buy">
                                        <div className="small-text">{currentTicket.buySellName}</div>
                                    </div>
                                </FormGroup>
                            </>}

                        </Form>
                        {currentTicket && currentTicket.typeBuy === 1 && <div className="bs-time text-center flex-1" >
                            <div className="text-center text-status">{t("Status")}: {t(this.checkStatusText(currentTicket))} &nbsp;
                                {currentTicket && currentTicket.receiptPath && <span title={t("Show receipt")} className="text-primary pointer" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                    <i className="fa fa-image pointer"></i>
                                </span>}</div>
                            {(currentTicket.status === 2 || currentTicket.status === 3) && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <div className="text-alert pt-1 text-center flex-1"> {t("The transaction will be canceled after")}: {(currentTicket.timeRemain.minutes > 9 ? currentTicket.timeRemain.minutes + "" : "0" + currentTicket.timeRemain.minutes) + ":" + (currentTicket.timeRemain.seconds > 9 ? currentTicket.timeRemain.seconds + "" : "0" + currentTicket.timeRemain.seconds)}</div>}
                            {currentTicket.status === 3 && currentTicket.timeRemainReport && currentTicket.timeRemainReport.total > 0 && <div className="text-alert pt-1"> {(currentTicket.timeRemainReport.minutes > 9 ? currentTicket.timeRemainReport.minutes + "" : "0" + currentTicket.timeRemainReport.minutes) + ":" + (currentTicket.timeRemainReport.seconds > 9 ? currentTicket.timeRemainReport.seconds + "" : "0" + currentTicket.timeRemainReport.seconds)}</div>}
                        </div>}
                        {currentTicket && currentTicket.typeBuy === 2 && currentTicket.status === 3 && <div className="bs-time text-center flex-1" >
                            <div className="text-center text-status">{t("Status")}: {t(this.checkStatusText(currentTicket))} &nbsp;
                            </div>
                            {currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <div className="text-alert pt-1 text-center flex-1"> {(currentTicket.timeRemain.minutes > 9 ? currentTicket.timeRemain.minutes + "" : "0" + currentTicket.timeRemain.minutes) + ":" + (currentTicket.timeRemain.seconds > 9 ? currentTicket.timeRemain.seconds + "" : "0" + currentTicket.timeRemain.seconds)}</div>}
                        </div>}
                        {currentTicket && currentTicket.typeBuy === 3 && <div className="bs-time text-center flex-1" >
                            <div className="text-center text-status">{t("Status")}: {t(this.checkStatusText(currentTicket))} &nbsp;
                                {currentTicket && currentTicket.receiptPath && <span title={t("Show receipt")} className="text-primary pointer" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                    <i className="fa fa-image pointer"></i>
                                </span>}</div>
                        </div>}
                        {/* {currentTicket && currentTicket.receiptPath && <div className="text-center">
                            <Button color="success" type="button" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                {t("Show receipt")}
                            </Button>
                        </div>} */}
                    </ModalBody>

                    {currentTicket && currentTicket.status < 3 && !currentTicket.otc && <> <ModalFooter>
                        {/*Pending*/}
                        {currentTicket.status === 1 &&
                            <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 3)}>
                                {t("Cancel")}
                            </Button>}
                        {/*Accept*/}
                        {currentTicket.status === 2 && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <>
                            <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                {t("Upload receipt")}
                            </label></div>
                            <Input type="file" onChange={this.handleFileSelected} id="fileImage" className="file-input" accept="image/png, image/gif, image/jpeg, image/jpg" />
                            {/* <Button color="success" type="button" onClick={() => this.handleFileSelected(currentTicket)}>
                                    {t("Upload receipt")}
                                </Button> */}
                            {currentTicket.type !== 3 &&
                                <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 3)}>
                                    {t("Cancel")}
                                </Button>
                            }
                        </>}
                    </ModalFooter> </>}
                    {/*Accept*/}
                    {currentTicket && currentTicket.status === 3 && currentTicket.otc && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 &&
                        <ModalFooter className="btn-confirm" onClick={() => this.handleOpenModal(currentTicket, 2)}>
                            <div> {t("Confirm")}</div>
                        </ModalFooter>}
                    {currentTicket && currentTicket.status === 3 && currentTicket.otc && currentTicket.timeRemainReport && currentTicket.timeRemainReport.total > 0 &&
                        <ModalFooter className="btn-report" onClick={() => this.handleOpenModal(currentTicket, 6)}>
                            <div> {t("Report")}</div>
                        </ModalFooter>
                    }
                    {currentTicket && currentTicket.status >= 5 && currentTicket.type === 1 &&
                        <ModalFooter className="button-close-modal" onClick={this.handleToogleModalBuySellOTC}>
                            <div> {t("Close")}</div>
                        </ModalFooter>
                    }
                    {currentTicket && currentTicket.status === 4 && currentTicket.type === 3 &&

                        <ModalFooter className="button-close-modal" onClick={this.handleToogleModalCloseFastSell}>
                            <div> {t("Close")}</div>
                        </ModalFooter>
                    }
                    {!currentTicket && <ModalFooter className="btn-confirm" onClick={this.handleBuySellOTC}>
                        <div>
                            {t("Buy")}
                            {remainTimeDiscount && Discount > 0 && <span className=""> {t("with discount")} {(remainTimeDiscount.minutes > 9 ? remainTimeDiscount.minutes + "" : "0" + remainTimeDiscount.minutes) + ":" + (remainTimeDiscount.seconds > 9 ? remainTimeDiscount.seconds + "" : "0" + remainTimeDiscount.seconds)}</span>}
                        </div>
                    </ModalFooter>}
                </Modal>
                {/* <Modal toggle={this.handleOpenModalConfirmTransfer} isOpen={modalConfirmTransferOpen} >
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Submit payment proof")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <label className="text-label">
                                {t("Attention! Please upload proof of payment so the transaction can be processed in case the seller does not confirm. Otherwise, after 15 minutes the transaction will be automatically canceled")}
                            </label>
                            <FormGroup>
                                <div >{t("Please upload the paid invoice for this transaction to confirm your payment.")} </div>
                                <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                    {t("Choose Image")}
                                </label></div>
                                <Input type="file" onChange={this.handleFileSelected} id="fileImage" className="file-input" accept="image/*" />
                                {confirmData.ReceiptURL && <div className="text-center">
                                    <img
                                        className="img-receipt"
                                        alt={confirmData.ReceiptURL}
                                        title={confirmData.ReceiptURL}
                                        src={confirmData.ReceiptURL}
                                    />
                                </div>}
                                <div className="text-center"> {errReceipt && <div className="error">{errReceipt}</div>}</div>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        > {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.hanldeConfirmTransfer}>
                            {t("Submit")}
                        </Button>
                    </ModalFooter>
                </Modal> */}
                <ModalConfirm
                    message={confirmText}
                    title={confirmTitle}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(BuySellOTC);
