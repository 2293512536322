import React from "react";
import { postNoAuth, redirectToURL } from "services/apiServices";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { toast, ToastContainer } from 'react-toastify';
import { validateEmail, validateMaxLength, validateMinLength } from "services/common";
import Loading from "../../components/Loading/Loading"
import withRouter from "../../components/common/withRouter";
import Topic from "./Topic";
import i18n from "i18next";
import configs from "configs/configs";
import { io } from "socket.io-client";
class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        refcode: '',
        chatid: '',
        password: '',
        confirmpassword: '',
        // captcha: ''
        email: '',
        fullname: '',
        OTCRefcode: ''
      },
      errors: {
        chatid: '',
        password: '',
        confirmpassword: '',
        // captcha: ''
        email: '',
        fullname: '',
        OTCRefcode: ''
      },
      typepass: "password",
      typeconfirm: "password",
      showpass: false,
      showconfirm: false,
      isLoading: false,
      lang: "en",
      selectedLang: {
        id: "en",
        img: "flag_english",
        name: "English",
      },
      // imgCaptcha: '',
      // captchaCode: '',
      arrLang: [
        {
          id: "en",
          img: "flag_english",
          name: "English",
        },
        {
          id: "zh",
          img: "flag_china",
          name: "中国"
        },
        {
          id: "hi",
          img: "flag_india",
          name: "भारत",
        },
        {
          id: "es",
          img: "flag_spanish",
          name: "España",
        },
        {
          id: "fr",
          img: "flag_france",
          name: "France",
        },
        {
          id: "ar",
          img: "flag_arabic",
          name: "عربي",
        },
        {
          id: "ru",
          img: "flag_russia",
          name: "Русский",
        },
        {
          id: "pt",
          img: "flag_portugal",
          name: "Português",
        },
        {
          id: "it",
          img: "flag_italian",
          name: "Italiano",
        },
        {
          id: "de",
          img: "flag_germany",
          name: "Deutsch",
        },
        {
          id: "vi",
          img: "flag_vietnam",
          name: "Tiếng Việt",
        }
      ],
    };
    this.socket = io(configs.SocketUM);
  }
  componentDidMount() {
    localStorage.setItem("token", "")
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });

    this.socket.on("UM", (msg) => {
      // console.log("Received message:", msg);
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
    //this.hanldeLoadOTCCountryByRefCode();
    if (this.props.params&&this.props.params.code) {
      let selectedLang = this.state.arrLang.find(x => x.id === this.props.params.lang);
      if (selectedLang) {
        localStorage.setItem("lang",  this.props.params.lang);
        i18n.changeLanguage( this.props.params.lang);
        this.setState({
          selectedLang: selectedLang
        })
      }
    }
  }

  componentWillUnmount() {
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();
  }
  handleShowPass = (type) => {
    if (type === 1) {
      this.setState({
        showpass: !this.state.showpass,
        typepass: this.state.showpass ? "password" : "text",
      });

    } else if (type === 2) {
      this.setState({
        showconfirm: !this.state.showconfirm,
        typeconfirm: this.state.showconfirm ? "password" : "text",
      });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };
  hanldeLoadOTCCountryByRefCode = async () => {
    this.setState({ isLoading: true });
    try {
      let params = {
        RefCode: this.props.params.code
      }
      let response = await postNoAuth('/LoadOTCCountryByRefCode', params);
      if (response && response.data.errorCode === 0) {
        this.setState({ isLoading: false });
        if (response.data.data != null) {
          let selectedLang = this.state.arrLang.find(x => x.id === response.data.data.language);
          this.setState({
            lang: response.data.data.language,
            selectedLang: selectedLang
          })
        }
      } else {
        this.setState({ isLoading: false });
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error(error);
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData, selectedLang } = this.state;
    let formIsValid = true;
    const newErrors = {
      // chatid: '',
      password: '',
      confirmpassword: '',
      email: '',
      fullname: '',
      OTCRefcode: '',
    };
    // if (!formData.chatid) {
    //   formIsValid = false;
    //   newErrors.chatid = t('Please enter your Telegram Chat ID.');
    // }
    // if (!validateEmail(formData.email)) {
    //   formIsValid = false;
    //   newErrors.email = t('Please enter a valid email address.');
    // }
    if (!formData.password) {
      formIsValid = false;
      newErrors.password = t('Please enter your password.');
    }
    if (!validateMinLength(formData.password, 6)) {
      formIsValid = false;
      newErrors.password = t('minCharacterMessage', { length: 6 });
    }
    if (!validateMaxLength(formData.password, 16)) {
      formIsValid = false;
      newErrors.password = t('maxCharacterMessage', { length: 16 });
    }

    if (!formData.confirmpassword) {
      formIsValid = false;
      newErrors.confirmpassword = t('Please enter your confirm password.');
    }
    else if (formData.confirmpassword !== formData.password) {
      formIsValid = false;
      newErrors.confirmpassword = t('Passwords must match.');
    }
    // if (!formData.captcha) {
    //   formIsValid = false;
    //   newErrors.captcha = t('Please enter captcha code.');
    // }
    if (!formData.email) {
      formIsValid = false;
      newErrors.email = t('Please enter your email.');
    } 
    if (!validateEmail(formData.email.trim())) {
      formIsValid = false;
      newErrors.email = t('Please enter a valid email.');
    }
    if (!formData.fullname) {
      formIsValid = false;
      newErrors.fullname = t('Please enter your Full Name.');
    }
    // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn

    if (!formIsValid) {
      this.setState({ errors: newErrors });
    } else {
      this.setState({ errors: newErrors });
      // Submit form nếu hợp lệ
      this.setState({ isLoading: true });
      try {
        // const response = await get('/users?page=1');
        let params = {
          // "RefCode": this.props.params.code,
          // "TelegramChatID": formData.chatid,
          // "CaptchaCode": this.state.captchaCode,
          // "CaptchaToken": formData.captcha,
          "Email": formData.email.trim(),
          "FullName": formData.fullname,
          "Country": selectedLang.id,
          "OTCRefcode": this.props.params&&this.props.params.code?this.props.params.code:"",
          "PasswordAgain": formData.confirmpassword.trim(),
          "Password": formData.password.trim(),
        };
        const response = await postNoAuth('/Users/RegisterWithEmail', params);
        if (response.data.errorCode === 0) {
          this.setState({ isLoading: false });
          toast.success(t(response.data.data.notify), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            onClose: () => redirectToURL('/e-login')
          });
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        });
      }
    }
  };
  changeLanguage = (lang) => {
    this.setState({ selectedLang: lang });
    localStorage.setItem("lang",  lang.id);
    i18n.changeLanguage(lang.id);
  }
  render() {
    const { lang, formData, errors, showpass, showconfirm, typepass, typeconfirm, isLoading, arrLang, selectedLang } = this.state;
    const { t } = this.props;
    const lang_change = lang === "en" ? "vi" : "en";

    return (
      <>
        <main ref="main" className="outside register">
          <section className="section section-shaped section-lg">
            {/* <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div> */}
            <Container >
              <Row className="justify-content-center">
                {/* <Col lg="6" className="flex-center">
                  <Topic />
                </Col> */}
                <Col lg="5" className="mt-5 mb-5 flex-center-start" >
                  <Card className="bg-register shadow ">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        {/* <img
                          className="icon-lang absolute-lang pointer"
                          alt={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          title={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          src={require(`assets/img/flags/${lang_change}.png`)}
                          onClick={() => this.changeLanguage(lang_change)}
                        /> */}
                        <div className="txt-title">{t("Sign up")}</div>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-3 ">
                          <Input placeholder="Email" className="input-outside" name="email" value={formData.email} onChange={this.handleChange} />
                          {errors.email && <div className="error">{errors.email}</div>}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Input placeholder={t("Full Name")} className="input-outside" name="fullname" value={formData.fullname} onChange={this.handleChange} />
                          {errors.fullname && <div className="error">{errors.fullname}</div>}
                        </FormGroup>
                        <FormGroup className="form-outside">
                          <InputGroup>
                            <Input
                              placeholder={t("Password")}
                              type={typepass}
                              autoComplete="off"
                              className="input-outside"
                              name="password" value={formData.password} onChange={this.handleChange}
                            />
                            <InputGroupAddon addonType="append" title={t("Show Password")} >
                              <InputGroupText className="input-outside">
                                <span className="pointer" onClick={() => this.handleShowPass(1)}> <span className="btn-inner--text btn-span"><i className={!showpass ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.password && <div className="error">{errors.password}</div>}
                        </FormGroup>
                        <FormGroup className="form-outside">
                          <InputGroup>
                            <Input
                              placeholder={t("Confirm Password")}
                              type={typeconfirm}
                              className="input-outside"
                              autoComplete="off"
                              name="confirmpassword" value={formData.confirmpassword} onChange={this.handleChange}
                            />
                            <InputGroupAddon addonType="append" title={t("Show Password")} >
                              <InputGroupText className="input-outside">
                                <span className="pointer" onClick={() => this.handleShowPass(2)}> <span className="btn-inner--text btn-span"><i className={!showconfirm ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.confirmpassword && <div className="error">{errors.confirmpassword}</div>}
                        </FormGroup>
                        <UncontrolledDropdown direction={"down"} className="input-lang">
                          <DropdownToggle className="input-lang-toogle" >
                            {selectedLang &&
                              <div className=" flex-center item-lang">
                                <img className={`icon-lang`} alt="..." src={require(`assets/img/flags/flaglang/${selectedLang.id}.png`)} />&nbsp; {selectedLang.name}
                                <div className="icon-chevron"><i className="fa fa-chevron-down"></i></div>
                              </div>}
                          </DropdownToggle>
                          <DropdownMenu className="input-drop-language">
                            {arrLang && arrLang.map((item, index) => (
                              <li key={`flag${index}`}>
                                <DropdownItem onClick={() => this.changeLanguage(item)} className="flex-center">
                                  <img
                                    className="icon-search"
                                    alt={item.name}
                                    title={item.name}
                                    src={require(`assets/img/flags/flaglang/${item.id}.png`)}
                                  />
                                  &nbsp;
                                  <div className="name">{item.name}</div>
                                </DropdownItem>
                              </li>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>


                        {/* <FormGroup>
                          <InputGroup className="">
                            <Input
                              placeholder={t("Captcha Code")}
                              type="text"
                              autoComplete="off"
                              name="captcha" value={formData.captcha} onChange={this.handleChange}
                            />
                            <InputGroupAddon addonType="append" >
                              <InputGroupText >
                                <img src={imgCaptcha} alt='' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.captcha && <div className="error">{errors.captcha}</div>}
                        </FormGroup> */}
                        <div>
                          <Button
                            className="button-outside"
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            {t("Sign up")}
                          </Button>
                        </div>
                      </Form>
                      <Row className="mt-3">
                        <Col xs="12" className="text-center">
                          <a
                            className="text-dark"
                            href="/e-login"
                          >
                            <small>{t("Already have an account?")} <span className="text-underline">{t("Sign in")}</span></small>
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="txt-register text-center">
                <div dangerouslySetInnerHTML={{ __html: t("HTMLTitleOutside") }}></div>
                {/* <span className="blue">{t("Superior")}</span>&nbsp;<span className="orange">{t("Benefits")}</span> */}
              </div>
              <Row className="justify-content-center text-center row-content">
                <Col lg="3" className="col-content">
                  <div>
                    <div className="header-content">{t("Learn Anytime, Anywhere")}</div>
                    <div className="col-line"></div>
                    <div className="body-content d-flex">
                      <img src={require("assets/img/icon-check.png")} className="icon-check" />
                      <div>
                      {t("Save travel time and study more effectively with concise and personalized lessons, allowing you to learn anytime, anywhere, on any device.")}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3" className="col-content-center justify-content-center">
                  <div>
                    <div className="header-content">{t("Study Motivation")}</div>
                    <div className="col-line"></div>
                    <div className="body-content d-flex">
                      <img src={require("assets/img/icon-check.png")} className="icon-check" />
                      <div>
                        {t("Attractive Reward System: Opportunity to receive scholarships after completing each module.")}
                        {t("Hunt for Valuable Scholarships: Participate in the scholarship hunt program when you complete the task of introducing new friends. Scholarships can be worth up to tens of thousands of USD, helping you save on tuition costs and providing additional motivation to study better.")}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3" className="col-content">
                  <div>
                    <div className="header-content">{t("Economic Potential")}</div>
                    <div className="col-line"></div>
                    <div className="body-content d-flex">
                      <img src={require("assets/img/icon-check.png")} className="icon-check" />
                      <div>
                        {t("Learn and Earn: Join the new member referral program and receive 2000 Bit tokens for the first activation. Share your knowledge and study experiences with the community, attract new learners, and earn commissions from them.")}
                        {t("Earn Passive Income: Build a large network of learners and earn stable passive income. Any new member who joins will need the first 100 Bits, and you can use the 2000 Bits gifted to share with new users. The value of 1 Bit is equivalent to 1 USD.")}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <ToastContainer pauseOnFocusLoss={false} />
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(withRouter(Register));
