import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Select from 'react-select'
import Loading from "../../components/Loading/Loading"
import { post, checkToken } from "services/apiServices";
import { validateEmail, cutNumberFormat, FormatNumberUSDT } from "services/common";

const options = [
    { value: "TRC20", label: 'TRON (TRC20)' },
    // { value: "BEP20", label: 'BNB Smart Chain (BEP20)' },
]

class WithdrawTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                Network: null,
                AddressOrEmail: '',
                Amount: 0,
                ReceiveAmount: "",
                FeeAmount: 0,
                OTP: '',
                GACode: '',
            },
            errors: {
                Network: '',
                AddressOrEmail: '',
                Amount: '',
                FeeAmount: '',
                OTP: '',
                GACode: '',
            },
            intervalFee: null,
            IsInternalTransfer: false
        }
        this.inputRef = React.createRef();
    }
    clearForm = () => {
        let newerrors = {
            Network: '',
            AddressOrEmail: '',
            Amount: '',
            FeeAmount: '',
            OTP: '',
            GACode: '',
        };
        let _data = {
            Network: null,
            AddressOrEmail: '',
            Amount: 0,
            ReceiveAmount: "",
            FeeAmount: 0,
            OTP: '',
            GACode: '',
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleWithdrawTransfer = async (e) => {
        const { formData, IsInternalTransfer } = this.state;
        let formIsValid = true;
        const newErrors = {
            Network: '',
            AddressOrEmail: '',
            Amount: '',
            FeeAmount: '',
            OTP: '',
            GACode: '',
        };
        if (!formData.AddressOrEmail) {
            formIsValid = false;
            newErrors.AddressOrEmail = t('Please enter your Address.');
        }
        if (!validateEmail(formData.AddressOrEmail) && formData.Network === null) {
            formIsValid = false;
            newErrors.AddressOrEmail = t('Please enter your address or a valid email.');
        }
        if (!IsInternalTransfer && validateEmail(formData.AddressOrEmail)) {
            formIsValid = false;
            newErrors.AddressOrEmail = t('Email does not exist in the system.');
        }
        if (!formData.Amount) {
            formIsValid = false;
            newErrors.ToAddress = t('Please enter your Withdraw Amount.');
        }
        if (formData.Amount <= 0) {
            formIsValid = false;
            newErrors.Amount = t('Input value must be greater than 0.');
        }
        // if (!formData.Network) {
        //     formIsValid = false;
        //     newErrors.Network = t('Invalid symbol.');
        // }
        // if (formData.Amount > this.props.BalanceUSDT) {
        //     formIsValid = false;
        //     newErrors.Amount = t('Not enough balance.');
        // }       
        if (this.props.TwoFA) {
            if (!formData.GACode) {
                formIsValid = false;
                newErrors.GACode = t('Please enter your Code.');
            }
        } else {
            if (!formData.OTP) {
                formIsValid = false;
                newErrors.OTP = t('Please enter OTP.');
            }
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Symbol: formData.Network ? formData.Network.value : "TRC20",
                AddressOrEmail: formData.AddressOrEmail,
                Amount: Number(formData.Amount),
                FeeAmount: formData.FeeAmount,
                OTP: formData.OTP,
                GACode: formData.GACode
            };
            this.props.hanldeWithdrawTransfer(params);
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handlePasteToInput = (type) => {
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AddressOrEmail: pastedText },
                        }));
                        this.handleCheckInternal(pastedText);
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, OTP: pastedText },
                        }));
                    } else if (type === 3) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, GACode: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };
    handleGetOTP = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/GetOTP', { Type: 1 });
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t(response.data.data.notify), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleGetFee = async (network) => {
        try {
            let response = await post('/Users/GetFeeWithdrawBySymbol', { Symbol: network });
            if (response.data.errorCode === 0) {
                this.setState((prevState) => ({
                    formData: { ...prevState.formData, FeeAmount: response.data.data.fee },
                }));
                this.handleChangeAmount(this.state.formData.Amount, response.data.data.fee);
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleToogleModalWithdrawTransfer = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalWithdrawTransfer();
    };

    handleFillMaxAmount = () => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: this.props.BalanceUSDT },
        }));
        this.handleChangeAmount(this.props.BalanceUSDT, this.state.formData.FeeAmount);
    };
    handleSelectChange = (e) => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Network: e },
        }));
    };
    checkValidateAddress = (address) => {
        if (/^(T)([a-km-zA-HJ-NP-Z0-9]){33}$/.test(String(address))) {
            return "TRC20";
        }
        else if (/^0x[0-9a-fA-F]{40}$/.test(String(address))) {
            return "BEP20";
        }
        return "";
    }
    handleCheckAddress = (value, IsInternalTransfer) => {
        const { intervalFee, formData } = this.state;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, AddressOrEmail: value },
        }));
        let network = this.checkValidateAddress(value);
        let select_option = options.find(x => x.value === network);
        if (select_option) {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: select_option },
            }));
        } else {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: null },
            }));
        }
        if (!IsInternalTransfer) {
            if (select_option) {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.handleGetFee(network);
                let _intervalFee = setInterval(() => {
                    this.handleGetFee(network);
                }, 5000);
                this.setState({ intervalFee: _intervalFee });
            } else {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.setState((prevState) => ({
                    formData: { ...prevState.formData, FeeAmount: 0 },
                }));
                this.handleChangeAmount(formData.Amount, 0)
            }
        } else {
            if (intervalFee) {
                clearInterval(intervalFee);
            }
            this.setState((prevState) => ({
                formData: { ...prevState.formData, FeeAmount: 0 },
            }));
            this.handleChangeAmount(formData.Amount, 0)
        }
        let errors = ""
        if (!formData.AddressOrEmail) {
            errors = t('Please enter your Address.');
        }
        if (!validateEmail(formData.AddressOrEmail) && !select_option) {
            errors = t('Please enter your address or a valid email.');
        }
        if (!IsInternalTransfer && validateEmail(formData.AddressOrEmail)) {
            errors = t('Email does not exist in the system.');
        }
        this.setState((prevState) => ({
            errors: { ...prevState.errors, AddressOrEmail: errors },
        }));
    };
    handleChangeAmount = (value, fee) => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: value },
        }));
        let amountReceive = cutNumberFormat(value - fee, 0.01);
        this.setState((prevState) => ({
            formData: { ...prevState.formData, ReceiveAmount: amountReceive },
        }));
    };
    handleCheckInternal = async (value) => {
        try {
            let response = await post('/Users/CheckInternalTransfer', { AddressOrEmail: value });
            if (response.data.errorCode === 0) {
                this.setState({ IsInternalTransfer: response.data.data.isInternalTransfer });
                this.handleCheckAddress(value, response.data.data.isInternalTransfer);
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleLoadModal = () => {
        this.clearForm()
    }
    handleCloseModal = () => {
        const { intervalFee } = this.state;
        if (intervalFee) {
            clearInterval(intervalFee);
        }
    }
    render() {
        const { modalWithdrawTransferOpen, TwoFA, BalanceUSDT, info } = this.props;
        const { isLoading, formData, errors } = this.state;

        return (
            <>
                <Modal toggle={this.handleToogleModalWithdrawTransfer} isOpen={modalWithdrawTransferOpen} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal}>
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Withdraw")}
                        </h5>
                        {info && !info.otc && <Button className="btn-vndusdt ml-auto"
                            color="warning"
                            onClick={this.props.onToogleModalSellUSDT}
                        >  {t("VNDUSDT")}
                        </Button>}
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalWithdrawTransfer}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="Amount">{t("Amount")}</label>
                                    <small className="ml-auto txt-small text-main-color">{t("Available Balance")}: {FormatNumberUSDT(BalanceUSDT)} USDT</small>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder="0"
                                        type="number"
                                        autoComplete="off"
                                        name="Amount" value={formData.Amount} onChange={(e) => this.handleChangeAmount(e.target.value, formData.FeeAmount)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <span className="text-success pointer" onClick={this.handleFillMaxAmount}>&nbsp;{t("MAX")}</span> &nbsp;| &nbsp;USDT&nbsp;
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.Amount && <div className="error">{errors.Amount}</div>}
                            </FormGroup>
                            <FormGroup >
                                <label>{t("Network Fee")}</label>
                                <Input placeholder='0' type="text" value={FormatNumberUSDT(formData.FeeAmount)} disabled />
                            </FormGroup>
                            <FormGroup >
                                <label>{t("Receive Amount")}</label>
                                <Input placeholder='0' type="text" value={formData.ReceiveAmount} disabled />
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="AddressOrEmail">{t("Address")} {t("or")} Email</label>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Address")} ${t("or")} Email`}
                                        type="text"
                                        autoComplete="off"
                                        name="AddressOrEmail" value={formData.AddressOrEmail} onChange={this.handleChange} ref={this.inputRef} onBlur={(e) => this.handleCheckInternal(e.target.value)}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(1)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AddressOrEmail && <div className="error">{errors.AddressOrEmail}</div>}
                            </FormGroup>
                            <FormGroup >
                                <label>{t("Network")}</label>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isSearchable={false}
                                    classNamePrefix="custom-select"
                                    placeholder={t("Network")}
                                    options={options}
                                    value={formData.Network}
                                    onChange={this.handleSelectChange}
                                />
                                {errors.Network && <div className="error">{errors.Network}</div>}
                            </FormGroup>
                            {!TwoFA && <FormGroup>
                                <label>OTP</label>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={t("OTP")}
                                        type="text"
                                        autoComplete="off"
                                        name="OTP" value={formData.OTP} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText >
                                            <span className="btn-sm pointer" onClick={() => this.handlePasteToInput(2)} title={t("Paste")}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>&nbsp;
                                            &nbsp;| &nbsp;<span className="btn-sm pointer" onClick={this.handleGetOTP} title={t("Get OTP")}> {t("Get OTP")}</span>&nbsp;
                                            {/* <Button
                                                className="btn-sm"
                                                color="success"
                                                type="button"
                                                onClick={this.handleGetOTP}
                                                title={t("Get OTP")}
                                            >
                                                {t("Get OTP")}
                                            </Button> */}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.OTP && <div className="error">{errors.OTP}</div>}
                            </FormGroup>}
                            {TwoFA &&
                                <FormGroup>
                                    <label>{t("Google Authenticator Code")}</label>
                                    <InputGroup className="input-group">
                                        <Input
                                            placeholder={t("Google Authenticator Code")}
                                            type="text"
                                            autoComplete="off"
                                            name="GACode" value={formData.GACode} onChange={this.handleChange} ref={this.inputRef}
                                        />
                                        <InputGroupAddon addonType="append" title={t("Paste")} >
                                            <InputGroupText >
                                                <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(3)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {errors.GACode && <div className="error">{errors.GACode}</div>}
                                </FormGroup>
                            }
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalWithdrawTransfer}
                        >                            {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.handleWithdrawTransfer}>
                            {t("Withdraw")}
                        </Button>
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(WithdrawTransfer);
