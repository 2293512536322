import React from "react";
// reactstrap components
import {
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { t } from "i18next";
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { get, post, checkToken, postFormData } from "services/apiServices";
import { formatDate, FormatNumberUSDT, hideCharacters, formatNumber } from "services/common";
import Loading from "../../components/Loading/Loading";
import SellUSDT from "views/Home/SellUSDT";
import ModalConfirm from "../../components/ConfirmModal/Confirm"
import Resizer from "react-image-file-resizer";
// import BuyUSDT from "views/Home/BuyUSDT";
import { Link } from "react-router-dom";
import configs from "configs/configs";
import BuyCoin from "views/Home/BuyCoin";

// import "moment/locale/vi";
if (localStorage.getItem("lang") === "vi") {
    moment.locale('vi');
    moment.updateLocale('vi', {
        months: [
            'Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6',
            'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'
        ],
        monthsShort: [
            '01', '02', '03', '04', '05', '06',
            '07', '08', '09', '10', '11', '12'
        ],
        weekdays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
    });
}

// import DateTimeField from 
const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]


class RPTBuySellUSDT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modalBankInfoOpen: false,
            modalBankUpdateOpen: false,
            modalSellUSDTOpen: false,
            selectedTrans: null,
            showReceipt: false,
            Receipt: "",
            modalConfirmTransferOpen: false,
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            errReceipt: "",
            showConfirm: false,
            confirmText: "",
            confirmTitle: "",
            currentType: 0,

        };
    }

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handleToogleBankInfo = () => {
        this.setState(prevState => ({
            modalBankInfoOpen: !prevState.modalBankInfoOpen
        }));
    }
    showBankInfo = (item) => {
        this.setState({ selectedTrans: item, modalBankInfoOpen: true });
    }
    clearInterval = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
    }
    handleUpdateBankInfo = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Purchase/UpdateBankInfo', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Update successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ modalBankUpdateOpen: false });
                // let _params = { ...this.state.params };
                // //reset pageindex
                // this.setState((prevState) => ({
                //     params: { ...prevState.params, PageIndex: 0 },
                // }));
                // _params.PageIndex = 1;
                // this.props.handleGetList(_params);
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    onToogleModalBankUpdate = () => {
        this.setState(prevState => ({
            modalBankUpdateOpen: !prevState.modalBankUpdateOpen
        }));
    }
    onToogleModalSellUSDT = () => {
        this.setState(prevState => ({
            modalSellUSDTOpen: !prevState.modalSellUSDTOpen
        }));
    }
    hanldeSellUSDT = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Purchase/SellUSDT', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState(prevState => ({
                    modalSellUSDTOpen: !prevState.modalSellUSDTOpen
                }));
                let _params = { ...this.state.params };
                //reset pageindex
                this.setState((prevState) => ({
                    params: { ...prevState.params, PageIndex: 0 },
                }));
                _params.PageIndex = 1;
                this.props.handleGetList(_params);
                this.props.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleshowReceipt = (url) => {
        this.setState(prevState => ({
            showReceipt: !prevState.showReceipt, Receipt: url
        }));
    }
    onToogleShowReceipt = () => {
        this.setState(prevState => ({
            showReceipt: !prevState.showReceipt
        }));
    }
    handleOpenModalConfirmTransfer = (item) => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.showReceipt,
            errReceipt: "",
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            selectedTrans: item
        }));
    }
    onToogleConfirmTransfer = () => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
        }));
    }
    hanldeConfirmTransfer = async (confirmData) => {
        // const { confirmData,selectedTrans } = this.state;
        const { info } = this.props;
        let formIsValid = true;
        if (!confirmData.Receipt) {
            formIsValid = false;
            this.setState({ errReceipt: t('Please choose a receipt.') });
        }
        if (formIsValid) {
            this.setState({ errReceipt: "" });
            let _formData = new FormData();
            _formData.append('Receipt', confirmData.Receipt ? confirmData.Receipt : null);
            _formData.append('TransID', confirmData.transID ? Number(confirmData.transID) : 0);
            this.setState({ isLoading: true });
            let url = "/Purchase/ConfirmTransfer"
            if (info.otc) {
                url = "/Purchase/OTCConfirmTransfer"
            }
            try {
                const response = await postFormData(url, _formData);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Requested successfully"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    this.setState(prevState => ({
                        modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
                    }));
                    // let _params = { ...this.state.params };
                    // //reset pageindex
                    // this.setState((prevState) => ({
                    //     params: { ...prevState.params, PageIndex: 0 },
                    // }));
                    // _params.PageIndex = 1;
                    // this.props.handleGetList(_params);
                    this.props.handleGetListBuySellPending();
                    this.props.hanldeLoadBasicInfo();
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    }
    handleFileSelected = (e, currentTicket) => {
        // debugger
        const files = Array.from(e.target.files);
        //resize image
        if (files[0]) {
            try {
                Resizer.imageFileResizer(
                    files[0],
                    768,
                    768,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        const imageUrl = URL.createObjectURL(uri);
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, Receipt: uri },
                        }));
                        this.hanldeConfirmTransfer({ Receipt: uri, transID: currentTicket.transID });
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, ReceiptURL: imageUrl }, errReceipt: ""
                        }));
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }
        }

        // console.log("img url", imageUrl);

    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        const { info } = this.props;
        let params = {
            TransID: this.state.selectedTrans.transID
        };
        this.setState({ isLoading: true });
        let url = "";
        if (this.state.currentType === 1) {
            url = "/Purchase/OTCAccecptedBuySell";
        } else if (this.state.currentType === 2) {
            if (info.otc) {
                url = "/Purchase/OTCConfirmReceiveTransfer"
            }
            else {
                url = "/Purchase/ConfirmReceiveTransfer"
            }
        } else if (this.state.currentType === 3) {
            url = "/Purchase/CancelBuySellUSDT";
        } else if (this.state.currentType === 4) {
            url = "/Purchase/OTCConfirmReceiveTransferNoBill";
        } else if (this.state.currentType === 5) {
            url = "/Purchase/OTCReportFakeBillBuyUSDT";
        } else if (this.state.currentType === 6) {
            url = "/Purchase/ReportFakeBillSellUSDT";
        } else if (this.state.currentType === 7) {
            url = "/Purchase/OTCReportBankInfo";
        }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                this.props.handleGetListBuySellPending();
                // let _params = { ...this.state.params };
                // //reset pageindex
                // this.setState((prevState) => ({
                //     params: { ...prevState.params, PageIndex: 0 },
                // }));
                // _params.PageIndex = 1;
                // this.props.handleGetList(_params);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item, type) {
        // Mở modal confirm
        let _confirmText = "";
        let _confirmTitle = "";
        switch (type) {
            case 1: {//OTC Accept
                _confirmText = "Are you sure you want to confirm this transaction?";
                break;
            }
            case 2: {//OTC Confirm received payment
                _confirmText = "I have received payment.";
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 3: {//Seller cancel
                _confirmText = "Are you sure you want to cancel this transaction?";
                _confirmTitle = "Cancel transaction";
                break;
            }
            case 4: {//OTC Confirm received payment not up reciept yet
                _confirmText = "I have received payment.";
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 5: {//OTC report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
            case 6: {//User report fake reciept
                _confirmText = "Report receipt as fake.";
                _confirmTitle = "Report";
                break;
            }
            case 7: {//User report incorrect bank info
                _confirmText = "Report incorrect bank account information.";
                _confirmTitle = "Report";
                break;
            }
        }
        this.setState({ selectedTrans: item, confirmText: t(_confirmText), confirmTitle: _confirmTitle, currentType: type });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    checkStatusText = (ticket) => {
        const { info } = this.props;
        let statusText = ticket.statusText;
        if (ticket.type === 1) { //buyer
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for sellers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "The invoice has not been uploaded yet";
                if (info.otc) {
                    statusText = "Waiting for the money transfer";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Waiting for the seller to confirm";
                if (info.otc) {
                    statusText = "Confirmation of receipt of money";
                }
            }
        } else {//seller
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for buyers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "Waiting for the money transfer";
                if (info.otc) {
                    statusText = "The invoice has not been uploaded yet";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Confirmation of receipt of money";
                if (info.otc) {
                    statusText = "Waiting for the seller to confirm";
                }
            }
        }
        return statusText;
    }
    render() {
        const { t, info, bankInfo, data, total, modalBuyUSDTOpen, modalSellUSDTOpen } = this.props;
        const { params, isLoading, modalBankInfoOpen, selectedTrans, showReceipt, Receipt, errReceipt, confirmData, confirmText, showConfirm, confirmTitle, modalConfirmTransferOpen } = this.state;
        let currentTicketBuy = data ? data.find(x => x.type === 1) : null;
        let currentTicketSell = data ? data.find(x => x.type === 2) : null;
        const teleBotlink = configs.TeleBotOTCLink + info.teleBotKey;
        return (
            <>
                {(info.otc || (data && data.length > 0)) && <Table striped bordered responsive className="rpt-table" >
                    <thead>
                        <tr>
                            <th >{t("Status")}</th>
                            <th >{t("Request Date")}</th>
                            <th >{t("Type")}</th>
                            <th >{t("Buyer/Seller")}</th>
                            <th >{t("Quantity")} (USDT)</th>
                            <th >{t("Price")} (VND/USDT)</th>
                            <th >{t("Amount")} (VND)</th>
                            <th >{t("Receipt")}</th>
                            <th className=" text-center cover-fund-th">
                                <div className="cover-button-header">
                                    {/* <Button className={`${info.otc ? "btn-deposit" : "btn-withdraw"} btn-fund btn-otc`} color={info.otc ? "success" : "secondary"} type="button" title={info.otc ? t("Cancel registration as OTC") : t("Register as OTC")} onClick={this.props.onOpenRegisterOTC}>OTC</Button> */}
                                    {info.otc && <a className={`btn-fund btn-otc btn-bot-otc`} title={t("Telegram")} href={teleBotlink} target="_blank"><i className="fa fa-telegram"></i></a>}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => {
                            let statusText = this.checkStatusText(item);
                            return (
                                <tr key={index}>
                                    <td >
                                        <div className="pb-1">
                                            {t(statusText)}
                                        </div>
                                        {info.otc && <>
                                            {/*Pending*/}
                                            {item.status === 1 && <div className="pb-1">
                                                <span className="btn-pill primary" onClick={() => this.handleOpenModal(item, 1)}>{t("Accept")} {item.type === 1 ? t("Sell").toLowerCase() : t("Buy").toLowerCase()}</span>
                                            </div>}
                                            {/*Accept*/}
                                            {item.status === 2 && item.type === 2 && item.timeRemain && item.timeRemain.total > 0 && <>
                                                <div className="pb-1">
                                                    <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 7)}>{t("Report")}</span>
                                                    <label htmlFor={`fileImageRPT${item.transID}`} className="btn-pill success">
                                                        {t("Upload receipt")}
                                                    </label>
                                                    <Input type="file" onChange={(e) => this.handleFileSelected(e, item)} id={`fileImageRPT${item.transID}`} className="file-input" accept="image/*" />
                                                    {/* <span className="btn-pill success" onClick={() => this.handleOpenModalConfirmTransfer(item)}>{t("Transfer")}</span> */}
                                                    <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 3)}>{t("Cancel")}</span>
                                                </div>
                                            </>}
                                            {/*Transferred*/}
                                            {item.status === 3 && item.type === 1 && item.timeRemain && item.timeRemain.total > 0 && <>
                                                <div className="pb-1">
                                                    <span className="btn-pill primary" onClick={() => this.handleOpenModal(item, 2)}>{t("Received money")}</span>
                                                    <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 5)}>{t("Report")}</span>
                                                </div>
                                                {/* {item.timeRemain && item.timeRemain.total > 0 && <div className="text-alert pt-1"> {(item.timeRemain.minutes > 9 ? item.timeRemain.minutes + "" : "0" + item.timeRemain.minutes) + ":" + (item.timeRemain.seconds > 9 ? item.timeRemain.seconds + "" : "0" + item.timeRemain.seconds)}</div>} */}
                                            </>
                                            }
                                            {/*Confirm when not reciept yet */}
                                            {item.status === 2 && item.type === 1 && <div className="pb-1">
                                                <span className="btn-pill primary" onClick={() => this.handleOpenModal(item, 4)}>{t("Received money")}</span>
                                            </div>}
                                        </>}
                                        {!info.otc && <>
                                            {/*Pending*/}
                                            {(item.status === 1 || item.status === 8) && <div className="pb-1">
                                                <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 3)}>{t("Cancel")}</span>
                                            </div>
                                            }
                                            {/*Accept*/}
                                            {item.status === 2 && item.type === 1 && item.timeRemain && item.timeRemain.total > 0 && <>
                                                <div className="pb-1">
                                                    <label htmlFor={`fileImageRPT${item.transID}`} className="btn-pill success">
                                                        {t("Upload receipt")}
                                                    </label>
                                                    <Input type="file" onChange={(e) => this.handleFileSelected(e, item)} id={`fileImageRPT${item.transID}`} className="file-input" accept="image/*" />
                                                    {/* <span className="btn-pill success" onClick={() => this.handleOpenModalConfirmTransfer(item)}>{t("Transfer")}</span> */}
                                                    <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 3)}>{t("Cancel")}</span>
                                                </div>
                                            </>}
                                            {/*Transferred*/}
                                            {item.status === 3 && item.type === 2 && item.timeRemain && item.timeRemain.total > 0 && <>
                                                <div className="pb-1">
                                                    <span className="btn-pill primary" onClick={() => this.handleOpenModal(item, 2)}>{t("Confirm")}</span>
                                                </div>
                                            </>
                                            }
                                            {item.status === 3 && item.type === 2 && item.timeRemainReport && item.timeRemainReport.total > 0 && <>
                                                <span className="btn-pill danger" onClick={() => this.handleOpenModal(item, 6)}>{t("Report")}</span>
                                            </>
                                            }
                                        </>}
                                        {(item.status === 2 || item.status === 3) && item.timeRemain && item.timeRemain.total > 0 && <div className="text-alert pt-1"> {(item.timeRemain.minutes > 9 ? item.timeRemain.minutes + "" : "0" + item.timeRemain.minutes) + ":" + (item.timeRemain.seconds > 9 ? item.timeRemain.seconds + "" : "0" + item.timeRemain.seconds)}</div>}
                                        {item.status === 3 && item.timeRemainReport && item.timeRemainReport.total > 0 && <div className="text-alert pt-1"> {(item.timeRemainReport.minutes > 9 ? item.timeRemainReport.minutes + "" : "0" + item.timeRemainReport.minutes) + ":" + (item.timeRemainReport.seconds > 9 ? item.timeRemainReport.seconds + "" : "0" + item.timeRemainReport.seconds)}</div>}
                                    </td>
                                    <td >{formatDate(item.requestDate)}</td>
                                    <td >{item.accNumberBank ? <span className="text-highlight pointer" onClick={() => this.showBankInfo(item)} title={t("Transfer information")}>{t(item.typeText)} <i className="fa fa-bank" /></span> : t(item.typeText)}</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {item.buySellName && <span className="show-email pointer" onClick={() => this.props.handleshowEmail(item, 1)}> <span className="btn-inner--text btn-span"><i className={!item.showEmail ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>}
                                            <span className="address-trunc " title={t(item.buySellName)} >{item.showEmail ? item.buySellName : hideCharacters(item.buySellName)}</span>
                                            &nbsp;{item.buySellName && <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(item.buySellName)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>}
                                        </div>
                                    </td>
                                    <td className="num">{FormatNumberUSDT(item.amountUSDT)}</td>
                                    <td className="num">{formatNumber(item.priceVND)}</td>
                                    <td className="num">{formatNumber(item.amountVND)}</td>
                                    <td >{item.receiptPath && <i className="fa fa-image pointer" title={t("Show receipt")} onClick={() => this.handleshowReceipt(item.receiptPath)}></i>}</td>
                                    <td className=" text-center ">
                                        {item.transferCode}&nbsp;
                                        {item.transferCode && <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(item.transferCode)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>}
                                    </td>

                                </tr>
                            )
                        })}
                        {(!data || data.length === 0) && <>
                            <tr className="text-center"><td colSpan={9}>{t("No data")}</td></tr>
                        </>}
                        {/* <tr className="r-total">
                            <td colSpan={4} className="text-center">{t("Total")}</td>
                            <td className="num">{total ? FormatNumberUSDT(total.totalAmountUSDT) : 0}</td>
                            <td className="num"></td>
                            <td className="num">{total ? formatNumber(total.totalAmountVND) : 0}</td>
                            <td className="num"></td>
                            <td className="num"></td>
                        </tr> */}
                    </tbody>
                </Table>}

                {isLoading && <Loading />}
                <Modal toggle={this.handleToogleBankInfo} isOpen={modalBankInfoOpen} size="lg">
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Transfer information")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.handleToogleBankInfo}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        {selectedTrans && <>
                            <p>{t("Transfer money to ")}</p>
                            <p>{t("Bank account number")}: <b className="text-bold">{selectedTrans.accNumberBank} </b>&nbsp; {selectedTrans.accNumberBank && <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedTrans.accNumberBank)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>}</p>
                            <p>{t("Bank account name")}: <b className="text-bold">{selectedTrans.cardholderNameBank}</b></p>
                            <p>{t("Bank name")}: <b className="text-bold">{selectedTrans.nameBank}</b> {selectedTrans.nameBank && <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedTrans.nameBank)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>}</p>
                            <p>{t("Transfer content (transaction code)")}: <b className="text-bold">{selectedTrans.transferCode}</b> {selectedTrans.transferCode && <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedTrans.transferCode)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span>}</p>
                            <p>{t("exactly with the amount")} <b className="text-bold">{formatNumber(selectedTrans.amountVND ? selectedTrans.amountVND : 0)}</b> <span className="btn-copy pointer text-primary" title={t("Copy")} onClick={() => this.handleCopyClick(selectedTrans.amountVND)}> <span className="btn-inner--text"><i className="ni ni-single-copy-04"></i></span></span> {t("to be confirmed for this transaction.")}</p>
                            {/* {timeRemain && timeRemain.total > 0 && <div className="text-alert pb-1">{(timeRemain.minutes > 9 ? timeRemain.minutes + "" : "0" + timeRemain.minutes) + ":" + (timeRemain.seconds > 9 ? timeRemain.seconds + "" : "0" + timeRemain.seconds)}</div>} */}
                        </>}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleBankInfo}
                        > {t("Close")}
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal toggle={this.onToogleShowReceipt} isOpen={showReceipt}>
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Receipt")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleShowReceipt}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="text-center">
                        <img src={Receipt} className="img-receipt"></img>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.onToogleShowReceipt}
                        > {t("Close")}
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* <Modal toggle={this.handleOpenModalConfirmTransfer} isOpen={modalConfirmTransferOpen} >
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Submit payment proof")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <label className="text-label">
                                {t("Attention! Please upload proof of payment so the transaction can be processed in case the seller does not confirm. Otherwise, after 15 minutes the transaction will be automatically canceled")}
                            </label>
                            <FormGroup>
                                <div >{t("Please upload the paid invoice for this transaction to confirm your payment.")} </div>
                                <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                    {t("Choose Image")}
                                </label></div>
                                <Input type="file" onChange={this.handleFileSelected} id="fileImage" className="file-input" accept="image/*" />
                                {confirmData.ReceiptURL && <div className="text-center">
                                    <img
                                        className="img-receipt"
                                        alt={confirmData.ReceiptURL}
                                        title={confirmData.ReceiptURL}
                                        src={confirmData.ReceiptURL}
                                    />
                                </div>}
                                <div className="text-center"> {errReceipt && <div className="error">{errReceipt}</div>}</div>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        > {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.hanldeConfirmTransfer}>
                            {t("Confirm")}
                        </Button>
                    </ModalFooter>
                </Modal> */}
                <ModalConfirm
                    message={confirmText}
                    title={confirmTitle}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />

                {/* <BankInfo modalBankInfoOpen={modalBankUpdateOpen} onToogleModalBankInfo={this.onToogleModalBankUpdate} handleUpdateBankInfo={this.handleUpdateBankInfo} bankInfo={bankInfo} /> */}
                <SellUSDT handleGetListBuySellPending={this.props.handleGetListBuySellPending} modalSellUSDTOpen={modalSellUSDTOpen} onToogleModalSellUSDT={this.props.onToogleModalSellUSDT} hanldeSellUSDT={this.props.hanldeSellUSDT} info={info} bankInfo={bankInfo} currentTicket={currentTicketSell} handleshowReceipt={this.handleshowReceipt} />
                <BuyCoin handleGetListBuySellPending={this.props.handleGetListBuySellPending} modalBuyUSDTOpen={modalBuyUSDTOpen} onToogleModalBuyUSDT={this.props.onToogleModalBuyUSDT} hanldeBuyUSDT={this.props.hanldeBuyUSDT} currentTicket={currentTicketBuy} info={info} hanldeLoadBasicInfo={this.props.hanldeLoadBasicInfo} handleshowReceipt={this.handleshowReceipt} />

            </>
        );
    }
}

export default withTranslation()(RPTBuySellUSDT);
