import React from "react";
// reactstrap components
import {
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Input,
    Button
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { t } from "i18next";
import Table from 'react-bootstrap/Table';
import Select from 'react-select'
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { hideCharacters, formatNumber, pushParamsToQueue, getParamsFromQueue, calculateTimeRemaining } from "services/common";
import withRouter from "../../components/common/withRouter";
import { get, checkToken } from "services/apiServices";
import { FormatNumberUSDT } from "services/common";
import Loading from "../../components/Loading/Loading"

// import DateTimeField from 
const sizeOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
]

class RPTMemberTree_NLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                Email: "",
                InputEmail: "",
                // FromDate: moment().format('yyyy-MM-DD'),
                // ToDate: moment().format('yyyy-MM-DD'),
                PageIndex: 0,
                PageSize: 10,
                IsDirect: true,
                IsF1WithdrawCom: 0,
                isChildPage: false
            },
            data: [],
            data_Sub: [],
            totalRows: 0,
            sortedColumn: 2, // Cột đang được sắp xếp
            sortDirection: 'desc', // Hướng sắp xếp ('asc' hoặc 'desc'),
            isLoading: false,
            timer: null,
            firstCount: true
            // startDate: moment(),
            // endDate: moment(),

        };
    }
    componentDidMount = () => {
        if (this.props.type === "3") {
            localStorage.setItem("paramsList", null);
            let _params = {
                Email: "",
                InputEmail: "",
                // FromDate: moment().format('yyyy-MM-DD'),
                // ToDate: moment().format('yyyy-MM-DD'),
                IsDirect: true,
                PageIndex: 1,
                PageSize: 10,
                IsF1WithdrawCom: 0,
                isChildPage: false
            }
            this.setState({
                data: [],
                TotalRows: 0,
                // startDate: moment(),
                // endDate: moment(),
            })
            this.handleGetMemberList(_params);
        }
    }
    handlePopState = () => {
        // Xử lý khi back lại hash trước đó
        // Ví dụ: console.log(window.location.hash);
    };
    handleSelectChange = (e) => {
        let _params = { ...this.state.params };
        if (e === null) {
            this.setState((prevState) => ({
                params: { ...prevState.params, IsF1WithdrawCom: 0 },
            }));
            _params.IsF1WithdrawCom = 0;
        } else {
            this.setState((prevState) => ({
                params: { ...prevState.params, IsF1WithdrawCom: e.value },
            }));
            _params.IsF1WithdrawCom = e.value;
        }
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        _params.IsDirect = false;
        this.handleGetMemberList(_params)
    };
    handlePageChange = (pageNumber) => {
        // Xử lý sự kiện chuyển trang ở đây
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: pageNumber.selected },
        }));
        let _params = { ...this.state.params };
        _params.PageIndex = pageNumber.selected + 1;
        this.handleGetMemberList(_params)
    };

    handleSearchChange = (e) => {
        this.setState((prevState) => ({
            params: { ...prevState.params, Email: e.target.value },
        }));
        this.setState((prevState) => ({
            params: { ...prevState.params, InputEmail: e.target.value },
        }));
    };

    handleLoadChild = (item) => {
        if (item.totalF1 > 0) {
            let email = item.email
            let _params = { ...this.state.params };
            //reset pageindex
            this.setState((prevState) => ({
                params: { ...prevState.params, InputEmail: "" },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, PageIndex: 0 },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, IsDirect: true },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, Email: email },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, isChildPage: true },
            }));
            _params.PageIndex = 1;
            _params.InputEmail = "";
            _params.Email = email;
            _params.IsDirect = true;
            //Save parent state
            this.setState((prevState) => ({ isLoading: !prevState.isLoading }));
            let params_parent = { ...this.state.params };
            pushParamsToQueue(params_parent);

            this.handleGetMemberList(_params);

        }
    };
    handleBack = () => {
        let _params = getParamsFromQueue();
        if (_params) {
            this.setState({ params: _params })
            let search_params = { ..._params }
            search_params.PageIndex = search_params.PageIndex + 1;
            this.handleGetMemberList(search_params);
        } else {
            _params = { ...this.state.params };
            //reset pageindex
            this.setState((prevState) => ({
                params: { ...prevState.params, PageIndex: 0 },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, IsDirect: true },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, InputEmail: "" },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, Email: "" },
            }));
            this.setState((prevState) => ({
                params: { ...prevState.params, isChildPage: false },
            }));
            _params.PageIndex = 1;
            _params.Email = "";
            _params.InputEmail = "";
            // _params.IsDirect = true;
            this.handleGetMemberList(_params);
        }

    };
    handleChangeSize = (e) => {
        let _params = { ...this.state.params };
        this.setState((prevState) => ({
            params: { ...prevState.params, PageSize: e.value },
        }));
        _params.PageSize = e.value;
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        this.handleGetMemberList(_params)
    };
    handleSearchAction = () => {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        if (_params.InputEmail) {
            this.setState((prevState) => ({
                params: { ...prevState.params, IsDirect: false },
            }));
            _params.IsDirect = false;
        }
        _params.PageIndex = 1;

        this.handleGetMemberList(_params);
    }
    handleSort(column) {
        const { sortedColumn, sortDirection } = this.state;
        // Kiểm tra nếu cột hiện tại đã được sắp xếp
        // Nếu đã sắp xếp theo cùng một cột, đảo ngược hướng sắp xếp
        let direction = "desc";
        if (sortedColumn === column) {
            direction = sortDirection === 'asc' ? 'desc' : 'asc';
        }
        // Cập nhật state với dữ liệu đã được sắp xếp và thông tin cột sắp xếp
        this.setState({
            sortedColumn: column,
            sortDirection: direction,
        });
        // this.setState((prevState) => ({
        //     params: { ...prevState.params, OrderByCol: column ? column : 2 },
        // }));
        // this.setState((prevState) => ({
        //     params: { ...prevState.params, OrderBy: direction === "asc" ? 0 : 1 },
        // }));

        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        // _params.OrderByCol = column ? column : 2;
        // _params.OrderBy = direction === "asc" ? 0 : 1;
        this.handleGetMemberList(_params)
    }
    hanldeChangeDate(e, type) {
        let _params = { ...this.state.params };
        //reset pageindex
        this.setState((prevState) => ({
            params: { ...prevState.params, PageIndex: 0 },
        }));
        _params.PageIndex = 1;
        let fromdate = { ...this.state.startDate };
        let todate = { ...this.state.endDate };
        if (type === 1) {
            this.setState({ startDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, FromDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.FromDate = moment(e).format('yyyy-MM-DD');
            fromdate = e;
        } else {
            this.setState({ endDate: e });
            this.setState((prevState) => ({
                params: { ...prevState.params, ToDate: moment(e).format('yyyy-MM-DD') },
            }));
            _params.ToDate = moment(e).format('yyyy-MM-DD');
            todate = e;
        }

        if (fromdate && todate) {
            if (moment(fromdate).isAfter(moment(todate))) {
                toast.error(t("'To Date' must be greater than 'From Date'"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                return false;
            }
        }
        this.handleGetMemberList(_params);
    }
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    getType = (type) => {
        switch (type) {
            case 1: return "Inactive"
            case 2: return "Active"
            case 3: return "Block"
            default: return "N/A"
        }
    }

    handleChange = (newDate) => {
        return this.setState({ date: newDate });
    }
    // componentDidUpdate(prevProps) {
    //     // Kiểm tra xem props có thay đổi không
    //     if (this.props.changeTabNLevel !== prevProps.changeTabNLevel) {
    //         let params = {
    //             Email: "",
    //             InputEmail: "",
    //             FromDate: moment().format('yyyy-MM-DD'),
    //             ToDate: moment().format('yyyy-MM-DD'),
    //             PageIndex: 0,
    //             PageSize: 10,
    //             IsDirect: true,
    //             IsF1WithdrawCom: 0
    //             // OrderByCol: 2,
    //             // OrderBy: 1
    //         };
    //         this.setState({
    //             params: params,
    //             sortedColumn: 2, // Cột đang được sắp xếp
    //             sortDirection: 'desc', // Hướng sắp xếp ('asc' hoặc 'desc'),
    //             startDate: moment(),
    //             endDate: moment(),
    //             isChildPage: false
    //         });
    //     }
    // }
    hanldeEnter = (e) => {
        var key = e.keyCode || e.which;
        e.preventDefault();
        if (key === 13) {
            this.handleSearchAction();
        }
        return false;
    }
    handleGetMemberList = async (_params) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetMember', _params);

            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false, TotalRows: response.data.totalRows, firstCount: true });
                // let _data = response.data.data;
                // _data.map((el, index) => {
                //     el = this.countDown(el);
                //     return el;
                // });
                this.setState({ data: response.data.data, data_Sub:response.data.data_Sub });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    componentDidUpdate(prevProps) {
        // Kiểm tra xem props có thay đổi không
        if (this.props.currentTab !== prevProps.currentTab && this.props.currentTab === "NLevel") {
            localStorage.setItem("paramsList", null);
            let _params = {
                Email: "",
                InputEmail: "",
                // FromDate: moment().format('yyyy-MM-DD'),
                // ToDate: moment().format('yyyy-MM-DD'),
                IsDirect: true,
                PageIndex: 1,
                PageSize: 10,
                IsF1WithdrawCom: 0,
                isChildPage: false
            }
            let params_state = { ..._params };
            params_state.PageIndex = params_state.PageIndex - 1;
            this.setState({
                data: [],
                TotalRows: 0,
                params: params_state
                // startDate: moment(),
                // endDate: moment(),
            })
            this.handleGetMemberList(_params);
        }
        // if (this.props.info.countdown !== prevProps.info.countdown) {
        //     let _data = [...this.state.data];
        //     _data.map((el, index) => {
        //         if (el.remainWithdrawHours !== "00:00:00") {
        //             el = this.countDown(el);
        //         }
        //         return el;
        //     });
        //     this.setState({ data: _data });
        // }
    }

    handleShowEmail = (item) => {
        if (!item.showEmail) {
            item.showEmail = true;
        } else {
            item.showEmail = false;
        }
        let _data = [...this.state.data];
        _data.map((el, index) => {
            if (el.email === item.email) {
                el = item;
                this.setState({ data: _data });
            }
            return el;
        });
    }
    countDown = (item) => {
        if (item) {
            let { info } = this.props;
            if (info && this.state.firstCount) {
                let timeString = item.remainWithdrawHours ? item.remainWithdrawHours : "00:00:00";
                let [hours, minutes, seconds] = timeString.split(':').map(Number);
                if (info.remainWithdrawHours !== '00:00:00' && info.seconds) {
                    item.totalSeconds = hours * 60 * 60 + minutes * 60 + info.seconds;
                    item.minutes = minutes;
                    item.hours = hours;
                    item.seconds = info.seconds;
                } else {
                    item.totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
                    item.minutes = minutes;
                    item.hours = hours;
                    item.seconds = seconds;
                }
                this.setState({ firstCount: false })
            } else {
                item.seconds--;
            }
            if (item.totalSeconds <= 0) {
                item.remainWithdrawHours = "00:00:00"
                return item;
            }
            if (item.seconds < 0) {
                item.minutes--;
                item.seconds = 59;
            }

            if (item.minutes < 0) {
                item.hours--;
                item.minutes = 59;
            }
            item.totalSeconds--;
            item.remainWithdrawHours = `${String(item.hours).padStart(2, '0')}:${String(item.minutes).padStart(2, '0')}:${String(item.seconds).padStart(2, '0')}`;
            return item;
        }
    }
    render() {
        const { t } = this.props;
        const { params, data, TotalRows, isLoading,data_Sub } = this.state;
console.log(data_Sub)
        const filterOptions = [
            { value: 0, label: t('All') },
            { value: 1, label: t('F1 Active Commission') },
        ]
        return (
            <>
                <div className="filter-group mb-3">
                    <div className="filter-select-lg">
                        <label className="text-bold">Email</label>
                        <InputGroup className="input-search">
                            <Input
                                placeholder={t("Email")}
                                type="text"
                                autoComplete="off"
                                name="Email" value={params.InputEmail} onChange={this.handleSearchChange}
                                onKeyUp={this.hanldeEnter}
                            />
                            <InputGroupAddon addonType="append" className="pointer" title={t("Search")}>
                                <InputGroupText onClick={this.handleSearchAction}>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="filter-select-lg">
                        <label className="text-bold">{t("Filter")}</label>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isSearchable={false}
                            options={filterOptions}
                            formatGroupLabel="Filter"
                            placeholder={t("Filter")}
                            defaultValue={filterOptions[0]}
                            value={filterOptions.find(x => x.value === params.IsF1WithdrawCom)}
                            onChange={(e) => this.handleSelectChange(e)}
                            classNamePrefix="custom-filter-select"
                        />
                    </div>
                    <div className="cover-fund1">
                        <div className="com-pending">{/*onClick={this.handleOpenModal}*/}
                            {data_Sub && <div className="text-primary-color href-link com-num com-num1"  >
                                <span title={t("Total Commission")}> <span className="pointer" onClick={() => this.handleGetListCom(1)} title={t("Max Bonus")}>{FormatNumberUSDT(data_Sub.totalMaxBonusBranch)}</span>
                                    &nbsp;<span className="text-high">+</span> <span className="pointer" onClick={() => this.handleGetListCom(2)} title={t("Min Bonus")} >{ FormatNumberUSDT(data_Sub.totalMinBonusBranch) } </span>
                                    {/* <span className="text-high">+</span> <span className="" title={t("Cutoff Commission")}>{info.comCutOff ? FormatNumberUSDT(info.comCutOff) : 0}</span>  */}
                                    <span className="text-high">=</span>&nbsp; <span className="" title={t("Total")}>{ FormatNumberUSDT(data_Sub.totalComPending)} </span></span> &nbsp;
                                {/* /<span className="f1 remain-day">{info.remainWithdrawComDay}D</span>
                                /<span className="f1">{info.totalF1BuyPackageDue}<i className="ni ni-single-02"></i></span> */}
                            </div>}
                            
                        </div>
                        {/* <Button className="btn-withdraw btn-fund" color="secondary" type="button" title={t("Withdraw Commission")}
                            onClick={this.handleOpenModal}> <i className="img-icon fa fa-minus"></i>
                        </Button> */}
                    </div>
                    <div className="filter-select-md ">
                        {params.isChildPage &&
                            <Button
                                color="secondary"
                                type="button"
                                onClick={this.handleBack}
                            >
                                {t("Back")}
                            </Button>
                        }
                    </div>

                </div>
                <Table striped bordered responsive className="rpt-table" >
                    <thead>
                        <tr>
                            <th className=" text-center num-stt " rowSpan={2}>#</th>
                            <th >Email</th>
                            <th >{t("Package")}</th>
                            <th >{t("Friends")}</th>
                            <th>{t("Learning Bonus")}</th>
                            <th>{t("Received")}</th>
                            {/* <th>{t("Lost")}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => {
                            var startIndex = params.PageSize * params.PageIndex + 1;
                            // var isReward = item.Level === 1 && CurrentPackageAmount > 0 && item.CurrentPackageAmount >= CurrentPackageAmount;
                            return (
                                <tr key={index}>
                                    <td className=" text-center ">{index + startIndex}</td>
                                    <td className="text">
                                        <div className="flex-center">
                                            <span className="show-email pointer" onClick={() => this.handleShowEmail(item)}> <span className="btn-inner--text btn-span"><i className={!item.showEmail ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                            &nbsp;
                                            &nbsp;
                                            <div className={(item.totalF1 > 0 ? "link-text pointer" : "") + " d-flex align-items-center"} title={item.email} onClick={() => this.handleLoadChild(item)}>
                                                {item.showEmail ? item.email : hideCharacters(item.email)}
                                            </div>
                                        </div>
                                    </td>
                                    <td ><span >{formatNumber(item.currentPackageAmount)}</span> </td>
                                    <td >{formatNumber(item.totalF1BuyPackage)}/{formatNumber(item.totalF1)}</td>
                                    <td className="num">{FormatNumberUSDT(item.totalBonus)} </td>
                                    {/* <td className=" text-center ">{formatNumber(item.totalF1BuyPackageDue)}</td> */}
                                    <td className="num">{FormatNumberUSDT(item.totalBonusReceived)}</td>
                                    {/* <td className="num">{FormatNumberUSDT(item.totalBonusUnReceived)}</td> */}
                                    {/* <td >{formatNumber(item.totalDownline)}</td>
                                    <td >{formatNumber(item.level)}</td> */}
                                    {/* <td className={(isReward ? "text-highlight" : "") + " num"}>{FormatNumberUSDT(item.CurrentPackageAmount)} {isReward && <i className="fa fa-trophy" aria-hidden="true"></i>}</td>
                                    <td className="num">{FormatNumberUSDT(item.TotalRewardReceived)}</td>
                                    <td className="num">{FormatNumberUSDT(item.TotalRewardLose)}</td>
                                    <td >{this.getType(item.Status)}</td> 
                                    <td >{formatDate(item.registerDate)}</td> */}
                                </tr>
                            )
                        })}
                        {(!data || data.length === 0) && <>
                            <tr className="text-center"><td colSpan={9}>{t("No data")}</td></tr>
                        </>}
                    </tbody>
                </Table>
                {TotalRows > 0 && params.PageSize > 0 &&
                    <>
                        <div className="paganition-custom">
                            <ReactPaginate
                                pageCount={Math.ceil(TotalRows / params.PageSize)}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                onPageChange={this.handlePageChange}
                                forcePage={params.PageIndex}
                                className="react-paginate"
                                activeClassName={'active'}
                                previousLabel={<i className="fa fa-angle-left"></i>}
                                nextLabel={<i className="fa fa-angle-right"></i>}
                            />
                            <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                options={sizeOptions}
                                formatGroupLabel="Size"
                                placeholder="Size"
                                isClearable={false}
                                defaultValue={sizeOptions[0]}
                                value={sizeOptions.find(x => x.value === params.PageSize)}
                                onChange={this.handleChangeSize}
                                classNamePrefix="pagesize-select"
                            />
                        </div>
                    </>}
                {isLoading && <Loading />}
            </>
        );
    }
}

export default withTranslation()(withRouter(RPTMemberTree_NLevel));
