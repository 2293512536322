import React from "react";
import { postNoAuth, redirectToURL } from "services/apiServices";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";

import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { t } from "i18next";
import Topic from "./Topic";
import configs from "configs/configs";
import { io } from "socket.io-client";
import { renderMultipleLang } from "services/common";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
        // captcha: '',
      },
      errors: {
        email: '',
        password: '',
        // captcha: '',
      },
      typepass: "password",
      showpass: false,
      isLoading: false,
      lang: localStorage.getItem("lang") && localStorage.getItem("lang") !== "null" ? localStorage.getItem("lang") : "en",
      isMobile: window.innerWidth < 992,
      arrLang: [
        {
          id: "en",
          img: "flag_english",
          name: "English",
        },
        {
          id: "zh",
          img: "flag_china",
          name: "中国"
        },
        {
          id: "hi",
          img: "flag_india",
          name: "भारत",
        },
        {
          id: "es",
          img: "flag_spanish",
          name: "España",
        },
        {
          id: "fr",
          img: "flag_france",
          name: "France",
        },
        {
          id: "ar",
          img: "flag_arabic",
          name: "عربي",
        },
        {
          id: "ru",
          img: "flag_russia",
          name: "Русский",
        },
        {
          id: "pt",
          img: "flag_portugal",
          name: "Português",
        },
        {
          id: "it",
          img: "flag_italian",
          name: "Italiano",
        },
        {
          id: "de",
          img: "flag_germany",
          name: "Deutsch",
        },
        {
          id: "vi",
          img: "flag_vietnam",
          name: "Tiếng Việt",
        }
      ],
      // imgCaptcha: '',
      // captchaCode: ''
    };
    this.socket = io(configs.SocketUM);
  }
  componentDidMount() {
    // renderMultipleLang();    
    let _lang = localStorage.getItem("lang") && localStorage.getItem("lang") !== "null" ? localStorage.getItem("lang") : "en";
    localStorage.clear();
    localStorage.setItem("lang", _lang);
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });

    this.socket.on("UM", (msg) => {
      // console.log("Received message:", msg);
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
  }

  componentWillUnmount() {
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();
  }
  handleShowPass = () => {
    this.setState({
      showpass: !this.state.showpass,
      typepass: this.state.showpass ? "password" : "text",
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };
  handleSubmit = async (e) => {
    e.preventDefault()
    const { t } = this.props;
    const { formData } = this.state;
    let formIsValid = true;
    const newErrors = {
      email: '',
      password: '',
    };
    if (!formData.email) {
      formIsValid = false;
      newErrors.email = t('Please enter your email.');
    }
    if (!formData.password) {
      formIsValid = false;
      newErrors.password = t('Please enter your password.');
    }
    if (!formIsValid) {
      this.setState({ errors: newErrors });
    } else {
      this.setState({ errors: newErrors });
      // Submit form 
      this.setState({ isLoading: true });
      try {
        let params = {
          "Email": formData.email.trim(),
          "Password": formData.password.trim(),//"aa06f243b"
          // "CaptchaCode": this.state.captchaCode,
          // "CaptchaToken": formData.captcha,
        };
        const response = await postNoAuth('/Users/LoginWithEmail', params);
        if (response.data.errorCode === 0) {
          this.setState({ isLoading: false });
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("userId", response.data.data.userID)
          localStorage.setItem("email", formData.email)
          localStorage.setItem("role", response.data.data.userRoleText)
          localStorage.setItem("lang", response.data.data.language)
          redirectToURL('/')
        } else {
          this.setState({ isLoading: false });
          toast.error(t(response.data.errorMessage), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
          });
        }
      } catch (error) {
        this.setState({ isLoading: false });
        let response = error.response;
        toast.error(t(response.data.errorMessage), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        });
      }
    }
  };
  changeLanguage = (lang) => {
    // i18n.changeLanguage(lang.id);
    // this.setState({ lang: lang.id });
    localStorage.setItem("lang", lang.id);
    window.location.reload();
  }
  render() {
    const { lang, formData, errors, showpass, typepass, isLoading, arrLang, isMobile } = this.state;
    const { t } = this.props;
    return (
      <>
        {/* <select onChange={this.changeLanguage}>
          <option value="en">English</option>
          <option value="vi">Vietnamese</option>
        </select> */}
        <main ref="main" className="outside login">
          <section className="section section-shaped section-lg">
            {/* <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div> */}
            <Container>
              <Row className="justify-content-center">
                {/* <Col lg="6" className="flex-center">
                  <Topic />
                </Col> 
                {lang && <img className={`icon-lang`} alt="..." src={require(`assets/img/flags/flag_lang/${lang}.png`)} />}
                */}
                <Col lg="6" className="flex-center-center" >
                  <div className="bg-login">
                    <div className="px-lg-5 pt-lg-5 pb-lg-3">
                      <div className="text-muted mb-6 title-outside">
                        {/* <UncontrolledDropdown direction={"down"} className="cover-drop-lang">
                          <DropdownToggle className="btn-lang-toogle" >
                            {lang && <img className={`icon-lang`} alt="..." src={require(`assets/img/flags/flaglang/${lang}.png`)} />}
                          </DropdownToggle>
                          <DropdownMenu className="drop-lang">
                            {arrLang && arrLang.map((item, index) => (
                              <li key={`flag${index}`}>
                                <DropdownItem onClick={() => this.changeLanguage(item)}>
                                  <img
                                    className="icon-search"
                                    alt={item.name}
                                    title={item.name}
                                    src={require(`assets/img/flags/flaglang/${item.id}.png`)}
                                  />
                                  <div className="name">{item.name}</div>
                                </DropdownItem>
                              </li>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                        <div className="txt-title">{t("Welcome back")}!</div>
                        <small className="small-title">{t("Please sign in before continuing.")}</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-5 form-outside">
                          {/* <label>Email</label> */}
                          <Input className="input-outside" placeholder="Email" name="email" value={formData.email} onChange={this.handleChange} />
                          {errors.email && <div className="error">{errors.email}</div>}
                        </FormGroup>
                        <FormGroup className="form-outside">
                          <InputGroup>
                            <Input
                              placeholder={t("Password")}
                              type={typepass}
                              autoComplete="off"
                              name="password" value={formData.password} onChange={this.handleChange}
                              className="input-outside"
                            />
                            <InputGroupAddon addonType="append" title={t("Show Password")} >
                              <InputGroupText className="input-outside">
                                <span className="pointer" onClick={() => this.handleShowPass()}> <span className="btn-inner--text btn-span"><i className={!showpass ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.password && <div className="error">{errors.password}</div>}
                        </FormGroup>
                        {/* <FormGroup>
                          <InputGroup>
                            <Input
                              placeholder={t("Captcha Code")}
                              autoComplete="off"
                              type="text"
                              name="captcha" value={formData.captcha} onChange={this.handleChange}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText >
                                <img src={imgCaptcha} alt='' />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.captcha && <div className="error">{errors.captcha}</div>}
                        </FormGroup> */}

                        {/* <div className="text-center  mt-4 div-btn-submit">
                          <Button
                            className="btn-submit"
                            color="success"
                            type="submit"
                          >
                            {t("Sign in")}
                          </Button>
                        </div> */}
                        <div className="text-right mb-3">
                          <a
                            className="text-light"
                            href="/forgot"
                          >
                            <small>{t("Forgot password")}?</small>
                          </a>
                        </div>
                        <div>
                          <Button
                            className="button-outside"
                            type="submit"
                          >
                            {t("Sign in")}
                          </Button>
                        </div>
                        {/* <Row className="mt-2 mb-2">
                          <Col xs="12" className="text-center">
                            <a
                              className="text-dark"
                              href="/forgot"
                            >
                              <small>{t("Forgot password")}?</small>
                            </a>
                          </Col>
                        </Row> */}
                        {/* <Row className="mt-3 ">
                          <Col xs="12" className="text-center tag-warn">
                            {t("Use Chrome browser for best results.")}
                          </Col>
                        </Row> */}
                      </Form>
                      <Row className="mt-3">
                        <Col xs="12" className="text-center">
                          <a
                            className="text-login-cover"
                            href="/register"
                          >
                            <small>{t("Don't have an account?")} <span className="text-login">{t("Sign up now")}</span></small>
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="flex-center-center item-login">
                 <img src={require("assets/img/item-login.png")}  />
                </Col>
              </Row>
            </Container>
            <ToastContainer pauseOnFocusLoss={false} />
            {isLoading && <Loading />}
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(Login);
