import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import QRCode from "react-qr-code";
import Select from 'react-select'
import Loading from "../../components/Loading/Loading"
import { get, checkToken, } from "services/apiServices";
import { calculateTimeRemaining } from "services/common";

const options = [
    { value: "TRC20", label: 'TRON (TRC20)', name: "TRON" },
    // { value: "BEP20", label: 'BNB Smart Chain (BEP20)', name: "BNB Smart Chain" },
]
class Deposit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Network: 'TRC20',
            Chain: "TRON",
            CurrentAddress: '',
            hasWallet: false,
            isLoading: false,
            expireTime: '',
            timeRemain: null,
            timer: null
        }
    }
    loadWallet = async (network) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetWallet');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.setState({ firstloadWallet: false })
                if (this.state.timer) {
                    clearInterval(this.state.timer);
                    this.setState({ timeRemain: null });
                }
                if (response.data.data != null) {
                    if (response.data.data.length > 0) {
                        let info_wallet = response.data.data.find(x => x.symbol === "TRC20");
                        if (info_wallet) {
                            this.setState({ hasWallet: true, CurrentAddress: info_wallet.walletAddress })
                        } else {
                            this.setState({ hasWallet: false, CurrentAddress: '' })
                        }
                        let _timer = setInterval(() => {
                            const timeRemaining = calculateTimeRemaining(info_wallet.expiredDate);
                            this.setState({ timeRemain: timeRemaining });
                            // console.log("total", timeRemaining.total);
                            if (timeRemaining.total <= 0) {
                                clearInterval(this.state.timer);
                                this.handleToogleModalDeposit();
                            }
                        }, 1000);
                        this.setState({ timer: _timer });
                    }
                } else {
                    this.setState({ hasWallet: false, CurrentAddress: '' })
                }
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    clearInterval = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
    }
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    handleToogleModalDeposit = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalDeposit();
    };
    handleSelectChange = (e) => {
        this.setState({ Network: e.value, Chain: e.name });
        this.loadWallet(e.value);
    };
    handleLoadModal = async () => {
        this.setState({ Network: options[0].value, Chain: options[0].name })
        this.loadWallet(options[0].value);
    }
    render() {
        const { modalDepositOpen, info } = this.props;
        const { isLoading, Network, Chain, CurrentAddress, hasWallet, timeRemain } = this.state;

        return (
            <>
                <Modal toggle={this.handleToogleModalDeposit} isOpen={modalDepositOpen} onOpened={this.handleLoadModal} onClosed={this.clearInterval}>
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Deposit")}
                        </h5>
                        {info && !info.otc && <Button className="btn-vndusdt ml-auto"
                            color="warning"
                            onClick={this.props.onToogleModalBuyUSDT}
                        >  {t("VNDUSDT")}
                        </Button>}
                        <button
                            aria-label={t("Close")}
                            className=" close ml-1"
                            type="button"
                            onClick={this.handleToogleModalDeposit}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" >
                            <FormGroup className="mb-3">
                                <label>Coin</label>
                                <Input placeholder={t("Coin")} type="text" value="USDT (Tether)" disabled />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <label>{t("Network")}</label>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isSearchable={false}
                                    classNamePrefix="custom-select"
                                    options={options}
                                    defaultValue={options[0]}
                                    onChange={this.handleSelectChange}
                                />
                            </FormGroup>
                            {timeRemain && timeRemain.total > 0 && <div className="text-alert pb-1">{t("Warning: This wallet address will expire in")} {(timeRemain.minutes > 9 ? timeRemain.minutes + "" : "0" + timeRemain.minutes) + ":" + (timeRemain.seconds > 9 ? timeRemain.seconds + "" : "0" + timeRemain.seconds)}</div>}
                            {hasWallet && <>
                                <FormGroup className="mb-3">
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={CurrentAddress}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <label>{t("Address")}</label>
                                    <InputGroup className="input-group" >
                                        <Input placeholder={t("Address")} type="text" name="Address" value={CurrentAddress} disabled className="fnt-13" />
                                        <InputGroupAddon addonType="append" >
                                            <InputGroupText >
                                                <div className="pointer" title={t("Copy")} onClick={() => this.handleCopyClick(CurrentAddress)}> <span className="btn-inner--text btn-span"><i className="fa fa-copy"></i></span></div>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-alert">{t("This address accepts only USDT")} {Network} ({Chain}).</div>

                            </>}
                            {!hasWallet && <div className="text-alert">{t("No wallet information on")} {Network} ({Chain}).</div>}

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalDeposit}
                        >                           {t("Close")}
                        </Button>
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(Deposit);
