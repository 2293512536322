const configs = {
    ApiRoot: "https://priapi.bitlearning.site/api",// 'https://testmemberapi.englishadowing.com/api', // "http://localhost:50198/api",
    WebRoot: 'https://bitlearning.site',
    RootSingleWord: "https://priapi.bitlearning.site/files/Word/",
    DetailWalletTON: "https://tonviewer.com",
    TeleBotLink: "", //https://t.me/EW_Member_bot?start=
    SocketUM: "https://um.bitlearning.site",
    SocketPrice: "https://socketprice.bitlearning.site",
    TeleBotOTCLink: "", //https://t.me/EW_OTC_bot?start=
    SocketBonus: "https://bonusdonate.bitlearning.site",
    SocketUserNotify: "https://notifydonate.bitlearning.site",
    SiteName: "BitLearning",
    SocketKickToken: "https://socketkicktoken.bitlearning.site",
    DetailTONScan: "https://tonscan.org",
    OTCRefLink: "https://t.me/BitLearningBot?start=",
    SocketPendingBuySell: "https://pendingbuysell.bitlearning.site",
    WebRootEmail: "https://web.bitlearning.site"
}
export default configs;