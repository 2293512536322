import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Container,
    Row,
    Col
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import { post, get, checkToken } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import HeaderNavbar from "components/Navbars/HeaderNavbar.js";
import BuyCoin from "views/Home/BuyCoin";
import Send from "views/Home/Send";
import Receive from "views/Home/Receive";
import Swap from "views/Home/Swap";
import WithdrawCoin from "./WithdrawCoin";
import { calculateTimeRemaining, calculateTimeRemaining2 } from "services/common";
import { io } from "socket.io-client";
import configs from "configs/configs";
import { FormatNumberNTC, FormatNumberAmount, FormatNumberUSDT, formatNumber } from "services/common";
import RPTTransaction from "../Report/RPTTransaction";
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import List_Swap from "views/Home/List_Swap";
import { redirectToURL } from "services/apiServices";
import BuySellOTC from "./BuySellOTC";
import BankInfo from "./BankInfo";
import List_BuyTON from "./List_BuyTON";
import { Dropdown } from 'react-bootstrap';
import BuySellNowOTC from "./BuySellNowOTC";
import List_BuyUSDT from "./List_BuyUSDT";


class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            info: {
                "twoFA": true,
                "refCode": "",
                "balance": 0,
                "comPending": 0,
                "currentPackageAmount": 0,
                "totalF1BuyPackageDue": 0
            },
            ListCoin: [],
            selectedCoin: null,
            // modalBuyCoinOpen: false,
            modalSend: false,
            modalReceive: false,
            modalSwap: false,
            showReceipt: false,
            selectedTrans: null,
            showReceipt: false,
            Receipt: "",
            modalConfirmTransferOpen: false,
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            errReceipt: "",
            modalWithdrawTransferOpen: false,
            timer: null,
            intervalGetList: null,
            data: [],
            prices: {
                TONUSDT: 1,
                BITUSDT: 1,
                OTCRate: 1,
                OTCRateFastSell: 1,
                SwapTONToUSDTFee: 0
            },
            bankInfo: {
                "nameBank": "",
                "accNumberBank": "",
                "cardholderNameBank": ""
            },
            hasWallet: true,
            CurrentAddress: '',
            dataTrans: [],
            TotalRows: 0,
            params: {
                PageIndex: 0,
                PageSize: 10,
                Type: 0,
            },
            AmountTON: 0,
            userList: [],
            totalRowUser: 0,
            PageIndex: 1,
            PageSize: 10,
            TypeTransaction: 1,//1: transaction, 2: airdrop, 3: gas fee
            currentTab: "Transaction",
            showPolicyModal: false,
            showInfoBITModal: false,
            IsLearning: true,
            showRefCode: false,
            modalBuySellOTC: false,
            OTCRefLink: "",
            modalBankInfoOpen: false,
            OTCInfo: null,
            OTCInfo_TON: null,
            remainTimeDiscount: null,
            timerDiscount: null,
            pricesLang: null,
            pricesAll: null,
            IsChecked: false,
            currentTicket: null,
            modalListBuyTON: false,
            modalListBuyUSDT: false,
            selectedOTCTON: null,
            userListTON: [],
            userListUSDT: [],
            totalRowUserTON: 0,
            totalRowUserUSDT: 0,
            selectedTicketSellNow: null,
            ListTicketSellNow: [],
            modalSelNow: false,
            selectedOTCUSDT: null,
            formDataUserSellNow: {
                AmountCoin: 0,
                AmountOfLang: 0,
            },
            errors: {
                AmountCoin: "",
                AmountOfLang: "",
            },
            modalBuySellNowOTC: false,
            selectedTicketSellNowOTC: null,
            bankInfoSellNow: {
                NameBank: '',
                AccNumberBank: '',
                CardholderNameBank: '',
                Discount: 0,
            }
        }
        this.inputRef = React.createRef();
        this.inputRef1 = React.createRef();
        this.socket = io(configs.SocketPrice);
        this.socketPendingBuySell = io(configs.SocketPendingBuySell, { auth: { userId: localStorage.getItem("userId") } });
        this.ListTransactionRef = React.createRef();
        this.socketKickToken = io(configs.SocketKickToken, { auth: { userId: localStorage.getItem("userId") } });
        this.socketUM = io(configs.SocketUM);
    }
    componentWillUnmount = () => {
        clearInterval(this.state.timer);
        this.ListTransactionRef.current.removeEventListener("scroll", this.handleScroll);
        this.socket.disconnect();
        this.socketKickToken.disconnect();
        this.socketPendingBuySell.disconnect();
        this.socketUM.disconnect();
    }
    componentDidMount = () => {
        // Lắng nghe các sự kiện
        this.socketUM.on("connect", () => {
            console.log("UM Connected");
        });
        this.socketUM.on("UM", (msg) => {
            if (msg.isUM) {
                localStorage.clear();
                redirectToURL("/um")
            }
        });
        this.socketKickToken.on("connect", () => {
            console.log("connected");
        });
        this.socketKickToken.on("KickToken", (msg) => {
            // console.log("KickToken", msg);
            if (msg) {
                this.setState((prevState) => ({
                    info: { ...prevState.info, isLearning: msg.IsLearning },
                }));
            }
        });
        this.socketPendingBuySell.on("connect", () => {
            console.log("pending connected");
        });
        this.socketPendingBuySell.on("ReloadPendingBuySell", (msg) => {
            if (msg.isLoad === 1) {
                this.handleGetListBuySellPending();
            }
            // if (msg.isLoad === 2) {
            //     this.handleGetListOTCSell();
            // }
        });
        this.socketPendingBuySell.on("ReloadPendingFastSell", (msg) => {
            if (msg.isLoad === 1) {
                this.handleGetListBuySellPendingSellNow();
            }
        });
        if (window.Telegram) {
            window.Telegram.WebApp.BackButton.isVisible = false;
        }
        this.ListTransactionRef.current.addEventListener("scroll", this.handleScroll);
        document.body.classList.add('bg-other');
        document.body.classList.remove('bg-index');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.hanldeLoadBasicInfo();
        this.handleGetListBuySellPending();
        this.handleGetListBuySellPendingSellNow();
        // let _interval = setInterval(() => {
        //     this.handleGetListBuySellPending()
        // }, 5000);
        // this.setState({ intervalGetList: _interval });
        //connect socket
        this.socket.on("connect", () => {
            // console.log("Price Connected");
        });
        this.socket.on("price", (msg) => {
            let listCoin = [...this.state.ListCoin];
            if (msg && msg.errorCode === 0 && msg.data) {
                let data = msg.data.data;
                listCoin.map((item) => {
                    if (item.symbol === "TON") {
                        item.balanceUSDT = item.balance * data.TONUSDT;
                    } else if (item.symbol === "BIT") {
                        item.balanceUSDT = item.balance * data.BITUSDT;
                    }
                    return item;
                })
                let _prices = {
                    TONUSDT: data.TONUSDT,
                    BITUSDT: data.BITUSDT,
                    OTCRate: data.OTCRate,
                    OTCRateFastSell: data.OTCRateFastSell,
                    SwapTONToUSDTFee: data.SwapTONToUSDTFee,
                }
                this.setState({ listCoin: listCoin, prices: _prices });
                this.setState({ pricesLang: msg.data.price, pricesAll: msg.data.data })
                if (this.state.selectedTicketSellNow) {
                    this.handleChangeAmount(this.state.selectedTicketSellNow);
                }
            }

            // if (msg.priceBuy) {
            //     let _price = msg.priceBuy ? Number(msg.priceBuy) : 0;
            //     this.setState((prevState) => ({
            //         formData: { ...prevState.formData, PriceVND: _price },
            //     }));
            //     this.handleChangeAmount(this.state.formData.AmountCoin, _price)
            // }
        });
    }
    componentDidUpdate(prevState) {
        // if ((this.state.pricesLang !== prevState.pricesLang || this.state.pricesAll !== prevState.pricesAll)) {
        //     this.handleChangeAmount()
        // }
        if (this.state.modalSelNow !== prevState.modalSelNow && this.state.selectedTicketSellNowOTC && this.state.modalSelNow) {
            this.handleChangeAmount(this.state.selectedTicketSellNowOTC)
        }
    }
    hanldeLoadBasicInfo = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/UserInfoBasic');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                if (response.data.data != null) {
                    let _info = response.data.data.userInfo;
                    // if (_info.trustBuyer) {

                    // }
                    _info.inHistory = true;
                    // _info.parentOTCLanguage = "ar";
                    // _info.remainWithdrawHours = "00:00:00";
                    // if (_info.otcRefCode) {
                    //     _info.otc = true;
                    // }
                    this.setState({
                        info: _info,
                    })
                    let listCoin = [];

                    if (_info.wallet_BIT !== null) {
                        listCoin.push({
                            symbolName: "Bittoken",
                            symbol: "BIT",
                            balance: _info.wallet_BIT,
                            balanceUSDT: 0,
                            symbolID: 1,
                            symbolText: "Bittoken"
                        })
                    }
                    if (_info.balance_USDT !== null) {
                        listCoin.push({
                            symbolName: "USDT",
                            symbol: "USDT",
                            balance: _info.balance_USDT,
                            balanceUSDT: _info.balance_USDT,
                            symbolID: 3,
                            symbolText: "USDT"
                        })
                    }
                    if (_info.wallet_TON !== null) {
                        listCoin.push({
                            symbolName: "Toncoin",
                            symbol: "TON",
                            balance: _info.wallet_TON,
                            balanceUSDT: 0,
                            symbolID: 2,
                            symbolText: "Toncoin"
                        })
                    }
                    let _selectedCoin = this.state.selectedCoin;
                    if (!this.state.selectedCoin) {
                        _selectedCoin = listCoin && listCoin.length > 0 ? listCoin[0] : null;
                        this.setState({ selectedCoin: _selectedCoin })
                    } else {
                        _selectedCoin = listCoin.find(x => x.symbol === this.state.selectedCoin.symbol);
                        this.setState({ selectedCoin: _selectedCoin });
                    }
                    if (response.data.data.bankInfo) {
                        this.setState({ bankInfo: response.data.data.bankInfo })
                    }

                    this.setState({
                        ListCoin: listCoin, 
                        OTCRefLink: configs.OTCRefLink + _info.otcRefCode,
                        OTCRefLinkEmail: configs.WebRootEmail +"/register/" + _info.otcRefCode + "/" + _info.language,
                        IsChecked: _selectedCoin.symbol === "BIT" ? _info.enableOTC : _selectedCoin.symbol === "TON" ? _info.enableOTC_TON : _info.enableOTC_USDT
                    }) //API trả thì sửa tên enableOTCTON
                    var _params = {
                        Type: this.state.TypeTransaction,
                        PageIndex: 1,
                        PageSize: 10,
                        Symbol: _selectedCoin ? _selectedCoin.symbolID : 0
                    }
                    this.handleGetAllTransaction(_params);
                    //CountDown time                    
                    // if (!this.state.countdownInterval) {
                    //     let countdownInterval = setInterval(() => {
                    //         let { info } = this.state;
                    //         this.countDown(info.remainWithdrawHours);
                    //     }, 1000);
                    //     this.setState({ countdownInterval: countdownInterval });
                    // }
                    localStorage.setItem("refCode", response.data.data.userInfo.refCode);
                    localStorage.setItem("TwoFA", response.data.data.userInfo.twoFA);
                    localStorage.setItem("Balance", response.data.data.userInfo.balance);
                    localStorage.setItem("CurrentPackageAmount", response.data.data.userInfo.currentPackageAmount);
                    localStorage.setItem("ComPending", response.data.data.userInfo.comPending);
                    localStorage.setItem("TotalF1", response.data.data.userInfo.totalF1BuyPackageDue);
                } else {
                    clearInterval(this.state.countdownInterval);
                }

            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    hanldeLoadOTCInfo = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetOTCInfo');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let OTCInfo = response.data.data.info;
                this.setState({ OTCInfo: OTCInfo })
                if (OTCInfo && OTCInfo.parentOTCID !== 0 && OTCInfo.parentOTCEnableOTC && this.state.selectedCoin.symbol === "BIT") {//If OTC child show modal OTC  
                    this.onToogleModalBuySellOTC();
                    if (this.state.timerDiscount) {
                        clearInterval(this.state.timerDiscount)
                    }
                    const endTime = moment().add(OTCInfo.minuteExpiredDate, 'minutes');
                    let _timerDisount = setInterval(() => {
                        let timeRemain = calculateTimeRemaining2(endTime);
                        if (timeRemain.total > 0) {
                            this.setState({ remainTimeDiscount: timeRemain })
                        } else {
                            this.setState({ remainTimeDiscount: null })
                            clearInterval(this.state.timerDiscount)
                        }
                    }, 1000);
                    this.setState({ timerDiscount: _timerDisount })
                }
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    hanldeGetOTCInfo = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetOTCInfo');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let OTCInfo = response.data.data.info;
                this.setState({ OTCInfo: OTCInfo })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    hanldeLoadOTCInfoByOTCID = async (item) => {
        this.setState({ isLoading: true });
        try {
            let _params = {
                OTCID: item.userID
            }
            let response = await get('/Users/GetOTCInfoByID', _params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let OTCInfo_TON = response.data.data.info;
                this.setState({ OTCInfo_TON: OTCInfo_TON })
                if (OTCInfo_TON && (OTCInfo_TON.otcEnableOTC_TON && this.state.selectedCoin.symbol === "TON") || (OTCInfo_TON.otcEnableOTC_USDT && this.state.selectedCoin.symbol === "USDT")) {//If OTC child show modal OTC  
                    this.onToogleModalBuySellOTC();
                } else {
                    toast.error(t("Invalid request."), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleChangeCoin = (coin) => {
        this.setState({
            selectedCoin: coin,
            TypeTransaction: 1,//1: transaction, 2: airdrop, 3: gas fee
            currentTab: "Transaction",
            IsChecked: coin.symbol === "BIT" ? this.state.info.enableOTC : coin.symbol === "TON" ? this.state.info.enableOTC_TON : this.state.info.enableOTC_USDT //API trả thì sửa tên enableOTCTON
        })
        const {
            PageSize,
        } = this.state;
        let _params = {
            Type: 1,
            PageIndex: 1,
            PageSize: PageSize,
            Symbol: coin ? coin.symbolID : 0
        };
        this.handleGetAllTransaction(_params, true);
        this.hanldeGetOTCInfo();
    }
    onToogleModalBuyCoin = () => {
        this.setState(prevState => ({
            modalBuyCoinOpen: !prevState.modalBuyCoinOpen
        }));
    }
    hanldeBuyCoin = async (params, fiat = true) => {
        this.setState({ isLoading: true });
        params.Symbol = this.state.selectedCoin.symbol;
        params.OTCId = params.Symbol === "TON" ? this.state.selectedOTCTON.userID : params.Symbol === "USDT" ? this.state.selectedOTCUSDT.userID : 0
        let url = "/Purchase/BuyCoin";
        // if (!fiat) {
        //     url = "/Users/SwapTONToBIT";
        // }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                // this.setState({
                //     modalBuyCoinOpen: false
                // });
                // this.handleGetListBuySellPending();
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest Coin you can buy is")) {
                    message = message.replace("The lowest Coin you can buy is", t("The lowest Coin you can buy is"))
                } else if (message && message.includes("The lowest Coin you can sell is")) {
                    message = message.replace("The lowest Coin you can sell is", t("The lowest Coin you can sell is"))
                } else if (message && message.includes("Min buy BIT is")) {
                    message = message.replace("Min buy BIT is", t("Min buy BIT is"))
                } else {
                    message = t(message)
                }
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                let message = response.data.errorMessage;
                if (message && message.includes("The lowest Coin you can buy is")) {
                    message = message.replace("The lowest Coin you can buy is", t("The lowest Coin you can buy is"))
                } else if (message && message.includes("The lowest Coin you can sell is")) {
                    message = message.replace("The lowest Coin you can sell is", t("The lowest Coin you can sell is"))
                } else if (message && message.includes("Min buy BIT is")) {
                    message = message.replace("Min buy BIT is", t("Min buy BIT is"))
                } else {
                    message = t(message)
                }
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleshowReceipt = (url) => {
        this.setState(prevState => ({
            showReceipt: !prevState.showReceipt, Receipt: url
        }));
    }
    onToogleShowReceipt = () => {
        this.setState(prevState => ({
            showReceipt: !prevState.showReceipt
        }));
    }
    onToogleModalWithdrawTransfer = () => {
        this.setState((prevState) => ({ modalWithdrawTransferOpen: !prevState.modalWithdrawTransferOpen }));
    }
    onToogleModalSend = () => {
        this.setState((prevState) => ({ modalSend: !prevState.modalSend }));
    }
    onToogleModalReceive = () => {
        if (!this.state.modalReceive) {
            this.loadWallet();
        }
        this.setState((prevState) => ({ modalReceive: !prevState.modalReceive }));
    }
    onToogleModalList_Swap = () => {
        if (!this.state.modalList_Swap) {
            let params = { PageIndex: 1, PageSize: 500, Username: "" };
            this.handleGetList(params);
        }
        this.setState((prevState) => ({ modalList_Swap: !prevState.modalList_Swap }));
        if (!this.state.modalList_Swap) {
            this.hanldeLoadOTCInfo();
        }
    }
    onToogleModalBuyTON = (item) => {
        this.setState({ selectedOTCTON: item });
        this.hanldeLoadOTCInfoByOTCID(item);
    }
    onToogleModalBuyUSDT = (item) => {
        this.setState({ selectedOTCUSDT: item });
        this.hanldeLoadOTCInfoByOTCID(item);
    }
    onTooglePolicyModal = () => {
        this.setState((prevState) => ({ showPolicyModal: !prevState.showPolicyModal }));
    }
    onToogleModalInfoBIT = () => {
        this.setState((prevState) => ({ showInfoBITModal: !prevState.showInfoBITModal }));
    }
    onToogleModalRefCode = () => {
        this.setState((prevState) => ({ showRefCode: !prevState.showRefCode }));
    }
    onToogleModalBuySellOTC = () => {
        this.setState((prevState) => ({ modalBuySellOTC: !prevState.modalBuySellOTC }));
        if (this.state.modalBuySellOTC) {
            clearInterval(this.state.timerDiscount);
        }
    }
    onToogleModalSwap = () => {
        this.setState((prevState) => ({ modalSwap: !prevState.modalSwap }));
    }
    hanldeWithdrawTransfer = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Fund/USDTTRC20WalletWithdraw', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Successfully created a withdrawal order!"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState(prevState => ({
                    modalWithdrawTransferOpen: !prevState.modalWithdrawTransferOpen
                }));
                let _params = { ...this.state.params };
                //reset pageindex
                this.setState((prevState) => ({
                    params: { ...prevState.params, PageIndex: 0 },
                }));
                _params.PageIndex = 1;
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleGetListBuySellPending = async () => {
        // this.setState({ isLoading: true });
        const { info } = this.props;
        const { currentTicket } = this.state;
        try {
            let response = await get('/UserReport/BuySellPendingHistory');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let old_pending = [...this.state.data];
                // response.data.data.pendingLst = [
                //     {
                //         "transID": 1054,
                //         "buySellName": "",
                //         "otc": false,
                //         "type": 1,
                //         "typeText": "Buy",
                //         "status": 2,
                //         "statusText": "The invoice has not been uploaded yet",
                //         "amountUSDT": 99.00000000,
                //         "priceVND": 25813.00000000,
                //         "amountVND": 24512312.00000000,
                //         "receipt": null,
                //         "receiptPath": "",
                //         "nameBank": "OCG",
                //         "accNumberBank": "12323424",
                //         "cardholderNameBank": "Jendy",
                //         "requestDate": "2024-06-05T16:35:31.947",
                //         "expiredDate": "2024-06-05T16:35:31.947",
                //         "transferCode": "BE55399737",
                //         "waittingDoneExpiredDate2": "",
                //         "typeBuy": 1,
                //         "typeBuyText": "Fiat",
                //         "discount": 0.05000000,
                //         "discountPercent": 5.00000000,
                //         "amountDiscount": 129065.00000000,
                //         "totalAmountVND": 2581300.00000000,
                //         "buyerChatID": "777777",
                //         "buyerUsername": "Julia",
                //         "buyerFullname": "Julia 123",
                //         "otcLanguage": "vi",
                //         "symbol": "TON",
                //     },
                //     {
                //         "transID": 1055,
                //         "buySellName": "",
                //         "otc": false,
                //         "type": 1,
                //         "typeText": "Buy",
                //         "status": 2,
                //         "statusText": "The invoice has not been uploaded yet",
                //         "amountUSDT": 101.00000000,
                //         "priceVND": 25813.00000000,
                //         "amountVND": 24512312.00000000,
                //         "receipt": null,
                //         "receiptPath": "",
                //         "nameBank": "OCG",
                //         "accNumberBank": "12323424",
                //         "cardholderNameBank": "Jendy",
                //         "requestDate": "2024-06-05T16:35:31.947",
                //         "expiredDate": "2024-06-05T16:35:31.947",
                //         "transferCode": "BE55399737",
                //         "waittingDoneExpiredDate2": "",
                //         "typeBuy": 1,
                //         "typeBuyText": "Fiat",
                //         "discount": 0.05000000,
                //         "discountPercent": 5.00000000,
                //         "amountDiscount": 129065.00000000,
                //         "totalAmountVND": 2581300.00000000,
                //         "buyerChatID": "777777",
                //         "buyerUsername": "Julia",
                //         "buyerFullname": "Julia 123",
                //         "otcLanguage": "vi",
                //         "symbol": "TON",
                //     }
                // ];
                let new_pending = response.data.data.pendingLst.map((el, index) => {
                    let old_item = old_pending && old_pending.find(x => x.transID === el.transID);
                    if (old_item && old_item.showEmail) {
                        el.showEmail = true;
                    }
                    if (old_item && old_item.timeRemain) {
                        el.timeRemain = old_item.timeRemain;
                    }
                    if (old_item && old_item.timeRemainReport) {
                        el.timeRemainReport = old_item.timeRemainReport;
                    }
                    return el;
                });
                let _currentTicket = null;
                if (!currentTicket) {
                    _currentTicket = new_pending && new_pending.length > 0 ? new_pending[0] : null;
                } else {
                    _currentTicket = new_pending && new_pending.find(x => x.transID === currentTicket.transID);
                    if (!_currentTicket) {
                        _currentTicket = new_pending && new_pending.length > 0 ? new_pending[0] : null;
                    }
                }
                this.setState({
                    currentTicket: _currentTicket,
                    data: new_pending,
                    total: response.data.data.totalPendingLst,
                })
                if (!this.state.timer) {
                    //timmer
                    let need_timer = response.data.data.pendingLst.some(x => x.status === 2 || x.status === 3);
                    if (need_timer) {
                        let _timer = setInterval(() => {
                            let data_pending = [...this.state.data];
                            data_pending.map((el, index) => {
                                if (el.status === 2 || el.status === 3) {
                                    let timeRemain = calculateTimeRemaining(el.expiredDate);
                                    el.timeRemain = timeRemain.total > 0 ? timeRemain : null;
                                }
                                //&& el.type === 2
                                if (el.status === 3 && !el.timeRemain && el.waittingDoneExpiredDate2) {// time out of receive money, start countdown to report
                                    let expiredDateReport = el.waittingDoneExpiredDate2;
                                    // if (!el.waittingDoneExpiredDate2) {
                                    //     expiredDateReport = moment(el.expiredDate).add(15, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSS");
                                    // }
                                    let timeRemain = calculateTimeRemaining(expiredDateReport);
                                    el.timeRemainReport = timeRemain.total > 0 ? timeRemain : null
                                }
                                return el;
                            });
                            let _currentTicket = null;
                            if (this.state.currentTicket) {
                                _currentTicket = data_pending && data_pending.find(x => x.transID === this.state.currentTicket.transID);
                            }
                            this.setState({ currentTicket: _currentTicket, data: data_pending });
                            // console.log("data_pending", data_pending);
                        }, 1000);
                        this.setState({ timer: _timer });
                    }
                }

                // if (localStorage.getItem("fromBuyPackage") === "true") {
                //     this.onToogleModalBuyUSDT();
                //     localStorage.setItem("fromBuyPackage", false)
                // }
            } else {
                // this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            // this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleSend = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Users/WithdrawCoin', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Successfully sent!"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState(prevState => ({
                    modalSend: !prevState.modalSend
                }));
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                let message = response.data.errorMessage;
                if (message && message.includes("Min withdraw BIT is")) {
                    message = message.replace("Min withdraw BIT is", t("Min withdraw BIT is"))
                } else if (message && message.includes("Min withdraw is")) {
                    message = message.replace("Min withdraw is", t("Min withdraw is"))
                } else {
                    message = t(message)
                }
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });

            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                let message = response.data.errorMessage;
                if (message && message.includes("Min withdraw BIT is")) {
                    message = message.replace("Min withdraw BIT is", t("Min withdraw BIT is"))
                } else if (message && message.includes("Min withdraw is")) {
                    message = message.replace("Min withdraw is", t("Min withdraw is"))
                } else {
                    message = t(message)
                }
                toast.error(message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    handleSwapTONtoUSDT = async (params) => {
        this.setState({ isLoading: true });
        try {
            const response = await post('/Users/SwapTONToUSDT', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Swap Successfully!"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState(prevState => ({
                    modalSwap: !prevState.modalSwap
                }));
                this.hanldeLoadBasicInfo();
                const { TypeTransaction, PageSize, selectedCoin } = this.state;
                var _params = {
                    Type: TypeTransaction,
                    PageIndex: 1,
                    PageSize: PageSize,
                    Symbol: selectedCoin ? selectedCoin.symbolID : 0
                }
                this.handleGetAllTransaction(_params);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    loadWallet = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetWallet');
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.setState({ firstloadWallet: false })
                if (response.data.data != null) {
                    let info_wallet = response.data.data;
                    if (info_wallet && info_wallet.walletAddress) {
                        this.setState({ hasWallet: true, CurrentAddress: info_wallet.walletAddress })
                    } else {
                        this.setState({ hasWallet: false, CurrentAddress: '' })
                    }
                }
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {

                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {

                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    handleGetAllTransaction = async (_params, reload = true) => {
        let url = "";
        if (_params.Type === 2) {
            url = "/UserReport/UserReportAirdrop";
        } else if (_params.Type === 3) {
            url = "/UserReport/UserReportGasFee";
        } else if (_params.Type === 4) {
            url = "/UserReport/ReceiveStudentship";
        } else {
            url = '/UserReport/AllWalletFundHistory';
        }
        this.setState({ isLoading: true });
        try {
            let response = await get(url, _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                let newData = [];
                if (reload) {
                    newData = response.data.data;
                } else {
                    newData = [...this.state.dataTrans, ...response.data.data]
                }

                this.setState({
                    dataTrans: newData,
                    TotalRows: response.data.totalRows,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    loadMoreData = () => {
        const {
            PageIndex,
            TotalRows,
            PageSize,
            TypeTransaction,
            selectedCoin
        } = this.state;
        let nextPage = PageIndex + 1;
        let maxPage = Math.ceil(TotalRows / PageSize);
        if (nextPage <= maxPage) {
            // console.log("loadMoreData")
            this.setState({ PageIndex: nextPage });
            let _params = {
                Type: TypeTransaction,
                PageIndex: nextPage,
                PageSize: PageSize,
                Symbol: selectedCoin ? selectedCoin.symbolID : 0
            }
            this.handleGetAllTransaction(_params, false);
        }
    };
    handleReloadTransaction = () => {
        const {
            PageSize,
            TypeTransaction,
            selectedCoin
        } = this.state;
        let _params = {
            Type: TypeTransaction,
            PageIndex: 1,
            PageSize: PageSize,
            Symbol: selectedCoin ? selectedCoin.symbolID : 0
        }
        this.setState({ PageIndex: 1 })
        this.handleGetAllTransaction(_params, true);
    }
    handleScroll = () => {
        this.setState({
            showScrollTop: document.documentElement.scrollTop > 0,
        });
        const refContainer = this.ListTransactionRef.current;
        if (refContainer) {
            const { scrollTop, scrollHeight, clientHeight } = refContainer;
            if (scrollHeight - scrollTop <= clientHeight + 1) {
                this.loadMoreData();
            }
        }
    };


    handleGetList = async (_params, reload = true) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetUserHasBIT', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                // response.data.data = [{ "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }];
                let newData = [];
                if (reload) {
                    newData = response.data.data;
                } else {
                    newData = [...this.state.userList, ...response.data.data]
                }
                this.setState({
                    userList: newData,
                    totalRowUser: response.data.totalRows,
                    // dataTotal: response.data.data.DataTotal,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleGetListOTCTON = async (_params, reload = true) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetUserHasTON', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                // response.data.data = [{ "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }];
                let newData = [];
                if (reload) {
                    newData = response.data.data;
                } else {
                    newData = [...this.state.userListTON, ...response.data.data]
                }
                this.setState({
                    userListTON: newData,
                    totalRowUserTON: response.data.totalRows,
                    // dataTotal: response.data.data.DataTotal,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleGetListOTCUSDT = async (_params, reload = true) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetUserHasUSDT', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                // response.data.data = [{ "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }, { "userID": 16, "username": "", "wallet_BIT": 1570.00000000 }, { "userID": 15, "username": "", "wallet_BIT": 1200.00000000 }, { "userID": 13, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 14, "username": "", "wallet_BIT": 1000.00000000 }, { "userID": 17, "username": "test", "wallet_BIT": 450.60000000 }, { "userID": 18, "username": "", "wallet_BIT": 300.00000000 }, { "userID": 41, "username": "abc", "wallet_BIT": 236.82560000 }, { "userID": 20, "username": "test", "wallet_BIT": 201.76348000 }];
                let newData = [];
                if (reload) {
                    newData = response.data.data;
                } else {
                    newData = [...this.state.userListUSDT, ...response.data.data]
                }
                this.setState({
                    userListUSDT: newData,
                    totalRowUserUSDT: response.data.totalRows,
                    // dataTotal: response.data.data.DataTotal,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleChangeTab = (tab) => {
        this.setState({ currentTab: tab });
        const {
            PageSize,
            TypeTransaction,
            selectedCoin
        } = this.state;
        let _params = {
            Type: TypeTransaction,
            PageIndex: 1,
            PageSize: PageSize,
            Symbol: selectedCoin ? selectedCoin.symbolID : 0
        };
        this.setState({ PageIndex: 1 });
        if (tab === "Transaction") {
            _params.Type = 1;
            this.setState({ TypeTransaction: 1, dataTrans: [] })
            this.handleGetAllTransaction(_params, true);
        }
        else if (tab === "AirDrop") {
            _params.Type = 2;
            this.setState({ TypeTransaction: 2, dataTrans: [] })
            this.handleGetAllTransaction(_params, true);
        }
        else if (tab === "GasFee") {
            // console.log("GasFee")
            _params.Type = 3;
            this.setState({ TypeTransaction: 3, dataTrans: [] });
            this.handleGetAllTransaction(_params, true);
        }
        else if (tab === "Scholarship") {
            // console.log("Scholarship")
            _params.Type = 4;
            this.setState({ TypeTransaction: 4, dataTrans: [] });
            this.handleGetAllTransaction(_params, true);
        }
    }
    handleLearn = () => {
        if (!this.state.info.isLearning) {
            this.onToogleModalList_Swap();
        }
        else {
            localStorage.setItem("search", null);
            redirectToURL("/learn")
        }
    }
    loadGasFee = () => {
        const {
            PageSize,
            TypeTransaction,
            selectedCoin
        } = this.state;
        let _params = {
            Type: TypeTransaction,
            PageIndex: 1,
            PageSize: PageSize,
            Symbol: selectedCoin ? selectedCoin.symbolID : 0
        };
        this.setState({ PageIndex: 1, dataTrans: [] })
        this.handleGetAllTransaction(_params, true);
    }
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    handleToogleBankInfo = () => {
        this.setState(prevState => ({
            modalBankInfoOpen: !prevState.modalBankInfoOpen
        }));
    }
    showBankInfo = (item) => {
        this.setState({ selectedTrans: item, modalBankInfoOpen: true });
    }
    handleUpdateBankInfo = async (params) => {
        params.Language = this.state.info.language;
        params.Symbol = this.state.selectedCoin.symbol;
        this.setState({ isLoading: true });
        try {
            const response = await post('/Purchase/UpdateBankInfo', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Update successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ modalBankUpdateOpen: false });
                // let _params = { ...this.state.params };
                // //reset pageindex
                // this.setState((prevState) => ({
                //     params: { ...prevState.params, PageIndex: 0 },
                // }));
                // _params.PageIndex = 1;
                // this.props.handleGetList(_params);
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    onToogleModalBankUpdate = () => {
        this.setState(prevState => ({
            modalBankUpdateOpen: !prevState.modalBankUpdateOpen
        }));
    }
    handleSwitch = async (e) => {
        let params = {
            "EnableOTC": e.target.checked,
            "Symbol": this.state.selectedCoin.symbol
        };
        this.setState({ isLoading: true });
        try {
            const response = await post('/Purchase/EnableOTC', params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false, IsChecked: params.EnableOTC });
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000,
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }

    handleChangeTicketSellNow = (ticket) => {
        this.setState({ selectedTicketSellNow: ticket })
    }

    handleChangeTicket = (ticket) => {
        this.setState({ currentTicket: ticket })
    }
    onToogleModalListBuyTON = () => {
        if (!this.state.modalListBuyTON) {
            let params = { PageIndex: 1, PageSize: 500, Username: "" };
            this.handleGetListOTCTON(params);
        }
        this.setState(prevState => ({
            modalListBuyTON: !prevState.modalListBuyTON
        }));

    }
    onToogleModalListBuyUSDT = () => {
        if (!this.state.modalListBuyUSDT) {
            let params = { PageIndex: 1, PageSize: 500, Username: "" };
            this.handleGetListOTCUSDT(params);
        }
        this.setState(prevState => ({
            modalListBuyUSDT: !prevState.modalListBuyUSDT
        }));

    }
    handleToogleModalSellNow = () => {
        this.setState(prevState => ({
            modalSelNow: !prevState.modalSelNow
        }));
    }
    handleGetListBuySellPendingSellNow = async () => {
        const { info } = this.props;
        const { currentTicket } = this.state;
        try {
            let response = await get('/UserReport/PendingFastSell');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                // response.data.data.pendingLst = [
                //     {
                //         "transID": 126,
                //         "userID": 19,
                //         "sellerUsername": "Jenie",
                //         "sellerFullname": "Jenie TEST 123",
                //         "amount": 15.00000000,
                //         "nameBank": "OTC name",
                //         "accNumberBank": "123456789",
                //         "cardholderNameBank": "1122346569",
                //         "requestDate": "2024-06-07T07:08:37.133",
                //         "language": "vi",
                //         "otc": false
                //     },
                //     {
                //         "transID": 127,
                //         "userID": 34,
                //         "sellerUsername": "JuliaDec96",
                //         "sellerFullname": "Julia Dec",
                //         "amount": 1062.00000000,
                //         "nameBank": "test vi",
                //         "accNumberBank": "25432543254",
                //         "cardholderNameBank": "testt",
                //         "requestDate": "2024-06-07T09:08:11.71",
                //         "language": "vi",
                //         "otc": false
                //     }
                // ];
                let selectedTicketSellNow = null;
                this.setState({ isLoading: false });
                if (response.data.data.pendingLst.length > 0) {

                    selectedTicketSellNow = response.data.data.pendingLst[0];
                    // else {
                    //     selectedTicketSellNow = response.data.data.pendingLst.find(x => x.transID === this.state.selectedTicketSellNow.transID);
                    //     if (!selectedTicketSellNow) {
                    //         selectedTicketSellNow = response.data.data.pendingLst[0];
                    //     }
                    // }
                }
                if (selectedTicketSellNow) {
                    if (selectedTicketSellNow.otc) {
                        this.setState({ modalBuySellNowOTC: true, selectedTicketSellNow: null })
                        this.setState({ selectedTicketSellNowOTC: selectedTicketSellNow })
                    }
                    else {
                        this.setState({ modalSelNow: true, selectedTicketSellNowOTC: null })
                        this.setState({ ListTicketSellNow: response.data.data.pendingLst ? response.data.data.pendingLst : [], selectedTicketSellNow: selectedTicketSellNow });
                        this.handleChangeAmount(selectedTicketSellNow)
                    }
                }
                else {
                    this.setState({ modalBuySellNowOTC: false, selectedTicketSellNowOTC: null })
                    this.setState({ modalSelNow: false, selectedTicketSellNow: null, ListTicketSellNow: [] })
                }
            } else {
                // this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            // this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    getUsername = (item, type = 0) => {
        let name = item.transID;
        if (item.email) {
            name = item.email;
        }else
        if (item.sellerUsername) {
            name = item.sellerUsername;
        }
        else if (item.sellerFullname) {
            name = item.sellerFullname;
        }
        if (type === 1) {
            return name;
        }
        if (type === 2) {
            return name.length>6? name.substring(0, 6) + '...':name;
        }
        return name + " (" + item.symbol + ")";
    }
    handleChangeAmount = async (selectedTicketSellNow) => {
        const { pricesLang, prices } = this.state;
        let Amount = selectedTicketSellNow ? Number(selectedTicketSellNow.amount) : 0;
        let amountReceive = 0;
        if (pricesLang) {
            if (selectedTicketSellNow && selectedTicketSellNow.language) {//check thêm dk OTC đủ phí TON
                let price = pricesLang[selectedTicketSellNow.language] * prices.TONUSDT;
                if (selectedTicketSellNow.symbol === "USDT") {
                    price = pricesLang[selectedTicketSellNow.language];
                }
                amountReceive = Amount * price * (1 - prices.OTCRateFastSell);
            }
        }
        if (selectedTicketSellNow) {
            this.setState((prevState) => ({
                selectedTicketSellNow: {
                    ...prevState.selectedTicketSellNow,
                    amountOfLang: amountReceive ? amountReceive.toFixed(3) : 0,
                },
            }));
        }

    };
    handleConfirmSellNow = async () => {
        this.setState({ isLoading: true });
        const { bankInfoSellNow, selectedTicketSellNow, formDataUserSellNow } = this.state;
        let params = {
            "TransID": selectedTicketSellNow.transID,
            "Amount": formDataUserSellNow.AmountCoin
        }
        try {
            const response = await post('/Purchase/AcceptFastSell', params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false, modalSelNow: false, modalBuySellOTC: true });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    checkCurrency = () => {
        const { selectedTicketSellNow } = this.state;
        let currency = "";
        if (selectedTicketSellNow) {
            currency = this.transferCurrency(selectedTicketSellNow.language);
        }
        return currency;
    }
    transferCurrency = (lang) => {
        switch (lang) {
            case "en": return "USD"
            case "vi": return "VND"
            case "ar": return "AED"
            case "de": return "EUR"
            case "es": return "EUR"
            case "fr": return "EUR"
            case "hi": return "INR"
            case "pt": return "EUR"
            case "it": return "EUR"
            case "ru": return "RUB"
            case "zh": return "CNY"
            case "usdt": return "USDT"
            case "bit": return "BIT"
        }
    }
    onToogleModalSellNowOTC = (bank) => {
        this.setState(prevState => ({
            modalBuySellNowOTC: !prevState.modalBuySellNowOTC,
            bankInfoSellNow: bank
        }));
    }
    handleConfirmSellNowOTC = async (params) => {
        const { bankInfoSellNow } = this.state;
        if (!bankInfoSellNow || (!bankInfoSellNow.NameBank || !bankInfoSellNow.AccNumberBank || !bankInfoSellNow.CardholderNameBank)) {
            toast.error(t("Please provide your bank information before performing this function."), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            return;
        }
        let _params = {
            "Amount": params.Amount,
            "NameBank": bankInfoSellNow.NameBank,
            "AccNumberBank": bankInfoSellNow.AccNumberBank,
            "CardholderNameBank": bankInfoSellNow.CardholderNameBank,
            "Symbol": this.state.selectedCoin.symbol
        }
        this.setState({ isLoading: true });
        try {
            const response = await post('Purchase/OTCRequestFastSell', _params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.hanldeLoadBasicInfo();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    hanldeFormatLang = (amount, lang) => {
        if (lang) {
            if (lang === "vi" || lang === "hi") {
                return formatNumber(amount, 0, 0)
            }
        }
        return formatNumber(amount)
    }

    render() {
        const { info, data, isLoading, ListCoin, selectedCoin, modalBuyCoinOpen, showReceipt,
            Receipt, modalSend, modalReceive, modalSwap, prices, bankInfo, hasWallet,
            CurrentAddress, dataTrans, TotalRows, currentTab, TypeTransaction,
            modalList_Swap, AmountTON, userList, totalRowUser, showPolicyModal, showInfoBITModal,
            showRefCode, modalBuySellOTC, OTCRefLink, modalBankUpdateOpen, OTCInfo, remainTimeDiscount,
            pricesLang, pricesAll, IsChecked, currentTicket, modalListBuyTON, userListTON, totalRowUserTON,
            ListTicketSellNow, selectedTicketSellNow, modalSelNow, formDataUserSellNow, errors, Coin, selectedOTCTON,
            OTCInfo_TON, selectedTicketSellNowOTC, modalBuySellNowOTC, modalListBuyUSDT, userListUSDT, totalRowUserUSDT, 
            PageSize, OTCRefLinkEmail } = this.state;
        let ListTicketBuy = data ? data.filter(x => (x.type === 1 || x.type === 3)) : [];


        return (
            <>
                <main className="cover-back wallet-page" ref={this.ListTransactionRef}>
                    <section className="section">
                        <Container className="container-lg ">
                            <div className="cover-rpt">
                                <div className="cover-rpt-header">
                                    <div className="flex-center header-wallet">
                                        <div>
                                            <div className="title-coin">
                                                {selectedCoin ? (
                                                    <>
                                                        {selectedCoin.symbolText}
                                                        {/* {selectedCoin.symbolText === "BIT Token" && <i className="fa fa-question icon-question" onClick={this.onToogleModalInfoBIT}></i>} */}
                                                    </>
                                                ) : ""}
                                            </div>
                                            <div className="cover-coin">
                                                <div className="price-coin">
                                                    <span className="unit">$</span>
                                                    {selectedCoin ? (
                                                        <>
                                                            <span className="before-decimal">
                                                                {String(FormatNumberAmount(selectedCoin.balanceUSDT)).split('.')[0]}
                                                            </span>
                                                            <span className="after-decimal">
                                                                {String(FormatNumberAmount(selectedCoin.balanceUSDT)).split('.')[1] ? "." + String(FormatNumberAmount(selectedCoin.balanceUSDT)).split('.')[1] : ".00"}
                                                            </span>
                                                        </>
                                                    ) : 0}
                                                </div>
                                                <div className="price-ton"> {selectedCoin ? formatNumber(selectedCoin.balance, 3, 3) : 0.000} {selectedCoin ? selectedCoin.symbol : ""} </div>
                                            </div>
                                        </div>
                                        <div>
                                            {selectedCoin && <div className="cover-img">
                                                <img
                                                    className="img-toncoin"
                                                    src={require(`assets/img/${selectedCoin.symbol}.png`)}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex mt-4 cover-button">
                                        {selectedCoin && selectedCoin.symbol === "BIT" &&
                                            <>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalList_Swap}>
                                                    <div type="button" title={t(("Buy"))} className="button-coin-BIT">
                                                        <i className="img-icon fa fa-exchange"></i>
                                                    </div>
                                                    <div className="text-button-BIT">{t(("Buy"))}</div>
                                                </div>
                                            </>
                                        }
                                        {selectedCoin && selectedCoin.symbol === "TON" &&
                                            <>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalListBuyTON}>
                                                    <div type="button" title={t(("Buy"))} className="button-coin-TON">
                                                        <i className="img-icon fa fa-exchange"></i>
                                                    </div>
                                                    <div className="text-button-TON">{t(("Buy"))}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalSend}>
                                                    <div type="button" title={t("Send")} className="button-coin-TON">
                                                        <i className="img-icon fa fa-arrow-up"></i>
                                                    </div>
                                                    <div className="text-button-TON">{t("Send")}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalReceive}>
                                                    <div type="button" title={t("Receive")} className="button-coin-TON">
                                                        <i className="img-icon fa fa-arrow-down"></i>
                                                    </div>
                                                    <div className="text-button-TON">{t("Receive")}</div>
                                                </div>
                                            </>
                                        }
                                        {selectedCoin && selectedCoin.symbol === "BIT" &&
                                            <>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalSend}>
                                                    <div type="button" title={t("Send")} className="button-coin-BIT">
                                                        <i className="img-icon fa fa-arrow-up"></i>
                                                    </div>
                                                    <div className="text-button-BIT">{t("Send")}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalReceive}>
                                                    <div type="button" title={t("Receive")} className="button-coin-BIT">
                                                        <i className="img-icon fa fa-arrow-down"></i>
                                                    </div>
                                                    <div className="text-button-BIT">{t("Receive")}</div>
                                                </div>
                                            </>
                                        }
                                        {selectedCoin && selectedCoin.symbol === "USDT" &&
                                            <>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalListBuyUSDT}>
                                                    <div type="button" title={t("Buy")} className="button-coin-USDT">
                                                        <i className="img-icon fa fa-exchange"></i>
                                                    </div>
                                                    <div className="text-button-USDT">{t("Buy")}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalSwap}>
                                                    <div type="button" title={t("Swap")} className="button-coin-USDT">
                                                        <i className="img-icon fa fa-exchange"></i>
                                                    </div>
                                                    <div className="text-button-USDT">{t("Swap")}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalSend}>
                                                    <div type="button" title={t("Send")} className="button-coin-USDT">
                                                        <i className="img-icon fa fa-arrow-up"></i>
                                                    </div>
                                                    <div className="text-button-USDT">{t("Send")}</div>
                                                </div>
                                                <div className="pointer text-center flex-button-text" onClick={this.onToogleModalReceive}>
                                                    <div type="button" title={t("Receive")} className="button-coin-USDT">
                                                        <i className="img-icon fa fa-arrow-down"></i>
                                                    </div>
                                                    <div className="text-button-USDT">{t("Receive")}</div>
                                                </div>
                                            </>
                                        }
                                        {/* <div className="pointer" onClick={this.onToogleModalBuyCoin}>
                                            <button type="button" title="Buy" className="button-coin">
                                                <i className="img-icon fa fa-plus"></i>
                                            </button>
                                            <br />
                                            <span className="text-button">Buy</span>
                                        </div> */}
                                        {selectedCoin && selectedCoin.symbol === "BIT" &&
                                            <>
                                                <div onClick={this.handleLearn} className="link-learn">
                                                    <div className="pointer text-center flex-button-text">
                                                        <div type="button" title={t("Learn")} className="button-coin-BIT button-learn">
                                                            <i className={`ni ni-hat-3 `}></i>
                                                        </div>
                                                        <div className="text-button-BIT">{t("Learn")}</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                {ListCoin && ListCoin.map((item, index) => {
                                    return (<div key={index} onClick={() => this.handleChangeCoin(item)} className={`pointer cover-information flex-center ${selectedCoin.symbol === item.symbol ? "active" : ""}`}>
                                        <img
                                            className="img-toncoin-small"
                                            src={require(`assets/img/${item.symbol}.png`)}
                                        />
                                        <div className="info">
                                            <div>{item.symbolName}</div>
                                            <div className="small-info">{formatNumber(item.balance, 3, 3)}</div>
                                        </div>
                                        <div className="info-price">
                                            {info && info.wallet_BIT >= info.minConditionShowLinkOTC && item.symbol === "BIT" && <div>
                                                <span onClick={this.onToogleModalRefCode} title={t("OTC Referral Code")}>
                                                    <img
                                                        className="img-small-icon"
                                                        src={require(`assets/img/forward.png`)}
                                                    /></span>
                                                {/* <span onClick={this.onToogleModalBankUpdate} title={t("Bank information")} className="text-dark"><i className="fa fa-bank"></i></span> */}
                                            </div>
                                            }
                                            {item.symbol === "TON" && <div>
                                                <span onClick={this.onToogleModalRefCode} title={t("OTC Referral Code")}>
                                                    <img
                                                        className="img-small-icon"
                                                        src={require(`assets/img/forward.png`)}
                                                    /></span>
                                                {/* <span onClick={this.onToogleModalBankUpdate} title={t("Bank information")} className="text-dark"><i className="fa fa-bank"></i></span> */}
                                            </div>
                                            }
                                            {item.symbol === "USDT" && <div>
                                                <span onClick={this.onToogleModalRefCode} title={t("OTC Referral Code")}>
                                                    <img
                                                        className="img-small-icon"
                                                        src={require(`assets/img/forward.png`)}
                                                    /></span>
                                                {/* <span onClick={this.onToogleModalBankUpdate} title={t("Bank information")} className="text-dark"><i className="fa fa-bank"></i></span> */}
                                            </div>
                                            }
                                            <div className="balance">${FormatNumberUSDT(item.balanceUSDT)}</div>

                                        </div>
                                    </div>)
                                })}

                                {/* <div className="cover-information d-flex">
                                    <img
                                        className="img-toncoin-small"
                                        src={require("assets/img/icon-bitcon.png")}
                                    />
                                    <div className="info">
                                        <div>BitCoin</div>
                                        <div className="small-info">0 BTC</div>
                                    </div>
                                    <div className="info-price">
                                        <div>$0.00</div>
                                    </div>
                                </div> */}


                                <h2 className="text-highlight text-center mb-4 mt-4"></h2>
                                <Tabs
                                    activeKey={currentTab}
                                    className="mb-3 justify-content-center"
                                    onSelect={(k) => this.handleChangeTab(k)}
                                >
                                    <Tab eventKey="Transaction" title={t("Transactions")} >
                                    </Tab>
                                    {selectedCoin && selectedCoin.symbol === "BIT" &&
                                        <Tab eventKey="AirDrop" title={t("Learned")} >
                                        </Tab>
                                    }
                                    {selectedCoin && selectedCoin.symbol === "TON" &&
                                        <Tab eventKey="GasFee" title={t("GasFee")}>
                                        </Tab>
                                    }
                                    {selectedCoin && selectedCoin.symbol === "USDT" &&
                                        <Tab eventKey="Scholarship" title={t("Scholarship")}>
                                        </Tab>
                                    }
                                </Tabs>
                                {dataTrans && dataTrans.length > 0 && <RPTTransaction TypeTransaction={TypeTransaction} Coin={selectedCoin} handleGetAllTransaction={this.handleGetAllTransaction} dataTrans={dataTrans} TotalRows={TotalRows} info={info} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} type={1} bankInfo={bankInfo} handleshowReceipt={this.handleshowReceipt} />}
                                {(!dataTrans || dataTrans.length <= 0) && <div className="text-center">{t("No data")}</div>}
                            </div>
                        </Container>
                    </section>
                </main >
                {
                    isLoading && <Loading />
                }

                {/* handleGetListBuySellPending={this.handleGetListBuySellPending}*/}
                {/* <BuyCoin Coin={selectedCoin} modalBuyCoinOpen={modalBuyCoinOpen} onToogleModalBuyCoin={this.onToogleModalBuyCoin} hanldeBuyCoin={this.hanldeBuyCoin} currentTicket={currentTicketBuy} info={info} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} handleshowReceipt={this.handleshowReceipt} /> */}
                <Send Coin={selectedCoin} modalSend={modalSend} onToogleModalSend={this.onToogleModalSend} prices={prices} BalanceUSDT={selectedCoin ? selectedCoin.balanceUSDT : 0} handleSend={this.handleSend} info={info} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} />
                <Receive Coin={selectedCoin} modalReceive={modalReceive} onToogleModalReceive={this.onToogleModalReceive} hasWallet={hasWallet} CurrentAddress={CurrentAddress} info={info} />
                <List_Swap prices={prices} Coin={selectedCoin} data={userList} TotalRows={totalRowUser} modalList_Swap={modalList_Swap} onToogleModalList_Swap={this.onToogleModalList_Swap} handleGetList={this.handleGetList} hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} info={info} />
                <BuySellOTC pricesAll={pricesAll} pricesLang={pricesLang} OTCInfo={OTCInfo} remainTimeDiscount={remainTimeDiscount}
                    Coin={selectedCoin} modalBuySellOTC={modalBuySellOTC} onToogleModalBuySellOTC={this.onToogleModalBuySellOTC}
                    info={info} hanldeBuyCoin={this.hanldeBuyCoin} ListTicketBuy={ListTicketBuy} currentTicket={currentTicket}
                    hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} handleshowReceipt={this.handleshowReceipt}
                    handleReloadTransaction={this.handleReloadTransaction} TypeTransaction={TypeTransaction}
                    modalList_Swap={modalList_Swap} onToogleModalList_Swap={this.onToogleModalList_Swap}
                    handleChangeTicket={this.handleChangeTicket}
                    modalListBuyTON={modalListBuyTON} onToogleModalListBuyTON={this.onToogleModalListBuyTON}
                    selectedOTCTON={selectedOTCTON} OTCInfo_TON={OTCInfo_TON}
                    hanldeFormatLang={this.hanldeFormatLang} PageSize={PageSize} handleGetAllTransaction={this.handleGetAllTransaction}
                />
                <List_BuyTON prices={prices} pricesLang={pricesLang} Coin={selectedCoin} data={userListTON} TotalRows={totalRowUserTON} modalListBuyTON={modalListBuyTON}
                    onToogleModalBuyTON={this.onToogleModalBuyTON} onToogleModalListBuyTON={this.onToogleModalListBuyTON} handleGetList={this.handleGetList}
                    hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} info={info} hanldeFormatLang={this.hanldeFormatLang} />
                <List_BuyUSDT prices={prices} pricesLang={pricesLang} Coin={selectedCoin} data={userListUSDT} TotalRows={totalRowUserUSDT} modalListBuyUSDT={modalListBuyUSDT}
                    onToogleModalBuyUSDT={this.onToogleModalBuyUSDT} onToogleModalListBuyUSDT={this.onToogleModalListBuyUSDT} handleGetList={this.handleGetList}
                    hanldeLoadBasicInfo={this.hanldeLoadBasicInfo} info={info} hanldeFormatLang={this.hanldeFormatLang} />

                <BuySellNowOTC pricesAll={pricesAll} pricesLang={pricesLang} Coin={selectedCoin}
                    modalBuySellNowOTC={modalBuySellNowOTC} onToogleModalSellNowOTC={this.onToogleModalSellNowOTC}
                    info={info} selectedTicketSellNowOTC={selectedTicketSellNowOTC}
                    hanldeFormatLang={this.hanldeFormatLang} handleConfirmSellNowOTC={this.handleConfirmSellNowOTC}
                    hanldeLoadBasicInfo={this.hanldeLoadBasicInfo}
                />
                <Swap Coin={selectedCoin} info={info} prices={prices} modalSwap={modalSwap} onToogleModalSwap={this.onToogleModalSwap} handleSwapTONtoUSDT={this.handleSwapTONtoUSDT} />
                <Modal toggle={this.onToogleShowReceipt} isOpen={showReceipt} >
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Receipt")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleShowReceipt}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="text-center">
                        <img src={Receipt} className="img-receipt"></img>
                    </ModalBody>
                    <ModalFooter className="button-close-modal" onClick={this.onToogleShowReceipt}>
                        {t("Close")}
                    </ModalFooter>
                </Modal>
                {/* <Modal toggle={this.onToogleModalInfoBIT} isOpen={showInfoBITModal} size="lg">
                    <div className=" modal-header">

                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleModalInfoBIT}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                    </ModalBody>                 
                </Modal> */}
                <Modal toggle={this.onToogleModalRefCode} isOpen={showRefCode}>
                    <div className=" modal-header">
                        <h5 className=" modal-title mr-3">
                            {selectedCoin && selectedCoin.symbol === 'TON' ? t("Share TON") : ""}
                            {selectedCoin && selectedCoin.symbol === 'BIT' ? t("Share BIT") : ""}
                            {selectedCoin && selectedCoin.symbol === 'USDT' ? t("Share USDT") : ""}
                        </h5>
                        <Form.Check type="switch" checked={IsChecked} onChange={this.handleSwitch} className="custom-switch" />
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleModalRefCode}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="body-modal-1">
                        <Form role="form">
                            <Form.Group className="form-address text-center">
                                <label className="label-wallet-2">
                                    {selectedCoin && selectedCoin.symbol === 'TON' ? t("Copy this link to send to the person with whom you wish to share TON in order to receive the national currency of the country you are residing in.") : ""}
                                    {selectedCoin && selectedCoin.symbol === 'USDT' ? t("Copy this link to send to the person with whom you wish to share USDT in order to receive the national currency of the country you are residing in.") : ""}
                                    {selectedCoin && selectedCoin.symbol === 'BIT' ? t("Copy this link and send it to the person with whom you wish to share 100 Bits, to receive the national currency of the country you are residing in.") : ""}
                                </label>
                                <div className="text-address text-center">{OTCRefLink}</div>
                                <div className="button-copy text-center mt-3" onClick={() => this.handleCopyClick(OTCRefLink)}>{t("Copy")}</div>
                                <br/>
                                <div className="text-address text-center">{OTCRefLinkEmail}</div>
                                <div className="button-copy text-center mt-3" onClick={() => this.handleCopyClick(OTCRefLinkEmail)}>{t("Copy")}</div>
                            </Form.Group>
                        </Form>

                        <BankInfo Coin={selectedCoin} modalBankInfoOpen={modalBankUpdateOpen} onToogleModalBankInfo={this.onToogleModalBankUpdate} handleUpdateBankInfo={this.handleUpdateBankInfo} bankInfo={bankInfo} onToogleModalSellNowOTC={this.onToogleModalSellNowOTC} />

                    </ModalBody >
                </Modal >
                <Modal toggle={this.handleToogleModalSellNow} isOpen={this.state.modalSelNow} >
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Buy")} {selectedTicketSellNow ? selectedTicketSellNow.symbol : ""}
                        </h5>
                        {ListTicketSellNow && ListTicketSellNow.length > 1 && <Dropdown className="custom-dropdown-menu modal-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {this.getUsername(selectedTicketSellNow, 2)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {ListTicketSellNow && ListTicketSellNow.map((item, index) => {
                                    return <Dropdown.Item key={index} onClick={() => this.handleChangeTicketSellNow(item)} >{this.getUsername(item)}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>}
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalSellNow}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <Form.Group className="mb-4">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="Amount">{t("Username")}</label>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <div> {selectedTicketSellNow?this.getUsername(selectedTicketSellNow, 1):""}</div>
                                </div>
                            </Form.Group>
                            <div className="cover-icon"></div>
                            <Form.Group >
                                <div className="d-flex align-items-center">
                                    <label htmlFor="Amount">{t("You receive")} </label>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <div>{selectedTicketSellNow ? selectedTicketSellNow.amount : ""}</div>
                                    <div className="unit-price">{selectedTicketSellNow ? selectedTicketSellNow.symbol : ""}</div>
                                </div>
                                {errors.AmountCoin && <div className="error">{errors.AmountCoin}</div>}
                            </Form.Group>
                            <div className="cover-icon mt-4">
                                <div className="icon-arrow">
                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <Form.Group className="">
                                <div className="d-flex align-items-end">
                                    <label>{t("You pay")} ({this.checkCurrency()})</label>
                                </div>
                                <div className="flex-center mt-1 price-buy">
                                    <div>
                                        <div>{selectedTicketSellNow && selectedTicketSellNow.amountOfLang ? this.hanldeFormatLang(selectedTicketSellNow.amountOfLang, selectedTicketSellNow.language) : 0}</div>
                                    </div>
                                    <div className="copy-price">
                                        <span title={t("Copy")} className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(selectedTicketSellNow ? this.hanldeFormatLang(selectedTicketSellNow.amountOfLang, selectedTicketSellNow.language) : 0)}>
                                            <span className="btn-inner--text"><i className="fa fa-copy icon-copy"></i></span>
                                        </span>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="btn-confirm" onClick={this.handleConfirmSellNow}>
                        <div>{t("Confirm")}</div>
                    </ModalFooter>
                </Modal>
                {/* {info.email&&<Link to ="/support" className="icon-comments">
                    <i className="fa fa-comments">
                </i></Link>} */}
                
                <ToastContainer pauseOnFocusLoss={false} />
            </>
        );
    }
}
export default withTranslation()(Wallet);
