import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/common/withRouter";
import { postNoAuth, redirectToURL } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import i18n from "i18next";
class PolicyPage extends React.Component {

  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <main ref="main" className="policy-page">
          <section className="section section-shaped section-lg">
            <Container >
              <Row className="justify-content-center">
                <Col className="d-flex justify-content-center" >
                  <Card className="bg-login shadow">
                    <CardBody className="px-lg-3 py-lg-5 ">
                      <h5 className="text-center text-bold text-primary-color">
                        {t("BitLearning Whitepaper: Empowering Community through Effective Learning")}
                      </h5>
                      <div>
                        <div className="content">
                          <div className="title">1. {t("Summary")}</div>
                          <div className="content-item">- {t("BitLearning is an educational project utilizing Blockchain technology to offer an effective and unique method of learning English through Listening - Speaking, combined with a 'Learn to Earn token' system, empowering the learner community.")} </div>
                          <div className="content-item">- {t("The project creates a creative, efficient, and accessible learning environment for everyone, while unlocking significant potential in the field of English education through Blockchain application.")}</div>
                        </div>

                        <div className="content">
                          <div className="title">2. {t("Challenges")}</div>
                          <div>{t("The traditional English education sector faces several challenges")}:</div>
                          <ul className="ul"><span className="text-strong">- {t("Lack of motivation")}:</span> {t("Learners easily become demotivated and give up due to a lack of motivation and suitable learning methods.")}
                            <li className="li"><span className="text-strong">{t("High learning costs")}:</span> {t('The cost of English learning at high-end centers can be burdensome for many.')}</li>
                            <li className="li"><span className="text-strong">{t("Passive learning methods")}:</span> {t("Traditional teaching methods are often passive, lacking interaction and feedback for learners.")}</li>
                          </ul>
                          <ul className="ul"><span className="text-strong">- {t("Lack of community connection")}:</span> {t("Absence of an interactive learning environment and connection among learners.")}</ul>
                        </div>

                        <div className="content">
                          <div className="title">3. {t("Solution")}</div>
                          <ul className="ul"><span className="text-strong">- {t("BitLearning addresses these challenges by")}:</span>
                            <li className="li"><span className="text-strong">{t("Effective Listening - Speaking learning method")}:</span> {t("Applying the Listening - Speaking learning method helps learners practice English listening and speaking abilities naturally and effectively.")}</li>
                          </ul>
                          <div className="content-item"><span className="text-strong">- {t('Learn to Earn token system')}:</span>{t("Rewarding tokens to learners upon completing lessons encourages active learning and provides motivation for knowledge acquisition.")}</div>
                          <div className="content-item"><span className="text-strong">- {t("Online learning platform")}:</span> {t("Providing an easily accessible online learning platform allows learners to study anytime, anywhere.")}</div>
                          <div className="content-item"><span className="text-strong">- {t("Dynamic learning community")}:</span> {t("Building a dynamic learning community connects learners to share experiences and support each other.")}</div>
                          <div className="content-item"><span className="text-strong">- {t("Unlocking potential with Blockchain")}:</span> </div>
                        </div>

                        <div className="content">
                          <div className="title">4. {t("Operating Model")}</div>
                          <div className="title-1">4.1 {t("Blockchain System")}</div>
                          <div className="content-item">- {t("Blockchain stores all information about lessons, token transactions transparently and securely.")}</div>
                          <ul className="ul">- {t("BitLearning Token (BIT) is used for:")}
                            <li className="li">{t("Rewarding learners upon completing lessons.")}</li>
                            <li className="li">{t("Paying for premium services on the platform.")}</li>
                            <li className="li">{t("Participating in project governance.")}</li>
                          </ul>

                          <div className="title-1">4.2 {t("Online Learning Platform")}</div>
                          <div className="content-item">- {t("Offers a variety of English lessons using the Listening - Speaking method")}.</div>
                          <div className="content-item">- {t("Applies modern learning methods, combining images, audio, video, and games to engage learners.")}</div>
                          <div className="content-item">- {t("Features an effective evaluation and progress tracking system.")}</div>
                          <div className="content-item">- {t("Utilizes Artificial Intelligence (AI) to assist learners in practicing pronunciation and intonation.")}</div>

                          <div className="title-1">4.3 {t("Community")}</div>
                          <div className="content-item">- {t("The BitLearning community is a place where learners can interact, share knowledge, and learning experiences.")}</div>
                          <div className="content-item">- {t("Decentralized Autonomous Organization (DAO) platform allows the community to participate in project development decisions.")}</div>
                        </div>

                        <div className="content">
                          <div className="title">5. {t("Technical Details")}</div>
                          <div className="content-item">- {t("Total BIT Token Supply: 210,000,000 BIT")}</div>
                          <div className="content-item">- {t("Number of Users Airdropped: 100,000")}</div>
                          <div className="content-item">- {t("Maximum BIT per Account: 2100 BIT")}</div>
                          <div className="content-item">- {t("Mining Method: Hold 100 BIT")}</div>
                          <div className="content-item">- {t("Distribution: From 0.1 to 10 BIT for each word or phrase learned")}</div>
                        </div>

                        <div className="content">
                          <div className="title">6. {t("Benefits")}</div>
                          <div className="title-1">6.1 {t("For Learners")}:</div>
                          <div className="content-item">- {t("Enhances English learning efficiency")}.</div>
                          <div className="content-item">- {t("Saves learning costs.")}</div>
                          <div className="content-item">- {t("Earns income from learning.")}</div>
                          <div className="content-item">- {t("Participates in a dynamic learning community for mutual support.")}</div>
                          <div className="content-item">- {t("Becomes part of a promising Blockchain project.")}</div>

                          <div className="title-1">6.2 {t("For the Project:")}</div>
                          <div className="content-item">- {t("Develops a strong user community.")}</div>
                          <div className="content-item">- {t("Attracts investment capital from the community.")}</div>
                          <div className="content-item">- {t("Creates a decentralized, autonomous, and efficient education ecosystem.")}</div>
                          <div className="content-item">- {t("Unlocks significant potential in the field of English education through Blockchain application.")}</div>
                        </div>

                        <div className="content">
                          <div className="title">7. {t("Development Roadmap:")}</div>
                          <div className="title-1">| {t("Phase 1 (Q1/2023 - Q1/2024)")}</div>
                          <div className="content-item">- {t("Develop and finalize the basic Listening - Speaking English learning platform with 3 levels: Basic, Intermediate, and Advanced.")}</div>
                          <div className="content-item">- {t("Develop an AI system to support learners in practicing pronunciation and intonation.")}</div>
                          <div className="content-item">- {t("Establish an initial learner community with 1000 members.")}</div>
                          <div className="content-item">- {t("Finalize tokenomics system and 'Learn to Earn token' model with reward ratios ranging from 0.1 BIT to 10 BIT for each word or phrase learned.")}</div>
                          <div className="content-item">- {t("Develop Smart Contracts to manage BIT tokens and transactions on the platform.")}</div>

                          <div className="title-1">| {t("Phase 2 (Q2/2024 - Q3/2024)")}</div>
                          <div className="content-item">- {t("Further develop features for content creation based on demand.")}</div>
                          <div className="content-item">- {t("Enhance interactive learning features such as games, quizzes, grammar tests, etc.")}</div>
                          <div className="content-item">- {t("BitLearning proudly announces successful expansion to 10 countries where English can be learned, including China, India, Spain, France, Arabia, Russia, Portugal, Italy, Germany, and Vietnam.")}</div>
                          <div className="content-item">- {t("Expand user community to 10,000 members.")}</div>
                          <div className="content-item">- {t("Increase token rewards for actively engaged learners.")}</div>
                          <div className="content-item">- {t("Develop a decentralized autonomous organization (DAO) system to allow community involvement in project development decisions.")}</div>
                          <div className="content-item">- {t("Collaborate with reputable educational institutions to offer high-quality courses.")}</div>

                          <div className="title-1">| {t("Phase 3 (Q4/2024 - Q1/2025)")}</div>
                          <div className="content-item">- {t("Develop an AI system to support learners in practicing English communication.")}</div>
                          <div className="content-item">- {t("BitLearning will continue expanding to 10 additional countries where English can be learned, such as Japan, South Korea, Indonesia, Malaysia, Thailand, Taiwan, Myanmar, Bangladesh, Laos, and Cambodia.")}</div>
                          <div className="content-item">- {t("Provide premium services such as IELTS, TOEIC test preparation, etc.")}</div>
                          <div className="content-item">- {t("Expand user community to 50,000 members.")}</div>
                          <div className="content-item">- {t("Develop a linkage system with offline English learning centers to provide diverse learning services.")}</div>
                          <div className="content-item">- {t("Collaborate with businesses to offer English training programs for employees.")}</div>


                          <div className="title-1">| {t("Phase 4 (From Q2/2025 onwards)")}</div>
                          <div className="content-item">- {t("Become a leading online learning platform utilizing AI technology.")}</div>
                          <div className="content-item">- {t("Expand user community to 100,000 members.")}</div>
                          <div className="content-item">- {t("Develop a BIT token payment system on the platform.")}</div>
                          <div className="content-item">- {t("Support other education projects adopting Bit token technology.")}</div>
                          <div className="content-item">- {t("Continue expanding to 10 more countries where English can be learned, such as Mexico, Brazil, Ecuador, Colombia, Venezuela, Bolivia, Chile, Paraguay, Uruguay, and Argentina.")}</div>
                          <div className="content-item">- {t("Support the international education community, including study abroad programs in countries like Australia, Canada, and the United States.")}</div>
                          <div className="content-item">- {t("Provide support for settlement in countries such as Australia, Canada, and the United States for active members.")}</div>
                        </div>

                        <div className="content">
                          <div className="title">8. {t("Conclusion")}</div>
                          <div className="content-item">{t("BitLearning is a creative and promising educational project that empowers the community and creates an effective English learning environment for everyone. Join BitLearning today to start your journey towards mastering English.")}</div>
                        </div>

                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default withTranslation()(withRouter(PolicyPage));
