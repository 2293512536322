import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    Container,
    Row,
    Col
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import { post, get, checkToken } from "services/apiServices";
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { validateMaxLength, validateMinLength, formatDate2, FormatNumberUSDT, calculateDayRemaining } from "services/common";
import Loading from "../../components/Loading/Loading"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import configs from "configs/configs";
import RPTReset2FA from "views/Report/RPTReset2FA";
import ModalConfirm from "../../components/ConfirmModal/Confirm"

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TwoFA: localStorage.getItem("2FA") === "true" ? true : false,
            refCode: localStorage.getItem("refCode"),
            name: localStorage.getItem("email"),
            modal2FAOpen: false,
            qrsecrectImg: "https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth://totp/124883?secret=XL47V2BD3DAVY3WB&issuer=BitIndex",
            qrsecrect: "XL47V2BD3DAVY3WB",
            qrcode: "",
            qrcodeErr: "",
            formData: {
                oldpassword: '',
                newpassword: '',
                confirmpassword: '',
            },
            errors: {
                oldpassword: '',
                newpassword: '',
                confirmpassword: '',
            },
            typepass: "password",
            typeconfirm: "password",
            typeoldpass: "password",
            showoldpass: false,
            showpass: false,
            showconfirm: false,
            isLoading: false,
            dataReset2FA: [],
            TotalRows: 0,
            confirmText: "",
            showConfirm: false,
            ComPending: localStorage.getItem("ComPending"),
            Balance: localStorage.getItem("Balance"),
            TotalF1: localStorage.getItem("TotalF1")
        }
        this.inputRef = React.createRef();
        this.inputRef1 = React.createRef();
    }
    componentDidMount() {
        document.body.classList.add('bg-other');
        document.body.classList.remove('bg-index');
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        // this.hanldeLoadBasicInfo();
    }
    // hanldeLoadBasicInfo = async () => {
    //     this.setState({ isLoading: true });
    //     try {
    //         let response = await get('/Users/UserInfoBasic');
    //         if (response && response.data.errorCode === 0) {
    //             this.setState({ isLoading: false });
    //             if (response.data.data != null) {
    //                 this.setState({
    //                     info: response.data.data.userInfo,
    //                     TwoFA: response.data.data.userInfo.twoFA,
    //                     refCode: response.data.data.userInfo.refCode,
    //                     ComPending: response.data.data.userInfo.comPending,
    //                     Balance: response.data.data.userInfo.balance,
    //                     TotalF1: response.data.data.userInfo.totalF1BuyPackageDue,
    //                 })
    //                 localStorage.setItem("refCode", response.data.data.userInfo.refCode);
    //                 localStorage.setItem("TwoFA", response.data.data.userInfo.twoFA);
    //                 localStorage.setItem("Balance", response.data.data.userInfo.balance);
    //                 localStorage.setItem("CurrentPackageAmount", response.data.data.userInfo.currentPackageAmount);
    //                 localStorage.setItem("ComPending", response.data.data.userInfo.comPending);
    //                 localStorage.setItem("TotalF1", response.data.data.userInfo.totalF1BuyPackageDue);
    //             }
    //         } else {
    //             this.setState({ isLoading: false });
    //             toast.error(t(response.data.errorMessage), {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 5000,
    //                 onOpen: () => checkToken(response.data)
    //             });
    //         }
    //     } catch (error) {
    //         this.setState({ isLoading: false });
    //         let response = error.response;
    //         if (response.data) {
    //             toast.error(t(response.data.errorMessage), {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 5000,
    //                 onOpen: () => checkToken(response.data)
    //             });
    //         } else {
    //             checkToken(response)
    //         }
    //     }
    // }
    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    handleChangePassword = async (e) => {
        e.preventDefault();
        const { formData } = this.state;
        let formIsValid = true;
        const newErrors = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
            otp: '',
            gacode: ''
        };

        if (!formData.oldpassword) {
            formIsValid = false;
            newErrors.oldpassword = t('Please enter your old password.');
        } else
            if (!validateMinLength(formData.oldpassword, 6)) {
                formIsValid = false;
                newErrors.oldpassword = t('minCharacterMessage', { length: 6 });
            }
            else if (!validateMaxLength(formData.oldpassword, 16)) {
                formIsValid = false;
                newErrors.oldpassword = t('maxCharacterMessage', { length: 16 });
            }

        if (!formData.newpassword) {
            formIsValid = false;
            newErrors.newpassword = t('Please enter your password.');
        }
        else if (!validateMinLength(formData.newpassword, 6)) {
            formIsValid = false;
            newErrors.newpassword = t('minCharacterMessage', { length: 6 });
        }
        else if (!validateMaxLength(formData.newpassword, 16)) {
            formIsValid = false;
            newErrors.newpassword = t('maxCharacterMessage', { length: 16 });
        }
        if (!formData.confirmpassword) {
            formIsValid = false;
            newErrors.confirmpassword = t('Please enter your confirm password.');
        }
        else if (formData.confirmpassword !== formData.newpassword) {
            formIsValid = false;
            newErrors.confirmpassword = t('Passwords must match.');
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn

        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            this.setState({ isLoading: true });
            try {
                //const response = await get('/ChangePassword');
                let params = {
                    "OldPassword": formData.oldpassword,
                    "NewPassword": formData.newpassword,
                    "ConfirmNewPassword": formData.confirmpassword,
                };
                const response = await post('/Users/ChangePassword', params);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Change password successfully!"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    // this.setState({ modalProfileOpen: false })
                    this.clearForm();
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };
    handleChangeQRCode = (e) => {
        const { value } = e.target;
        this.setState({ qrcode: value });
    };
    handleGetGACode = async (e) => {
        const { info } = this.props;
        let _2FAOpen = this.state.modal2FAOpen;
        this.setState({ modal2FAOpen: !_2FAOpen })
        this.setState({ qrcode: "" })
        this.setState({ qrcodeErr: "" })
        if (!_2FAOpen && !info.twoFA) {
            this.setState({ isLoading: true });
            try {
                let response = await post('/Users/GoogleAuth');
                if (response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    this.setState({ qrsecrectImg: response.data.data.qrPath })
                    this.setState({ qrsecrect: response.data.data.secretKey })
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    };
    handleGetOTP = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/GetOTP', { Type: 3 });
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t(response.data.data.Notify), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleSetTwoFA = async (e) => {
        this.setState({ qrcodeErr: "" });
        const { info } = this.props;
        if (!this.state.qrcode) {
            this.setState({ qrcodeErr: t("Please enter your Code.") });
            return;
        }
        let _params = {
            "Enable2FA": !info.twoFA,
            "GACode": this.state.qrcode
        }
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/SetTwoFA', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success((_params.Enable2FA ? t("Enable") : t("Disable")) + " " + t("Two-Factor Authentication").toLowerCase() + " " + t("successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                localStorage.setItem("2FA", _params.Enable2FA);
                this.props.hanldeLoadBasicInfo();
                this.setState({ qrcode: "", modal2FAOpen: !this.state.modal2FAOpen });
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleOpenChangePW = () => {
        this.setState(prevState => ({
            modalProfileOpen: !prevState.modalProfileOpen
        }));
        this.clearForm();
    }
    handleShowPass = (type) => {
        if (type === 1) {
            this.setState({
                showoldpass: !this.state.showoldpass,
                typeoldpass: this.state.showoldpass ? "password" : "text",
            });
        } else if (type === 2) {
            this.setState({
                showpass: !this.state.showpass,
                typepass: this.state.showpass ? "password" : "text",
            });
        } else if (type === 3) {
            this.setState({
                showconfirm: !this.state.showconfirm,
                typeconfirm: this.state.showconfirm ? "password" : "text",
            });
        }
    };
    clearForm = () => {
        let _data = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
            otp: '',
            gacode: ''
        }
        let newerrors = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
            otp: '',
            gacode: ''
        }
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    }
    handlePasteToInput = (e, type) => {
        e.preventDefault();
        let inputRef = type === 0 ? this.inputRef1 : this.inputRef;
        if (inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 0) {
                        this.setState({ qrcode: pastedText });
                    } else if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, otp: pastedText },
                        }));
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, gacode: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };
    handleToogleModalProfile = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalProfile();
        this.clearForm();
    };
    handleReset2FA = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/RequestReset2FA',);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ qrcode: "", modal2FAOpen: !this.state.modal2FAOpen });
                var _params = {
                    Status: 0,
                    PageIndex: 1,
                    PageSize: 10
                }
                this.handleGetList(_params);
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleGetList = async (_params) => {
        this.setState({ isLoading: true });
        try {
            let response = await get('/Users/GetReset2FA', _params);
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                this.setState({
                    dataReset2FA: response.data.data,
                    TotalRows: response.data.totalRows,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            console.error(error);
        }
    };
    handleChangeTab = (tab) => {
        if (tab === "2FA") {
            var _params = {
                Status: 0,
                PageIndex: 1,
                PageSize: 10
            }
            this.handleGetList(_params);
        }
    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        this.setState({ showConfirm: false });
        this.setState({ isLoading: true });
        try {
            let response = await post('/Users/WithdrawCom');
            if (response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Withdraw commission successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({
                    Balance: response.data.data.balance,
                    ComPending: response.data.data.comPending,
                })
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };
    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal = () => {
        // Mở modal confirm
        this.setState({ confirmText: t("Are you sure to withdraw the pending commission?") })
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    render() {
        const { info } = this.props;
        const { confirmText, showConfirm, ComPending, Balance, TotalF1, formData, errors, modal2FAOpen, qrcode, qrcodeErr, showoldpass, showpass, showconfirm, typeoldpass, typepass, typeconfirm, isLoading, refCode, dataReset2FA, TotalRows } = this.state;
        const reflink = configs.WebRoot + "/s/" + refCode;
        const teleBotlink = configs.TeleBotLink + info.teleBotKey;
        const TwoFA = info ? info.twoFA : false;
        // let remain = info.expiredWithdrawComDateString ? calculateDayRemaining(info.expiredWithdrawComDateString).days : 0;

        return (
            <>
                {/* <HeaderNavbar info={info} fluid={false} /> */}
                <main ref="main" className="cover-back-profile">
                    <section className="section">
                        <Container className="container-lg ">
                            <div className="cover-profile">
                                {/* <div className="com-pending in-proile" title={t("Pending Commission")}>
                                    <label>{t("Pending Commission")} <span>{info.expiredWithdrawComDateString ? "(" + t("Expired Date") + ": " + formatDate2(info.expiredWithdrawComDateString) + ")" : ""}</span></label>
                                    <div>
                                        <a className="t-link" title={t("Pending Commission")} href="/transaction/2"><span className="num">{FormatNumberUSDT(info.comPending)} /</span></a>
                                        <a className="t-link" title={t("Members")} href="/transaction/3"><span className="f1 remain-day">{info.remainWithdrawComDay}D</span></a>
                                    </div>
                                </div> */}
                                <div className="frame-panel">
                                    <div sm="12" lg="6" className="panel-left">
                                        <div className="text-center">
                                            <label className="" >
                                                {t("Support")}
                                            </label>
                                            <Link className="href-link in-proile" title={t("Support")} to="/support">
                                                <span className="icon-text"> <i className="fa fa-comments"></i></span>&nbsp;
                                            </Link>
                                        </div>
                                    </div>
                                    &nbsp;
                                    &nbsp;
                                    <div sm="12" lg="6" className="panel-right">
                                        {info.teleBotKey && <>
                                            <div className="text-center">
                                                <label className="" >
                                                    {t("Balance fluctuations")}
                                                </label>
                                                <Link className="href-link" to={teleBotlink} target="_blank" title={t("Balance fluctuations")}>
                                                    Telegram <span className="btn-icon btn-copy pointer text-primary ml-1"><span className="icon-text"> <i className="fa fa-telegram"></i></span></span>
                                                </Link>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>

                                <Tabs
                                    defaultActiveKey="changePW"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    onSelect={(k) => this.handleChangeTab(k)}
                                >
                                    <Tab eventKey="changePW" title={t("Change Password")}>
                                        <div >
                                            <Form role="form" onSubmit={this.handleChangePassword}>
                                                <FormGroup className="mb-3">
                                                    <label>{t("Old Password")}</label>
                                                    <InputGroup className="input-group" >
                                                        <Input placeholder={t("Old Password")} type={typeoldpass} name="oldpassword" value={formData.oldpassword} onChange={this.handleChange} />
                                                        <InputGroupAddon addonType="append" title={t("Show Password")} >
                                                            <InputGroupText >
                                                                <span className="pointer" onClick={() => this.handleShowPass(1)}> <span className="btn-inner--text btn-span"><i className={!showoldpass ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {errors.oldpassword && <div className="error">{errors.oldpassword}</div>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>{t("New Password")}</label>
                                                    <InputGroup className="input-group">
                                                        <Input
                                                            placeholder={t("New Password")}
                                                            type={typepass}
                                                            autoComplete="off"
                                                            name="newpassword" value={formData.newpassword} onChange={this.handleChange}
                                                        />
                                                        <InputGroupAddon addonType="append" title={t("Show Password")} >
                                                            <InputGroupText >
                                                                <span className="pointer" onClick={() => this.handleShowPass(2)}> <span className="btn-inner--text btn-span"><i className={!showpass ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {errors.newpassword && <div className="error">{errors.newpassword}</div>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>{t("Confirm Password")}</label>
                                                    <InputGroup className="input-group">
                                                        <Input
                                                            placeholder={t("Confirm Password")}
                                                            type={typeconfirm}
                                                            autoComplete="off"
                                                            name="confirmpassword" value={formData.confirmpassword} onChange={this.handleChange}
                                                        />
                                                        <InputGroupAddon addonType="append" title={t("Show Password")} >
                                                            <InputGroupText >
                                                                <span className="pointer" onClick={() => this.handleShowPass(3)}> <span className="btn-inner--text btn-span"><i className={!showconfirm ? "fa fa-eye" : "fa fa-eye-slash"}></i></span></span>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    {errors.confirmpassword && <div className="error">{errors.confirmpassword}</div>}
                                                </FormGroup>
                                            </Form>
                                            <div className="text-center">
                                                <Button color={"primary"} type="submit" onClick={this.handleChangePassword}>
                                                    {t("Submit")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="2FA" title={t("Two-Factor Authentication")}>
                                        <div className="group-control">
                                            <div className="d-flex cover-2fa mt-2">
                                                <div>
                                                    <div className="title-lg">{t("Two-Factor Authentication")} (2FA)</div>
                                                </div>
                                                <div title={`${(TwoFA ? t("Disable") : t("Enable"))} ${t("Two-Factor Authentication").toLowerCase()}`} className="ml-auto">
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={TwoFA}//
                                                        onChange={this.handleGetGACode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <RPTReset2FA handleGetList={this.handleGetList} data={dataReset2FA} TotalRows={TotalRows} />
                                    </Tab>
                                </Tabs>
                            </div>

                        </Container>
                    </section>
                </main>
                <Modal toggle={() => this.setState({ modal2FAOpen: !modal2FAOpen })} isOpen={modal2FAOpen}>
                    <div className=" modal-header">
                        <h5 className=" modal-title" id="exampleModalLabel">
                            {(TwoFA ? t("Disable") : t("Enable"))} {t("Two-Factor Authentication").toLowerCase()}
                        </h5>
                        <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => this.setState({ modal2FAOpen: !modal2FAOpen })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        {!TwoFA && <>
                            <div className="bold text-center mb-2">{t("Scan this QR and enter the 2FA code from your Google Authenticator App to confirm")}</div>
                            <div className="text-center">
                                <img
                                    alt={t("Two-Factor Authentication")}
                                    title={t("Two-Factor Authentication")}
                                    src={this.state.qrsecrectImg}
                                />
                                <br />
                                <br />
                                <Button color="light" className="btn-icon btn-copy" onClick={() => this.handleCopyClick(this.state.qrsecrect)}><span className="icon-text">{this.state.qrsecrect} <i className="ni ni-single-copy-04"></i></span></Button>&nbsp;
                            </div>

                            <FormGroup>
                                <InputGroup className="input-group mt-4">
                                    <Input
                                        placeholder={t("Enter Google Authenticator Code")}
                                        type="text"
                                        autoComplete="off"
                                        name="qrcode" value={qrcode} onChange={this.handleChangeQRCode} ref={this.inputRef1}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} onClick={(e) => this.handlePasteToInput(e, 0)}>
                                        <InputGroupText>
                                            <i className="fa fa-paste"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {qrcodeErr && <div className="error">{qrcodeErr}</div>}
                            </FormGroup>
                        </>}
                        {TwoFA && <>
                            <div className="bold mb-2">{t("Enter your code in Google Authenticator App")}</div>
                            <FormGroup>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={t("Google Authenticator Code")}
                                        type="text"
                                        autoComplete="off"
                                        name="qrcode" value={qrcode} onChange={this.handleChangeQRCode} ref={this.inputRef1}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-sm pointer" onClick={(e) => this.handlePasteToInput(e, 0)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {qrcodeErr && <div className="error">{qrcodeErr}</div>}
                            </FormGroup>
                            <div>{t("Click")} <span className="pointer text-underline text-highlight" onClick={this.handleReset2FA}>{t("here")}</span> {t("to reset your two-factor authentication")}</div>
                        </>}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => this.setState({ modal2FAOpen: !modal2FAOpen })}
                        >
                            {t("Close")}
                        </Button>
                        <Button color={!TwoFA ? "success" : "danger"} type="button" onClick={this.handleSetTwoFA}>
                            {t(!TwoFA ? "Enable" : "Disable")}
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalConfirm
                    message={confirmText}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                {isLoading && <Loading />}
                <ToastContainer pauseOnFocusLoss={false} />
            </>
        );
    }
}
export default withTranslation()(Profile);
