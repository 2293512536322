import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"
import { post, checkToken, postFormData } from "services/apiServices";
import { cutNumberFormat, FormatNumberCoin, formatNumber } from "services/common";
import configs from "configs/configs";
import { io } from "socket.io-client";
import ModalConfirm from "../../components/ConfirmModal/Confirm";
import Resizer from "react-image-file-resizer";
class BuyCoin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                AmountCoin: "",
                PriceVND: 0,
                AmountVND: 0
            },
            errors: {
                AmountCoin: '',
                AmountVND: '',
            },
            intervalFee: null,
            IsInternalTransfer: false,
            modalConfirmTransferOpen: false,
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            errReceipt: "",
            showConfirm: false,
            confirmText: "",
            confirmTitle: "",
            currentType: 0,
        }
        this.inputRef = React.createRef();
        this.socket = io(configs.SocketPrice);
    }

    clearForm = () => {
        let newerrors = {
            AmountCoin: '',
            AmountVND: '',
        };
        let _data = {
            AmountCoin: "",
            PriceVND: 0,
            AmountVND: 0,
        };
        //fill current ticket 
        const { currentTicket } = this.props;
        this.socket.disconnect();
        if (currentTicket) {
            _data = {
                AmountCoin: currentTicket.amountUSDT,
                PriceVND: currentTicket ? currentTicket.priceVND : 0,
                AmountVND: currentTicket ? formatNumber(currentTicket.amountVND) : 0,
            };
        }
        else {
            this.socket = io(configs.SocketPrice);
            this.socket.on("connect", () => {
                // console.log("Price Connected");
            });
            this.socket.on("price", (msg) => {
                // console.log("msg", msg);
                if (msg && msg.errorCode === 0) {
                    let data = msg.data;
                    let _price = 0;
                    if (this.props.Coin.symbol === "TON") {
                        _price = data.TONVND ? Number(data.TONVND) : 0;
                    } else if (this.props.Coin.symbol === "BIT") {
                        _price = data.BITVND ? Number(data.BITVND) : 0;
                    }
                    this.setState((prevState) => ({
                        formData: { ...prevState.formData, PriceVND: _price },
                    }));
                    this.handleChangeAmount(this.state.formData.AmountCoin, _price)
                }
            });
        }
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleBuyCoin = async (e) => {
        const { formData } = this.state;
        let formIsValid = true;
        const newErrors = {
            AmountCoin: "",
        };

        if (!formData.AmountCoin) {
            formIsValid = false;
            newErrors.AmountCoin = t('Please enter Coin amount.');
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Amount: formData.AmountCoin ? Number(formData.AmountCoin) : 0
            };
            this.props.hanldeBuyCoin(params);
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handleToogleModalBuyCoin = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalBuyCoin();
    };
    handleChangeAmount = (value, price) => {
        value = value ? Number(value) : "";
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountCoin: value },
        }));
        let amountReceive = formatNumber(value * price, 0, 0);
        this.setState((prevState) => ({
            formData: { ...prevState.formData, AmountVND: amountReceive },
        }));
    };
    handleCheckInternal = async (value) => {
        //get socket update price VND
    }
    handleLoadModal = () => {
        this.clearForm();
    }
    handleCloseModal = () => {
        //disconnect socket price
        if (this.socket) {
            this.socket.disconnect();
        }
    }
    //Confirm
    handleOpenModalConfirmTransfer = (item) => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.showReceipt,
            errReceipt: "",
            confirmData: {
                Receipt: null,
                ReceiptURL: ""
            },
            selectedTrans: item
        }));
    }
    onToogleConfirmTransfer = () => {
        this.setState(prevState => ({
            modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
        }));
    }
    hanldeConfirmTransfer = async (confirmData) => {
        // const { confirmData } = this.state;
        const { currentTicket } = this.props;
        const { info } = this.props;
        let formIsValid = true;
        if (!confirmData.Receipt) {
            formIsValid = false;
            this.setState({ errReceipt: t('Please choose a receipt.') });
        }
        if (formIsValid) {
            this.setState({ errReceipt: "" });
            let _formData = new FormData();
            _formData.append('Receipt', confirmData.Receipt ? confirmData.Receipt : null);
            _formData.append('TransID', currentTicket.transID ? Number(currentTicket.transID) : 0);
            this.setState({ isLoading: true });
            let url = "/Purchase/ConfirmTransfer"
            if (info.otc) {
                url = "/Purchase/OTCConfirmTransfer"
            }
            try {
                const response = await postFormData(url, _formData);
                if (response && response.data.errorCode === 0) {
                    this.setState({ isLoading: false });
                    toast.success(t("Requested successfully"), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    this.setState(prevState => ({
                        modalConfirmTransferOpen: !prevState.modalConfirmTransferOpen
                    }));
                    // this.handleToogleModalBuyCoin();
                    this.props.hanldeLoadBasicInfo();
                } else {
                    this.setState({ isLoading: false });
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                }
            } catch (error) {
                this.setState({ isLoading: false });
                let response = error.response;
                if (response && response.data) {
                    toast.error(t(response.data.errorMessage), {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        onOpen: () => checkToken(response.data)
                    });
                } else {
                    checkToken(response)
                }
            }
        }
    }
    handleFileSelected = (e) => {
        // debugger
        const files = Array.from(e.target.files);
        //resize image
        if (files[0]) {
            try {
                Resizer.imageFileResizer(
                    files[0],
                    768,
                    768,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        const imageUrl = URL.createObjectURL(uri);
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, Receipt: uri },
                        }));
                        this.hanldeConfirmTransfer({ Receipt: uri });
                        this.setState((prevState) => ({
                            confirmData: { ...prevState.confirmData, ReceiptURL: imageUrl }, errReceipt: ""
                        }));
                    },
                    "file",
                    200,
                    200
                );
            } catch (err) {
                console.log(err);
            }
        }

        // console.log("img url", imageUrl);

    }
    handleConfirm = async () => {
        // Xử lý khi người dùng chọn OK
        const { info, currentTicket } = this.props;
        let params = {
            TransID: currentTicket.transID
        };
        this.setState({ isLoading: true });
        let url = "";
        if (this.state.currentType === 1) {
            url = "/Purchase/OTCAccecptedBuySell";
        } else if (this.state.currentType === 2) {
            if (info.otc) {
                url = "/Purchase/OTCConfirmReceiveTransfer"
            }
            else {
                url = "/Purchase/ConfirmReceiveTransfer"
            }
        } else {
            url = "/Purchase/CancelBuySellUSDT";
        }
        try {
            const response = await post(url, params);
            if (response && response.data.errorCode === 0) {
                this.setState({ isLoading: false });
                toast.success(t("Requested successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                this.setState({ showConfirm: false });
                // this.handleToogleModalBuyCoin();
                this.props.handleGetListBuySellPending();
            } else {
                this.setState({ isLoading: false });
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleCancel = () => {
        // Xử lý khi người dùng chọn Close
        this.setState({ showConfirm: false });
    };
    handleOpenModal(item, type) {
        // Mở modal confirm
        let _confirmText = "";
        let _confirmTitle = "";
        switch (type) {
            case 1: {//OTC Accept
                _confirmText = "Are you sure you want to confirm this transaction?";
                break;
            }
            case 2: {//OTC Confirm received payment
                _confirmText = "I have received payment.";
                _confirmTitle = "Confirm transaction";
                break;
            }
            case 3: {//Seller cancel
                _confirmText = "Are you sure you want to cancel this transaction?";
                _confirmTitle = "Cancel transaction";
                break;
            }
        }
        this.setState({ selectedTrans: item, confirmText: t(_confirmText), confirmTitle: _confirmTitle, currentType: type });
        this.setState(prevState => ({
            showConfirm: !prevState.showConfirm
        }));
    };
    componentDidUpdate(prevProps) {
        // Close popup when complete transaction
        if (this.props.currentTicket !== prevProps.currentTicket && !this.props.currentTicket && this.props.modalBuyCoinOpen) {
            this.handleToogleModalBuyCoin();
        }
        // // Close popup when disputed transaction
        // if (this.props.currentTicket !== prevProps.currentTicket && this.props.currentTicket && this.props.currentTicket.status === 2 && (!this.props.currentTicket.timeRemain || this.props.currentTicket.timeRemain.total <= 0) && this.props.modalBuyCoinOpen) {
        //     this.handleToogleModalBuyCoin();
        // }
        // Open popup when exist transaction
        if (this.props.currentTicket !== prevProps.currentTicket && this.props.info && this.props.info.otc === false && this.props.currentTicket && this.props.currentTicket.type === 1 && !this.props.modalBuyCoinOpen && this.props.currentTicket.status <= 3) {
            this.handleToogleModalBuyCoin();
        }
    }
    checkStatusText = (ticket) => {
        const { info } = this.props;
        let statusText = ticket.statusText;
        if (ticket.type === 1) { //buyer
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for sellers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "The invoice has not been uploaded yet";
                if (info.otc) {
                    statusText = "Waiting for the money transfer";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Waiting for the seller to confirm";
                if (info.otc) {
                    statusText = "Confirmation of receipt of money";
                }
            }
        } else {//seller
            if (ticket.status === 1) {//pending
                statusText = "The system is looking for buyers";
                if (info.otc) {
                    statusText = "Awaiting confirmation";
                }
            }
            if (ticket.status === 2) {//accepted
                statusText = "Waiting for the money transfer";
                if (info.otc) {
                    statusText = "The invoice has not been uploaded yet";
                }
            }
            if (ticket.status === 3) {//Transferred
                statusText = "Confirmation of receipt of money";
                if (info.otc) {
                    statusText = "Waiting for the seller to confirm";
                }
            }
        }
        return statusText;
    }
    render() {
        const { modalBuyCoinOpen, currentTicket, Coin } = this.props;
        const { isLoading, formData, errors, errReceipt, confirmData, confirmText, showConfirm, confirmTitle, modalConfirmTransferOpen } = this.state;
        return (
            <>
                <Modal toggle={this.handleToogleModalBuyCoin} isOpen={modalBuyCoinOpen} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal} backdrop="static">
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title flex-100">
                            {t("Buy ")} {Coin ? Coin.symbolName : "Coin"}
                        </h5>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="Amount">{t("Quantity")}</label>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled={currentTicket ? true : false}
                                        placeholder="0"
                                        type="number"
                                        autoComplete="off"
                                        name="AmountCoin" value={formData.AmountCoin} onChange={(e) => this.handleChangeAmount(e.target.value, formData.PriceVND)}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText className={currentTicket ? "btn-disable" : ""}>
                                            &nbsp;{Coin ? Coin.symbolName : "Coin"}&nbsp;
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AmountCoin && <div className="error">{errors.AmountCoin}</div>}
                            </FormGroup>
                            {/* <FormGroup >
                                <label>{t("Price")} VND/Coin</label>
                                <Input placeholder='0' type="text" value={formatNumber(formData.PriceVND)} disabled />
                            </FormGroup> */}
                            <FormGroup >
                                <label>{t("Amount to be transferred")} VND</label>
                                <InputGroup className="input-group">
                                    <Input placeholder='0' type="text" value={currentTicket ? formatNumber(currentTicket.amountVND) : formData.AmountVND} disabled />
                                    <InputGroupAddon addonType="append" title={t("Copy")} >
                                        <InputGroupText className="btn-disable" >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket ? formatNumber(currentTicket.amountVND) : formData.AmountVND)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                            {currentTicket && currentTicket.accNumberBank && <>
                                <FormGroup>
                                    <label htmlFor="AccNumberBank">{t("Transfer to account number")}</label>
                                    <InputGroup className="input-group">
                                        <Input
                                            disabled
                                            placeholder={`${t("Transfer to account number")}`}
                                            type="text"
                                            autoComplete="off"
                                            name="AccNumberBank" value={currentTicket.accNumberBank}
                                        />
                                        <InputGroupAddon addonType="append" title={t("Copy")} >
                                            <InputGroupText className="btn-disable" >
                                                <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.accNumberBank)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="CardholderNameBank">{t("Cardholder name")}</label>
                                    <InputGroup className="input-group">
                                        <Input
                                            disabled
                                            placeholder={`${t("Cardholder name")}`}
                                            type="text"
                                            autoComplete="off"
                                            name="CardholderNameBank" value={currentTicket.cardholderNameBank}
                                        />
                                        <InputGroupAddon addonType="append" title={t("Copy")} >
                                            <InputGroupText className="btn-disable">
                                                <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.cardholderNameBank)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label htmlFor="NameBank">{t("Bank name")}</label>
                                    <InputGroup className="input-group">
                                        <Input
                                            disabled
                                            placeholder={`${t("Bank name")}`}
                                            type="text"
                                            autoComplete="off"
                                            name="NameBank" value={currentTicket.nameBank}
                                        />
                                        <InputGroupAddon addonType="append" title={t("Copy")} >
                                            <InputGroupText className="btn-disable" >
                                                <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.nameBank)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </>}
                            {currentTicket && currentTicket.transferCode && <FormGroup>
                                <div className="d-flex align-items-end">
                                    <label htmlFor="transferCode">{t("Transfer content")}</label>
                                </div>
                                <InputGroup className="input-group">
                                    <Input
                                        disabled
                                        placeholder=""
                                        type="text"
                                        autoComplete="off"
                                        name="transferCode" value={currentTicket.transferCode}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Copy")} >
                                        <InputGroupText className="btn-disable" >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handleCopyClick(currentTicket.transferCode)}> <span className="btn-inner--text"><i className="fa fa-copy"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>}
                            {currentTicket && currentTicket.buySellName && <> <FormGroup>
                                <label htmlFor="Seller">{t("Seller")}</label>
                                <Input
                                    disabled={true}
                                    placeholder={`${t("Seller")}`}
                                    type="text"
                                    autoComplete="off"
                                    name="Seller" value={currentTicket.buySellName}
                                />
                            </FormGroup>
                            </>}

                        </Form>
                        <div className="bs-time text-center flex-1" >
                            {currentTicket && <div className="text-center">{t("Status")}: {t(this.checkStatusText(currentTicket))}  {currentTicket && currentTicket.receiptPath && <span title={t("Show receipt")} className="text-primary pointer" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                <i className="fa fa-image pointer"></i>
                            </span>}</div>}
                            {currentTicket && (currentTicket.status === 2 || currentTicket.status === 3) && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <div className="text-alert pt-1 text-center flex-1"> {t("Waiting Time")}: {(currentTicket.timeRemain.minutes > 9 ? currentTicket.timeRemain.minutes + "" : "0" + currentTicket.timeRemain.minutes) + ":" + (currentTicket.timeRemain.seconds > 9 ? currentTicket.timeRemain.seconds + "" : "0" + currentTicket.timeRemain.seconds)}</div>}
                        </div>
                        {/* {currentTicket && currentTicket.receiptPath && <div className="text-center">
                            <Button color="success" type="button" onClick={() => this.props.handleshowReceipt(currentTicket.receiptPath)}>
                                {t("Show receipt")}
                            </Button>
                        </div>} */}
                    </ModalBody>

                    {currentTicket && currentTicket.status < 3 && <> <ModalFooter>
                        {/*Pending*/}
                        {currentTicket.status === 1 &&
                            <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 3)}>
                                {t("Cancel")}
                            </Button>}
                        {/*Accept*/}
                        {currentTicket.status === 2 && currentTicket.timeRemain && currentTicket.timeRemain.total > 0 && <>
                            <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                {t("Upload receipt")}
                            </label></div>
                            <Input type="file" onChange={this.handleFileSelected} id="fileImage" className="file-input" accept="image/*" />
                            {/* <Button color="success" type="button" onClick={() => this.handleFileSelected(currentTicket)}>
                                    {t("Upload receipt")}
                                </Button> */}
                            <Button color="danger" type="button" onClick={() => this.handleOpenModal(currentTicket, 3)}>
                                {t("Cancel")}
                            </Button>
                        </>}
                    </ModalFooter> </>}
                    {currentTicket && currentTicket.status >= 5 && <> <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalBuyCoin}
                        >  {t("Close")}
                        </Button>
                    </ModalFooter> </>}
                    {!currentTicket && <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalBuyCoin}
                        >  {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.handleBuyCoin}>
                            {t("Buy")}
                        </Button>
                    </ModalFooter>}
                </Modal>
                {/* <Modal toggle={this.handleOpenModalConfirmTransfer} isOpen={modalConfirmTransferOpen} >
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Submit payment proof")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form">
                            <label className="text-label">
                                {t("Attention! Please upload proof of payment so the transaction can be processed in case the seller does not confirm. Otherwise, after 15 minutes the transaction will be automatically canceled")}
                            </label>
                            <FormGroup>
                                <div >{t("Please upload the paid invoice for this transaction to confirm your payment.")} </div>
                                <div className="text-center"> <label htmlFor="fileImage" className="file-label">
                                    {t("Choose Image")}
                                </label></div>
                                <Input type="file" onChange={this.handleFileSelected} id="fileImage" className="file-input" accept="image/*" />
                                {confirmData.ReceiptURL && <div className="text-center">
                                    <img
                                        className="img-receipt"
                                        alt={confirmData.ReceiptURL}
                                        title={confirmData.ReceiptURL}
                                        src={confirmData.ReceiptURL}
                                    />
                                </div>}
                                <div className="text-center"> {errReceipt && <div className="error">{errReceipt}</div>}</div>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.onToogleConfirmTransfer}
                        > {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.hanldeConfirmTransfer}>
                            {t("Submit")}
                        </Button>
                    </ModalFooter>
                </Modal> */}
                <ModalConfirm
                    message={confirmText}
                    title={confirmTitle}
                    onConfirm={() => this.handleConfirm()}
                    onCancel={this.handleCancel}
                    open={showConfirm}
                />
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(BuyCoin);
