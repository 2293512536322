import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import "assets/scss/outside.scss?v1.1.0";
import { withTranslation } from "react-i18next";
import withRouter from "../../components/common/withRouter";
import { postNoAuth, redirectToURL } from "services/apiServices";
import { toast, ToastContainer } from 'react-toastify';
import { t } from "i18next";
import i18n from "i18next";
import Loading from "../../components/Loading/Loading";
import configs from "configs/configs";
import { io } from "socket.io-client";
class Activation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 5,
      errorCode: -1,
      isLoading: false,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    };
    this.countdownInterval = null;
    this.socket = io(configs.SocketUM);
  }

  async componentDidMount() {
    localStorage.setItem("token", "")
    // Lắng nghe các sự kiện
    this.socket.on("connect", () => {
      console.log("UM Connected");
    });

    this.socket.on("UM", (msg) => {
      // console.log("Received message:", msg);
      if (msg.isUM) {
        localStorage.clear();
        redirectToURL("/um")
      }
    });
    this.setState({ isLoading: true });
    try {
      let params = {
        "ActiveCode": this.props.params.code
      };
      let response = await postNoAuth('/Users/Active', params);
      if (response.data.errorCode === 0) {

        this.setState({ isLoading: false });
        this.setState({ errorCode: response.data.errorCode })
        if (response.data.errorCode === 0) {
          this.countdownInterval = setInterval(this.countdown, 1000);
        }
      } else {
        this.setState({ isLoading: false });
        this.setState({ errorCode: response.data.errorCode })
        // toast.error(t(response.data.errorMessage), {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: 10000,
        // });
      }
    } catch (error) {
      let response = error.response;
      this.setState({ isLoading: false });
      this.setState({ errorCode: response.data.errorCode })
      // toast.error(t(response.data.errorMessage), {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 10000,
      // });
    }
  }
  componentWillUnmount() {
    // Khi component unmount, xóa bộ đếm ngược
    clearInterval(this.countdownInterval);
    // Ngắt kết nối khi component bị hủy
    this.socket.disconnect();

  }
  countdown = () => {
    this.setState((prevState) => ({
      count: prevState.count - 1,
    }), () => {
      // Khi count đạt đến 0, thực hiện redirect
      if (this.state.count === 0) {
        clearInterval(this.countdownInterval);
        // Thực hiện redirect đến URL khác
        redirectToURL('/e-login')
      }
    });
  };
  handleSubmit = (e) => {
    redirectToURL('/e-login')
  };
  changeLanguage = (lang) => {
    this.setState({ lang: lang });
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    window.location.reload();
  }
  render() {
    const { t, isLoading } = this.props;
    const { lang } = this.state;
    const lang_change = lang === "en" ? "vi" : "en";
    return (
      <>
        <main ref="main" className="outside">
          <section className="section section-shaped section-lg">
            <Container >
              <Row className="justify-content-center">
                <Col lg="6" className="flex-center-center" >
                  <Card className="bg-login text-center  shadow ">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-muted mb-4">
                        {/* <img
                          className="icon-lang absolute-lang pointer"
                          alt={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          title={lang_change === "vi" ? "Tiếng Việt" : "English"}
                          src={require(`assets/img/flags/${lang_change}.png`)}
                          onClick={() => this.changeLanguage(lang_change)}
                        /> */}
                        <div className="txt-title">{t("Activation")}</div>
                      </div>
                      {
                        this.state.errorCode === 0 &&
                        <>
                          <h4 className="text-warning">{t("Congratulations")}</h4>
                          <div>{t("Your account has been successfully created.")}</div>
                        </>
                      }
                      {
                        this.state.errorCode === 1 &&
                        <>
                          <h4 className="text-danger">{t("Something went wrong")}</h4>
                          <div>{t("Please check your information and try again.")}</div>
                        </>
                      }
                      <div>
                        <Button
                          className="button-outside mt-3"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          {t("Sign in")} {this.state.count > 0 && this.state.errorCode === 0 ? " (" + this.state.count + ")" : ""}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <ToastContainer pauseOnFocusLoss={false} />
        {isLoading && <Loading />}
      </>
    );
  }
}

export default withTranslation()(withRouter(Activation));
