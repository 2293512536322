import moment from 'moment';
import { t } from "i18next";
export function validateEmail(input) {
    // Biểu thức chính quy để kiểm tra địa chỉ Gmail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(input);
}
export function validateMaxLength(value, maxLength) {
    return value.length <= maxLength;
}

export function validateMinLength(value, maxLength) {
    return value.length >= maxLength;
}
export function formatNumber(n, max = 8, min = 0) {
    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return formatter.format(n);
}
export function cutNumber(amount, filter) {
    let flts = filter.toString().split(".");
    let fl_num = 0;
    if (flts.length > 1) {
        fl_num = filter.toString().split(".")[1].length;
    }
    let arr_num = amount.toString().split(".");//array num
    let num = arr_num[0];
    if (arr_num.length > 1) {
        let end_num = arr_num[1].substr(0, fl_num);
        num += "." + end_num;
    }

    return Number(num);
}
export function cutNumberFormat(amount, filter) {
    let flts = filter.toString().split(".");
    let fl_num = 0;
    if (flts.length > 1) {
        fl_num = filter.toString().split(".")[1].length;
    }
    if (amount) {
        let arr_num = amount.toString().split(".");//array num
        let num = formatNumber(Number(arr_num[0]));
        if (arr_num.length > 1) {
            let end_num = arr_num[1].substr(0, fl_num);
            num += "." + end_num;
        }
        return num;
    }

    return amount;


}
export function FormatNumberUSDT(number) {
    return cutNumberFormat(number, 0.01)
}
export function FormatNumberAmount(number) {
    return cutNumberFormat(number, 0.001)
}
export function FormatNumberAmount2(number) {
    return formatNumber(number, 3, 0)
}
export function FormatNumberNTC(number) {
    return cutNumberFormat(number, 0.000001)
}
export function FormatNumberCom(number) {
    return cutNumberFormat(number, 0.00000001)
}
export function formatDate(date) {
    if (date) {
        let _date = new Date(Date.parse(date));
        if (_date) {
            return moment.utc(date).format("DD/MM/YYYY HH:mm:ss")
        }
        return "N/A";
    }
    else {
        return "N/A";
    }
}
export function formatDate2(date) {
    if (date) {
        let _date = new Date(Date.parse(date));
        if (_date) {
            return moment.utc(date).format("DD/MM/YYYY")
        }
        return "N/A";
    }
    else {
        return "N/A";
    }
}
export function nextDays(date, numOfDay) {
    if (date) {
        let _date = moment(date, "DD/MM/YYYY").toDate();
        if (_date) {
            return moment(_date).add(numOfDay, 'days').format("DD/MM/YYYY");
        }
        return "N/A";
    }
    else {
        return "N/A";
    }
}
export function renderHash(parameters) {
    var postfix = '';

    for (var el in parameters) {
        var parameter = parameters[el];

        if (postfix)
            postfix += '&';

        postfix += encodeURIComponent(el);

        if (parameter)
            postfix += '=' + encodeURIComponent(parameter);
    }

    return '#' + postfix;
}
export function readUrlHashParams() {
    const result = {};
    const hashParam = window.location.hash.substring(1);
    const params = hashParam.split("&");

    params.forEach((set) => {
        const paramSet = set.split("=");
        const paramName = paramSet[0];
        const paramValue = paramSet[1] ? decodeURIComponent(paramSet[1]) : "";
        result[paramName] = paramValue;
    });

    return result;
}

export function calculateTimeRemaining(endTime) {
    const now = moment();
    const end = moment.utc(endTime);
    const total = end.diff(now);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((total % (1000 * 60)) / 1000);

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
}
export function calculateTimeRemaining2(endTime) {
    const now = moment();
    const total = endTime.diff(now);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((total % (1000 * 60)) / 1000);

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
}
export function calculateDayRemaining(endTime) {
    let _endtime = endTime.split('T')[0]
    const now = moment().startOf('day');
    const end = moment.utc(_endtime);
    const total = end.diff(now);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((total % (1000 * 60)) / 1000);

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
}
export function calculateDayRemaining2(date, remainDays = 0) {
    const now = moment(date, "DD/MM/YYYY");
    const end = moment.utc().add(remainDays, 'days').startOf('day');
    const total = end.diff(now);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((total % (1000 * 60)) / 1000);

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
}
export function hideCharacters(str) {
    if (str && str.length > 5) {
        return str.substr(0, 5) + '*****';//.repeat(str.length - 5);
    }
    return str;
}
export function renderString() {
    var arr = {
        // 
    }
    let string = "{"
    for (var props in arr) {
        string += `"` + props + `":"` + props + `",`;
    }
    string += "}";
    console.log(string);
}
export function renderMultipleLang() {
    let multiple_lang = [];
    let html_language_ar = "";
    let html_language_de = "";
    let html_language_en = "";
    let html_language_es = "";
    let html_language_fr = "";
    let html_language_hi = "";
    let html_language_it = "";
    let html_language_pt = "";
    let html_language_ru = "";
    let html_language_vi = "";
    let html_language_zh = "";
    let i = 0;
    for (var key in multiple_lang) {
        var val = multiple_lang[key];
        if (i === multiple_lang.length - 1) {
            html_language_ar += `"` + val.code + `":"` + val.ar + `" \n`;
            html_language_de += `"` + val.code + `":"` + val.de + `" \n`;
            html_language_en += `"` + val.code + `":"` + val.en + `" \n`;
            html_language_es += `"` + val.code + `":"` + val.es + `" \n`;
            html_language_fr += `"` + val.code + `":"` + val.fr + `" \n`;
            html_language_hi += `"` + val.code + `":"` + val.hi + `" \n`;
            html_language_it += `"` + val.code + `":"` + val.it + `" \n`;
            html_language_pt += `"` + val.code + `":"` + val.pt + `" \n`;
            html_language_ru += `"` + val.code + `":"` + val.ru + `" \n`;
            html_language_vi += `"` + val.code + `":"` + val.vi + `" \n`;
            html_language_zh += `"` + val.code + `":"` + val.zh + `" \n`;
        } else {
            html_language_ar += `"` + val.code + `":"` + val.ar + `", \n`;
            html_language_de += `"` + val.code + `":"` + val.de + `", \n`;
            html_language_en += `"` + val.code + `":"` + val.en + `", \n`;
            html_language_es += `"` + val.code + `":"` + val.es + `", \n`;
            html_language_fr += `"` + val.code + `":"` + val.fr + `", \n`;
            html_language_hi += `"` + val.code + `":"` + val.hi + `", \n`;
            html_language_it += `"` + val.code + `":"` + val.it + `", \n`;
            html_language_pt += `"` + val.code + `":"` + val.pt + `", \n`;
            html_language_ru += `"` + val.code + `":"` + val.ru + `", \n`;
            html_language_vi += `"` + val.code + `":"` + val.vi + `", \n`;
            html_language_zh += `"` + val.code + `":"` + val.zh + `", \n`;
        }

        i++;
    }
    console.log("AR");
    console.log(html_language_ar);

    console.log("DE");
    console.log(html_language_de);

    console.log("EN");
    console.log(html_language_en);

    console.log("ES");
    console.log(html_language_es);

    console.log("FR");
    console.log(html_language_fr);

    console.log("HI");
    console.log(html_language_hi);

    console.log("IT");
    console.log(html_language_it);

    console.log("PT");
    console.log(html_language_pt);

    console.log("RU");
    console.log(html_language_ru);

    console.log("VI");
    console.log(html_language_vi);

    console.log("ZH");
    console.log(html_language_zh);
}
export function pushParamsToQueue(params) {
    let list = JSON.parse(localStorage.getItem("paramsList"));
    // console.log("params", params);
    if (!list) {
        list = []
    }
    list.push(params);
    localStorage.setItem("paramsList", JSON.stringify(list));
}
export function getParamsFromQueue() {
    let list = JSON.parse(localStorage.getItem("paramsList"));
    // console.log("list", list);
    let params = null;
    if (list) {
        params = list.pop();
        localStorage.setItem("paramsList", JSON.stringify(list));
    }
    // console.log("back params", params);
    return params;
}
