import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading"


class RegisterOTC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                NameBank: '',
                AccNumberBank: '',
                CardholderNameBank: '',
            },
            errors: {
                NameBank: '',
                AccNumberBank: '',
                CardholderNameBank: '',
            },
        }
        this.inputRef = React.createRef();
    }
    clearForm = () => {
        let newerrors = {
            NameBank: '',
            AccNumberBank: '',
            CardholderNameBank: '',
        };
        let _data = {
            NameBank: '',
            AccNumberBank: '',
            CardholderNameBank: '',
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    handleSubmit = async (e) => {
        const { formData } = this.state;
        let formIsValid = true;
        const newErrors = {
            NameBank: '',
            AccNumberBank: '',
            CardholderNameBank: ''
        };
        if (!formData.NameBank) {
            formIsValid = false;
            newErrors.NameBank = t('Please enter the bank name.');
        }

        if (!formData.AccNumberBank) {
            formIsValid = false;
            newErrors.AccNumberBank = t('Please enter your account number.');
        }

        if (!formData.CardholderNameBank) {
            formIsValid = false;
            newErrors.CardholderNameBank = t("Please enter the cardholder's name.");
        }

        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                NameBank: formData.NameBank,
                AccNumberBank: formData.AccNumberBank,
                CardholderNameBank: formData.CardholderNameBank
            };
            this.props.handleRegisterOTC(params);
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };

    handlePasteToInput = (type) => {
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, NameBank: pastedText },
                        }));
                        this.handleCheckInternal(pastedText);
                    } else if (type === 2) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AccNumberBank: pastedText },
                        }));
                    } else if (type === 3) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, CardholderNameBank: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };
    handleToogleModalRegisterOTC = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalRegisterOTC();
    };

    handleLoadModal = () => {
        const { bankInfo } = this.props;
        let _data = {
            NameBank: bankInfo ? bankInfo.nameBank : "",
            AccNumberBank: bankInfo ? bankInfo.accNumberBank : "",
            CardholderNameBank: bankInfo ? bankInfo.cardholderNameBank : "",
        };
        this.setState({ formData: _data });
    }

    render() {
        const { modalRegisterOTCOpen } = this.props;
        const { isLoading, formData, errors } = this.state;

        return (
            <>
                <Modal toggle={this.handleToogleModalRegisterOTC} isOpen={modalRegisterOTCOpen} onOpened={this.handleLoadModal}>
                    <div className=" modal-header">
                        <h5 className=" modal-title">
                            {t("Register as OTC")}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close"
                            type="button"
                            onClick={this.handleToogleModalRegisterOTC}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <label htmlFor="NameBank">{t("Bank name")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Bank name")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="NameBank" value={formData.NameBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(1)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.NameBank && <div className="error">{errors.NameBank}</div>}
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="AccNumberBank">{t("Account number")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Account number")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="AccNumberBank" value={formData.AccNumberBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(2)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.AccNumberBank && <div className="error">{errors.AccNumberBank}</div>}
                            </FormGroup>
                            <FormGroup>
                                <label htmlFor="CardholderNameBank">{t("Cardholder name")}</label>
                                <InputGroup className="input-group">
                                    <Input
                                        placeholder={`${t("Cardholder name")}`}
                                        type="text"
                                        autoComplete="off"
                                        name="CardholderNameBank" value={formData.CardholderNameBank} onChange={this.handleChange} ref={this.inputRef}
                                    />
                                    <InputGroupAddon addonType="append" title={t("Paste")} >
                                        <InputGroupText >
                                            <span className="btn-no-border btn-sm pointer" onClick={() => this.handlePasteToInput(3)}> <span className="btn-inner--text"><i className="fa fa-paste"></i></span></span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.CardholderNameBank && <div className="error">{errors.CardholderNameBank}</div>}
                            </FormGroup>

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            type="button"
                            onClick={this.handleToogleModalRegisterOTC}
                        >                            {t("Close")}
                        </Button>
                        <Button color="primary" type="button" onClick={this.handleSubmit}>
                            {t("Submit")}
                        </Button>
                    </ModalFooter>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(RegisterOTC);
