import React from "react";
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    FormGroup,
    // Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import Select from 'react-select'
import Loading from "../../components/Loading/Loading"
import { post, checkToken } from "services/apiServices";
import { validateEmail, cutNumberFormat, FormatNumberAmount } from "services/common";
import QRCode from "react-qr-code";

const options = [
    { value: "TON", label: 'TON' },
    // { value: "BEP20", label: 'BNB Smart Chain (BEP20)' },
]

class Receive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                Network: null,
                AddressOrEmail: '',
                Amount: 0,
                ReceiveAmount: "",
                FeeAmount: 0,
            },
            errors: {
                Network: '',
                AddressOrEmail: '',
                Amount: '',
                FeeAmount: '',
            },
            intervalFee: null,
            IsInternalTransfer: false,
            hasWallet: true,
            CurrentAddress: '',
            Network: '',
            count: 0,
            isDisable: false,

        }
        this.inputRef = React.createRef();
        this.countdownInterval = null;
    }
    componentWillUnmount() {
        // Khi component unmount, xóa bộ đếm ngược
        clearInterval(this.countdownInterval);
        // Ngắt kết nối khi component bị hủy    
    }
    clearForm = () => {
        let newerrors = {
            Network: '',
            AddressOrEmail: '',
            Amount: '',
            FeeAmount: '',
        };
        let _data = {
            Network: null,
            AddressOrEmail: '',
            Amount: 0,
            ReceiveAmount: "",
            FeeAmount: 0,
        };
        this.setState({ formData: _data });
        this.setState({ errors: newerrors });
    };

    hanldeReceive = async (e) => {
        const { formData, IsInternalTransfer } = this.state;
        let formIsValid = true;
        const newErrors = {
            Network: '',
            AddressOrEmail: '',
            Amount: '',
            FeeAmount: '',
        };
        if (!formData.AddressOrEmail) {
            formIsValid = false;
            newErrors.AddressOrEmail = t('Please enter your Address.');
        }
        if (!formData.Amount) {
            formIsValid = false;
            newErrors.ToAddress = t('Please enter your Withdraw Amount.');
        }
        if (formData.Amount <= 0) {
            formIsValid = false;
            newErrors.Amount = t('Input value must be greater than 0.');
        }
        // Kiểm tra điều kiện khác tùy thuộc vào yêu cầu của bạn
        if (!formIsValid) {
            this.setState({ errors: newErrors });
        } else {
            this.setState({ errors: newErrors });
            // Submit form nếu hợp lệ
            let params = {
                Symbol: formData.Network ? formData.Network.value : "TON",
                AddressOrEmail: formData.AddressOrEmail,
                Amount: Number(formData.Amount),
                FeeAmount: formData.FeeAmount,
            };
            this.props.hanldeReceive(params);
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: { ...prevState.formData, [name]: value },
        }));
    };

    handleCopyClick = (text) => {
        try {
            navigator.clipboard.writeText(text);
            toast.success(t("Copy successfully!"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
        this.handleRequestDeposit();
    };

    handlePasteToInput = (type) => {
        if (this.inputRef.current) {
            navigator.clipboard.readText()
                .then((pastedText) => {
                    if (type === 1) {
                        this.setState((prevState) => ({
                            formData: { ...prevState.formData, AddressOrEmail: pastedText },
                        }));
                    }
                })
                .catch((error) => {
                    console.log('Error reading clipboard data:', error);
                });
        }
    };

    handleGetFee = async (network) => {
        try {
            let response = await post('/Users/GetFeeWithdrawBySymbol', { Symbol: network });
            if (response.data.errorCode === 0) {
                this.setState((prevState) => ({
                    formData: { ...prevState.formData, FeeAmount: response.data.data.fee },
                }));
                this.handleChangeAmount(this.state.formData.Amount, response.data.data.fee);
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    };

    handleToogleModalReceive = () => {
        // Xử lý khi người dùng chọn Close
        this.props.onToogleModalReceive();
    };

    handleFillMaxAmount = () => {

        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: this.props.BalanceCoin ? this.props.BalanceCoin : 0 },
        }));
        this.handleChangeAmount(this.props.BalanceCoin, this.state.formData.FeeAmount);
    };
    handleSelectChange = (e) => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Network: e },
        }));
    };
    checkValidateAddress = (address) => {
        if (/^(T)([a-km-zA-HJ-NP-Z0-9]){33}$/.test(String(address))) {
            return "TRC20";
        }
        else if (/^0x[0-9a-fA-F]{40}$/.test(String(address))) {
            return "BEP20";
        }
        return "";
    }
    handleCheckAddress = (value, IsInternalTransfer) => {
        const { intervalFee, formData } = this.state;

        this.setState((prevState) => ({
            formData: { ...prevState.formData, AddressOrEmail: value },
        }));
        let network = this.checkValidateAddress(value);
        let select_option = options.find(x => x.value === network);
        if (select_option) {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: select_option },
            }));
        } else {
            this.setState((prevState) => ({
                formData: { ...prevState.formData, Network: null },
            }));
        }
        if (!IsInternalTransfer) {
            if (select_option) {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.handleGetFee(network);
                let _intervalFee = setInterval(() => {
                    this.handleGetFee(network);
                }, 5000);
                this.setState({ intervalFee: _intervalFee });
            } else {
                if (intervalFee) {
                    clearInterval(intervalFee);
                }
                this.setState((prevState) => ({
                    formData: { ...prevState.formData, FeeAmount: 0 },
                }));
                this.handleChangeAmount(formData.Amount, 0)
            }
        } else {
            if (intervalFee) {
                clearInterval(intervalFee);
            }
            this.setState((prevState) => ({
                formData: { ...prevState.formData, FeeAmount: 0 },
            }));
            this.handleChangeAmount(formData.Amount, 0)
        }
        let errors = ""
        if (!formData.AddressOrEmail) {
            errors = t('Please enter your Address.');
        }
        if (!validateEmail(formData.AddressOrEmail) && !select_option) {
            errors = t('Please enter your address or a valid email.');
        }
        if (!IsInternalTransfer && validateEmail(formData.AddressOrEmail)) {
            errors = t('Email does not exist in the system.');
        }
        this.setState((prevState) => ({
            errors: { ...prevState.errors, AddressOrEmail: errors },
        }));
    };
    handleChangeAmount = (value, fee) => {
        this.setState((prevState) => ({
            formData: { ...prevState.formData, Amount: value },
        }));
        let amountReceive = cutNumberFormat(value - fee, 0.01);
        this.setState((prevState) => ({
            formData: { ...prevState.formData, ReceiveAmount: amountReceive },
        }));
    };
    handleCheckInternal = async (value) => {
        try {
            let response = await post('/Users/CheckInternalTransfer', { AddressOrEmail: value });
            if (response.data.errorCode === 0) {
                this.setState({ IsInternalTransfer: response.data.data.isInternalTransfer });
                this.handleCheckAddress(value, response.data.data.isInternalTransfer);
            } else {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false });
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    handleLoadModal = () => {
        this.clearForm()
    }
    handleCloseModal = () => {
        const { intervalFee } = this.state;
        if (intervalFee) {
            clearInterval(intervalFee);
        }
    }
    shortTenAddress = (address) => {
        let shortenedAddress = address;
        if (address && address.length > 8) {
            shortenedAddress = address.replace(/^(.{10}).*(.{10})$/, '$1......$2');
        }
        return shortenedAddress;
    }
    handleRequestDeposit = async () => {
        if (this.state.isDisable) {
            return;
        }
        let param = {
            address: this.props.CurrentAddress
        }
        this.setState({ isLoading: false, isDisable: true });
        try {
            let response = await post('/Users/RequestDeposit', param);
            if (response.data.errorCode === 0) {
                toast.success(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
                this.setState({ count: 5 });
                this.countdownInterval = setInterval(this.countdown, 1000);
            } else {
                this.setState({ isLoading: false, isDisable: false });//, isDisable: false 
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            }
        } catch (error) {
            this.setState({ isLoading: false, isDisable: false });//, isDisable: false
            let response = error.response;
            if (response && response.data) {
                toast.error(t(response.data.errorMessage), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    onOpen: () => checkToken(response.data)
                });
            } else {
                checkToken(response)
            }
        }
    }
    countdown = () => {
        this.setState((prevState) => ({
            count: prevState.count - 1,
        }), () => {
            // Khi count đạt đến 0, thực hiện redirect
            if (this.state.count === 0) {
                clearInterval(this.countdownInterval);
                this.setState({ isDisable: false });
                // Thực hiện redirect đến URL khác
                // redirectToURL('/login')
            }
        });
    };
    render() {
        const { modalReceive, Coin, hasWallet, CurrentAddress } = this.props;
        const { isLoading, formData, errors, isDisable, count } = this.state;

        return (
            <>
                <Modal toggle={this.handleToogleModalReceive} isOpen={modalReceive} onOpened={this.handleLoadModal} onClosed={this.handleCloseModal}>
                    <div className=" modal-header align-items-center">
                        <h5 className=" modal-title">
                            {t("Receive")} {Coin ? Coin.symbolName : "Coin"}
                        </h5>
                        <button
                            aria-label={t("Close")}
                            className=" close  ml-1"
                            type="button"
                            onClick={this.handleToogleModalReceive}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody className="body-modal-1">
                        <Form role="form" onSubmit={this.handleSubmit}>
                            {hasWallet && <>
                                <FormGroup className="form-qr">
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "75%", width: "75%", textAlign: "center", margin: "0 auto", display: "block", padding: "1px"}}                                            
                                            value={CurrentAddress}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    {this.props.Coin && this.props.Coin.symbol === "BIT" &&
                                        <div className="text-qr mt-3 text-center">{t("Scan QR with camera to send")} {Coin ? Coin.symbolName : "Coin"} {t("to your Wallet.")}</div>
                                    }
                                    {this.props.Coin && this.props.Coin.symbol !== "BIT" && 
                                    <>
                                        <div className="form-request text-center">
                                            <label className="label-wallet-2">{t("After depositing, please click here to request priority transaction processing.")}</label>
                                            <Button block={isDisable} className="button-copy text-center mt-3" onClick={() => this.handleRequestDeposit()}>{t("Request now!")} {isDisable && count > 0 && <span>({count})</span>}</Button>
                                        </div>
                                    </>}
                                </FormGroup>
                                <FormGroup className="form-address text-center">
                                <label className="label-wallet-2">{t('textAddress', { coin: Coin ? Coin.symbolName : "Coin" })}</label>                                    
                                <div className="text-address text-center">{CurrentAddress}</div>
                                    <div className="button-copy text-center mt-3" onClick={() => this.handleCopyClick(CurrentAddress)}>{t("Copy Address")}</div>
                                </FormGroup>
                            </>}
                            {!hasWallet && <div className="text-alert">{t("No wallet information on")} {Coin ? Coin.symbol : ""}.</div>}
                        </Form>
                    </ModalBody>
                </Modal>
                {isLoading && <Loading />}
            </>
        );
    }
}
export default withTranslation()(Receive);
